import React from 'react';
import happy from '../../static/images/happyParrot.png'
import { useTranslation } from "react-i18next";
export default function AlertForSetAvailability({ open, onClose, forCourse, forAvailability }) {
    const { t } = useTranslation();
    return (
        <>{
            open &&
            <div className="popup-modal h-100 all-center">
                <div className="popup-modal-body sm p-fix " style={{ bottom: '20px', left: '20px' }}>
                    <div className='my-3'>
                        <img className='d-block mx-auto' style={{ width: '120px', height: '120px' }} src={happy} alt="parrot" />
                    </div>
                    {forAvailability && <p className='ms-900 text-center'>{'Kindly set your availability to get class bookings and request for Arabic learning tutions.'}</p>}
                    {forCourse && <p className='ms-500 text-center mt-2 mb-4 px-3'>Note*: You have to enroll yourself in SayDhad Courses for taking tutions.</p>}
                    <button className='btn btn-login ms-700 fs-14 mb-3 mx-auto d-block' style={{ width: '200px' }} onClick={onClose}>{t('button.okay')}</button>
                </div>
            </div>
        }
        </>
    );
}
