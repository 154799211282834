import React, { useEffect, useRef } from 'react'
import fb from '../config/firebaseAuth';
import helper from '../helper/index';
import { ref, push, onChildAdded, set, increment } from "firebase/database";
import moment from 'moment';
import { FaTelegramPlane } from 'react-icons/fa';
const db = fb.database
let prevDate = "Today"
let condition = 0
let rootRoomid = 0
let unsubscribe = ""

const adduserDiv = (message, time) => {
    try {
        const userDiv = document.getElementById("chat-body")
        if (condition === 0 || (prevDate.toString() !== moment(time).format('MMMM DD, YYYY').toString())) {
            prevDate = moment(time).format('MMMM DD, YYYY')
            const chatDayBox = document.createElement('div')
            const chatDay = document.createElement('div')
            chatDayBox.classList.add("chat-day-box")
            chatDay.classList.add("chat-day")
            chatDayBox.append(chatDay)
            chatDay.innerHTML = moment(time).format('MMMM DD, YYYY')
            userDiv.append(chatDayBox)
            condition = 1;
        }

        const ob = document.createElement('div')
        const chatDivContainer = document.createElement('div')
        const chatbox = document.createElement('div')
        const timeDiv = document.createElement('div')
        const clearDiv = document.createElement('div')
        chatDivContainer.classList.add("user-chat-box-container")
        chatbox.classList.add("user-chat-box-body")
        timeDiv.classList.add("chat-time")
        chatDivContainer.append(chatbox)
        chatDivContainer.append(timeDiv)
        chatbox.innerHTML = message
        timeDiv.innerHTML = moment(time).format('hh : mm a')
        clearDiv.classList.add("clear")
        ob.classList.add("p-rel")
        ob.append(chatDivContainer)
        ob.append(clearDiv)
        userDiv.append(ob)
        userDiv.scrollTop = userDiv.scrollHeight;
    }
    catch (err) {

    }
}
const addRemoteDiv = (message, time) => {
    try {
        const remoteDiv = document.getElementById("chat-body")
        if (condition === 0 || (prevDate.toString() !== moment(time).format('MMMM DD, YYYY').toString())) {
            prevDate = moment(time).format('MMMM DD, YYYY')
            const chatDayBox = document.createElement('div')
            const chatDay = document.createElement('div')
            chatDayBox.classList.add("chat-day-box")
            chatDay.classList.add("chat-day")
            chatDayBox.append(chatDay)
            chatDay.innerHTML = moment(time).format('MMMM DD, YYYY')
            remoteDiv.append(chatDayBox)
            condition = 1;
        }

        const ob = document.createElement('div')
        const chatDiv = document.createElement('div')
        const clearDiv = document.createElement('div')
        chatDiv.innerHTML = message
        chatDiv.classList.add("remote-chat-box")
        
        const timeDiv = document.createElement('div')
        timeDiv.classList.add("chat-time","pl-3")
        timeDiv.style.clear = "both"
        timeDiv.innerHTML = moment(time).format('hh : mm a')

        clearDiv.classList.add("clear")
        ob.classList.add("p-rel")
        ob.append(chatDiv)
        ob.append(timeDiv)
        ob.append(clearDiv)
        remoteDiv.append(ob)
        remoteDiv.scrollTop = remoteDiv.scrollHeight;

    }
    catch (err) {

    }
}
const enable = () => {
    unsubscribe = onChildAdded(ref(db, `supportChat/${rootRoomid}/messages`), (data) => {
        const val = data.val()
        if (parseInt(val.createdBy) === parseInt(helper.getid())) {
            adduserDiv(val.message, val.createdAt)
        }
        else {
            addRemoteDiv(val.message, val.createdAt)
        }
    })

}


export default function SupportChat({ myId, roomId, userName }) {
    let msg = useRef()
    useEffect(() => {
        try {
            const userDiv = document.getElementById("chat-body")
            userDiv.innerHTML = ""
            condition = 0
            rootRoomid = roomId
        } catch { }
        enable()
        return () => {
            try {
                unsubscribe()
            }
            catch { }
        }
    }, [roomId])



    const pushMessages = async () => {
        const date = new Date()
        if (msg.current.value) {
            push(ref(db, `supportChat/${roomId}/messages`), {
                message: msg.current.value,
                createdBy: myId,
                createdAt: moment(date).format('YYYY-MM-DD hh:mm:ss A')
            });
            set(ref(db, `supportChat/${roomId}/count`), {
                user: 0,
                admin: increment(1)
            })
            msg.current.value = ""
        }

    }

    return (
        <div className="chat-box px-3 mx-auto ltr" style={{ maxWidth: '500px', minWidth: '350px' }}>
            <div className="chat-header py-3 px-2 br-1">
                <p className="ms-900">Chatting with {userName ? userName : "Anonymous"}</p>
            </div>
            <div id="chat-body" className="chat-room-body chat-body">

            </div>

            <section className='pb-2 chat-footer-container'>
                < form onSubmit={(e) => { e.preventDefault(); pushMessages() }}>
                    <div className="chat-footer  py-2 px-2 d-flex mx-2 align-items-center">
                        <input type="text" ref={msg} placeholder="Type a message here ..." className="chat-input-box w-100 h-100 ms-700" />
                        <button type="submit" className="btn chat-send pointer br-1 all-center"><FaTelegramPlane className='fs-30 text-white' /></button>
                    </div>
                </form >
            </section>
        </div>
    )
}
