import * as React from "react";

const SvgChat = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <clipPath id="Chat_svg__a">
        <path
          data-name="Rectangle 436"
          transform="translate(9272 4324)"
          fill="#fff"
          stroke="#707070"
          d="M0 0h30v30H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 37"
      transform="translate(-9272 -4324)"
      clipPath="url(#Chat_svg__a)"
    >
      <g data-name="Iconly/Bold/Chat">
        <g data-name="Chat">
          <path
            data-name="Path 4391"
            d="M9287.024 4327a12 12 0 1 1-5.924 22.438 2.04 2.04 0 0 0-1.788-.433l-2.424.721a.749.749 0 0 1-.988-.937l.8-2.689a1.258 1.258 0 0 0-.084-1.082 12.705 12.705 0 0 1-1.62-6 12.008 12.008 0 0 1 12.028-12.018Zm5.484 10.492a1.538 1.538 0 1 0 1.536 1.538 1.532 1.532 0 0 0-1.536-1.538Zm-5.532 0a1.53 1.53 0 0 0-1.536 1.526 1.536 1.536 0 1 0 3.072.012 1.532 1.532 0 0 0-1.536-1.538Zm-5.532 0a1.532 1.532 0 0 0-1.536 1.538 1.536 1.536 0 1 0 3.072 0 1.532 1.532 0 0 0-1.536-1.538Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgChat;
