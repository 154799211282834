/* eslint-disable */
import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useSelector } from 'react-redux'
import moment from 'moment'
export default function ApexChart({ data, week }) {
  const storeData = useSelector(state => state)
  const [chartData, setchartData] = useState(
    {

      series: [{
        name: 'Classes/week',
        data: data
      }],
      options: {
        chart: {
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        yaxis: {
          title: {
            text: 'Classess'
          }
        },
        xaxis: {
          title: {
            text: `Week (${moment(storeData.calendarDate).format('MMM YYYY')})`
          },
          categories: week
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
      },
    }
  )
  return (
    <div className='w-100'>
      <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={250} />
    </div>
  )
}
