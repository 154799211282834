import React from 'react';
export default function ProgressBar({ width, height, bg, now, total }) {

    return (
        <>
            <div className='progress-body br-1' style={{ width: width, height: height, backgroundColor: 'whitesmoke' }}>
                <div className='progress-bar p-rel br-1 h-100'
                    style={{
                        backgroundColor: bg,
                        width: `${(now * 100) / total}%`
                    }}>
                </div>

            </div>
        </>
    )
}
