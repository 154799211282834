import React, { useState } from 'react'
import ReactStars from 'react-rating-stars-component'
import user from '../controller/user'
import helper from '../helper'
import AlertPopUp from './AlertPopUp'
import avatar from '../static/images/avatar.png'
import { useTranslation } from "react-i18next";

export default function RateTutor({ open, onClose, data, cb }) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        rate: 0,
        comment: '',
        error: ''
    })
    const [alert, setAlert] = useState({
        open: false,
        msg: '',
        type: 'success',
    })


    const submit = async () => {
        if (state.rate === 0) {
            setState({ ...state, error: t('messages.rate.selectRate') })
            return
        }
        else if (!state.comment) {
            setState({ ...state, error: t('messages.rate.writeReview') })
            return
        }
        const reqData = {
            ratings: state.rate,
            review: state.comment,
            tutorId: data.tutorId,
            studentId: helper.getid()
        }

        await user.giveRating(reqData)
            .then((res) => {
                if (res.data.status) {
                    setAlert({ ...alert, open: true, type: 'ok', msg: t('messages.rate.ratingSubmitted') })
                }
                else {
                    setAlert({ ...alert, open: true, type: 'fail', msg: t('messages.rate.scheduleClassRate') })
                }
            })

    }
    return (
        <>
            {open &&
                <div className="popup-modal h-100 all-center">
                    <div className="popup-modal-body sm">
                        <div className="col-12 d-flex justify-content-end">
                            <i className='fas fa-times-circle hover-shadow tx-grey fs-30 pointer mr-3  my-2' onClick={onClose}></i>
                        </div>
                        <section className='all-center'>
                            <div className='my-3 row px-4' style={{ maxWidth: '400px' }}>

                                <div className="col-10 d-flex">
                                    <div className=" d-flex align-items-center mx-2 p-rel">
                                        <img width={'50px'} height={'50px'} className='rounded-circle' src={data.profilePicture ? data.profilePicture : avatar} alt="profile" />
                                        <div className={`${data.isOnline ? "online-tag" : "offline-tag"} p-abs`} style={{ bottom: '0px', right: '0px' }}></div>
                                    </div>
                                    <div className="">
                                        <p className='f-fred fs-14 lh-15 tx-lt-black'>{data.name}</p>
                                        <p className='ms-500 fs-12 tx-lt-black'>{data.from}</p>
                                        <div className='d-flex align-items-center' style={{ lineHeight: '15px' }}>
                                            <ReactStars
                                                edit={false}
                                                value={data.ratings ? parseFloat(data.ratings).toFixed(1) : 0}
                                                count={5}
                                                size={20}
                                                isHalf={true}
                                                emptyIcon={<i className="far fa-star"></i>}
                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                fullIcon={<i className="fa fa-star"></i>}
                                                classNames="my-0 py-0"
                                                activeColor="#ffcb00"
                                            />
                                            <span className="tx-lt-grey ms-700 mt-1 fs-14 ml-2">{data.ratings ? parseFloat(data.ratings).toFixed(1) : 0}</span>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-2 all-center">
                                    <img height={'25px'} width={'25px'} className='rounded-circle' src={data.flag_uri} alt="flag" />
                                </div>
                                <div className="col-12 border-dot mt-3 my-2"></div>
                                <div className="col-12">
                                    <h4 className='mt-2 f-fred '>{t('messages.rate.rateThe')} <span className='tx-green'>{t('auth.tutor')}</span></h4>
                                    <ReactStars
                                        onChange={(rate) => setState({ ...state, rate: rate })}
                                        count={5}
                                        size={30}
                                        classNames="my-0 py-0"
                                        activeColor="#ffcb00"
                                        isHalf={true}
                                        emptyIcon={<i className="far fa-star"></i>}
                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                        fullIcon={<i className="fa fa-star"></i>}
                                    />
                                    <div className='my-2 tutor-dash' style={{ height: 'initial' }}>
                                        <textarea cols="30" rows="5" placeholder={t('messages.rate.write')} className='input-box w-100 p-2 ms-700' onChange={(e) => setState({ ...state, comment: e.target.value })} style={{ height: 'initial' }}></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <p className='tx-error my-2 ms-500 text-center'>{state.error}</p>
                                    <button className='btn btn-tutor-profile mb-3 mx-auto d-block' style={{ width: '200px' }} onClick={() => submit()}>{t('button.submitRating')}</button>

                                </div>
                            </div>

                        </section>
                    </div>

                    <AlertPopUp
                        open={alert.open}
                        type={alert.type}
                        msg={alert.msg}
                        onClose={() => { cb(); setAlert({ ...alert, open: false }); }}
                    />
                </div>
            }
        </>
    )
}
