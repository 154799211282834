/* eslint-disable */
import React, { useState, useEffect } from 'react'
import user from '../controller/user'
import Backdrop from '../component/Backdrop'
import UserSyllabusCard from '../component/UserSyllabusCard'
import UserCourseCallpopup from '../component/UserCourseCallpopup'
import helper from '../helper'
import AlertPopUp from '../component/AlertPopUp'
import { useHistory, useParams } from 'react-router-dom'
import ReadMore from '../component/ReadMore'
import CourseCertificate from './CourseCertificate'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
function UserCoursesDetails() {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const { id } = useParams()
    const history = useHistory()
    const [pageload, setPageload] = useState(true)
    const [resData, setResData] = useState({})
    const [shedule, setShedule] = useState(false)
    const [state, setState] = useState({
        openConfirm: false,
        openAlert: false,
        type: 'ok',
        msg: ""
    })

    useEffect(() => {
        apiCall()
        return () => { }
         //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const apiCall = async () => {
        await user.getCourseById(id, helper.getid())
            .then((res) => {
                if (res.data.result) {
                    setResData(res.data.result[0].course[0])
                }
                setPageload(false)
            })
    }

    const enrolledInCourse = async () => {
        const reqData = {
            student_id: helper.getid(),
            course_id: id
        }

        setState({ ...state, openConfirm: false })

        setPageload(true)
        user.enrolledIntoCourse(reqData).then((res) => {
            if (res.data.status) {
                setState({ ...state, openConfirm: false, openAlert: true, type: 'ok', msg: t('messages.course.enrolled') })
                apiCall()
            } else {
                setState({ ...state, openConfirm: false, openAlert: true, type: 'fail', msg: res.data.error })

            }
            setPageload(false)
        })
    }

    return (
        <>
            {
                !pageload ?
                    <div className={`user-course-details ${reduxState.language === 'ar' && "text-right"}`}>
                        <button className="btn back-btn py-1 fs-14 ls f-fred px-3 mr-3" onClick={() => history.goBack()}>{t('general.back')}</button>
                        <section className="row gx-0 mx-0 mt-2">
                            <div className="col-lg-3 col-md-4 col-6 px-1 my-2">
                                <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="fs-18 ms-900 lh-15">{t('general.total')}</p>
                                        <p className="sub-heading">{t('general.courses.lesson')}</p>
                                    </div>
                                    <p className="ms-500 fs-40 tx-green">{resData?.totalLessons}</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6 px-1 my-2">
                                <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="fs-18 ms-900 lh-15">{t('general.total')}</p>
                                        <p className="sub-heading">{t('general.courses.slides')}</p>
                                    </div>
                                    <p className="ms-500 fs-40 tx-green">{resData?.totalSlide}</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-12 px-1 my-2">
                                <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="fs-18 ms-900 lh-15">{t('general.courses.totalEnrolled')}</p>
                                        <p className="sub-heading">{t('general.student')}</p>
                                    </div>
                                    <p className="ms-500 fs-40 tx-green">{resData?.totEnrolledStudent}</p>
                                </div>
                            </div>
                        </section>
                        <div className="row tx-lt-grey">
                            <div className="col-lg-7 col-md-8 col-12 mb-2">
                                <p className="f-fred text-capitalize mt-2  fs-24">{resData?.title}</p>
                                <p className="ms-500 fs-14 text-capitalize">{resData?.subTitle}</p>
                            </div>
                            <div className="col-lg-5 col-md-4 col-12 mb-2  d-flex justify-content-lg-end justify-content-md-end align-items-center">
                                {resData?.enrolled_courses ? <button className="btn btn-login  fs-14  wrap" onClick={() => history.push(`/user/dashboard/tutor-by-course/${resData?.title}`)}>{t('general.startLesson')}</button>
                                    :
                                    <button className="btn btn-login py-2 fs-14  wrap" style={{ width: '150px' }} onClick={() => {
                                        if (helper.getPlanDetails().isPurchase) {
                                            enrolledInCourse()
                                        } else {
                                            history.push('/user/dashboard/subscription')
                                        }
                                    }}>{t('general.enroll')}</button>}
                            </div>
                        </div>
                        <div className="border-dot my-2 mb-3"></div>
                        <section className="row gx-0 mx-0 fs-14  ms-500 text-capitalize">
                            <div className="col-lg-2 col-md-4 col-6 mb-2">
                                <p className="ms-900  mb-1">{t('general.courses.expLevel')} </p>
                                <span className="px-2 py-1 b-grey mt-1 rounded w-100"> {resData?.experienceLevel}</span>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 mb-2">
                                <p className="ms-900 mb-1">{t('general.courses.courseLength')}</p>
                                <span className="px-2 py-1 b-grey mt-1 rounded w-100"> {resData?.totalLessons} {t('tutorDash.library.lessons')}</span>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 mb-2">
                                <p className="ms-900  mb-1">{t('general.courses.slides')}</p>
                                <span className="px-2 py-1 b-grey mt-1 rounded w-100"> {resData?.totalSlide} {t('tutorDash.library.slides')}</span>
                            </div>
                        </section>
                        <p className="my-3 f-fred fs-24" style={{ color: '#C3C3C3' }}>{t('general.courses.overview')}</p>


                        <section className="row gx-0 mx-0">
                            <div className="col-lg-4 mb-2 px-1">
                            <p className="ms-900 fs-14">{t('tutorDash.library.whatLearn')}</p>
                                <ReadMore
                                    text={resData?.description}
                                    initialNoOfWords={30}
                                />

                            </div>
                            <div className="col-lg-4 mb-2 px-1">
                            <p className="ms-900 fs-14">{t('tutorDash.library.whyCourse')}</p>
                                <ReadMore
                                    text={resData?.descriptionCourse}
                                    initialNoOfWords={30}
                                />
                            </div>

                        </section>
                        <div className="border-dot mt-2 mb-3"></div>
                        <p className="my-3 f-fred fs-24" style={{ color: '#C3C3C3' }}>{t('general.courses.Syllabus')}</p>

                        <section className="row gx-0 mx-0 ">
                            {
                                resData?.syllabusList.map((syllabus, index) => {
                                    return (
                                        <div key={`syllabus${index}`} className="col-lg-6 col-md-6 col-12 pr-2 my-2">
                                            <UserSyllabusCard
                                                syllabusId={syllabus.id}
                                                name={syllabus.name}
                                                slides={syllabus.noOfSlides}
                                                lessonNo={index + 1}
                                                id={id}
                                                data={syllabus}
                                                isEnrolled={resData?.enrolled_courses}
                                                previousCompletedSlide={index != 0 ? resData?.syllabusList[index - 1].total_completed_slide : 0}
                                                previousTotalSlide={index != 0 ? resData?.syllabusList[index - 1].noOfSlides : 0}
                                            />
                                        </div>
                                    )
                                })
                            }

                        </section>


                        <section className="mt-2 ">
                            <h4 className="mt-3 f-fred  mb-2" style={{ color: '#C3C3C3' }}>{t('general.courses.certificate')}</h4>
                            <CourseCertificate
                                title={resData?.title}
                                courseId={resData?.id}
                                isDownload={resData?.total_slide_complete === resData?.totalSlide}
                            />
                        </section>


                    </div> : <Backdrop />}


            <AlertPopUp
                open={state.openAlert}
                msg={state.msg}
                type={state.type}
                onClose={() => { setState({ ...state, openAlert: false, openConfirm: false }) }}
            />
            {shedule && <UserCourseCallpopup courseId={id} close={() => setShedule(false)} />}
        </>
    )
}

export default UserCoursesDetails
