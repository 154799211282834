/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import logo from '../static/images/Logo.svg'
import auth from '../controller/auth'
import helper from '../helper/index'
import Backdrop from '../component/Backdrop'
import firebase from '../config/firebaseAuth';
import { Link, NavLink } from 'react-router-dom'
import googleImage from "../static/images/Group 587.png";
import facebookImage from "../static/images/Group 586.png";
import quoteIcon from '../static/images/quote.svg'
import ToggleHidePassword from '../component/ToggleHidePassword'
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet'
import LanguageTranslator from '../component/LanguageTranslator'

export default function UserLogin() {
    const { t } = useTranslation();
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const resp = helper.isLoggedIn()
        if (resp.res && resp.role === "tutor") {
            const status = helper.getProfileStatus();
            if (status === "Approved") {
                history.push(`/tutor/dashboard/home`)
            }
            else {
                history.push(`/tutor/signupcheck`);
            }
        }
        else if (resp.res && resp.role === "user") {
            history.push(`/user/dashboard/home`)
        }

        const url = new URLSearchParams(window.location.search)

        if (url.get('token')) {
            try {

                let decEmail = helper.decrypt(url.get("token").toString().replace(/ /g, "+"))
                if (decEmail) setEmail(decEmail);
            } catch { }
        }


        return () => {
            setOpen(false); setPassword(); setEmail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [])

    const submit = () => {
        const data = { email: email ? email.toLowerCase() : email, password: password }
        let resp;
        resp = helper.validate("email", email);
        if (!resp.isValid) {
            helper.showMessage(resp.msg, "error")
            return;
        }
        if (!password) {
            helper.showMessage("please enter password", "error")
            return;
        }
        setOpen(true)
        auth.userLogin(data)
            .then((res) => {
                if (res.data.status) {
                    helper.saveCredential(
                        res.data.result.token,
                        "user", res.data.result.id,
                        "Approved",
                        res.data.result.email,
                        res.data.result.name
                    )
                    history.push(`/user/dashboard/home`)
                }
                else {
                    if (res.data.error === "Please verify the account") {
                        const encrEmail = helper.encrypt(email)
                        history.push(`/user/verify-otp?tk1=${encrEmail}`)
                    }
                    else {
                        helper.showMessage(res.data.error, "error")
                    }

                }
                setOpen(false)
            })
    }




    const signInWithGoogle = async () => {
        const response = await firebase.signInWithGoogle();
        if (!response.status) {
            return helper.showMessage(response.error, "error")
        }
        const reqData = {
            socialType: "google",
            uid: response.uid
        };

        setOpen(true)
        await auth.userSocialLogin(reqData).then((res) => {
            if (res.data.status) {
                if (res.data.result.isRegistrationComplete) {
                    helper.saveCredential(
                        res.data.result.token,
                        "user", res.data.result.id,
                        "approved",
                        res.data.result.email)
                    history.push(`/user/dashboard/home`)
                } else if (!res.data.result.isRegistrationComplete) {
                    const decEmail = helper.encrypt(res.data.result.email)
                    const resData = {
                        email: res.data.result.email,
                        id: res.data.result.id,
                        isRegistrationComplete: false,
                        name: res.data.result.name,
                        role: 2,
                        token: res.data.result.token
                    }
                    localStorage.setItem('HDSUFSD', helper.encrypt(JSON.stringify(resData)))
                    history.push(`/user/little-more?tk1=${decEmail}&auth=google`)
                }
            }
            else {
                helper.showMessage(res.data.error, "error")
            }
            setOpen(false)
        }).catch((err) => { });
    };

    const signInWithFacebook = async () => {
        Promise.all([firebase.signInFacebook()]).then(async (resp) => {
            if (!resp[0].status) {
                return helper.showMessage(resp[0].error, "error")
            }
            const reqData = {
                socialType: "facebook",
                uid: resp[0].uid
            };
            setOpen(true)
            await auth.userSocialLogin(reqData).then((res) => {
                if (res.data.status) {
                    if (res.data.result.isRegistrationComplete) {
                        helper.saveCredential(
                            res.data.result.token,
                            "user", res.data.result.id,
                            "approved",
                            res.data.result.email)
                        history.push(`/user/dashboard/home`)
                    } else if (!res.data.result.isRegistrationComplete) {
                        const decEmail = helper.encrypt(res.data.result.email)
                        const resData = {
                            email: res.data.result.email,
                            id: res.data.result.id, // 99
                            isRegistrationComplete: false,
                            name: res.data.result.name,
                            role: 2,
                            token: res.data.result.token
                        }
                        localStorage.setItem('HDSUFSD', helper.decrypt(JSON.stringify(resData)))
                        history.push(`/user/little-more?tk1=${decEmail}&auth=facebook`)
                    }
                }
                else {
                    helper.showMessage(res.data.error, "error")
                }
                setOpen(false)
            }).catch((e) => { });
        });
    };


    return (
        <div className="tutor-login-home lg-bg-image">
            <Helmet>
                <title>Student Log In - SayDhad</title>
                <meta name="description" content="SayDhad welcomes you to the student login section to become a master in the Arabic language. Create an account by filling in the basic details, and you will have access to tools and resources to succeed in your journey.  Login now and enjoy access to lesson notes, practice notes, and more." />
                <link rel="canonical" href=" https://saydhad.com/login/" />
            </Helmet>
            <section className="row gx-0 mx-0 h-100 py-5 cpx-4" >
                <div className="col-lg-7  col-md-7 cpx-4 d-lg-flex d-md-flex d-none  align-items-start flex-column f-fred text-white">
                    <div className="overlay-bg"></div>
                    <div className="fs-61 overlay-text">
                        <h1>
                            <img src={quoteIcon} alt="quote" />
                        </h1>
                        <p className="text-white" style={{ lineHeight: '66px' }}>{t('auth.tutorQuote1')}</p>
                        <p style={{ lineHeight: '66px' }}>{t('auth.tutorQuote2')}</p>
                        <p className="fs-31 mt-3">~{t('general.quoteAuthor')}</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-5 my-3 bg-white login-home-box px-3 py-3  all-center" >
                    <div className="row gx-0 mx-0 w-100">
                        <div className="col-lg-10  mx-auto d-flex flex-column justify-content-between">
                            <div className="mb-2 ml-auto">
                                <LanguageTranslator />
                            </div>
                            <div className="upper">
                                <div className="all-center ">
                                    <NavLink to="/"> <img alt='logo' width="230px" src={logo} /></NavLink>
                                </div>
                                <form onSubmit={(e) => { e.preventDefault(); submit() }}>
                                    <div className="all-center  flex-column">
                                        <p className="f-fred text-center fs-24 mb-1 mt-3 heading-y-margin">{t('auth.loginto')} <span className="tx-green">{t('auth.saydhad')}</span>
                                            {" "}
                                            <span className="fs-24 link-black f-fred link-blank"> {t('auth.student')}</span>
                                        </p>
                                        <div className="mt-1 w-100 f-robo">
                                            <div className="input-box mb-2">
                                                <input maxLength={40} type="text" defaultValue={email} placeholder={t('auth.forgot.email')} className="px-2 py-1 ms-700 box-height" onChange={(e) => { setEmail(e.target.value); }} />
                                            </div>
                                            <ToggleHidePassword onChange={(e) => { setPassword(e.target.value); }} />
                                        </div>
                                        <Link to="/user/forgot-password" className="ms-500  fs-14">
                                            <span className="tx-sky">{t('auth.forgotPassword')}</span>
                                        </Link>
                                        <button type='submit' className="btn btn-tutor-profile heading-y-margin box-height mt-3 px-2 w-100" >{t('auth.proceed')}</button>
                                    </div>
                                </form>
                                <div className="inline-text my-4 all-center">
                                    <p ><span className="bold">OR</span></p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <img src={facebookImage} onClick={() => signInWithFacebook()} className='pointer mr-2 f-fred box-height d-inline-block' alt="facebook" />
                                    <img src={googleImage} className='pointer f-fred box-height d-inline-block' onClick={() => signInWithGoogle()} alt="google" />
                                </div>
                            </div>
                            <div className="all-center flex-column mt-5">
                                <p className="f-fred text-center fs-16 mb-2"> <span className="tx-green">{t('auth.newAccount')}</span></p>
                                <div className='all-center w-100'>
                                    <button className="btn btn-login w-50 box-height" onClick={() => history.push('/signup')}>{t('auth.createStudent')}</button>
                                    <button className="btn btn-login w-50 box-height ml-2" onClick={() => history.push('/tutor/signup')}>{t('auth.createTutor')}</button>
                                </div>
                                <p className="my-2 mt-4 ms-500 text-center login-footer-text" style={{ width: '80%' }}>{t('auth.policy.text1')} <span className="tx-lt-black ms-900">{t('auth.saydhad')}</span>. {t('auth.policy.text2')} <span className="ms-900 tx-lt-black"> <NavLink to="/web/terms-condition">{t('auth.policy.terms')} </NavLink></span> {t('auth.policy.and')} <span className="ms-900 tx-lt-black"><NavLink to="/web/privacy-policy">{t('auth.policy.policy')}</NavLink></span></p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            {open && <Backdrop />}
            <Helmet htmlAttributes={{ dir: "ltr" }} />
        </div>
    )
}
