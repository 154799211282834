/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import avatar from '../static/images/avatar.png'
import helper from '../helper/index'
import testHelper from '../helper/tutor'
import ReactStars from "react-rating-stars-component";
import { useSelector } from 'react-redux'
import calendar from '../controller/calendar'
import { DualRing } from 'react-awesome-spinners'
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function FindTutorByCalendar({ onClose, date, open }) {
    const { t } = useTranslation();
    const history = useHistory()
    const outSide = useRef()
    const storeData = useSelector(state => state)
    const [slots, setSlots] = useState([])
    const [tutorListing, setTutorListing] = useState([])
    const [loader, setLoader] = useState({ open: false, err: '' })

    const seletedStartTime = useRef()
    useEffect(() => {
        getTimeSlot()
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const getTimeSlot = () => {
        const currentDate = new Date()
        let startTime = moment(new Date(currentDate.setHours(currentDate.getHours() + 2))).format('HH')
        let endTime = '24:00:00'
        let duration = '00:00:00'
        if (storeData.planMinutes === 15) {
            duration = '00:15'
        } else if (storeData.planMinutes === 30) {
            duration = '00:30'
        }
        else if (storeData.planMinutes === 45) {
            duration = '00:45'
        }
        if (moment(date).format('YYYY-MM-DD') !== moment(currentDate).format('YYYY-MM-DD')) {
            startTime = '00:00:00'
        }
        setSlots(testHelper.getSlots(startTime + ":" + "00", endTime, duration))
    }

    const getTutor = async () => {
        const req = {
            startTime: seletedStartTime.current.value,
            endTime: moment(seletedStartTime.current.value, 'HH:mm').add(storeData.planMinutes, 'minutes').format('HH:mm'),
            date: moment(date).format('YYYY-MM-DD')
        }
        setLoader({
            ...loader,
            open: true,
            err: ''
        })
        await calendar.getAvailableTutorByDateTime(req).then((res) => {
            if (res.data.status) {
                setTutorListing(res.data.result)
                setLoader({
                    ...loader,
                    open: false,
                    err: ''
                })
                if(res.data.result.length === 0){
                    setLoader({
                        ...loader,
                        open: false,
                        err: "Tutors are not available on your selected time"
                    })
                }
            } else {
                setLoader({
                    ...loader,
                    open: false,
                    err: res.data.error
                })
            }
        }).catch((err) => {
            setLoader({
                ...loader,
                open: false,
                err: err
            })
        })
    }
    return (
        <>
            {
                open &&
                <div className="all-center popup-modal"  ref={outSide} id="outSide" onClick={(e)=>{helper.handleOutSide(e,outSide.current.id,()=>onClose())}}>
                    <div className={`left-popup p-fix tp-0 rt-0 p-5 sm ${storeData.language === 'ar' && "text-right"}`}>
                        <h5 className="f-fred fs-24 mb-0">{t('general.calendar.findAvailable')} <span className="tx-green">{t('general.calendar.tutors')}</span><span className="float-right fs-24 tx-grey pointer" onClick={onClose}><i className="far hover-shadow fa-times-circle"></i></span></h5>
                        <p className="sub-heading mb-3">{t('general.calendar.tutorInfo')}</p>
                        <p className='fs-18 text-center ms-700 my-3 ltr'>{moment(date).format('DD MMMM YYYY')}</p>

                        <section className='row'>
                            <div className='col-2 mb-3 d-flex align-items-center'>
                                <i className="fas fs-24 fa-calendar-day tx-green"></i>
                            </div>
                            <div className='col-10 ms-700 mb-3 tutor-dash h-100'>
                                <select ref={seletedStartTime} className='py-2 input-box p-2 w-100 ltr' onChange={() => getTutor()} style={{ minWidth: '200px' }}>
                                    <option value="">{t('general.calendar.selectTiming')}</option>
                                    {
                                        slots.map((data) => <option value={data}>{moment(data, 'hh:mm').format('hh : mm A')}</option>)
                                    }
                                </select>
                            </div>
                            <div className="col-2">
                                <i className="far  fs-24 fa-clock tx-green"></i>
                            </div>
                            <div className="ms-700 col-10 mb-3">
                                <p className='ms-700'> {storeData.planMinutes} {t('general.minutes')}</p>
                            </div>
                        </section>
                        <section className='my-1'>
                            {tutorListing.map((data, index) => {
                                return (
                                    <div key={`tutor${index}`} className={` tutor-big-card pointer b-grey bg-white mb-2 px-2`} onClick={()=>history.push(`/user/dashboard/schedule/${data.tutor_id}`)}>
                                        <section className='d-flex'>
                                            <div className="all-center my-2">
                                                <div className='p-rel mr-2'>
                                                    <img style={{ height: '50px', width: '50px' }} className="rounded-circle mx-1" src={data.profileData ? data.profileData : avatar} alt="avatar" />
                                                </div>
                                            </div>
                                            <div className='w-100 d-flex justify-content-between'>
                                                <div className="my-2 pl-1">
                                                    <p className="text-capitalize fs-16 f-fred">{data.name}</p>
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-inline-block py-0 my-0 mr-1">
                                                            <ReactStars
                                                                edit={false}
                                                                value={data.rating ? parseFloat(data.rating).toFixed(1) : 0}
                                                                count={5}
                                                                size={15}
                                                                isHalf={true}
                                                                emptyIcon={<i className="far fa-star"></i>}
                                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                                fullIcon={<i className="fa fa-star"></i>}
                                                                classNames="my-0 py-0"
                                                                activeColor="#ffcb00"
                                                            />
                                                        </div>
                                                        <span className="tx-black ms-700 fs-14">{data.rating ? parseFloat(data.rating).toFixed(1) : 0}</span>
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-end justify-content-end p-rel">
                                                    {helper.getDateDifference(data.createdOn) < 30 && <p className="new-flag px-2 fs-18 ms-500">{t('general.new')}</p>}
                                                    <span className="p-2">

                                                        <img width="25px" height="25px" className="rounded-circle" src={data.flag_uri}
                                                            alt="flag" />

                                                    </span>
                                                </div>
                                            </div>

                                        </section>
                                    </div>
                                )
                            })}


                            <div className='all-center my-2'>
                                {!loader.err && loader.open ? <DualRing size={40} color="#ffcb00" /> : <p className='ms-700  fs-14 tx-red text-center mx-4'>{loader.err}</p>}
                            </div>
                        </section>

                    </div>
                </div>
            }
        </>
    )
}
