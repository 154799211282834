/* eslint-disable */
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import helper from '../helper/index'
import parot from '../static/images/parrot-1.png'
import Slide from 'react-reveal/Slide';
import { Link } from 'react-router-dom';
import SvgChat from '../Icons/Chat'
import SvgDashboard from '../Icons/Dashboard'
import SvgStudent from '../Icons/Student'
import SvgLibrary from '../Icons/Library'
import SvgSupport from '../Icons/Support'
import SvgLogout from '../Icons/Logout'
import SvgStudents from '../Icons/Students'
import { FaAffiliatetheme } from "react-icons/fa";
import { FaTimes } from 'react-icons/fa'
export default function Menubar({ cb }) {
    const history = useHistory()
    const { page } = useParams()
    return (<>

        <Slide left>

            <div className="menu px-3 p-fix tp-0 lt-0 bg-yellow  h-100 d-lg-none d-md-none" style={{ zIndex: '5', overflowY: 'auto',width : '85%' }}>
                <div className="menu-top all-center"><p className="f-fred fs-20">SAY <span className="tx-yellow">DHAD</span></p></div>

                <section className='d-flex h-100 flex-column justify-content-between align-items-between'>
                    <div className=''>
                        <p>
                            <FaTimes onClick={cb} size={22} style={{ right: '10px' }} className='mx-2 inactive-icon-color   pointer pointer mt-1' />
                        </p>

                        <ul className="mt-2" onClick={cb}>
                            <li onClick={() => history.push('/user/dashboard/home')} className={`${page === "home" && "active-tab"}`}>
                                <div className="f-fred text-upper  d-flex align-items-center">
                                    <span className='icon-width d-flex align-items-center'>

                                        <SvgDashboard className={`active-icon-color`} />
                                    </span>
                                    <p>Dashboard</p>
                                </div>
                            </li>
                            <li onClick={() => history.push('/user/dashboard/tutor')} className={`${page === "tutor" && "active-tab "}`}>
                                <div className="f-fred text-upper d-flex align-items-center">
                                    <span className='icon-width d-flex align-items-center'>
                                        <SvgStudents className={`active-icon-color`} />
                                    </span>
                                    <p>Tutors</p>
                                </div>
                            </li>
                            <li onClick={() => history.push('/user/dashboard/course')} className={`${page === "course" && "active-tab "}`}>
                                <div className="f-fred text-upper d-flex align-items-center">
                                    <span className='icon-width d-flex align-items-center'>
                                        <SvgLibrary className={`active-icon-color`} />
                                    </span>
                                    <p>Courses</p>
                                </div>

                            </li>
                            <li onClick={() => history.push('/user/dashboard/calendar')} className={`${page === "calendar" && "active-tab"}`}>
                                <div className="f-fred text-upper d-flex align-items-center">
                                    <span className='icon-width d-flex align-items-center'>
                                        <SvgStudent className={`active-icon-color`} />
                                    </span>
                                    <p>Calendar</p>
                                </div>
                            </li>

                            <li onClick={() => history.push('/user/dashboard/myprogress')} className={`${page === "myprogress" && "active-tab "}`}>
                                <div className="f-fred text-upper d-flex align-items-center">
                                    <span className='icon-width d-flex align-items-center'>
                                        <SvgLibrary className={`active-icon-color`} />
                                    </span>
                                    <p>My Progress</p>
                                </div>
                            </li>

                            <li onClick={() => history.push('/user/dashboard/chat')} className={`${page === "chat" && "active-tab "}`}>
                                <div className="f-fred text-upper d-flex align-items-center">
                                    <span className='icon-width d-flex align-items-center'>
                                        <SvgChat className={`active-icon-color`} />
                                    </span>
                                    <p>Chat</p>
                                </div>

                            </li>
                            <li onClick={() => history.push('/user/dashboard/support')} className={`${page === "support" && "active-tab "}`}>
                                <div className="f-fred text-upper d-flex align-items-center">
                                    <span className='icon-width'>
                                        <SvgSupport className={`active-icon-color`} />
                                    </span>
                                    <p>Support</p>
                                </div>
                            </li>
                            <li onClick={() => history.push('/user/dashboard/referral')} className={`${page === "referral" && "active-tab"}`}>
                                <div className="f-fred text-upper d-flex align-items-center">
                                    <span className='icon-width d-flex align-items-center'>
                                        <FaAffiliatetheme size={22} className={`active-icon-color`} />
                                        {/* <i  className={`fas fa-hand-peace fs-24 ${page === "referral" ? "active-icon-color" : 'inactive-icon-color'}`}></i> */}
                                    </span>
                                    <p>Referral</p>
                                </div>
                                {/* <p className="f-fred text-upper"><span className='icon-width'><i className="fas fa-hand-peace tx-lt-black mr-1"></i></span>Referral</p> */}

                            </li>
                            

                            <li onClick={() => { helper.logOut(); history.push('/') }}>
                                <div className="f-fred text-upper d-flex align-items-center">
                                    <span className='icon-width d-flex align-items-center'>
                                        <SvgLogout className={`active-icon-color`} />
                                    </span>
                                    <p>LOGOUT</p>
                                </div>
                            </li>

                        </ul>


                    </div>
                    <div className='d-flex justify-content-between pb-3 mt-3'>
                        <div className="ms-700 fs-14 ml-3">
                            <Link to="/about-us" onClick={cb} className="d-block"><span className='link-hover'>About SayDhad</span></Link>
                            <Link to="/user/dashboard/privacy-policy" onClick={cb} className="d-block"><span className='link-hover'>Privacy policy</span></Link>
                            <Link to="/user/dashboard/terms-condition" onClick={cb} className="d-block"><span className='link-hover'>Terms & condition</span></Link>
                            <p className="mt-3 fs-12 mb-2">©2021. SayDhad. All Rights Reserved</p>
                        </div>

                        <div className="menu-bottom mb-3 mr-3  rt-0 bt-0">
                            <img width="60px" src={parot} alt="parot" />
                        </div>
                    </div>
                </section>
            </div>
        </Slide>

    </>)
}
