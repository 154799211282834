export const constant = {
    BASE_URL: process.env.REACT_APP_API_BASE_URL + "/v1",
    TUTOR_URL: process.env.REACT_APP_API_BASE_URL + '/v1/tutor',
    AGORA_URL: process.env.REACT_APP_AGORA_URL,
    DOMAIN: process.env.REACT_APP_API_SERVER,
    LIVE_URL: process.env.REACT_APP_URL,
    SOCKET_URL: process.env.REACT_APP_API_BASE_URL,

    /* Firebase push notifications */
    VAPID_KEY: process.env.REACT_APP_VAPID_KEY,

    /* Agora */
    AGORA_CUSTOMER_KEY: process.env.REACT_APP_AGORA_CUSTOMER_KEY,
    AGORA_CUSTOMER_SECRET_KEY: process.env.REACT_APP_AGORA_CUSTOMER_SECRET_KEY,

    /* AWS */
    AWS_S3_URL: process.env.REACT_APP_AWS_S3,

    /* App links */
    ANDROID_APP: process.env.REACT_APP_ANDROID_LINK,
    IOS_APP: process.env.REACT_APP_IOS_LINK,

    /* Payment links */
    PAYMENT_GATEWAY_URL: process.env.REACT_APP_PAYMENT,

    /* XE API's */
    XE_URL: "https://xecdapi.xe.com",
    XE_API_KEY: "d6fs33t8f02rrj3kfi23mlvmse",
    XE_API_ID: "saydhad.com211473908"
}

