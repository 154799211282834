import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import logo from '../static/images/Logo.svg'
import auth from '../controller/auth'
import Backdrop from '../component/Backdrop'
import carpet from '../static/images/green-carpet.svg'
export default function VerifyAccount() {
    const [error, setError] = useState('')
    const history = useHistory()
    const [open, setOpen] = useState(true)
    useEffect(() => {
        const url = new URLSearchParams(window.location.search)
        apiCall(url.get('token'));
        return () => { }
    }, [])

    const apiCall = async (token) => {
        await auth.tutorVerify(token)
            .then((res) => {
                if (res.data.status) {
                    setOpen(false)
                }
                else {
                   
                    setError(res.data.error)
                    setOpen(false)
                }
            })
    }
    return (
        <>
            {!open ? <div className="tutor-login-home all-center flex-column">
                <div className="all-center w-100 mb-2">
                    <div className="all-center mb-3">
                        <img width="80%" src={logo} alt="logo" />
                    </div>
                </div>
                {error ? <p className="f-fred tx-sky">{error}</p> :
                    <p className="f-fred tx-green">You account successfully verified</p>}

                <p className="tx-lt-grey ms-700 px-2 text-center">In order to continue, please click on then continue</p>
                <div className="select-role all-center mt-2">
                </div>
                <button className="btn btn-login mt-4 box-height" style={{ width: '150px' ,zIndex:2}} onClick={() => history.push('/welcome')}>continue</button>

                <div className="p-fix bt-0 w-100 ">
                    <img src={carpet} alt="carpet" />
                </div>
            </div> : <Backdrop />}
        </>
    )
}