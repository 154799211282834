/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import logo from '../static/images/Logo.svg'
import googleImage from "../static/images/Group 587.png";
import facebookImage from "../static/images/Group 586.png";
import auth from "../controller/auth";
import helper from "../helper/index";
import Backdrop from "../component/Backdrop";
import firebase from '../config/firebaseAuth';
import { Link, NavLink } from "react-router-dom";
import quoteIcon from '../static/images/quote.svg'
import ToggleHidePassword from "../component/ToggleHidePassword";
import { useTranslation } from "react-i18next";
import '../static/css/auth.css'
import { Helmet } from "react-helmet";
import LanguageTranslator from "../component/LanguageTranslator";
export default function TutorLogin() {
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const resp = helper.isLoggedIn()
    if (resp.res && resp.role === "tutor") {
      const status = helper.getProfileStatus();
      if (status === "Approved") {
        history.push(`/tutor/dashboard/home`)
      }
      else {
        history.push(`/tutor/signupcheck`);
      }
    }
    else if (resp.res && resp.role === "user") {
      history.push(`/user/dashboard/home`)
    }
    return () => {
      setOpen(false); setPassword(); setEmail();
    }

  }, [])

  const submit = () => {
    const data = { email: email ? email.toLowerCase() : email, password: password };
    let resp;
    resp = helper.validate("email", email);
    if (!resp.isValid) {
      helper.showMessage(resp.msg, "error")
      return;
    }
    if (!password) {
      helper.showMessage("please enter password", "error")
      return;
    }
    setOpen(true);
    auth.tutorLogin(data).then((res) => {
      if (res.data.status) {
        helper.saveCredential(
          res.data.result.token,
          "tutor",
          res.data.result.id,
          res.data.result.profileStatus,
          res.data.result.email
        );
        if (res.data.result.profileStatus === "Pending") {
          history.push(`/tutor/signupcheck`);
        } else if (res.data.result.profileStatus === "Uploaded") {
          history.push(`/tutor/signupcheck`);
        } else if (res.data.result.profileStatus === "Approved") {
          history.push(`/tutor/dashboard/home`);
        }
      } else {
        if (res.data.error === "Please verify the account") {
          const encrEmail = helper.encrypt(email);
          history.push(`/tutor/verify-otp?tk1=${encrEmail}`);
        } else {
          helper.showMessage(res.data.error, "error")
        }
      }

      setOpen(false);
    });
  };


  const signInWithGoogle = async () => {
    const response = await firebase.signInWithGoogle();
    if (!response.status) {
      return helper.showMessage(response.error, "error")
    }
    const reqData = {
      socialType: "google",
      uid: response.uid
    };
    setOpen(true)
    await auth.tutorSocialLogin(reqData).then((res) => {
      if (res.data.status) {
        helper.saveCredential(
          res.data.result.token,
          "tutor",
          res.data.result.id,
          res.data.result.profileStatus,
          res.data.result.email
        );
        if (res.data.result.profileStatus === "Pending") {
          history.push(`/tutor/signupcheck`);
        } else if (res.data.result.profileStatus === "Uploaded") {
          history.push(`/tutor/signupcheck`);
        } else if (res.data.result.profileStatus === "Approved") {
          history.push(`/tutor/dashboard/home`);
        }
      } else {
        helper.showMessage(res.data.error, "error")
      }
      setOpen(false)
    });
  };

  const signInWithFacebook = async () => {
    Promise.all([firebase.signInFacebook()]).then(async (resp) => {
      if (!resp[0].status) {
        return helper.showMessage(resp[0].error)
      }
      const reqData = {
        socialType: "facebook",
        uid: resp[0].uid
      };
      setOpen(true)
      await auth
        .tutorSocialLogin(reqData)
        .then((res) => {
          if (res.data.status) {
            helper.saveCredential(
              res.data.result.token,
              "tutor",
              res.data.result.id,
              res.data.result.profileStatus,
              res.data.result.email
            );
            if (res.data.result.profileStatus === "Pending") {
              history.push(`/tutor/signupcheck`);
            } else if (res.data.result.profileStatus === "Uploaded") {
              history.push(`/tutor/signupcheck`);
            } else if (res.data.result.profileStatus === "Approved") {
              history.push(`/tutor/dashboard/home`);
            }
          } else {
            helper.showMessage(res.data.error, "error")
          }
          setOpen(false)
        })
        .catch((e) => { });
    });
  };

  return (
    <div
      className="tutor-login-home  lg-bg-image">
      {/* SEO */}
      <Helmet>
        <title>Tutor Log In - SayDhad</title>
        <meta name="description" content="Learning becomes more interactive with SayDhad. If you wish to teach Arabic, follow the easy login process today. After logging in, you are welcome to our online learning platform, where you are welcome to teach thousands of students. Create an account now." />
        <link rel="canonical" href=" https://saydhad.com/tutor/login/" />
      </Helmet>
      <section className="row gx-0 mx-0 h-100 py-5 cpx-4">
        <div className="col-lg-7 col-md-7 cpx-4 d-lg-flex d-md-flex d-none  align-items-start flex-column f-fred text-white">
          <div className="overlay-bg"></div>
          <div className="fs-61 overlay-text">
            <h1>
              <img src={quoteIcon} alt="quote" />
            </h1>
            <p className="text-white" style={{ lineHeight: '66px' }}>{t('auth.tutorQuote1')}</p>
            <p style={{ lineHeight: '66px' }}>{t('auth.tutorQuote2')}</p>
            <p className="fs-31 mt-3">~{t('general.quoteAuthor')}</p>
          </div>
        </div>
        <div className="col-lg-4 my-3 col-md-5 bg-white login-home-box px-3 py-3 all-center">
          <div className="row gx-0 mx-0 w-100">
            <div className="col-lg-10 mx-auto d-flex flex-column justify-content-between">
              <div className="mb-2 ml-auto">
                <LanguageTranslator />
              </div>
              <div className="upper">
                <div className="all-center px-2">
                  <NavLink to="/"> <img width="230px" src={logo} alt="logo" /></NavLink>
                </div>

                <form onSubmit={(e) => { e.preventDefault(); submit() }}>
                  <div className="all-center flex-column">
                    <p className="f-fred text-center fs-24 mb-1 mt-3 heading-y-margin">{t('auth.loginto')} <span className="tx-green">{t('auth.saydhad')}</span>
                      <span className="fs-24 link-black f-fred link-blank"> {t('auth.tutor')}</span>
                    </p>
                    <div className="mt-1 w-100 ms-500">
                      <div className="input-box mb-2">
                        <input
                          maxLength={40}
                          type="text"
                          placeholder={t('auth.forgot.email')}
                          className="px-2 py-1 ms-700 box-height"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                      <ToggleHidePassword onChange={(e) => { setPassword(e.target.value) }} />
                    </div>
                    <Link to="/tutor/forgot-password" className="ms-500  fs-14">
                      <span className="tx-sky">{t('auth.forgotPassword')}</span>
                    </Link>
                    <button
                      type="submit"
                      className="btn btn-tutor-profile box-height heading-y-margin mt-3 px-2  w-100">
                      {t('auth.proceed')}
                    </button>
                  </div>
                </form>
                <div className="inline-text my-4 all-center">
                  <p ><span className="bold">OR</span></p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <img src={facebookImage} height={'39px'} onClick={() => signInWithFacebook()} className='pointer mr-2 f-fred box-height d-inline-block' alt="facebook" />
                  <img src={googleImage} className='pointer f-fred box-height d-inline-block' onClick={() => signInWithGoogle()} alt="google" />
                </div>
              </div>

              <div className="all-center flex-column mt-5">
                <p className="f-fred text-center fs-16 mb-2"> <span className="tx-green">{t('auth.newAccount')}</span></p>

                <div className='all-center w-100'>
                  <button className="btn btn-login w-50 box-height" onClick={() => history.push('/tutor/signup')}>{t('auth.createTutor')}</button>
                  <button className="btn btn-login w-50 box-height ml-2" onClick={() => history.push('/signup')}>{t('auth.createStudent')}</button>
                </div>
                <p className="my-2 mt-4 ms-500 text-center login-footer-text" style={{ width: '80%' }}>{t('auth.policy.text1')} <span className="tx-lt-black ms-900">{t('auth.saydhad')}</span>. {t('auth.policy.text2')} <span className="ms-900 tx-lt-black"> <NavLink to="/web/terms-condition">{t('auth.policy.terms')} </NavLink></span> {t('auth.policy.and')} <span className="ms-900 tx-lt-black"><NavLink to="/web/privacy-policy">{t('auth.policy.policy')}</NavLink></span></p>
              </div>

            </div>
          </div>
        </div>
      </section>
      {open && <Backdrop />}
      <Helmet htmlAttributes={{ dir: "ltr" }} />
    </div>
  );
}
