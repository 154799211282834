/* eslint-disable */
import React, { useState, useEffect } from 'react'
import agora from '../controller/agora'
import CourseCard from './CourseCard'
import SyllabusCard from './SyllabusCard'
import { useParams } from 'react-router-dom'
import tutor from '../controller/tutor'
import helper from '../helper'
import { DualRing } from 'react-awesome-spinners'

export default function StudentCourse({ open, onClose, studentId ,isAttendanceMark}) {
    const [state, setState] = useState({ browse: false, data: [], load: true })
    const { channelName } = useParams()
    const [activeCourse, setActiveCourse] = useState({
        index: -1,
    })
    useEffect(() => {
        getEnrolledCourse()
    }, [])

    const getEnrolledCourse = async () => {
        setState({ ...state, data: [], load: true, browse: false })
        await agora.getStudentEnrolledCourses(channelName).then((res) => {
            if (res.data.status) {
                getLibrary(res.data.result)
            }
        })
    }

    const getLibrary = async (courseData) => {
        await tutor.getCourseFromLibrary(helper.getid())
            .then((res) => {
                let list = []
                let tutorEnrolledCourseIds = []
                if (res.data.status) {
                    res.data.result.forEach((data) => {
                        data.course.forEach((course) => {
                            tutorEnrolledCourseIds.push(course.id)
                        })
                    })
                    courseData.forEach((data) => {
                        data.course.forEach((course) => {
                            if (tutorEnrolledCourseIds.includes(course.id)) {
                                course.isTutorEnrolled = true
                            } else {
                                course.isTutorEnrolled = false
                            }
                            list.push(course)
                        })
                    })
                    setState({ ...state, data: list, load: false })
                } else {
                    courseData.forEach((data) => {
                        data.course.forEach((course) => {
                            if (tutorEnrolledCourseIds.includes(course.id)) {
                                course.isTutorEnrolled = true
                            } else {
                                course.isTutorEnrolled = false
                            }
                            list.push(course)
                        })
                    })
                    setState({ ...state, data: list, load: false })
                }
            })
    }

    const onCompletedSyllabus = (courseId, syllabusId) => {
        let list = [...state.data]
        state.data.forEach((course) => {
            if (course.id == courseId) {
                course.syllabusList.some((data) => {
                    if (data.syllabus_id == syllabusId) {
                        data.total_completed_slide = data.slideDetails.length
                        return true
                    }

                })
            }
        })
        setState({ ...state, data: list, load: false })
    }

    return (
        <>
            {open &&
                <div className="all-center popup-modal">
                    <div className="left-popup p-fix tp-0 rt-0  p-4 sm" >
                        <p className="ms-900 fs-24  mb-0">Course <span className="tx-green">details</span><span className="float-right fs-24 tx-grey pointer" onClick={onClose}><i className="far fa-times-circle hover-shadow"></i></span></p>
                        <p className="sub-heading">{state.browse ? "My enrolled courses" : "Student enrolled courses"}</p>

                        {!state.browse ? <div>
                            <section className='mt-3'>
                                {
                                    state.data.map((course, index) =>
                                        <>
                                            <CourseCard
                                                key={`cc${index}`}
                                                showProgress={true}
                                                title={course.title}
                                                subTitle={course.subTitle}
                                                courseLength={course.totalLessons}
                                                slides={course.totalSlide}
                                                isEnroll={course.enrolled_courses}
                                                isTutorEnrolled={course.isTutorEnrolled}
                                                completedSlide={course.total_slide_complete}
                                                pic={course.imgName}
                                                isExpand={activeCourse.index === index}
                                                onClick={() => setActiveCourse({ ...activeCourse, index: activeCourse.index === index ? -1 : index })}
                                            />
                                            {
                                                activeCourse.index === index && course.syllabusList.map((syllabus, index) =>
                                                    <SyllabusCard
                                                        key={`sc${index}`}
                                                        isTutorEnrolled={course.isTutorEnrolled}
                                                        lessonNo={index + 1}
                                                        title={syllabus.name}
                                                        slide={syllabus.noOfSlides}
                                                        isComplete={syllabus.total_completed_slide === syllabus.slideDetails.length}
                                                        islock={index === 0 ? false : course.syllabusList[index - 1].total_completed_slide !== course.syllabusList[index - 1].slideDetails.length}
                                                        showProgress={true}
                                                        completedSlides={syllabus.total_completed_slide}
                                                        files={syllabus.slideDetails}
                                                        courseId={course.id}
                                                        studentId={studentId}
                                                        enrolledCourseId={course.enrolled_course_id}
                                                        onComplete={() => onCompletedSyllabus(course.id, syllabus.syllabus_id)}
                                                        isAttendanceMark={isAttendanceMark}
                                                    />)

                                            }
                                        </>)
                                }

                                {state.data.length === 0 && <p className="text-center my-2">Please enroll student in course</p>}

                                {!state.load && <button className='btn btn-login px-3' onClick={() => setState({ ...state, browse: true })}>Browse my library</button>}

                            </section>


                        </div>
                            :
                            <BrowseCourses onUpdate={() => getEnrolledCourse()} studentCourses={state.data} onClose={() => setState({ ...state, browse: false })} />
                        }
                        {state.load && <div className='all-center my-4'>
                            <DualRing size={40} color="#20bef8" />
                        </div>}
                    </div>
                </div>
            }
        </>
    )
}

const BrowseCourses = ({ onClose, studentCourses, onUpdate }) => {
    const { channelName } = useParams()
    const [load, setLoad] = useState(false)
    const [activeCourse, setActiveCourse] = useState({
        index: -1,
    })
    const [state, setState] = useState({ data: [], load: true })


    useEffect(() => {
        getLibrary()
        return () => { }
    }, [])

    const getLibrary = async () => {
        await tutor.getCourseFromLibrary(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    let list = []
                    let studentEnrolledCourseIds = []
                    studentCourses.forEach((data) => studentEnrolledCourseIds.push(data.id))
                    res.data.result.forEach((data) => {
                        data.course.forEach((result) => {
                            if (studentEnrolledCourseIds.includes(result.id)) {
                                studentCourses.some((data) => {
                                    if (data.id == result.id) {
                                        result.total_slide_complete = data.total_slide_complete
                                        return true
                                    }
                                })
                                result.isStudentEnrolled = true
                            } else {
                                result.total_slide_complete = 0
                                result.isStudentEnrolled = false
                            }
                            list.push(result)
                        })
                    })
                    setState({ ...state, data: list, load: false })

                }
            })
    }

    const enrollStudent = async (courseId) => {
        const reqData = {
            channel_name: channelName,
            course_id: courseId
        }
        setLoad(true)
        await agora.enrollStudentInCourse(reqData).then((res) => {
            if (res.data.status) {
                onUpdate()
            }

            setLoad(false)
        })
    }
    return (
        <>
            <section className='mt-3'>
                <span className='mb-2 d-inline-block ms-700 pointer' onClick={onClose}><i className="fas  hover-shadow fa-chevron-left fs-18"></i> Back</span>
                <section className='mt-3'>
                    {
                        state.data.map((course, index) =>
                            <>
                                <CourseCard
                                    key={`sc${index}`}
                                    showProgress={course.isStudentEnrolled}
                                    title={course.title}
                                    subTitle={course.subTitle}
                                    courseLength={course.totalLessons}
                                    slides={course.totalSlide}
                                    isEnroll={course.isStudentEnrolled}
                                    isTutorEnrolled={course.isTutorEnrolled}
                                    completedSlide={course.total_slide_complete}
                                    pic={course.imgName}
                                    onEnrolled={() => enrollStudent(course.id)}
                                    isExpand={activeCourse.index === index}
                                    onClick={() => setActiveCourse({ ...activeCourse, index: activeCourse.index === index ? -1 : index })}
                                />
                                {
                                    activeCourse.index === index && course.syllabusList.map((syllabus, index) =>
                                        <SyllabusCard
                                            key={`scs${index}`}
                                            lessonNo={index + 1}
                                            title={syllabus.name}
                                            slide={syllabus.noOfSlides}
                                            isComplete={true}
                                            islock={false}
                                            showProgress={false}
                                            completedSlides={0}
                                            files={[]}
                                            courseId={0}
                                            studentId={0}
                                            enrolledCourseId={0}
                                        />)

                                }
                            </>)
                    }


                </section>
                {state.load && <div className='all-center my-4'>
                    <DualRing size={40} color="#20bef8" />
                </div>}
                {load && <div style={{ backgroundColor: 'rgba(233, 232, 229, 0.4)' }} className='all-center  w-100 h-100 lt-0 rt-0 bt-0 tp-0 p-abs my-4'>
                    <DualRing size={40} color="#20bef8" />
                </div>}
            </section>

        </>)
}
