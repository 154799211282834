/* eslint-disable */
import React from 'react'
import block from '../static/images/home/Path 4437.svg'
import logo from '../static/images/Logo.svg'
import Google from '../static/images/home/Google.svg'
import Apple from '../static/images/home/Apple.svg'
import instagram from '../static/images/home/instagram.svg'
import facebook from '../static/images/home/facebook.svg'
import linkedin from '../static/images/home/linkedin.svg'
import twitter from '../static/images/home/twitter.svg'
import Tiktok from '../static/images/tiktok.png'
import bottomImg from '../static/images/home/Mask Group 45.svg'
import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { constant } from '../config/Contant'
export default function WebFooter() {
    const { t } = useTranslation();
    const pathName = window.location.pathname
    const reduxState = useSelector(state => state)
    return (
        <footer className='web-footer'>
            <div><img src={block} alt="block" className='footer-quote' /></div>
            <section className='row'>
                <div className={`col-lg-4 mb-2 ${reduxState.language === 'ar' && "text-right"}`}>
                    <NavLink to="/"><img className='logo mt-3' src={logo} alt="logo" /></NavLink>
                </div>
                <div className="col-lg-8 mb-2 d-flex justify-content-lg-end justify-content-md-end">
                    <div className='mt-3'>
                        <a href={constant.ANDROID_APP} target={"_blank"}><img src={Google} className="mx-2" alt="google" /></a>
                        <a href={constant.IOS_APP} target={"_blank"}><img src={Apple} alt="apple" rel="noopener noreferrer" /></a>
                    </div>
                </div>
            </section>
            <div style={{ border: '2px dotted grey' }} className="mt-4"></div>
            <section className='row mt-4'>
                <div className="col-lg-6 mb-2">
                    <div className={`footer-navigation ${reduxState.language === 'ar' && "text-right"}`}>
                        <ul>
                            <li><Link to="/" className={`${pathName === "/" && "active-web-link"} `}>{t('home.footer.student')}</Link></li>
                            <li><Link to="/tutor-knowlegde" className={`${pathName === "/tutor-knowlegde" && "active-web-link"} `}>{t('home.footer.tutor')}</Link></li>
                            <li><Link to='/about-us' className={`${pathName === "/about-us" && "active-web-link"} `}>{t('home.footer.about')}</Link></li>
                            <li><Link to="/support" className={`${pathName === "/support" && "active-web-link"} `}>{t('home.footer.contact')}</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-6 mb-2 d-flex justify-content-lg-end justify-content-md-end">
                    <div className='footer-navigation-icon'>
                        <ul>
                            <li><a rel="noopener noreferrer" href="https://www.facebook.com/SayDhad1/" target={'_blank'}><img src={facebook} alt="facebook" /></a></li>
                            <li><a rel="noopener noreferrer" href=" https://www.instagram.com/saydhad/" target={'_blank'}><img src={instagram} alt="instagram" /></a></li>
                            <li><a rel="noopener noreferrer" href="https://x.com/Saydhad" target={'_blank'}><img src={twitter} alt="twitter" /></a></li>
                            <li><a rel="noopener noreferrer" href="https://www.linkedin.com/in/say-dhad-975022238/" target={'_blank'}><img src={linkedin} alt="linkedin" /></a></li>
                            <li><a rel="noopener noreferrer" href="https://www.youtube.com/@saydhad" target={'_blank'}><img style={{ width: 25, height: 25, borderRadius: "100%" }} src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFCrY7tyLD52dUnE70wukCaEgXTRxEMDs3Hg&usqp=CAU'} alt="youTube" /></a></li>
                            <li><a rel="noopener noreferrer" href="https://www.tiktok.com/@saydhad.com" target={'_blank'}><img style={{ width: 25, height: 25, borderRadius: "100%" }} src={Tiktok} alt="tiktok" /></a></li>

                        </ul>
                    </div>
                </div>
            </section>
            <section className='row mt-4'>
                <div className={`col-lg-6 mb-2 order-1 ${reduxState.language === 'ar' && "text-right"}`}>
                    <div className='footer-legal-link'>
                        <ul>
                            <li><Link to='/web/terms-condition'>{t('home.footer.terms')}</Link></li>
                            <li><Link to='/web/privacy-policy'>{t('home.footer.privacy')}</Link></li>
                            <li><Link to='/web/disclaimer'>{t('home.footer.disclaimer')}</Link></li>
                            <br />
                            <li><Link to='/web/refund-policy'>{t('home.footer.refund policy')}</Link></li>
                            <li><Link to='/web/cancel-policy'>{t('home.footer.cancel policy')}</Link></li>
                            <br />
                            <li><Link to='/faqs/student'>{t("home.footer.Students FAQs")}</Link></li>
                            <li><Link to='/faqs/tutor'>{t('home.footer.Tutor FAQs')}</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-6 order-lg-2 order-3 mb-2 d-flex justify-content-lg-end justify-content-md-end justify-content-center">
                    <div className='dhad-right'><p>{t('home.footer.rights')}</p></div>
                </div>
            </section>
            <section className='mt-3'>
                <img src={bottomImg} alt="" className="mx-auto d-block mw-100" />
            </section>
        </footer>
    )
}
