/* eslint-disable */
import React from 'react'
import { useTranslation } from "react-i18next";
import moment from 'moment';
export default function PayoutCard({name,btn,onChange,data}) {
    const { t } = useTranslation();
    return (
        <section className={`notify-card b-grey tx-lt-grey p-2 my-2 row gx-0 mx-0 fs-14 ms-500`}>
        <div className="col-lg-1 wrap col-md-2 col-4 all-center mb-2">
                <button className={`btn w-100 ${btn == "transferred" ? "btn-transferred" : btn == "btn-transferred" ? "btn-read" : "" } w-100 py-1 ms-700 wrap`}>{t(`tutorDash.payout.${[name]}`)}</button>
        </div>
        <div className="col-lg-2 col-md-10 col-8 px-2 mb-2">
            <p className="f-fred ">{data.transaction_id}</p>
            <p className="ms-500 ">{t('tutorDash.payout.transactionId')}</p>
        </div>
        <div className="col-lg-3 col-md-6 col-12 mb-2 d-flex">
            <div className="mr-3">
            <p className="f-fred ">{data.total_calls}</p>
            <p className="ms-500 ">{t('tutorDash.payout.calls')}</p>
            </div>
            <div className="mr-3">
            <p className="f-fred ">{data.total_minutes || "00:00:00"}</p>
            <p className="ms-500 ">{t('tutorDash.payout.minutes')}</p>
            </div>
            <div className="mr-2">
            <p className="f-fred ">{data.pay_amount}</p>
            <p className="ms-500 ">{t('tutorDash.payout.amount')}</p>
            </div>
        </div>
        <div className="col-lg-5  col-md-6 col-12 mb-2  d-flex justify-content-lg-end justify-content-md-end pr-2">
            <div className="mr-3">
            <p className="f-fred ltr">{moment(data.start_date).format('DD MMM')} - {moment(data.end_dat).format('DD MMM, YYYY')}</p>
            <p className="ms-500 ">{t('tutorDash.payout.billingCycle')}</p>
            </div>
            <div className="mr-3">
            <p className="f-fred ltr">{moment(data.created_at).format('DD MMM, YYYY')}</p>
            <p className="ms-500 ">{t('tutorDash.payout.date')}</p>
            </div>
            <div className="mr-2">
            <p className="f-fred ltr">{moment(data.created_at).format('hh:mm a')}</p>
            <p className="ms-500 ">{t('tutorDash.payout.time')}</p>
            </div>
        </div>
       
        <div className="col-lg-1 col-md-4 col-12 px-1 all-center">
                <button className="btn btn-detail text-white w-100 py-1 f-fred tx-lt-black fs-14" onClick={onChange}>{t('tutorDash.payout.detail')}</button>
        </div>
    </section>
    )
}
