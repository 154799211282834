import React from 'react'
import { useTranslation } from "react-i18next";
export default function LeftPop({ close, body, title}) {
    const { t } = useTranslation();
    return (
        <div className="all-center popup-modal">
            <div className="left-popup p-fix tp-0 rt-0  p-5 sm" >
                <h4 className="f-fred tx-lt-grey">{t('tutorSidebar.book.slot')} <span className="tx-green">{t('tutorSidebar.stuDet.det')}</span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                <p className="sub-heading">{title}</p>
                {body}
            </div>
        </div>
    )
}
