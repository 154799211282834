/* eslint-disable */
import React, { useEffect, useState ,useRef} from 'react'
import { FiXCircle } from "react-icons/fi";
import { FaCalendarCheck } from "react-icons/fa";
import moment from 'moment';
import tutor from '../controller/tutor';
import helper from '../helper/index';
import avatar from '../static/images/avatar.png'
import Backdrop from './Backdrop';
function UserNotifyPopup({ NotifyClose, data }) {
    const [resData, setResData] = useState()
    const [pageload, setPageload] = useState(true)
    const outSide = useRef()
    useEffect(() => {
        apiCall()
        return () => { }
    }, [])

    const apiCall = async () => {
        await tutor.getTutorNotificationsById(helper.getid(), data.id)
            .then((res) => {
                if (res.data.status) {
                    setResData(res.data.result)
                }
                setPageload(false)
            })
    }

   

    return (
        <div className="faded" ref={outSide} id="outSide" onClick={(e)=>{helper.handleOutSide(e,outSide.current.id,()=>NotifyClose())}}>
            <div className="p-5 tx-lt-black rightpop-up " >
                <div className="row">
                    <div className="col-10">
                        <h4 className="f-fred">Notification <span className="tx-green">details</span></h4>
                        <p className="sub-heading">{moment(data.created_at).format('ddd, MMM YYYY hh:mm A')}</p>
                    </div>
                    <div className="col-2 d-flex justify-content-end tx-grey pointer"><FiXCircle size="2.5rem" className='hover-shadow' onClick={NotifyClose} /></div>
                    <div className="text-left my-3 ms-900 tx-lt-grey">
                        <h5>{data.type}</h5>
                    </div>
                    <p className="mb-3 ms-500 text-justify tx-lt-grey"> {data.description}</p>
                </div>

                {!pageload && resData &&
                    <>
                        <div className="row  b-grey px-2 py-2 f-robo my-2  ">
                            <div className="col-lg-2 col-3 mb-2 ">
                                <img style={{ height: '40px', width: '40px' }} className="rounded-circle" src={resData.profilePicture ? resData.profilePicture : avatar} alt="tutor" />
                            </div>
                            <div className="col-lg-4 col-9 mb-2 text-left  f-fred tx-lt-grey">
                                <p>{resData.name ? resData.name : "Anonymous"}</p>
                                <p className='ms-500 fs-14'>Student</p>
                            </div>
                            <div className="col-lg-6 my-auto mb-2">
                                <button className="btn bg-green ms-700 w-100 py-1 px-2 text-white fs-12 wrap"><FaCalendarCheck /> &nbsp;
                                    {moment(resData.date).format('DD MMM YYYY')} {resData.start_time && resData.start_time != "Invalid date" && " at " + moment(`${resData.start_time}`,'HH:mm').format('hh:mm a')}
                                </button>
                            </div>
                        </div>

                        
                    </>
                }
                {pageload && <Backdrop />}
            </div>
        </div>
    )
}

export default UserNotifyPopup
