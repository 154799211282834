import moment from 'moment';
import React from 'react'
import { useState, useRef } from 'react';
import helper from '../helper/index';
import user from '../controller/user';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { EmailShareButton, FacebookShareButton,LinkedinShareButton, TelegramShareButton, WhatsappShareButton } from "react-share";
import { EmailIcon, FacebookIcon, WhatsappIcon, TelegramIcon, LinkedinIcon } from "react-share";

function UserReferPopup({ code, handleClose }) {
    const reduxState = useSelector(state => state)
   
    const { t } = useTranslation();
    const outSide = useRef()
    const [emailList, setEmailList] = useState([])
    const email = useRef()
    const referralForm = useRef()

    const shareCode = async () => {
        const req = {
            referral_code: code,
            user_id: helper.getid(),
            emails: emailList
        }
        if (emailList.length === 0) {
            helper.showMessage("Please add atleast one email","warning")
            return
        }
        await user.shareReferralCode(req).then((res) => {
           
            if (res.data.status) {
                setEmailList([])
                helper.showMessage("Referral code shared successfully","success")  
                handleClose() 
            }
            else {
                helper.showMessage(res.data.error,"error")
            }
        })
    }

   
    const addEmail = () => {
        if (!email.current.value) {
        helper.showMessage("please enter valid email","warning")
          
            return
        }
        const resp = helper.validate('email', email.current.value)
        if (!resp.isValid) {
           
            helper.showMessage(resp.msg,"warning")
            return
        }
        let list = [...emailList]
        list.push({
            email: email.current.value
        })
        referralForm.current.reset()
        setEmailList(list)
    }

    const remove = (index) => {
        let list = [...emailList]
        list.splice(index, 1)
        setEmailList(list)
    }

    return (
        <div className={`faded ${reduxState.language === 'ar' && "text-right"}`} ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => handleClose()) }}>
            <div className="p-5 rightpop-up" >
                <form ref={referralForm} onSubmit={(e) => { e.preventDefault(); shareCode() }}>
                    <div className="row gx-0 mx-0 ">
                        <div className="col-10 px-0 ">
                            <h4 className="f-fred tx-lt-black">{t('userSidebar.referral.getFree')} <span className="tx-green">10 {t('userSidebar.referral.min')}</span></h4>
                            <p className="sub-heading">{moment(new Date()).format('ddd, DD MMM YYYY hh:mm A')}</p>
                        </div>
                        <div className="col-2 d-flex justify-content-end  tx-grey align-items-center "><i className="far pointer hover-shadow fs-24 fa-times-circle" onClick={handleClose}></i></div>

                        <div className="col-lg-8 mt-3 mb-3 ms-900">
                            <h5 className="tx-lt-grey">{t('userSidebar.referral.code')}:</h5>
                            <h5 className="tx-sky">{code ? code : "NA"}</h5>

                        </div>
                        <div className="col-lg-4"></div>
                        <p className="mt-2 fs-16 text-justify tx-lt-grey ms-500"> {t('userSidebar.referral.para')} </p>
                        <p className="ms-900 mt-2">Eg: friend-one@example.com, friend-two@example.com</p>
                        <WhatsappShareButton type='button' url={`https://saydhad.com/user/signup/${code}`} title={`Hello👋🏽, Thank you for using the Referral Management for SayDhad.You can use the below free minutes on SayDhad classes. Referral code : ${code} Minutes`}>
                            <WhatsappIcon className='m-1' size={30} round />
                        </WhatsappShareButton>
                        <FacebookShareButton type='button' quote={`Hello👋🏽, Thank you for using the Referral Management for SayDhad.You can use the below free minutes on SayDhad classes. Referral code : ${code} Minutes`} hashtag='learnArabic' url={`https://saydhad.com/user/signup/${code}`}>
                            <FacebookIcon className='m-1' size={30} round />
                        </FacebookShareButton>
                        <LinkedinShareButton type='button' title='SayDhad referral' source='saydhad.com' url={`https://saydhad.com/user/signup/${code}`} summary={`Hello👋🏽, Thank you for using the Referral Management for SayDhad.You can use the below free minutes on SayDhad classes. Referral code : ${code} Minutes`}>
                            <LinkedinIcon className='m-1' size={30} round />
                        </LinkedinShareButton>
                        <TelegramShareButton type='button' url={`https://saydhad.com/user/signup/${code}`} title={`Hello👋🏽, Thank you for using the Referral Management for SayDhad.You can use the below free minutes on SayDhad classes. Referral code : ${code} Minutes`}>
                            <TelegramIcon className='m-1' size={30} round />
                        </TelegramShareButton>
                        <EmailShareButton type='button' subject='Saydhad referral' url={`https://saydhad.com/user/signup/${code}`} body={`Hello👋🏽, Thank you for using the Referral Management for SayDhad.You can use the below free minutes on SayDhad classes. Referral code : ${code} Minutes`}>
                            <EmailIcon className='m-1' size={30} round />
                        </EmailShareButton>

                        <div className="col-lg-12  d-flex justify-content-center my-3 ms-700">
                            <input ref={email} type="text" placeholder={t('userSidebar.referral.friend')} className="w-100 b-grey form-control p-2" />
                        </div>
                        <div className="col-12">
                            {emailList.map((data, index) => {
                                return (
                                    <span key={`email${index}`} className='my-1 mr-2 mb-1 d-inline-block b-grey ms-500 px-2'>{data.email} <i className='fas pointer fa-times fs-14' onClick={() => remove(index)}></i> </span>
                                )
                            })}
                        </div>
                        <div className="col-12 mt-2">
                            <button type='button' className='btn bg-sky text-white fs-14 px-2 py-1 f-fred ls ' onClick={addEmail}>{t('userSidebar.referral.add')}</button>
                        </div>
                        <div className="col-lg-9 my-2 ">
                            <button type='submit' className="btn fs-14 btn-tutor-profile f-fred ls">{t('userSidebar.referral.freeMin')}</button>
                        </div>


                    </div>
                </form>

            </div>
        </div>
    )
}

export default UserReferPopup

