import React, { useState } from 'react'
import arrow1 from '../static/images/slidearrow1.svg'
import arrow2 from '../static/images/slidearrow2.svg'
import logo from '../static/images/logo.png'
import Slide from 'react-reveal/Slide';
import agora from '../controller/agora';
import { useParams } from 'react-router-dom';
import helper from '../helper';
export default function TutorSlideShow({ data, cb, startIndex, name, lesson, onMarkAsStudy, progressIndex, courseId, enrolledCourseId, studentId }) {
    const [gridView, setGridView] = useState(true)
    const { channelName } = useParams()
    const [start, setStart] = useState(false)
    const [isFull, setIsFull] = useState(false)
    const [state, setState] = useState({
        currentIndex: startIndex,
        noOfSlides: data.length
    })

    const [progressData, setProgressData] = useState(progressIndex)
    const [isComplete, setIsComplete] = useState(false)

    function fullScreen(isFull) {
        try {
            if (!isFull) {
                setIsFull(true)
                const ob = document.getElementById("syllab-fullscreen");
                ob.requestFullscreen();
            } else {
                setIsFull(false)
                document.exitFullscreen();
            }
        } catch (err) {

        }

    }

    const markAttendance = (index) => {
        let list = [...progressData]
        if (index === 0) {
            list.push(index)
            setProgressData(list)
            onMarkAsStudy(index)
            markSlide(index)
            setState({ ...state, currentIndex: state.currentIndex < data.length - 1 ? state.currentIndex + 1 : state.currentIndex })
        }
        else {
            if (list.includes(index - 1)) {
                list.push(index)
                setProgressData(list)
                onMarkAsStudy(index)
                markSlide(index)
                if (index === data.length - 1) {
                    setIsComplete(true)

                } else {
                    setState({ ...state, currentIndex: state.currentIndex < data.length - 1 ? state.currentIndex + 1 : state.currentIndex })
                }
            } else {
                alert("Please complete previous slide")
            }
        }
    }

    const markSlide = async (index) => {
        const req = {
            "student_id": studentId,
            "channel_name": channelName,
            "enrolled_course_id": enrolledCourseId,
            "course_id": courseId,
            "syllabus_id": data[index].syllabus_id,
            "syllabus_slide_id": data[index].id
        }
        await agora.markSlideAsStudy(req).then(async (res) => {
            if (res.data.status) {
                markStudentCourse()
            }
        })
    }

    const markStudentCourse = async () => {
        const req = {
            "tutor_id": helper.getid(),
            "channel_name": channelName,
            "course_id": courseId,
            "syllabus_id": data[0].syllabus_id
        }
        await agora.markStudentCourseSyllabus(req).then((res) => {
            if (res.data.status) { }
        }).catch((err) => { })
    }
    return (
        <>
            <div className="popup-modal all-center">
                <div id="syllab-fullscreen" className="popup-modal-body p-rel md px-2 hide-scroll"
                //  onClick={() => {
                //     let ob = document.getElementById("drop-icon-slide")
                //     if (ob?.checked)
                //         ob.checked = false
                // }}
                >
                    {start && <div className='py-2 bg-white p-sticky tp-0' >
                        <div className='d-flex w-100 align-items-center justify-content-between'>
                            <p className='mx-2 ms-700'><span className='tx-green'>Completed slides</span> : {progressData.length}/{data.length}</p>
                            <div className='all-center'>
                                {gridView && !progressData.includes(state.currentIndex) && <button className='btn bg-sky py-1 px-2 br-1 text-white fs-16 ms-700' onClick={() => markAttendance(state.currentIndex)}>Mark as complete</button>
                                }
                                <p className='mx-2 ms-700 fs-14'>
                                    {progressData.includes(state.currentIndex) ?
                                        <>
                                            <i className="far fa-check-square mx-2 tx-green fs-16"></i> <span className='tx-green'>This slide has been completed</span>

                                        </>
                                        :
                                        <>
                                            <i className="fas fa-exclamation-triangle tx-red fs-16 mx-2"></i> <span className='tx-red'>Complete this slide</span>
                                        </>
                                    }
                                </p>
                            </div>

                        </div>
                    </div>}
                    {!start ? <div className="row" style={{ height: '450px' }}>
                        <div className="col-lg-5 col-md-4 all-center">
                            <img style={{ maxWidth: '100%' }} className="br-2" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYaPsCZiq6EmcTAC6Pi9n-myuGdcLsREcYRA&usqp=CAU" alt="banner" />
                        </div>
                        <div className="col-lg-7 col-md-8 f-fred d-flex flex-column justify-content-center align-items-md-center align-items-sm-center">
                            <img style={{ width: '200px' }} src={logo} alt="logo" />
                            <div className="mt-3">
                                <h5 className="text-capitalize">{name}</h5>
                                <p>Lesson {lesson}</p>
                                <button className="btn btn-tutor-profile mr-3 my-2" style={{ width: '120px' }} onClick={() => setStart(true)}>START</button>
                                <button className="btn cancel-subscription fs-14 ls my-2" style={{ width: '120px' }} onClick={() => cb()}>CANCEL</button>

                            </div>
                        </div>
                    </div> : <>
                        <section className='my-3'>
                            {!isComplete ? <div className='all-center ' style={{ minHeight: '450px' }}>
                                <img style={{ maxWidth: '100%' }} className='h-100 br-1' src={helper.replaceS3Url(data[state.currentIndex].fileName)} alt="slide-complete" />
                            </div>
                                :
                                <div className="d-flex flex-column align-items-center" style={{ minHeight: '450px' }}>
                                    <img style={{ maxWidth: '100%' }} className="br-2 h-100" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYaPsCZiq6EmcTAC6Pi9n-myuGdcLsREcYRA&usqp=CAU" alt="banner" />

                                    <h4 className="f-fred tx-lt-grey my-3">Thank you</h4>
                                    <div className='mb-3'>
                                        <button className="btn btn-tutor-profile mr-3 my-2" style={{ width: '120px' }} onClick={() => { setIsComplete(false); setState({ ...state, currentIndex: 0 }) }}>START AGAIN</button>
                                        <button className="btn cancel-subscription f-fred fs-14 ls my-2" style={{ width: '120px' }} onClick={() => cb()}>CLOSE</button>

                                    </div>
                                </div>
                            }
                        </section>
                        {
                            !gridView ?
                                <Slide bottom>
                                    <section className='p-sticky bt-0 w-100 lt-0'>
                                        <div className='row gx-0 mx-0 pt-2 bg-white w-100'>
                                            <section className='col-lg-5 col-md-4 col-12 mb-2 d-flex align-items-center'>
                                                <p className='ms-700 tx-lt-grey mx-2 overflow-elipse'>
                                                    <span className='ms-900'>Title :</span>  {data[state.currentIndex].title}
                                                </p>
                                            </section>
                                            <section className='col-lg-3 col-md-4 col-12 mb-2 d-flex  align-items-center'>
                                                {!progressData.includes(state.currentIndex) && <button className='btn bg-sky py-1 px-2 br-1 text-white fs-16 ms-700' onClick={() => markAttendance(state.currentIndex)}>Mark as complete</button>}
                                            </section>
                                            <section className='col-lg-4 col-md-7 col-12 mb-2'>
                                                <div className='all-center'>
                                                    {state.currentIndex > 0 && <img src={arrow1} className="mx-2 pointer hover-shadow" alt="arrow" onClick={() => { setState({ ...state, currentIndex: state.currentIndex > 0 ? state.currentIndex - 1 : state.currentIndex }) }} />}
                                                    <span className='f-fred fs-20 tx-lt-black mx-1'>Slide {state.currentIndex + 1} of {data.length}</span>
                                                    {state.currentIndex < data.length - 1 && <img src={arrow2} className="mx-2 pointer hover-shadow" onClick={() => { setState({ ...state, currentIndex: state.currentIndex < data.length - 1 ? state.currentIndex + 1 : state.currentIndex }) }} alt="arrow" />}
                                                    <section className='p-rel c-dropdown  d-inline-block'>
                                                        <input type="checkbox" className='c-drop-icon d-none' id="drop-icon-slide" />
                                                        <label htmlFor="drop-icon-slide"> <i className="fas fa-ellipsis-v c-dropdown-icon pointer ml-2 hover-shadow"></i></label>
                                                        <div className='p-abs c-dropdown-body bg-sky  fs-16  ms-700 rt-0 bg-white' style={{
                                                            width: '190px', bottom: '25px',
                                                            right: '20px', top: 'initial'
                                                        }}>
                                                            <ul style={{ listStyle: 'none' }}>
                                                                <li onClick={cb} style={{ listStyle: 'none' }}><label htmlFor="drop-icon-slide" > CLOSE</label></li>
                                                                <li onClick={() => fullScreen(isFull)}><label htmlFor="drop-icon-slide" >{isFull ? "EXIT FULLSCREEN" : " FULLSCREEN"}</label></li>
                                                                <li onClick={() => setGridView(!gridView)}><label htmlFor="drop-icon-slide">{!gridView ? "OPEN GRID" : "CLOSE GRID"}</label></li>
                                                            </ul>
                                                        </div>
                                                    </section>
                                                </div>
                                            </section>
                                        </div>
                                    </section>
                                </Slide>
                                :
                                <Slide bottom>
                                    <section className='p-sticky bt-0 lt-0 w-100 hide-scroll'>
                                        <div className='d-flex align-items-center hide-scroll ' style={{ overflowX: 'auto', backgroundColor: 'rgba(128, 128, 128, 0.418)' }}>
                                            {data.map((file, index) =>
                                                <div key={`ss${index}`} className={`${state.currentIndex === index ? "border-sky" : "border-yellow"} br-1 bg-white mx-2 d-inline-block my-2 pointer`}
                                                    onClick={() => { setState({ ...state, currentIndex: index }) }}
                                                >
                                                    <img style={{ width: '150px', height: '100px' }} className='br-1' src={helper.replaceS3Url(file.fileName)} alt="file" />
                                                    <div className='d-flex align-items-center' style={{ width: '150px' }}>
                                                        {progressData.includes(index) && <i className="far fa-check-square mx-1 tx-green fs-16"></i>}
                                                        <p className='fs-14 ms-700 my-2 text-center overflow-elipse px-1'>{file.title}</p>

                                                    </div>
                                                </div>)}
                                        </div>
                                        <i className='fas p-abs tp-0 rt-0 m-2 fa-times d-inline-block fs-20 hover-shadow pointer bg-sky rounded-circle' onClick={() => setGridView(!gridView)}></i>
                                    </section>
                                </Slide>
                        }

                    </>
                    }
                </div>
            </div>

        </>
    )
}
