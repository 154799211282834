/* eslint-disable */
import React, { useState, useEffect } from 'react'
import ProgressBar from '../component/ProgressBar'
import SlidesShow from './SlideShow'
import ThreeDot from './ThreeDot'
export default function SyllabusCard({ title, slide, lessonNo, isComplete, islock, showProgress, files, isTutorEnrolled, completedSlides, courseId, enrolledCourseId,onComplete ,studentId,isAttendanceMark}) {
    const [state, setState] = useState({
        openSlide: false,
        index: 0,
        completedSlides: completedSlides
    })
    const [progressIndex, setProgressIndex] = useState([])
    useEffect(() => {
        let list = []
        files.forEach((data, index) => {
            if (data.isCompleted) {
                list.push(index)
            }
        })
        setProgressIndex(list)
    }, [])

    const increaseProgress = (index) => {
        isAttendanceMark()
        if (slide >= state.completedSlides) {
            if(state.completedSlides + 1 === slide){
                onComplete()
            }
            setState({
                ...state,
                index: state.index,
                completedSlides: state.completedSlides + 1
            })
            let list = [...progressIndex]
            list.push(index)
            setProgressIndex(list)  
        }
    }
    return (
        <>
            <section className="library-card b-grey br-1 mb-2 fs-14 ms-500 py-2 bg-white px-2">
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center '>
                        <div className="my-1 mr-2">
                            <p className="f-fred lh-15">{lessonNo}</p>
                            <p>lesson</p>
                        </div>
                        <div className="my-1">
                            <p className="f-fred lh-15 fs-14 text-capitalize">{title}</p>
                            <p>lesson title</p>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className="my-1 mr-2">
                            <p className="f-fred lh-15 fs-14 text-capitalize">{slide}</p>
                            <p>slides</p>
                        </div>

                        {showProgress &&
                            <>

                                {isComplete ?
                                    <div>
                                        <i className="far fa-check-square tx-green fs-16"></i>
                                    </div>
                                    : ''}

                                {islock ?
                                    <div>
                                        <i className="fas fa-lock tx-red fs-16"></i>
                                    </div> :
                                    <div className=''>
                                        <ThreeDot
                                            isTutorEnrolled={isTutorEnrolled}
                                            data={["View slides"]}
                                            onClick={() => setState({ ...state, openSlide: true, index: 0 })}
                                        />
                                    </div>}
                            </>
                        }

                    </div>
                </div>
                {showProgress &&
                    <div>
                        <ProgressBar
                            width="100%"
                            height={"8px"}
                            bg={state.completedSlides === slide ? "#099d14":"#ffcb00"}
                            now={state.completedSlides}
                            total={slide}
                        />
                        <div className='text-dark ms-700 mt-1'>
                            completed : {state.completedSlides}/{slide}
                        </div>
                    </div>
                    }
            </section>

            {state.openSlide &&
                <SlidesShow
                    data={files}
                    cb={() => setState({ ...state, openSlide: false })}
                    startIndex={0}
                    name={title}
                    progressIndex={progressIndex}
                    lesson={lessonNo}
                    onMarkAsStudy={(index) => increaseProgress(index)}
                    courseId={courseId}
                    enrolledCourseId={enrolledCourseId}
                    studentId = {studentId}
                />}
        </>
    )
}
