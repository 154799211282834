import React, { useState } from 'react'
import { useHistory } from 'react-router'
import logo from '../static/images/Logo.svg'
import helper from '../helper/index'
import Backdrop from '../component/Backdrop'
import firebase from '../config/firebaseAuth';
import googleImage from "../static/images/Group 587.png";
import facebookImage from "../static/images/Group 586.png";
import auth, { verifyUserEmail } from "../controller/auth";
import quoteIcon from '../static/images/quote.svg'
import ToggleHidePassword from '../component/ToggleHidePassword'
import { NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet'
import LanguageTranslator from '../component/LanguageTranslator'

export default function UserSignup() {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const submit = async () => {
    let resp;
    resp = helper.validate("email", email);
    if (!resp.isValid) {
      helper.showMessage(resp.msg, "error")
      return;
    }
    resp = helper.validate("password", password);
    if (!resp.isValid) {
      helper.showMessage(resp.msg, "error")
      return;
    }
    const decEmail = helper.encrypt(email ? email.toLowerCase() : email)
    const decPass = helper.encrypt(password)

    await verifyUserEmail({ email: email ? email.toLowerCase() : email }).then((res) => {
      if (res.data.status) {
        history.push(`/select-role?tk1=${decEmail}&&tk2=${decPass}`)
      } else {
        helper.showMessage(res.data.error, "info")
      }
    })

  }


  const signInWithGoogle = async () => {
    const response = await firebase.signInWithGoogle();
    if (!response.status) {
      return helper.showMessage(response.error, "error")
    }
    const reqData = {
      socialType: "google",
      uid: response.uid
    };
    setOpen(true)
    await auth.userSocialLogin(reqData).then((res) => {
      if (res.data.status) {
        if (res.data.result.isRegistrationComplete) {
          helper.saveCredential(
            res.data.result.token,
            "user", res.data.result.id,
            "approved",
            res.data.result.email)
          history.push(`/user/dashboard/home`)
        } else if (!res.data.result.isRegistrationComplete) {
          const decEmail = helper.encrypt(res.data.result.email)
          const resData = {
            email: res.data.result.email,
            id: res.data.result.id, // 99
            isRegistrationComplete: false,
            name: res.data.result.name,
            role: 2,
            token: res.data.result.token
          }
          localStorage.setItem('HDSUFSD', helper.decrypt(JSON.stringify(resData)))
          history.push(`/user/little-more?tk1=${decEmail}&auth=google`)
        }
      }
      else {
        helper.showMessage(res.data.error, "error")
      }
      setOpen(false)
    }).catch((e) => { });
  };

  const signInWithFacebook = async () => {

    Promise.all([firebase.signInFacebook()]).then(async (resp) => {
      if (!resp[0].status) {
        return helper.showMessage(resp[0].error, "error")
      }
      const reqData = {
        socialType: "facebook",
        uid: resp[0].uid
      };
      setOpen(true)
      await auth
        .userSocialLogin(reqData)
        .then((res) => {
          if (res.data.status) {
            if (res.data.result.isRegistrationComplete) {
              helper.saveCredential(
                res.data.result.token,
                "user", res.data.result.id,
                "approved",
                res.data.result.email)
              history.push(`/user/dashboard/home`)
            } else if (!res.data.result.isRegistrationComplete) {
              const decEmail = helper.encrypt(res.data.result.email)
              const resData = {
                email: res.data.result.email,
                id: res.data.result.id, // 99
                isRegistrationComplete: false,
                name: res.data.result.name,
                role: 2,
                token: res.data.result.token
              }
              localStorage.setItem('HDSUFSD', helper.decrypt(JSON.stringify(resData)))
              history.push(`/user/little-more?tk1=${decEmail}&auth=facebook`)
            }
          }
          else {
            helper.showMessage(res.data.error, "error")
          }
          setOpen(false)
        })
        .catch((e) => { });
    });
  };

  return (
    <div className="tutor-login-home lg-bg-image" >
      {/* SEO */}
      <Helmet>
        <title>Create Your Learning Profile - SayDhad</title>
        <meta name="description" content="Create your learning profile and become fluent in Arabic with SayDhad experts. Just sign in by adding some basic details, and you will get access to the journey of learning Arabic. Create your learning profile now and stay ahead to learn and become proficient." />
        <link rel="canonical" href=" https://saydhad.com/signup/" />
      </Helmet>
      {/* <div className="mb-2 d-flex p-2" style={{ position: "relative", zIndex: 2 }}>
        <LanguageTranslator className={"ml-auto"} />
      </div> */}
      <section className="row gx-0 mx-0 h-100 py-5 cpx-4">
        <div className="col-lg-7 col-md-7 cpx-4 d-lg-flex d-md-flex d-none  align-items-start flex-column f-fred text-white">
          <div className="overlay-bg"></div>
          <div className="fs-61 overlay-text">
            <h1>
              <img src={quoteIcon} alt="quote" />
            </h1>
            <p className="text-white" style={{ lineHeight: '66px' }}>{t('auth.tutorQuote1')}</p>
            <p style={{ lineHeight: '66px' }}>{t('auth.tutorQuote2')}</p>
            <p className="fs-31 mt-3">~{t('general.quoteAuthor')}</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-5 my-3  bg-white login-home-box px-3 py-3  all-center" >

          <div className="row gx-0 mx-0 w-100">
            <div className="col-lg-10 mx-auto d-flex flex-column justify-content-between">
              <div className="mb-2 ml-auto">
                <LanguageTranslator />
              </div>
              <div className="upper">
                <div className="all-center px-2">
                  <NavLink to="/"> <img width="230px" src={logo} alt="logo" /></NavLink>
                </div>
                <div className="all-center flex-column">
                  <p className="f-fred text-center heading-y-margin fs-24 mb-1 mt-3">{t('auth.createStudentProfile')} </p>
                  {/* <p className="f-fred text-center fs-24 heading-y-margin mb-1 mt-3">{t('auth.create-a')} <span className="tx-green">{t('auth.learning')}</span> {t('auth.profile')}</p> */}
                  <div className="mt-1 w-100">
                    <div className="input-box mb-2">
                      <input maxLength={40} type="text" placeholder={t('auth.forgot.email')} className="px-2 py-1 ms-700 box-height" onChange={(e) => { setEmail(e.target.value); }} />
                    </div>
                    <ToggleHidePassword onChange={(e) => { setPassword(e.target.value); }} />

                  </div>
                  <button className="btn heading-y-margin btn-tutor-profile mt-3 px-2 box-height w-100" onClick={() => submit()}>{t('auth.proceed')}</button>
                </div>
                <div className="inline-text my-4 all-center">
                  <p ><span className="bold">OR</span></p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <img src={facebookImage} height={'39px'} onClick={() => signInWithFacebook()} className='pointer mr-2 f-fred box-height d-inline-block' alt="facebook" />
                  <img src={googleImage} className='pointer f-fred box-height d-inline-block' onClick={() => signInWithGoogle()} alt="google" />
                </div>
              </div>
              <div className="all-center flex-column">
                <button className="btn btn-login  w-100 mt-5 box-height" onClick={() => history.push('/login')}>{t('auth.loginExistAccount')}</button>
                <p className="my-2 mt-4 ms-500 text-center login-footer-text" style={{ width: '80%' }}>{t('auth.policy.text1')} <span className="tx-lt-black ms-900">{t('auth.saydhad')}</span>. {t('auth.policy.text2')} <span className="ms-900 tx-lt-black"> <NavLink to="/web/terms-condition">{t('auth.policy.terms')} </NavLink></span> {t('auth.policy.and')} <span className="ms-900 tx-lt-black"><NavLink to="/web/privacy-policy">{t('auth.policy.policy')}</NavLink></span></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {open && <Backdrop />}
      <Helmet htmlAttributes={{ dir: "ltr" }} />
    </div>
  )
}
