import React from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";

export default function Privacypolicy() {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div>
                <div className="row ms-500">
                    <div className="col-lg-10 col-12 mx-auto py-2">

                        <p>{t('legalPages.privacyPolicy.para1')}</p>
                        <p>{t('legalPages.privacyPolicy.para2')}</p>
                        <p>{t('legalPages.privacyPolicy.para3')}</p>
                        <p>{t('legalPages.privacyPolicy.para4')}</p>
                        <p>{t('legalPages.privacyPolicy.para5')}</p>
                        <ul>
                            <li>{t('legalPages.privacyPolicy.bullet1')}</li>
                            <li>{t('legalPages.privacyPolicy.bullet2')}</li>
                            <li>{t('legalPages.privacyPolicy.bullet3')}</li>
                            <li>{t('legalPages.privacyPolicy.bullet4')}</li>
                            <li>{t('legalPages.privacyPolicy.bullet5')}</li>
                            <li>{t('legalPages.privacyPolicy.bullet6')}</li>
                            <li>{t('legalPages.privacyPolicy.bullet7')}</li>
                            <li>{t('legalPages.privacyPolicy.bullet8')}</li>
                            <li>{t('legalPages.privacyPolicy.bullet9')}</li>
                            <li>{t('legalPages.privacyPolicy.bullet10')}</li>
                        </ul>
                        <p>{t('legalPages.privacyPolicy.para6')}</p>
                        <p>{t('legalPages.privacyPolicy.para7')}</p>
                        <p>{t('legalPages.privacyPolicy.para8')}</p>
                        <p>{t('legalPages.privacyPolicy.para9')}</p>
                        <p>{t('legalPages.privacyPolicy.para10')}</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
