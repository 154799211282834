/* eslint-disable */
import axios from 'axios'
import helper from '../helper/index'
import { constant } from '../config/Contant'
const BASE_URL = constant.BASE_URL
const URL = constant.TUTOR_URL

const getBasicInfo = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'get',
            url: `${URL}/basic-Info/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const updateBasicInfo = async (userData) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'put',
            url: `${URL}/basic-Info`,
            data: userData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const updateIntroduction = async (userData) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'put',
            url: `${URL}/introduction`,
            data: userData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const updateTeachingStyle = async (userData) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'put',
            url: `${URL}/teaching-style`,
            data: userData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}



const updateAbout = async (userData) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'put',
            url: `${URL}/about-me`,
            data: userData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}


const getworkExperience = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'get',
            url: `${URL}/work-experience/${id}`,

            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}


const addWorkExperience = async (userData) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'post',
            url: `${URL}/tutor-work-experience`,
            data: userData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const deleteWorkExperience = async (userData) => {
    try {
        const token = helper.getToken()

        const res = await axios({
            method: 'delete',
            url: `${URL}/tutor-work-experience`,
            data: userData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}


const getEducation = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'get',
            url: `${URL}/education/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}


const addEducation = async (userData) => {
    try {
        const token = helper.getToken()

        const res = await axios({
            method: 'post',
            url: `${URL}/add-education`,
            data: userData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const deleteEducation = async (userData) => {
    try {
        const token = helper.getToken()

        const res = await axios({
            method: 'delete',
            url: `${URL}/tutor-education`,
            data: userData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const getLanguage = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'get',
            url: `${URL}/language/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}


const addLanguage = async (userData) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'post',
            url: `${URL}/add-language`,
            data: userData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const delLanguage = async (userData) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'delete',
            url: `${URL}/tutor-language`,
            data: userData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const addCertificate = async (userData) => {
    try {

        const token = helper.getToken()
        const res = await axios({
            method: 'post',
            url: `${URL}/teaching-certificate`,
            data: userData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const getCertificate = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'get',
            url: `${URL}/teaching-certificate/${id}`,

            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const delCertificate = async (userData) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'delete',
            url: `${URL}/teaching-certificate`,
            data: userData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const addDigInDeep = async (userData) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'put',
            url: `${URL}/dig-in-deep`,
            data: userData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const getDigInDeep = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'get',
            url: `${URL}/dig-in-deep/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}


const submitProfile = async (userData) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'post',
            url: `${URL}/upload-tutor-profile`,
            data: userData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const addBankInfo = async (userData) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'put',
            url: `${URL}/bank-detail`,
            data: userData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const getBankInfo = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'get',
            url: `${URL}/bank-detail/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const addProfilePic = async (userdata) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'put',
            url: `${URL}/profile-picture`,
            data: userdata,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const addIntroVideo = async (userdata) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'put',
            url: `${URL}/intro-video`,
            data: userdata,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const getCourse = () => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/course-detail`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getCourseListing = (userId) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/course/course-detail-list?user_id=${userId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getCourseById = (id, tid) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/course/find-course-detail-by-id?courseId=${id}&user_id=${tid}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getCourseFromLibrary = (id) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/course/find-course-by-tutorid/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const addCourseIntoLibrary = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `post`,
            url: `${BASE_URL}/tutor/course-add-library`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const removeCourseFromLibrary = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `delete`,
            url: `${BASE_URL}/tutor/course-remove-library`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}



const addAvailibilty = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `post`,
            url: `${BASE_URL}/tutor/tutor-class-availability`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}



const getAvailibilty = (id) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/tutor-class-availability?tutor_id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const updateAvailibilty = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `put`,
            url: `${BASE_URL}/tutor/tutor-class-availability`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const createTicket = (data) => {
    try {
        const token = helper.getToken()
        const role = helper.getRoleType()
        if(role === "user"){
            const res = axios({
                method: `post`,
                url: `${BASE_URL}/student/create-support-ticket`,
                data: data,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            return res
        }else if(role === "tutor"){
            const res = axios({
                method: `post`,
                url: `${BASE_URL}/tutor/create-support-ticket`,
                data: data,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }) 
            return res
        }
        
        
    }
    catch (err) {
        return err;
    }
}


const ticketResolve = (data) => {
    try {
        const token = helper.getToken()
        const role = helper.getRoleType()
        if(role === "user"){
            const res = axios({
                method: `put`,
                url: `${BASE_URL}/student/edit-status-student-support-ticket`,
                data: data,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            return res
        }else if(role === "tutor"){
            const res = axios({
                method: `put`,
                url: `${BASE_URL}/tutor/edit-status-tutor-support-ticket`,
                data: data,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }) 
            return res
        }
        
        
    }
    catch (err) {
        return err;
    }
}

const getTicket = (tutor_id) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/support-ticket-listing?user_id=${tutor_id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const getSupportCount = (userId) => {
    try {
        const token = helper.getToken()
        let role = 2;
        let roleType = helper.getRoleType()
        if(roleType === "tutor"){
            role = 3
        }
       
        const res = axios({
            method: `get`,
            url: `${BASE_URL}//support-knowledgebase?user_id=${userId}&role=${role}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getFaqs = (faqFor) => {
    try {
        let roleType = helper.getRoleType()
        let role = faqFor || 2
        if (roleType === "tutor") {
            role = 3
        }
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/find-faq-categories?role=${role}&id=`,
            // headers: {
            //     'Authorization': `Bearer ${token}`
            // }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const getHowToVideos = () => {
    try {
        const token = helper.getToken()
        let roleType = helper.getRoleType()
        let role = 2
        if (roleType === "tutor") {
            role = 3
        }
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/support-videos-list?role=${role}&id=`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const searchHowToVideos = ({ title }) => {
    try {
        const token = helper.getToken()
        let roleType = helper.getRoleType()
        let role = 2
        if (roleType === "tutor") {
            role = 3
        }
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/find-support-videos?role=${role}&title=${title}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}



const searchHowToArticles= ({ title: title }) => {
    try {
        const token = helper.getToken()
        let roleType = helper.getRoleType()
        let role = 2
        if (roleType === "tutor") {
            role = 3
        }
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/find-support-articles?role=${role}&title=${title}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getArticles = () => {
    try {
        const token = helper.getToken()
        let roleType = helper.getRoleType()
        let role = 2
        if (roleType === "tutor") {
            role = 3
        }
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/support-article-list?role=${role}&id=`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const getBookedSlot = (id, date) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/find-student-slot-booked?tutor_id=${id}&date=${date}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getBookedStudentDetailsSlot = (id, date) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/student/find-slot-booked-student-by-tutorId?tutor_id=${id}&date=${date}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const OverrideTimeSlot = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `put`,
            url: `${BASE_URL}/tutor/edit-slot-availability`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getEnrolledStudent = (id) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/find-enrolled-student-list-by-tutorId/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getEnrolledStudentDetails = (tutor_id, studentId) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/find-enrolled-student-detail-by-tutorId?tutor_id=${tutor_id}&student_id=${studentId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getTutorAnalytics = (tutor_id, startDate, endDate) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/find-stats-and-analytics?tutor_id=${tutor_id}&start_date=${startDate}&end_date=${endDate}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getTutorNotifications = (tutor_id) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/notifications-list/${tutor_id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getTutorNotificationsById = (tutor_id, id) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor//notifications-by-Id?user_id=${tutor_id}&id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}



const markAsRead = (tutor_id, notification_id) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `put`,
            url: `${BASE_URL}/tutor/notification/mark-as-read`,
            data: { user_id: tutor_id, id: notification_id },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const createChatRoom = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `post`,
            url: `${BASE_URL}/chat/create-chat-room`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getChatStudentList = (id) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/chat-student-list-by-tutorId/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const getChatStudentDetail = (tutor_id, student_id) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/chat/find-booked-slot-student-by-tutorId?tutor_id=${tutor_id}&student_id=${student_id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getlegalPages = () => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/admin/policy-pages`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const cancelClass = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `put`,
            url: `${BASE_URL}/tutor/cancel-booked-class`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const rescheduleClass = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `put`,
            url: `${BASE_URL}/tutor/booked-class-re-schedule`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const changePassword = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `post`,
            url: `${BASE_URL}/tutor/change-password`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getBookedClassStudentDetail = (tutor_id, st_time, end_time, date) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/student/selected-slot-student-details-by-tutorId?tutor_id=${tutor_id}&start_time=${st_time}&end_time=${end_time}&date=${date}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const tutorClassend = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `put`,
            url: `${BASE_URL}/tutor/add-booking-class-time`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const tutorDirectClassend = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `put`,
            url: `${BASE_URL}/tutor/add-direct-class-end-time`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const setOnline = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `put`,
            url: `${BASE_URL}/tutor/online`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const tutorTestimonials = (id, page, limit) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/testimonial-details?tutorId=${id}&page=${page}&limit=${limit}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getAttendaceRecord = ({tid,startDate,endDate}) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/attendance-history?tutor_id=${tid}&start_date=${startDate}&end_date=${endDate}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const checkForTutorCourseAvailability = () => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/check-course-enrolled`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

export default {
    getBasicInfo, updateBasicInfo, updateIntroduction, updateTeachingStyle,
    updateAbout, getworkExperience, addWorkExperience, deleteWorkExperience, getEducation,
    addEducation, deleteEducation, getLanguage, addLanguage, delLanguage,
    addDigInDeep, getDigInDeep, submitProfile, getCertificate, addCertificate, delCertificate,
    getBankInfo, addBankInfo, addProfilePic, addIntroVideo, getCourseListing,
    getCourse, getCourseById, addCourseIntoLibrary, removeCourseFromLibrary, getCourseFromLibrary,
    addAvailibilty, getAvailibilty, updateAvailibilty, createTicket, getTicket, getFaqs,
    getBookedSlot, OverrideTimeSlot, getHowToVideos,searchHowToArticles,searchHowToVideos, getArticles, getEnrolledStudent, getEnrolledStudentDetails,
    getTutorAnalytics, getBookedStudentDetailsSlot, getTutorNotifications, markAsRead,
    getChatStudentDetail, getChatStudentList, createChatRoom, getlegalPages, cancelClass, rescheduleClass, changePassword,
    getBookedClassStudentDetail, tutorClassend, setOnline, getTutorNotificationsById, tutorTestimonials,getAttendaceRecord, tutorDirectClassend,
    ticketResolve,getSupportCount,checkForTutorCourseAvailability
}