/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import logo from '../static/images/Logo.svg'
import tutorParrot from '../static/images/teacherParrot.png'
import userParrot from '../static/images/parrot.png'
import helper from '../helper/index'
import auth from '../controller/auth'
import Backdrop from '../component/Backdrop'
import carpet from '../static/images/green-carpet.svg'
import { useTranslation } from "react-i18next";

export default function SelectRole() {
    const { t } = useTranslation();
    const [active, setActive] = useState(true)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState(3)
    const [open, setOpen] = useState(false)
    const history = useHistory()

    useEffect(() => {
        try {
            const url = new URLSearchParams(window.location.search)
            let decEmail = helper.decrypt(url.get("tk1").toString().replace(/ /g, "+"))
            let decPass = helper.decrypt(url.get("tk2").toString().replace(/ /g, "+"))
            setEmail(decEmail)
            setPassword(decPass)
            if (!decEmail || !decPass) {
                history.goBack()
            }
            helper.logOut()
        } catch (err) {
            helper.logOut()
            history.goBack()
        }
        return () => { }
    }, [])

    const submit = () => {
        setOpen(true)
        if (role === 3) {
            const data = {
                email: email,
                password: password,
                role: role
            }
            auth.tutorRegister(data)
                .then((res) => {
                    setOpen(false)
                    if (res.data.status) {
                        helper.saveCredential(res.data.result.token, "tutor", res.data.result.id, res.data.result.profileStatus, res.data.result.email)
                        const url = new URLSearchParams(window.location.search)
                        history.push(`/tutor/verify-otp?tk1=${url.get("tk1").toString().replace(/ /g, "+")}`)
                    }
                    else {
                        if (res.data.error === "Please verify the account") {
                            const encrEmail = helper.encrypt(email);
                            history.push(`/tutor/verify-otp?tk1=${encrEmail}`);
                        } else {
                            helper.showMessage(res.data.error, "info")
                        }
                    }
                })
        }
        else {
            const url = new URLSearchParams(window.location.search)
            let decEmail = url.get("tk1").toString().replace(/ /g, "+")
            let decPass = url.get("tk2").toString().replace(/ /g, "+")
            history.push(`/user/little-more?tk1=${decEmail}&&tk2=${decPass}`)
        }

    }

    return (
        <>
            <div className="p-fix tp-0 pl-4 tx-lt-black">
                <button className="btn btn-tutor-profile fs-12 mt-3 mb-2" style={{ width: '150px' }} onClick={() => history.goBack()}>{t('userOnBoard.goback')}</button>
            </div>
            <div className="tutor-login-home all-center flex-column">
                <div className="all-center w-100 mb-2">
                    <div className="all-center mb-3">
                        <img width="80%" src={logo} alt="amazon-pay" />
                    </div>
                </div>
                <p className="f-fred fs-24 px-3 text-center">{t('userOnBoard.selectRole.heading')} <span className="tx-sky">{role === 3 ? t('userOnBoard.selectRole.tutor') : t('userOnBoard.selectRole.student')}</span> {t('userOnBoard.selectRole.panel')}</p>
                <p className="tx-lt-grey ms-500 px-2 text-center">{t('userOnBoard.selectRole.title1')} {role === 3 ? t('userOnBoard.selectRole.tutor') : t('userOnBoard.selectRole.student')} {t('userOnBoard.selectRole.title2')} </p>
                <div className="select-role all-center mt-3">
                    <div className="role-box mx-2">
                        <div className={`role-body pointer px-2  p-rel ${active ? "active-box" : ""}`} onClick={() => { setActive(true); setRole(3) }}>
                            {active && <i className="far fa-check-circle p-abs rt-0 m-2 fs-22 tx-green"></i>}

                            <div className="all-center w-100 h-100">
                                <img width="85px" src={userParrot} alt="parrot" />
                            </div>
                        </div>
                        <p className={`ms-700 fs-18 mt-2 ${active ? "tx-lt-black" : "tx-lt-grey"}`}>{t('userOnBoard.selectRole.wantTeach')}</p>

                    </div>
                    <div className="role-box mx-2">
                        <div className={`role-body pointer p-rel role-body p-rel ${!active ? "active-box" : ""}`} onClick={() => { setActive(false); setRole(2) }}>
                            {!active && <i className="far fa-check-circle p-abs rt-0 m-2 fs-22 tx-green"></i>}
                            <div className="all-center w-100 h-100">
                                <img width="85px" src={tutorParrot} alt="parrot" />
                            </div>
                        </div>
                        <p className={`ms-700 fs-18 mt-2 ${!active ? "tx-lt-black" : "tx-lt-grey"}`}>{t('userOnBoard.selectRole.wantLearn')}</p>
                    </div>
                </div>
                <button className="btn btn-login p-rel  mt-3" style={{ width: '150px', zIndex: 3 }} onClick={() => submit()}>{t('userOnBoard.continue')}</button>
                <div className="p-fix bt-0 w-100 ">
                    <img src={carpet} alt="carpet" />
                </div>
                {open && <Backdrop />}
            </div>
        </>
    )
}
