/* eslint-disable */
import fb from '../config/firebaseAuth';
import { ref, get, child } from "firebase/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import calendar from '../controller/calendar';
import helper from '../helper/index'
import store from '../redux/AppState'
import { constant } from '../config/Contant'
const db = fb.database

const getMessagesbyRoomId = async (roomId) => {
    let count = 0
    await get(child(ref(db), `supportChat/${roomId}`)).then((snapshot) => {
        count = snapshot.size
    }).catch(() => {
        return 0
    });
    return count;
}

const messaging = getMessaging();

// Push Notification 
const getPushPermission = async ()=>{
    getToken(messaging, { vapidKey: constant.VAPID_KEY }).then(async (currentToken) => {
        if (currentToken) {
            
              addDeviceToken(currentToken)
        } else {
               }
    }).catch((err) => {
       
    
    });
}

onMessage(messaging, (payload) => {
    store.setPushNotification({
        msg : payload.notification.body,
        title : payload.notification.title,
        open : true
    })
 })


const addDeviceToken = async (token) => {
    await calendar.addDeviceToken({ device_token: token, user_id: helper.getid() }).then((res) => {
    }).catch((err) => {
    })
}
export default { getMessagesbyRoomId,addDeviceToken,getPushPermission }