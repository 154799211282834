import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import helper from '../helper/index'
import parot from '../static/images/parrot-1.png'
import { Link } from 'react-router-dom'
import SvgChat from '../Icons/Chat'
import Slide from 'react-reveal/Slide';
import SvgDashboard from '../Icons/Dashboard'
import SvgStudent from '../Icons/Student'
import SvgLibrary from '../Icons/Library'
import SvgSupport from '../Icons/Support'
import SvgLogout from '../Icons/Logout'
import SvgStudents from '../Icons/Students'
import SvgPayout from '../Icons/Payout'
import { FaPortrait } from 'react-icons/fa'
export default function Menubar({ cb, tab }) {
    const history = useHistory()
    const { page } = useParams()
    return (<>
     <Slide left>
        <div className="menu px-3 p-fix tp-0 lt-0 bg-yellow  h-100 d-lg-none d-md-none" style={{ zIndex: '5' ,width : '85%'}}>
            <div className="menu-top all-center"><p className="f-fred fs-20">SAY <span className="tx-yellow">DHAD</span></p></div>

            <section className='d-flex h-100 flex-column justify-content-between align-items-between'>
                <div>
                    <p><i className="fas fa-times fs-26 mt-2 p-2 pointer tx-lt-black" onClick={() => cb()}></i></p>

                    <ul className="mt-2" onClick={cb}>
                        <li onClick={() => { history.push('/tutor/dashboard/home'); }} className={`${page === "home" && "active-tab "}`}>
                            <div className="f-fred text-upper  d-flex align-items-center">
                                <span className='icon-width d-flex align-items-center'>
                                    <SvgDashboard className="active-icon-color" />
                                </span>
                                <p>Dashboard</p>
                            </div>
                        </li>
                        <li onClick={() => { history.push('/tutor/dashboard/calendar'); }} className={`${page === "calendar" && "active-tab "}`}>
                            <div className="f-fred text-upper d-flex align-items-center">
                                <span className='icon-width d-flex align-items-center'>
                                    <SvgStudent className="active-icon-color" />
                                </span>
                                <p>Calendar</p>
                            </div>
                        </li>
                        <li onClick={() => { history.push('/tutor/dashboard/library'); }} className={`${page === "library" && "active-tab "}`}>
                            <div className="f-fred text-upper d-flex align-items-center">
                                <span className='icon-width d-flex align-items-center'>
                                    <SvgLibrary className="active-icon-color" />
                                </span>
                                <p>Courses</p>
                            </div>

                        </li>
                        <li onClick={() => { history.push('/tutor/dashboard/mystudent'); }} className={`${page === "mystudent" && "active-tab "}`}>
                            <div className="f-fred text-upper d-flex align-items-center">
                                <span className='icon-width d-flex align-items-center'>
                                    <SvgStudents className="active-icon-color" />
                                </span>
                                <p>Students</p>
                            </div>
                        </li>
                        <li onClick={() => { history.push('/tutor/dashboard/mychat'); }} className={`${page === "mychat" && "active-tab "}`}>
                            <div className="f-fred text-upper d-flex align-items-center">
                                <span className='icon-width d-flex align-items-center'>
                                    <SvgChat className="active-icon-color" />
                                </span>
                                <p>Chat</p>
                            </div>
                        </li>
                        <li onClick={() => { history.push('/tutor/dashboard/payout'); }} className={`${page === "payout" && "active-tab "}`}>
                            <div className="f-fred text-upper d-flex align-items-center">
                                <span className='icon-width d-flex align-items-center'>
                                    <SvgPayout className="active-icon-color" />
                                </span>
                                <p>Payouts</p>
                            </div>

                        </li>
                        <li onClick={() => { history.push('/tutor/dashboard/attendance') }} className={`${page === "attendance" && "active-tab "}`}>
                            <div className="f-fred text-upper d-flex align-items-center">
                                <span className='icon-width'>
                                    <FaPortrait size={24} className="active-icon-color" />
                                    {/* <i className={`fas fa-list-alt  fs-24 ${page === "attendance" ? "active-icon-color" : 'inactive-icon-color'}`}></i> */}
                                </span>
                                <p>Attendance</p>
                            </div>

                        </li>
                        <li onClick={() => { history.push('/tutor/dashboard/support'); }} className={`${page === "support" && "active-tab "}`}>
                            <div className="f-fred text-upper d-flex align-items-center">
                                <span className='icon-width'>
                                    <SvgSupport className="active-icon-color" />
                                </span>
                                <p>Support</p>
                            </div>
                        </li>

                        <li onClick={() => { helper.logOut(); history.push('/') }}>
                            <div className="f-fred text-upper d-flex align-items-center">
                                <span className='icon-width d-flex align-items-center'>
                                    <SvgLogout className="active-icon-color" />
                                </span>
                                <p>LOGOUT</p>
                            </div> </li>

                    </ul>



                    <div className='d-flex justify-content-between pb-3 mt-3'>
                        <div className="ms-700 fs-14 ml-3">
                            <Link to="/about-us" className="d-block"><span className='link-hover'>About SayDhad</span></Link>
                            <Link to="/tutor/dashboard/privacy-policy" className="d-block"><span className='link-hover'>Privacy policy</span></Link>
                            <Link to="/tutor/dashboard/terms-condition" className="d-block"><span className='link-hover'>Terms & condition</span></Link>
                            <p className="mt-3 fs-12 mb-2">©2021. SayDhad. All Rights Reserved</p>
                        </div>
                        <div className=" mb-3 mr-3 ">
                            <img width="60px" src={parot} alt="parrot" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </Slide>
    </>)
}
