/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react'
import '../static/css/modal.css'
import helper from '../helper/index'
import tutor from '../controller/tutor'
import Backdrop from '../component/Backdrop'
import moment from 'moment'
import ChangePasswordInput from './ChangePasswordInput'
import { getLanguage } from '../controller/master'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { getCountryList } from '../controller/common'
import S3FileUpload from './S3FileUpload'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { fileUploadToS3 } from '../helper/utils'

const BasicInfoPop = ({ close, cb, data }) => {
    const { t } = useTranslation();
    const outSide = useRef()
    const [pageload, setPageload] = useState(false)
    const [error, setError] = useState('')
    const [profileImg, setProfileImg] = useState()
    const [introvideo, setIntroVideo] = useState()
    const [state, setState] = useState({
        openImgCropper: false,
        openVideo: false,
        countryList: [],
        timeZoneList: [],
        country: '',
        timeZone: '',
        imgFile: '',
        videoFile: '',
        code: ''
    })

    const [phone, setPhone] = useState()

    useEffect(() => {
        getCountry('')
    }, [])

    const getCountry = async (countryName) => {
        let code = ""
        state.countryList.some((data) => {
            if (data.country_name === countryName) {
                code = data.country_code
                setState({ ...state, timeZoneList: data.timezone, country: countryName, code })
                return true
            } else { return false }
        })
        if (!code) {
            await getCountryList(code).then((res) => {
                if (res.data.status) {
                    let timeZone = [], code = ""
                    res.data.result.some((result) => {
                        if (data && result.country_name === data.from) {
                            timeZone = result.timezone
                            code = result.country_code
                            return true
                        } else {
                            return false
                        }
                    })
                    setState({ ...state, countryList: res.data.result, timeZoneList: timeZone, code })
                }
            })
        }
    }

    /* Handle submit profile */
    const submitProfile = async () => {
        const ob = document.getElementById("basicInfo")
        const form = new FormData(ob)
        form.append("tutorId", helper.getid())
        form.append("profilePicture", state.imgFile)
        form.append("profileVideo", state.videoFile)

        /* check validation */
        if (!form.get('name') || !form.get('from') || !form.get('timeZone1') || !form.get('dateOfBirth') || !form.get('phoneNo') || !form.get('introduction')) {
            helper.showMessage("Please fill the required fields")
            return
        }

        form.append("timeZone", form.get("timeZone1").split("#")[0])
        form.append("time_zone_city", form.get("timeZone1").split("#")[1])
        let validateResp = helper.validate("name", form.get('name'))
        if (!validateResp.isValid) {
            helper.showMessage("Please enter valid name")
            return
        }

        if (form.get('introduction').trim().length < 100) {
            helper.showMessage("Introduction have atleast 100 characters")
            return
        }


        if (data && data.profilePicture) { }
        else if (!state.imgFile) {
            helper.showMessage("Please select profile image")
            return
        }

        if (data && data.profileVideo) { }
        else if (!state.videoFile) {
            helper.showMessage("Please select introductory video")
            return
        }
        setPageload(true)
        await tutor.updateBasicInfo(form).then(async (res) => {
            cb()
            close()
            setPageload(false)
        }).catch((err) => { setPageload(false) })
    }

    return (
        <>
            <div className="all-center popup-modal" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                <div className="left-popup p-fix tp-0 rt-0  p-5 sm" >
                    <h4 className="f-fred mb-0 tx-lt-black">{t('tutorSidebar.generalInfo.general')} <span className="tx-green">{t('tutorSidebar.generalInfo.info')}</span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                    <p className="sub-heading mb-3">{t('tutorSidebar.generalInfo.basicInfo')}</p>
                    <form id="basicInfo" onSubmit={(e) => { e.preventDefault() }}>
                        <div className="row ms-700 fs-14 f-fred">
                            <div className="col-6 col-12 mb-2 ">
                                <input maxLength={40} type="text" name="name" className="d-block px-2 w-100 input-box f-fred" placeholder={t('tutorSidebar.generalInfo.name') + " *"} defaultValue={data && data.name} />
                            </div>
                            <div className="col-6 col-12 mb-2">
                                <input max={moment().format('YYYY-MM-DD')} type="text" name="dateOfBirth" className="d-block  px-2 w-100 input-box f-fred"
                                    onFocus={(e) => { e.target.type = "date"; e.target.defaultValue = data && moment(data.dateOfBirth).format('MM/DD/YYYY'); }}
                                    onBlur={(e) => {
                                        e.target.type = "text";
                                        e.target.defaultValue = data && moment(data.dateOfBirth).format('MM/DD/YYYY');
                                    }} placeholder={t('tutorSidebar.generalInfo.dob') + " *"}
                                    defaultValue={data && moment(data.dateOfBirth).format('YYYY-MM-DD')}
                                />
                            </div>

                            <div className="col-6 col-12 mb-2">
                                {state.countryList.length > 0 && <select onChange={(e) => { getCountry(e.target.value) }} className="input-box w-100  f-fred my-0" defaultValue={data && data.from} name="from" >
                                    <option value="">{t('tutorSidebar.generalInfo.selectCountry') + " *"}</option>
                                    {state.countryList.map((data) => <option key={data.country_name} value={data.country_name}>{data.country_name}</option>)}
                                </select>
                                }
                            </div>
                            <div className="col-6 col-12 mb-2">
                                {state.timeZoneList.length > 0 && <select className='input-box w-100 f-fred my-0' name="timeZone1">
                                    <option value="">{t('tutorSidebar.generalInfo.selectTimeZone') + " *"}</option>
                                    {state.timeZoneList.map((timezone) => <option selected={data?.timeZone + "#" + data?.time_zone_city === timezone?.timeZone + "#" + timezone?.city ? true : false} value={timezone.timeZone + "#" + timezone.city}>{timezone.city + " (" + timezone.timeZone + " UTC)"}</option>)}
                                </select>}
                            </div>

                            {state.code && <div className="col-12 mb-2">
                                <PhoneInput
                                    country={state.code.toLowerCase()}
                                    disableDropdown={true}
                                    value={data?.phoneNo}
                                    inputProps={{ name: 'phoneNo', placeholder: t('tutorSidebar.generalInfo.mobile') + " *" }}
                                    buttonClass="phone-drop"
                                    containerClass="phone-number-box"
                                    inputClass="phone-number input-sbox"
                                    onChange={phone => setPhone(phone)}
                                />
                            </div>}
                            <div className="col-12 py-0  mb-2">
                                <textarea minLength={100} maxLength={500} name="introduction" rows="8" placeholder={`${t('tutorSidebar.generalInfo.yourIntro') + " *"} (100-500 character)`} className="input-box w-100 p-2 f-fred my-0 " defaultValue={data?.introduction} style={{ height: 'initial' }} />
                            </div>

                            <div className="col-lg-6 col-md-6 col-12 pl-1 mt-2 mb-1">
                                <label htmlFor="profileim" onClick={() => setState({ ...state, openImgCropper: true, imgFile: "" })} className="btn btn-green fs-14 ms-700 px-2 py-2">{t('tutorSidebar.generalInfo.uploadImg') + " *"} <small>(Max 5MB)</small></label>
                                <small>{profileImg?.name}</small>
                                <input type="file" accept='image/*' className="d-none" name="prfImg" id="profileimg" onChange={(e) => {
                                    /* Validation for image */
                                    if ((e.target.files[0].size / 1024) / 1024 > 1) {
                                        helper.showMessage("Image size should be less than 5MB", "info")
                                    } else {
                                        setProfileImg(e.target.files[0]);
                                        setState({ ...state, openImgCropper: true, imgFile: e.target.files[0] })
                                    }
                                }} />
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 px-1 mt-2 mb-1">
                                {state.imgFile ? <img width="70px" className="rounded-circle float-right" height="70px" src={state.imgFile} alt="profile" /> :
                                    data && data.profilePicture && <img width="70px" className="rounded-circle float-right" height="70px" src={data.profilePicture} alt="profile" />
                                }
                            </div>
                            <div className="col-12 px-1 mt-2 mb-2">
                                <label htmlFor="introVide" onClick={() => setState({ ...state, openVideo: true, videoFile: "" })} className="btn btn-green fs-14 ms-700 px-2 py-2" >{t('tutorSidebar.generalInfo.uploadVideo') + " *"} <small>(Max 200MB)</small></label>
                                <small>{introvideo?.name}</small>
                                <input type="file" accept='video/*' className="d-none" name="introVideo" id="introVideo" onChange={(e) => {
                                    if ((e.target.files[0].size / 1024) / 1024 > 15) {
                                        helper.showMessage("Intro video should be less than 200MB", "info")
                                    } else {
                                        setIntroVideo(e.target.files[0]);
                                        setState({ ...state, openVideo: true, videoFile: e.target.files[0] })
                                    }
                                }} />
                            </div>
                            <div className="col-12  px-2 mt-2 mb-1">
                                {state.videoFile ? <video className="br-1 bg-smoke" width="100%" height="230px" src={state.videoFile} controls ></video> :
                                    data && data.profileVideo && <video className="br-1 bg-smoke" width="100%" height="230px" src={data.profileVideo} controls ></video>
                                }
                            </div>

                            <div className="col-12 ">
                                <p className="ms-500 f-14 tx-error text-center mt-2">{error}</p>
                            </div>
                            <div className="col-lg-9 col-12  px-2 mt-2 mb-3">
                                <button type="submit" className="btn btn-login w-100" onClick={() => submitProfile()}>{t('tutorSidebar.updateInfo')}</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>

            {/* Handle image cropper */}
            {state.openImgCropper && <S3FileUpload type="image" file={state.imgFile} onClose={() => setState({ ...state, openImgCropper: false, imgFile: null })} cb={(url) => setState({ ...state, openImgCropper: false, imgFile: url })} />}
            {state.openVideo && <S3FileUpload type="video" file={state.videoFile} onClose={() => setState({ ...state, openVideo: false, videoFile: null })} cb={(url) => setState({ ...state, openVideo: false, videoFile: url })} />}

            {pageload && <Backdrop />}

        </>
    )
}

const TeachingPop = ({ close, cb, data, languages }) => {
    const { t } = useTranslation();
    const outSide = useRef()
    const [pageload, setPageload] = useState(false)

    const [langlist, setLanglist] = useState([])
    const [lng, setLang] = useState('')
    const [dialect, setDialect] = useState('')
    const [level, setLevel] = useState('')

    const teachingStyle = useRef()
    const aboutMe = useRef()

    const [state, setState] = useState({
        languages: [],
        dialects: []
    })

    /* get language and dialects */

    useEffect(() => {
        async function getLngDialects() {
            await getLanguage().then((res) => {
                if (res.data.status) {
                    setState({ ...state, ...res.data.result })
                }
            })
        }

        getLngDialects()
    }, [])

    const addlng = () => {
        if (!lng || !dialect || !level) {
            helper.showMessage("Please select all three fields", "info"); return;
        }
        const isExist = languages.concat(langlist).filter((data) => data.dialect === dialect && data.language === lng && data.fluency === level)
        if (isExist.length > 0) {
            helper.showMessage("Language already exist", "info"); return;
        }
        const list = [...langlist]
        list.push({
            language: lng,
            fluency: level,
            dialect: dialect,
            tutorId: helper.getid()
        })
        setLanglist([...list])
    }

    const removelng = (index) => {
        const list = [...langlist]
        list.splice(index, 1)
        setLanglist(list)
    }

    const submit = async () => {
        if (!teachingStyle.current.value || !aboutMe.current.value) {
            helper.showMessage("Please fill required fields", "info"); return;
        }
        if (teachingStyle.current.value.trim().length < 100) {
            return helper.showMessage("Teaching style have atleast 100 characters", "info")
        }
        if (aboutMe.current.value.trim().length < 100) {
            return helper.showMessage("About me have atleast 100 characters", "info")
        }
        setPageload(true)
        Promise.race([tutor.updateTeachingStyle({ teachingStyle: teachingStyle.current.value, tutorId: helper.getid() }), tutor.updateAbout({ aboutMe: aboutMe.current.value, tutorId: helper.getid() }), tutor.addLanguage(langlist)])
            .then((res) => {
                cb()
                close()
                setPageload(false)
            })

    }

    return (
        <>
            <div className="all-center popup-modal" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                <div className="left-popup p-fix tp-0 rt-0 p-5 sm" >
                    <h4 className="f-fred mb-0">{t('tutorSidebar.aboutTeach.heading')} <span className="tx-green">{t('tutorSidebar.aboutTeach.lng')}</span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                    <p className="sub-heading mb-3">{t('tutorSidebar.updateInfo')}</p>
                    <form id="teaching" onSubmit={(e) => { e.preventDefault(); submit() }}>
                        <div className="row ms-700 fs-14">
                            <div className="col-12 all-center mb-2 px-1">
                                <textarea minLength={100} maxLength={500} ref={teachingStyle} className="w-100 input-box p-2 f-fred my-0" placeholder={`${t('tutorSidebar.aboutTeach.style') + " *"} (100-500 characters)`} defaultValue={data?.teachingStyle} rows="8" style={{ height: 'initial' }}></textarea>
                            </div>
                            <div className="col-12 all-center mb-2 px-1">
                                <textarea minLength={100} maxLength={800} ref={aboutMe} className="w-100 input-box p-2 f-fred my-0" placeholder={`${t('tutorSidebar.aboutTeach.aboutYou') + " *"} (100-800 characters)`} defaultValue={data && data.aboutMe} rows="8" style={{ height: 'initial' }}></textarea>
                            </div>
                            <div className="col-lg-6 col-md-6 col-6 pr-1">
                                <select className="d-block px-2 w-100 f-fred input-box mt-2" onChange={(e) => setLang(e.target.value)}>
                                    <option value="">{t('tutorSidebar.lng.selLng') + " *"}</option>
                                    {state.languages.map((lng, index) => <option key={`lng${index}`} value={lng.name}>{lng.name}</option>)}
                                </select>
                            </div>
                            <div className="col-lg-6 col-md-6 col-6 pl-1">
                                <select className="d-block px-2 w-100 input-box mt-2 f-fred text-capitalize" onChange={(e) => setDialect(e.target.value)}>
                                    <option value="">{t('tutorSidebar.lng.dialect') + " *"}</option>
                                    {state.dialects.map((dialect, index) => <option key={`dialect${index}`} value={dialect.name}>{dialect.name}</option>)}

                                </select>
                            </div>
                            <div className="col-12">
                                <select className="d-block px-2 w-100 f-fred input-box mt-2" onChange={(e) => setLevel(e.target.value)}>
                                    <option value="">{t('tutorSidebar.lng.selFluency') + " *"}</option>
                                    <option value="Native">Native</option>
                                    <option value="Advanced">Advanced</option>
                                    <option value="Fluent">Fluent</option>
                                </select>
                            </div>

                            <div className="col-6 my-2">
                                <button className="btn btn-green fs-14 ms-700 px-2 py-2" type="button" onClick={addlng}>{t('tutorSidebar.lng.addLng')}</button>
                            </div>
                            <div className="col-12 mt-2">
                                {langlist.map((lng, index) =>
                                    <span key={index} className="lang-tags text-capitalize fs-12 mr-2 mb-2 wrap d-inline-block">{lng.language}( {lng.dialect}-{lng.fluency} ) <i className="fas fa-times fs-16 ml-1 pointer" onClick={() => removelng(index)}></i> </span>
                                )}
                            </div>
                            <div className="col-lg-9 col-12  px-2 mt-2 mb-3">
                                <button type="submit" className="btn btn-login fs-14 ms-700 w-100">{t('tutorSidebar.updateInfo')}</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
            {pageload && <Backdrop />}
        </>
    )
}

const WorkExperiencePop = ({ close, cb }) => {
    const { t } = useTranslation();
    const outSide = useRef()
    const [pageload, setPageload] = useState(false)
    const [error, setError] = useState('')
    const name = useRef()
    const from = useRef()
    const desc = useRef()
    const to = useRef()
    const submit = async () => {

        const data = {
            tutorId: helper.getid(),
            title: name.current.value,
            description: desc.current.value,
            from: from.current.value,
            to: to.current.value,
        }

        if (!data.title || !data.description || !data.from || !data.to) {
            helper.showMessage("All fields are required")
            return;
        }
        else if (from === to) {
            helper.showMessage("Please add valid experience.")
            return;
        }
        let validateResp = helper.validate("name", data.title)
        if (!validateResp.isValid) {
            helper.showMessage("Please enter valid work experience title", "info")
            return
        }
        // validateResp = helper.validate("name", data.description)
        // if (!validateResp.isValid) {
        //     helper.showMessage("Please enter valid work experience.", "info")
        //     return
        // }

        setPageload(true)
        await tutor.addWorkExperience([data])
            .then((res) => {
                if (res.data.status) {
                    cb()
                    close()
                }
                setPageload(false)
            })
    }

    const ExpCom =
        <div className="row ms-700 fs-14">
            <div className=" col-12 mb-2 px-1">
                <input maxLength={100} ref={name} type="text" name="title" className="d-block px-2 w-100 input-box my-0 f-fred" placeholder={t('tutorSidebar.work.workExp') + " *"} />
            </div>
            <div className=" col-12 mb-2 px-1">
                <textarea maxLength={500} ref={desc} name="description" className="input-box w-100 p-2 my-0 f-fred" style={{ height: 'initial' }} rows="8" placeholder={t('tutorSidebar.work.desc') + " *"}></textarea>
            </div>

            <div className="col-6 mb-2 pr-1 pl-2">

                <input max={moment().format('YYYY-MM')} ref={from} type="text" name="from" className="d-block  px-2 w-100 input-box f-fred"
                    onFocus={(e) => { e.target.type = "month"; }}
                    onBlur={(e) => {
                        e.target.type = "text";

                    }} placeholder={t('tutorSidebar.work.from') + " *"}

                />
            </div>
            <div className="col-6 mb-2 pl-1 pr-2">
                <input max={moment().format('YYYY-MM')} ref={to} type="text" name="to" className="d-block  px-2 w-100 input-box f-fred"
                    onFocus={(e) => { e.target.type = "month"; }}
                    onBlur={(e) => { e.target.type = "text"; }}
                    placeholder={t('tutorSidebar.work.to') + " *"}
                />
            </div>


        </div>

    return (
        <>
            <div className="all-center popup-modal" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                <div className="left-popup p-fix tp-0 rt-0  p-5 sm" >
                    <h4 className="f-fred mb-0 tx-lt-black">{t('tutorSidebar.work.work')}<span className="tx-green"> {t('tutorSidebar.work.exp')}</span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                    <p className="sub-heading mb-3">{t('tutorSidebar.work.subTitle')}</p>
                    {ExpCom}
                    <div className="col-12 px-0">
                        <p className="ms-700 f-14 tx-sky text-center mt-2">{error}</p>
                    </div>
                    <div className="col-lg-9 col-12  px-0 mt-2 mb-3">
                        <button type="submit" className="btn btn-login fs-14  w-100" onClick={() => submit()}>{t('tutorSidebar.update')}</button>
                    </div>

                </div>
            </div>
            {pageload && <Backdrop />}
        </>
    )
}

const EducationPop = ({ close, cb }) => {
    const { t } = useTranslation();
    const outSide = useRef()
    const [pageload, setPageload] = useState(false)
    const [error, setError] = useState('')
    const degree = useRef()
    const from = useRef()
    const to = useRef()
    const college = useRef()
    const submit = async () => {
        const data = {
            tutorId: helper.getid(),
            degree: degree.current.value,
            university: college.current.value,
            from: from.current.value,
            to: to.current.value
        }

        if (!data.degree || !data.university || !data.from || !data.to) {
            helper.showMessage("All fields are required")
            return;
        }
        else if (from === to) {
            return helper.showMessage("Please add valid education year")
        }
        else if (from > to) {
            return helper.showMessage("Please add valid education year")
        }
        let validateResp = helper.validate("name", data.degree)
        if (!validateResp.isValid) {
            helper.showMessage("Please enter valid degree name", "info")
            return
        }
        validateResp = helper.validate("name", data.university)
        if (!validateResp.isValid) {
            helper.showMessage("Please enter valid university name", "info")
            return
        }
        setPageload(true)
        await tutor.addEducation([data])
            .then((res) => {
                if (res.data.status) {

                    cb()
                    close()
                }
                setPageload(false)
            })
    }

    return (
        <>
            <div className="all-center popup-modal" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                <div className="left-popup p-fix tp-0 rt-0  p-5 sm" >
                    <h4 className="f-fred mb-0">{t('tutorSidebar.educ.edu')}<span className="tx-green"> {t('tutorSidebar.educ.qual')}</span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                    <p className="sub-heading mb-3">{t('tutorSidebar.educ.subTitle')}</p>
                    <div className="row ms-700 fs-14">
                        <div className="col-lg-6 col-12 mb-2 px-1">
                            <select ref={degree} name="degree" className='my-0' id="">
                                <option value="">{t('tutorSidebar.educ.eduDeg') + " *"}</option>
                                <option value="Diploma">Diploma</option>
                                <option value="Bachelors">Bachelors</option>
                                <option value="Masters">Masters</option>
                                <option value="PHD">PHD</option>
                            </select>
                            {/* <input maxLength={100} placeholder={t('tutorSidebar.educ.eduDeg') + " *"} ref={degree} type="text" name="degree" className="d-block f-fred px-2 w-100 input-box" /> */}
                        </div>
                        <div className="col-lg-6 col-12 mb-2 px-1">
                            <input maxLength={100} placeholder={t('tutorSidebar.educ.univer') + " *"} ref={college} type="text" name="college" className="d-block px-2 w-100 f-fred input-box" />
                        </div>
                        <div className="col-6 mb-2 px-1 ">
                            <input max={moment().format('YYYY-MM')} ref={from} type="text" name="from" className="d-block  px-2 w-100 input-box f-fred"
                                onFocus={(e) => { e.target.type = "month"; }}
                                onBlur={(e) => { e.target.type = "text"; }}
                                placeholder={t('tutorSidebar.educ.from') + " *"}
                            />
                        </div>
                        <div className="col-6 mb-2 px-1 ">
                            <input max={moment().format('YYYY-MM')} ref={to} type="text" name="to" className="d-block  px-2 w-100 input-box f-fred"
                                onFocus={(e) => { e.target.type = "month"; }}
                                onBlur={(e) => { e.target.type = "text"; }}
                                placeholder={t('tutorSidebar.educ.to') + " *"}
                            />
                        </div>
                        <div className="col-12 ">
                            <p className="ms-700 f-14 tx-sky text-center mt-2">{error}</p>
                        </div>
                        <div className="col-lg-9 col-12 px-1 col-12 mt-2 mb-3">
                            <button type="submit" className="btn btn-login  w-100" onClick={() => submit()}>{t('tutorSidebar.updateInfo')}</button>
                        </div>

                    </div>

                </div>
            </div>
            {pageload && <Backdrop />}
        </>
    )
}

const CertificatePop = ({ close, cb }) => {
    const { t } = useTranslation();
    const outSide = useRef()
    const [pageload, setPageload] = useState(false)
    const [error, setError] = useState('')
    const issue_athority = useRef()
    const certificate_date = useRef()
    const title = useRef()
    const file = useRef()
    const [certificate, setCertificate] = useState()

    const submit = async () => {

        const form = new FormData()
        form.append('tutorId', helper.getid())
        form.append('issue_authority', issue_athority.current.value)
        form.append('certificate_date', certificate_date.current.value)
        form.append('title', title.current.value)


        if (!issue_athority.current.value || !certificate_date.current.value || !title.current.value) {
            helper.showMessage("Please fill required fields")
            return
        }
        else if (!certificate) {
            helper.showMessage("Please select certificate file")
            return
        } else if (!certificate.name.endsWith(".pdf")) {
            helper.showMessage("Only Pdf file is allowed")
            return
        }
        if (certificate && ((certificate.size / 1024) / 1024) > 2) {
            helper.showMessage("certificate  should be less than 2 Mb")
            return
        }
        let validateResp = helper.validate("name", title.current.value)
        if (!validateResp.isValid) {
            helper.showMessage("Please enter valid certificate title", "info")
            return
        }
        validateResp = helper.validate("name", issue_athority.current.value)
        if (!validateResp.isValid) {
            helper.showMessage("Please enter valid issue authority name", "info")
            return
        }

        setPageload(true)

        let url = await fileUploadToS3("tutor_certificates", certificate)
        form.append('fileURL', url)

        await tutor.addCertificate(form)
            .then((res) => {
                if (res.data.status) {
                    cb()
                    close()
                } else {
                    helper.showMessage(res.data.error)
                }
                setPageload(false)
            })
            .catch((err) => { })
    }

    return (
        <>
            <div className="all-center popup-modal" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                <div className="left-popup p-fix tp-0 rt-0 p-5 sm" >
                    <h4 className="f-fred mb-0 tx-lt-black">{t('tutorSidebar.cert.cert')}<span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                    <p className="sub-heading mb-3"> {t('tutorSidebar.cert.subTitle')}</p>

                    <div className="row f-fred fs-14">
                        <div className="col-12 mb-2">
                            <input maxLength={50} ref={title} placeholder={t('tutorSidebar.cert.title') + " *"} type="text" name="name" className="d-block px-2 w-100 input-box" />
                        </div>
                        <div className="col-6 mb-2 px-1">
                            <input maxLength={50} ref={issue_athority} placeholder={t('tutorSidebar.cert.auth') + " *"} type="text" name="name" className="d-block px-2 w-100 input-box" />
                        </div>
                        <div className="col-6 mb-2 px-1 ">
                            <input max={moment().format("YYYY-MM")} ref={certificate_date} type="text" name="from" className="d-block  px-2 w-100 input-box f-fred"
                                onFocus={(e) => { e.target.type = "month"; }}
                                onBlur={(e) => { e.target.type = "text"; }}
                                placeholder={t('tutorSidebar.cert.month-year') + " *"}
                            />
                        </div>
                        <div className="col-12 mt-2 mb-1">
                            <label htmlFor="certImg" className="btn btn-green fs-14 ms-700 px-2 py-2"> {t('tutorSidebar.cert.addCert') + " *"} (.pdf)</label>
                            <input ref={file} type="file" accept='.pdf' className="d-none" id="certImg" onChange={(e) => {
                                if ((e.target.files[0].size / 1024) / 1024 > 1) {
                                    helper.showMessage("Certificate pdf should be less than 1MB", "info")
                                } else if (!e.target.files[0].name.endsWith(".pdf")) {
                                    helper.showMessage("Only Pdf file is allowed")
                                    return
                                }
                                else {
                                    setCertificate(e.target.files[0])
                                }
                            }} />
                        </div>
                        <div className="col-12 mt-2 mb-1">
                            {certificate && <p>{certificate.name}</p>
                            }
                        </div>

                        <div className="col-12 ">
                            <p className="f-robo f-14 tx-sky text-center mt-2">{error}</p>
                        </div>
                        <div className="col-lg-8 col-12  px-1 mt-2 mb-3">
                            <button type="submit" className="btn btn-login fs-14  w-100" onClick={() => submit()}> {t('tutorSidebar.updateInfo')}</button>
                        </div>

                    </div>


                </div>
            </div>
            {pageload && <Backdrop />}
        </>
    )
}

const DigInDeepPop = ({ close, cb, data }) => {
    const outSide = useRef()
    const { t } = useTranslation();
    const [error, setError] = useState('')
    const [whyDhad, setWhyDhad] = useState(data?.why_dhad)
    const [hearDhad, setHearDhad] = useState('')
    const submit = () => {
        if (!whyDhad || !hearDhad) {
            helper.showMessage("Please fill required fields")
            return;
        }
        let validateResp = helper.validate("name", hearDhad)
        if (!validateResp.isValid) {
            helper.showMessage("Please select hear about sayDhad.", "info")
            return
        }
        if (whyDhad.trim().length < 100) {
            return helper.showMessage("Why Saydhad have atleast 100 characters", "info")
        }
        cb({ hearDhad: hearDhad, whyDhad: whyDhad })
        close();
    }

    return (
        <>
            <div className="all-center popup-modal" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                <div className="left-popup p-fix tp-0 rt-0  p-5 sm" >
                    <h4 className="f-fred mb-0 tx-lt-black">{t('tutorSidebar.why.why')}<span className="tx-green"> {t('tutorSidebar.why.saydhad')}?</span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                    <p className="sub-heading mb-3">{t('tutorSidebar.updateInfo')}</p>
                    <div className="row f-fred fs-14">
                        <div className="col-12 col-12 mb-2 pr-1">

                            <textarea minLength={100} maxLength={800} placeholder='Why Saydhad * (100-800 character)' onChange={(e) => setWhyDhad(e.target.value)} name="why_dhad" className="input-box w-100 my-0 p-2 f-fred" style={{ height: 'initial' }} rows="8" defaultValue={data && data.why_dhad}></textarea>
                        </div>
                        <div className="col-12  mb-2 pl-1">
                            <p className="mt-2 tx-lt-black fs-18 ">{t('tutorSidebar.why.where')} <span className='tx-green'>{t('tutorSidebar.why.saydhad')}?</span></p>
                            <p className="sub-heading mb-3">{t('tutorSidebar.updateInfo') + " *"}</p>

                            <div className="radio-item mb-2 d-block">
                                <input onChange={() => setHearDhad("family or friend")} type="radio" id="hear_about_dhad" name="hear_about_dhad" className="mb-3 mr-2" />
                                <label htmlFor="hear_about_dhad" className="pointer">{t('tutorOnBoard.digInDeep.hear.options.family')}</label>
                            </div>
                            <div className="radio-item mb-2 d-block">
                                <input onChange={() => setHearDhad("social medial or developement")} type="radio" id="social" name="hear_about_dhad" className="mb-3 mr-2" />
                                <label htmlFor="social" className="pointer">{t('tutorOnBoard.digInDeep.hear.options.social')}</label>
                            </div>
                            <div className="radio-item mb-2 d-block">
                                <input onChange={(e) => setHearDhad("tv commercial")} type="radio" id="tv" name="hear_about_dhad" className="mb-3 mr-2" />
                                <label htmlFor="tv" className="pointer">{t('tutorOnBoard.digInDeep.hear.options.tv')}</label>
                            </div>
                            <div className="radio-item mb-3 d-block">
                                <input onChange={(e) => setHearDhad("google search")} type="radio" id="google" name="hear_about_dhad" className="mb-3 mr-2" />
                                <label htmlFor="google" className="pointer">{t('tutorOnBoard.digInDeep.hear.options.google')}</label>
                            </div>
                        </div>

                        <div className="col-12 ">
                            <p className="ms-700 f-14 tx-sky text-center mt-2">{error}</p>
                        </div>
                        <div className="col-lg-9 col-12  px-2 mt-2 mb-3">
                            <button type="submit" className="btn btn-login fs-14  w-100" onClick={() => submit()}>{t('tutorSidebar.updateInfo')}</button>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

/* Profile modals */
const AddLanguagePOP = ({ close, cb }) => {
    const outSide = useRef()
    const [pageload, setPageload] = useState(false)
    const [error, setError] = useState('')
    const { t } = useTranslation();
    const [langlist, setLanglist] = useState([])
    const [lng, setLang] = useState('')
    const [dialect, setDialect] = useState('')
    const [level, setLevel] = useState('')


    const [state, setState] = useState({
        languages: [],
        dialects: []
    })

    /* get language and dialects */

    useEffect(() => {
        async function getLngDialects() {
            await getLanguage().then((res) => {
                if (res.data.status) {
                    setState({ ...state, ...res.data.result })
                }
            })
        }

        async function getLngDialects() {
            await getLanguage().then((res) => {
                if (res.data.status) {
                    setState({ ...state, ...res.data.result })
                }
            })
        }

        getLngDialects()
        getLngDialects()
    }, [])

    const addlng = () => {
        if (!lng || !dialect || !level) {
            helper.showMessage("Please select all three fields")
            return
        }
        const list = [...langlist]
        list.push({
            language: lng,
            fluency: level,
            dialect: dialect,
            tutorId: helper.getid()
        })
        setLanglist([...list])
    }
    const removelng = (index) => {
        const list = [...langlist]
        list.splice(index, 1)
        setLanglist(list)
    }

    const submit = async () => {
        if (langlist.length === 0) {
            helper.showMessage("please add atleat one language")
            return
        }
        setPageload(true)
        await tutor.addLanguage(langlist)
            .then((res) => {
                if (res.data.status) {
                    cb()
                    close()
                }
                setPageload(false)
            })
    }



    return (
        <>
            <div className="all-center popup-modal" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                <div className="left-popup p-fix tp-0 rt-0  p-5 sm" >
                    <h4 className="f-fred mb-0">{t('tutorSidebar.lng.add')} <span className="tx-green">{t('tutorSidebar.lng.lng')}</span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                    <p className="sub-heading mb-3">{t('tutorSidebar.lng.update')}</p>
                    <form id="teaching" onSubmit={(e) => { e.preventDefault(); submit() }}>
                        <div className="row ms-700 fs-14">
                            <div className="col-lg-6 col-md-6 col-6 px-1">
                                <select className="d-block px-2 w-100 input-box mt-2 f-fred text-capitalize" onChange={(e) => setDialect(e.target.value)}>
                                    <option value="">{t('tutorSidebar.lng.dialect')}</option>
                                    {state.dialects.map((dialect, index) => <option key={`dialect${index}`} value={dialect.name}>{dialect.name}</option>)}

                                </select>
                            </div>
                            <div className="col-lg-6 col-md-6 col-6 px-1">
                                <select className="d-block px-2 w-100 f-fred input-box mt-2" onChange={(e) => setLang(e.target.value)}>
                                    <option value="">{t('tutorSidebar.lng.selLng')}</option>
                                    {state.languages.map((lng, index) => <option key={`lng${index}`} value={lng.name}>{lng.name}</option>)}
                                </select>
                            </div>

                            <div className="col-12">
                                <select className="d-block px-2 w-100 input-box mt-2" onChange={(e) => setLevel(e.target.value)}>
                                    <option value="">{t('tutorSidebar.lng.selFluency')}</option>
                                    <option value="native">Native</option>
                                    <option value="advanced">Advanced</option>
                                    <option value="fluent">fluent</option>
                                </select>
                            </div>

                            <div className="col-6 my-2">
                                <button className="btn btn-green fs-14 ms-700 px-2 py-2" type="button" onClick={addlng}>{t('tutorSidebar.lng.addLng')}</button>
                            </div>
                            <div className="col-12 mt-2">
                                {langlist.map((lng, index) =>
                                    <span key={index} className="lang-tags text-capitalize fs-12 mr-2 mb-2 wrap d-inline-block">{lng.language}( {lng.dialect}-{lng.fluency} ) <i className="fas fa-times fs-16 ml-1 pointer" onClick={() => removelng(index)}></i> </span>
                                )}
                            </div>
                            <div className="col-12 ">
                                <p className="f-fred f-14 tx-sky text-center mt-2">{error}</p>
                            </div>
                            <div className="col-lg-8 col-12  px-1 mt-2 mb-3">
                                <button type="submit" className="btn btn-login w-100">{t('tutorSidebar.update')}</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
            {pageload && <Backdrop />}
        </>
    )
}

const AddIntroPOP = ({ close, cb, data }) => {
    const { t } = useTranslation();
    const outSide = useRef()
    const [pageload, setPageload] = useState(false)
    const [error, setError] = useState('')
    const intro = useRef()
    const submit = async () => {
        if (!intro.current.value) {
            helper.showMessage("please fill required fields")
            return
        }
        if (intro.current.value.trim().length < 100) {
            helper.showMessage("Introduction have atleast 100 characters")
            return
        }
        setPageload(true)
        await tutor.updateIntroduction({ introduction: intro.current.value, tutorId: helper.getid() })
            .then((res) => {
                if (res.data.status) {
                    cb()
                    close()
                }
                setPageload(false)

            })

    }

    return (
        <>
            <div className="all-center popup-modal" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                <div className="left-popup p-fix tp-0 rt-0 p-5 sm" >
                    <h4 className="f-fred mb-0 tx-lt-black">{t('tutorSidebar.edit')} <span className="tx-green">{t('tutorSidebar.intro.intro')} </span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                    <p className="sub-heading mb-3">{t('tutorSidebar.updateInfo')}</p>
                    <form id="teaching" onSubmit={(e) => { e.preventDefault(); submit() }}>
                        <div className="row ms-700 fs-14">
                            <div className="col-12 all-center mb-2 px-1">
                                <textarea minLength={100} ref={intro} maxLength={500} className="w-100 input-box p-2 my-0" placeholder={`${t('tutorSidebar.generalInfo.yourIntro') + " *"} (500 character max)`} defaultValue={data.introduction} rows="8" style={{ height: 'initial' }} />
                            </div>
                            <div className="col-12 ">
                                <p className="f-fred f-14 tx-sky text-center mt-2">{error}</p>
                            </div>
                            <div className="col-lg-8 col-12  px-2 mt-2 mb-3">
                                <button type="submit" className="btn btn-login w-100">{t('tutorSidebar.intro.update')}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {pageload && <Backdrop />}
        </>
    )
}

const AddAboutPOP = ({ close, cb, data }) => {
    const { t } = useTranslation();
    const outSide = useRef()
    const [pageload, setPageload] = useState(false)
    const [error, setError] = useState('')
    const aboutMe = useRef()

    const submit = async () => {
        if (!aboutMe.current.value) {
            helper.showMessage("please fill required fields")
            return
        }
        if (aboutMe.current.value.trim().length < 100) {
            helper.showMessage("About you have atleast 100 characters.")
            return
        }
        setPageload(true)
        await tutor.updateAbout({ aboutMe: aboutMe.current.value, tutorId: helper.getid() })
            .then((res) => {
                if (res.data.status) {
                    cb()
                    close()
                }
                setPageload(false)

            })

    }



    return (
        <>
            <div className="all-center popup-modal" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                <div className="left-popup p-fix tp-0 rt-0  p-5 sm" >
                    <h4 className="f-fred mb-0 tx-lt-black">{t('tutorSidebar.edit')} <span className="tx-green"> {t('tutorSidebar.about')}</span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                    <p className="sub-heading mb-3">{t('tutorSidebar.updateInfo')}</p>
                    <form id="teaching" onSubmit={(e) => { e.preventDefault(); submit() }}>
                        <div className="row ms-700 fs-14">
                            <div className="col-12 all-center mb-2 px-1">
                                <textarea minLength={100} ref={aboutMe} maxLength={800} className="w-100 input-box p-2 my-0" placeholder={`${t('tutorSidebar.aboutTeach.aboutYou') + " *"} (100-800 characters)`} defaultValue={data.aboutMe} rows="8" style={{ height: 'initial' }}></textarea>
                            </div>
                            <div className="col-12 ">
                                <p className="f-fred f-14 tx-sky text-center mt-2">{error}</p>
                            </div>
                            <div className="col-lg-8 col-12  px-2 mt-2 mb-3">
                                <button type="submit" className="btn btn-login fs-14 w-100">{t('tutorSidebar.updateInfo')}</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
            {pageload && <Backdrop />}
        </>
    )
}

const AddTeachingPop = ({ close, cb, data }) => {
    const { t } = useTranslation();
    const outSide = useRef()
    const [pageload, setPageload] = useState(false)
    const [error, setError] = useState('')

    const teachingStyle = useRef()


    const submit = async () => {
        if (!teachingStyle.current.value) {
            helper.showMessage("please fill required fields")
            return
        }
        if (teachingStyle.current.value.trim().length < 100) {
            helper.showMessage("Teaching style have alteast 100 characters")
            return
        }
        setPageload(true)

        await tutor.updateTeachingStyle({ teachingStyle: teachingStyle.current.value, tutorId: helper.getid() })
            .then((res) => {
                if (res.data.status) {
                    cb()
                    close()
                }
                setPageload(false)

            })

    }



    return (
        <>
            <div className="all-center popup-modal" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                <div className="left-popup p-fix tp-0 rt-0  p-5 sm" >
                    <h4 className="f-fred mb-0 tx-lt-black">{t('tutorSidebar.teaching.edit')} <span className="tx-green">{t('tutorSidebar.teaching.style')}</span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                    <p className="sub-heading mb-3">{t('tutorSidebar.updateInfo')}</p>
                    <form id="teaching" onSubmit={(e) => { e.preventDefault(); submit() }}>
                        <div className="row ms-700 fs-14">
                            <div className="col-12 all-center mb-2 px-1">
                                <textarea minLength={100} maxLength={500} ref={teachingStyle} className="w-100 input-box p-2 my-0" placeholder={`${t('tutorSidebar.aboutTeach.style') + " *"} (100-500 characters)`} defaultValue={data.teachingStyle} rows="8" style={{ height: 'initial' }}></textarea>
                            </div>
                            <div className="col-12 ">
                                <p className="f-fred f-14 tx-sky text-center mt-2">{error}</p>
                            </div>
                            <div className="col-lg-8 col-12  px-2 mt-2 mb-3">
                                <button type="submit" className="btn btn-login w-100">{t('tutorSidebar.updateInfo')}</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
            {pageload && <Backdrop />}
        </>
    )
}

const AddBankPop = ({ close, cb, data }) => {
    const { t } = useTranslation();
    const outSide = useRef()
    const [pageload, setPageload] = useState(false)
    const [error, setError] = useState('')
    const submit = async () => {
        const ob = document.getElementById("bank-form")
        const form = new FormData(ob)
        form.append("tutorId", helper.getid())
        if (!form.get('bankName')) {
            helper.showMessage("Please enter bank name")
            return
        }
        else if (!form.get('accountNumber')) {
            helper.showMessage("Please enter account number")
            return
        }
        else if (form.get('accountNumber').length != 16) {
            helper.showMessage('Account number should be 16 digit')
            return
        }
        else if (isNaN(form.get('accountNumber'))) {
            helper.showMessage('Please add valid account number')
            return
        }
        else if (!form.get('bicNumber')) {
            helper.showMessage("Please enter bic number")
            return
        }
        else if (!form.get('iban')) {
            helper.showMessage("Please enter iban")
            return
        }
        setPageload(true)
        await tutor.addBankInfo(form)
            .then((res) => {
                if (res.data.status) {
                    cb(res.data.result)
                    close()
                    helper.showMessage("Bank details successfully saved.")
                } else {
                    helper.showMessage(res.data.error)
                }
                setPageload(false)
            }).catch((err) => { })
    }



    return (
        <>
            <div className="all-center popup-modal" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                <div className="left-popup p-fix tp-0 rt-0 p-5 sm" >
                    <h4 className="f-fred mb-0 tx-lt-black">{t('tutorSidebar.bank.edit')}<span className="tx-green"> {t('tutorSidebar.bank.info')}</span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                    <p className="sub-heading mb-3">{t('tutorSidebar.updateInfo')}</p>
                    <form id="bank-form" onSubmit={(e) => { e.preventDefault(); submit() }}>
                        <div className="row ms-700 fs-14">
                            <div className="col-12 all-center mb-2 px-1">
                                <input type="text" maxLength={50} className="input-box w-100 px-2" placeholder={t('tutorSidebar.bank.name')} name="bankName" defaultValue={data && data.bankName} />
                            </div>
                            <div className="col-12 all-center mb-2 px-1">
                                <input type="text" className="input-box w-100 px-2"
                                    pattern='[0-9]*' title='Account number should contain only digits.'
                                    maxLength={16} placeholder={t('tutorSidebar.bank.account')} name="accountNumber" defaultValue={data && data.accountNumber} />
                            </div>
                            <div className="col-6 all-center mb-2 px-1">
                                <input type="text" maxLength={34}
                                    className="input-box w-100 px-2" placeholder={t('tutorSidebar.bank.bic')} name="bicNumber" defaultValue={data && data.bicNumber} />
                            </div>
                            <div className="col-6 all-center mb-2 px-1">
                                <input type="text" maxLength={20} className="input-box w-100 px-2" placeholder={t('tutorSidebar.bank.iban')} name="iban" defaultValue={data && data.iban} />
                            </div>
                            <div className="col-12 ">
                                <p className="f-fred f-14 tx-sky text-center mt-2">{error}</p>
                            </div>
                            <div className="col-lg-8 col-12  px-2 mt-2 mb-3">
                                <button type="submit" className="btn btn-login  w-100">{t('tutorSidebar.updateInfo')}</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
            {pageload && <Backdrop />}
        </>
    )
}

const EditProfilePop = ({ close, cb, data }) => {
    const { t } = useTranslation();
    const outSide = useRef()
    const [pageload, setPageload] = useState(false)
    const [error, setError] = useState('')
    const [profileImg, setProfileImg] = useState()
    const [phone, setPhone] = useState()
    const [state, setState] = useState({
        openImgCropper: false,
        openVideo: false,
        countryList: [],
        timeZoneList: [],
        country: '',
        timeZone: '',
        imgFile: '',
        videoFile: '',
        code: ''
    })

    useEffect(() => {
        getCountry("")
    }, [])

    const getCountry = async (countryName) => {
        let code = ""
        state.countryList.some((data) => {
            if (data.country_name === countryName) {
                code = data.country_code
                setState({ ...state, timeZoneList: data.timezone, country: countryName, code })
                return true
            } else {
                return false
            }
        })
        if (!code) {
            await getCountryList(code).then((res) => {
                if (res.data.status) {
                    let timeZone = [], code = ""
                    res.data.result.some((result) => {
                        if (data && result.country_name === data.from) {
                            timeZone = result.timezone
                            code = result.country_code
                            return true
                        } else {
                            return false
                        }
                    })
                    setState({ ...state, countryList: res.data.result, timeZoneList: timeZone, code })
                }
            })
        }
    }

    const submitProfile = async () => {
        const ob = document.getElementById("tutor-profile")
        const form = new FormData(ob)
        form.append("tutorId", helper.getid())
        form.append("profilePicture", state.imgFile)


        if (!form.get('name') || !form.get('from') || !form.get('dateOfBirth') || !form.get('phoneNo') || !form.get('timeZone1')) {
            helper.showMessage("please fill the required fields")
            return
        }

        form.append("timeZone", form.get("timeZone1").split("#")[0])
        form.append("time_zone_city", form.get("timeZone1").split("#")[1])

        let validateResp = helper.validate("name", form.get('name'))
        if (!validateResp.isValid) {
            helper.showMessage("Please enter valid name")
            return
        }

        // validateResp = helper.validate("phno", form.get('phoneNo'))
        // if (!validateResp.isValid) {
        //     helper.showMessage("Please enter valid mobileNo")
        //     return
        // }

        setPageload(true)
        await tutor.updateBasicInfo(form)
            .then(() => {
                cb()
                close()
                setPageload(false)
            })
    }
    return (
        <>
            <div className="all-center popup-modal" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                <div className="left-popup p-fix tp-0 rt-0 p-5 sm" >
                    <h4 className="f-fred mb-0 tx-lt-black">{t('tutorSidebar.update')} <span className="tx-green"> {t('tutorSidebar.profile.profile')}</span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                    <p className="sub-heading mb-3">{t('tutorSidebar.profile.basicInfo')}</p>
                    <form id="tutor-profile" onSubmit={(e) => { e.preventDefault() }}>
                        <div className="row f-fred fs-14">
                            <div className="col-6 col-12 mb-2">
                                <input maxLength={30} type="text"
                                    pattern='[a-zA-Z ,.-]*' title='Name only contains a-z and special symbol (,.-).'
                                    name="name" className="d-block px-2 w-100 input-box" placeholder={t('tutorSidebar.profile.name')} defaultValue={data && data.name} />
                            </div>
                            <div className="col-6 col-12 mb-2 ">
                                <input max={moment().format('YYYY-MM-DD')} type="text" name="dateOfBirth" className="d-block  px-2 w-100 input-box f-fred"
                                    onFocus={(e) => { e.target.type = "date"; e.target.defaultValue = data && moment(data.dateOfBirth).format('MM/DD/YYYY'); }}
                                    onBlur={(e) => {
                                        e.target.type = "text";
                                        e.target.defaultValue = data && moment(data.dateOfBirth).format('MM/DD/YYYY');
                                    }} placeholder="Your date of birth *"
                                    defaultValue={data && moment(data.dateOfBirth).format('YYYY-MM-DD')}
                                />
                            </div>

                            <div className="col-6 col-12 mb-2">
                                {state.countryList.length > 0 && <select onChange={(e) => { getCountry(e.target.value) }} className="input-box w-100 text-upper f-fred my-0" defaultValue={data && data.from} name="from" >
                                    <option value="">{t('tutorSidebar.generalInfo.selectCountry')}</option>
                                    {state.countryList.map((data) => <option key={data.country_name} value={data.country_name}>{data.country_name}</option>)}
                                </select>
                                }
                            </div>
                            <div className="col-6 col-12 mb-2">
                                {state.timeZoneList.length > 0 && <select className='input-box w-100 text-upper f-fred my-0' name="timeZone1">
                                    <option value="">{t('tutorSidebar.generalInfo.selectTimeZone')}</option>
                                    {state.timeZoneList.map((timezone) => <option selected = {data?.timeZone + "#" + data?.time_zone_city === timezone?.timeZone + "#" + timezone?.city ? true : false} value={timezone.timeZone + "#" + timezone.city}>{timezone.city + " (" + timezone.timeZone + " UTC)"}</option>)}
                                </select>}
                            </div>

                            {state.code && <div className="col-12 mb-2">
                                <PhoneInput
                                    country={state.code.toLowerCase()}
                                    disableDropdown={true}
                                    value={data?.phoneNo}
                                    inputProps={{ name: 'phoneNo', placeholder: t('tutorSidebar.generalInfo.mobile') + " *" }}
                                    buttonClass="phone-drop"
                                    containerClass="phone-number-box"
                                    inputClass="phone-number input-sbox"
                                    onChange={phone => setPhone(phone)}
                                />
                            </div>}

                            <div className="col-lg-6 col-md-6 col-12 px-1 mt-2 mb-1">
                                <label htmlFor="profileIm" className="btn btn-green fs-14 ms-500 px-2 py-2" onClick={() => setState({ ...state, openImgCropper: true, imgFile: "" })}>{t('tutorSidebar.profile.uploadImg')}</label>
                                <input type="file" accept='image/*' className="d-none" name="profileImg" id="profileImg" onChange={(e) => {
                                    if ((e.target.files[0].size / 1024) / 1024 > 1) {
                                        helper.showMessage("Image size should be less than 1MB", "info")
                                    } else {
                                        setProfileImg(e.target.files[0]);
                                    }
                                }} />
                            </div>
                            <div className="col-lg-6  col-md-6 col-12 px-2 mt-2 mb-1">
                                {state.imgFile ? <img width="70px" height="70px" className="rounded-circle float-right" src={state.imgFile} alt="profile" /> :
                                    (data && data.profilePicture) && <img width="70px" height="70px" className="rounded-circle float-right" src={data.profilePicture} alt="profile" />
                                }
                            </div>

                            <div className="col-12 ">
                                <p className="f-fred f-14 tx-sky text-center mt-2">{error}</p>
                            </div>
                            <div className="col-lg-8 col-12 mt-2 mb-3">
                                <button type="submit" className="btn btn-login w-100" onClick={() => submitProfile()}>{t('tutorSidebar.updateInfo')}</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
            {state.openImgCropper && <S3FileUpload type="image" file={state.imgFile} onClose={() => setState({ ...state, openImgCropper: false, imgFile: null })} cb={(url) => setState({ ...state, openImgCropper: false, imgFile: url })} />}

            {pageload && <Backdrop />}

        </>
    )
}

const PasswordResetPop = ({ close }) => {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation();
    const outSide = useRef()
    const [pageload, setPageload] = useState(false)
    const [error, setError] = useState('')
    const submit = async () => {
        const ob = document.getElementById("tutor-profile")
        const form = new FormData(ob)
        form.append("tutorId", helper.getid())
        if (!form.get('oldPassword') || !form.get('newPassword')) {
            helper.showMessage("please fill the required fields")
            return
        }
        else if (form.get('newPassword') !== form.get('confirmNewPassword')) {
            helper.showMessage("Password mismatch")
            return
        }
        const res = helper.validate("password", form.get('newPassword'))
        if (!res.isValid) {
            helper.showMessage(res.msg)
            return
        }

        setPageload(true)

        await tutor.changePassword(form)
            .then((res) => {
                if (res.data.status) {
                    close()
                    helper.showMessage("password successfully changed.")
                }
                else {
                    helper.showMessage(res.data.error.message)
                }
                setPageload(false)
            })
    }

    return (
        <>
            <div className="all-center tutor-dash popup-modal" ref={outSide} id="outSide" onClick={(e) => { helper.handleOutSide(e, outSide.current.id, () => close()) }}>
                <div className={`left-popup p-fix tp-0 rt-0  p-5 sm ${reduxState.language === 'ar' && "text-right"}`}>
                    <h4 className="f-fred mb-0 tx-lt-black"> {t('userSidebar.password.change')} <span className="tx-green">{t('userSidebar.password.password')}</span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                    <p className="mb-4 sub-heading">{t('userSidebar.password.note')}</p>
                    <form id="tutor-profile" onSubmit={(e) => { e.preventDefault() }}>

                        <div className=" mb-2 ">
                            <ChangePasswordInput placeholder={t('userSidebar.password.oldPass')} name="oldPassword" />
                        </div>

                        <div className="mb-2 ">
                            <ChangePasswordInput placeholder={t('userSidebar.password.newPass')} name="newPassword" />
                        </div>
                        <div className="mb-2 ">
                            <ChangePasswordInput placeholder={t('userSidebar.password.confPass')} name="confirmNewPassword" />
                        </div>

                        <p className='my-2 ms-500 tx-error text-center'>{error}</p>

                        <button type="submit" className="btn btn-login w-100 my-3" onClick={() => submit()}>{t('tutorSidebar.updateInfo')}</button>


                    </form>
                </div>
            </div>
            {pageload && <Backdrop />}

        </>
    )
}

export default {
    BasicInfoPop, TeachingPop,
    WorkExperiencePop, EducationPop, CertificatePop, DigInDeepPop,
    AddLanguagePOP, AddIntroPOP, AddTeachingPop, AddAboutPOP, AddBankPop, EditProfilePop, PasswordResetPop
}