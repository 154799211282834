/* eslint-disable */
import axios from 'axios'
import helper from '../helper/index'
import { constant } from '../config/Contant'
const URL = constant.BASE_URL
const sendClassReq = async ({tid,sid,min,courseName,referralId}) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'post',
            url: `${URL}/web-socket/send-class-notification/${tid}/${sid}/${min}/${courseName}/${referralId}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const acceptRejectClassReq = async ({channel,status,startTime}) => {
    try {
        const token = helper.getToken()
      
        const res = await axios({
            method: 'post',
            url: `${URL}/web-socket/tutor/class-request-accept-reject/${channel}/${status}/${startTime}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

export default {sendClassReq,acceptRejectClassReq}