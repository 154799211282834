/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Menubar from './Menubar'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import helper from '../helper/index'
import avatar from '../static/images/avatar.png'
import store from '../redux/AppState'
import pops from '../component/PopUps'
import tutor from '../controller/tutor'
import firebasePush from '../helper/firebaseDb'
import ClassNotification from './ClassNotificationPop'
import AlertClassPop from '../component/AlertClassPop'
import 'react-dates/initialize';
import calendarIcon from '../static/images/dashboard/Calendar.svg'
import chatIcon from '../static/images/dashboard/Chat.svg'
import { FaBars, FaBell } from 'react-icons/fa'
import { socket } from '../config/socket'
import LanguageTranslator from '../component/LanguageTranslator'
import { useTranslation } from "react-i18next";
import UserTour from '../webtour/UserTour'
import { tutorSteps } from '../webtour/steps'
import AlertForSetAvailability from '../component/tutor/AlertForSetAvailability'
let intervalResp1 = ""
export default function Header() {
    const { t } = useTranslation();
    const history = useHistory()
    const { page } = useParams()
    const storeData = useSelector(state => state)
    const [openMenu, setOpenMenu] = useState(false)
    const [state, setState] = useState({
        changePass: false,
        profilePic: null,
        country: null,
        isOnline: false
    })
    const [upcomingClass, setUpcomingClass] = useState({
        open: false,
        data: []
    })
    const [upcomingClassData, setUpcomingClassData] = useState([])
    const [alertState, setAlertState] = useState({ open: false, is_availability_set: false, is_course_enrolled: false })

    useEffect(() => {
        getProfile()
        getTodayClasses(helper.getid(), moment(new Date()).format('YYYY-MM-DD'))
        firebasePush.getPushPermission()
        socketEnable()
        setTimeout(() => {
            checkForCoursesAndAvailability() /* Alert after 5 seconds */
        }, 5000);
        return () => { try { clearInterval(intervalResp1) } catch { } }
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getProfile = async () => {
        await tutor.getBasicInfo(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    store.setProfilePic(res.data.result && res.data.result.profilePicture && res.data.result.profilePicture)
                    store.setFlag(res.data.result && res.data.result.flag_uri)
                    store.setName(res.data.result && res.data.result.name)
                    setState({ ...state, profilePic: res.data.result && res.data.result.profilePicture && res.data.result.profilePicture, country: res.data.result && res.data.result.flag_uri, isOnline: res.data.result && res.data.result.isOnline })

                }
            }).catch((err) => {
                // localStorage.clear()
                // history.push('/tutor/login')
            })
    }

    const getTodayClasses = async (id, date) => {
        await tutor.getBookedStudentDetailsSlot(id, date).then((res) => {
            if (res.data.status) {
                let list = []
                res.data.result.forEach((data) => {
                    if ((moment(data.end_time, 'hh:mm').format('HH:mm') >= moment().format("HH:mm")) && (data.status === "Booked" || data.status === "Completed")) {
                        list.push(data)
                    }
                })
                setUpcomingClassData(list)
            }
        }).catch((err) => { })
    }

    const socketEnable = () => {
        socket.on(`onNotification${helper.getid()}`, (data) => {
            try {
                if (data.tutDetail && data.tutDetail.type == "Direct class request") {
                    store.setClassNotification({
                        msg: data.tutDetail.description,
                        title: data.tutDetail.type,
                        open: true,
                        data: data
                    })
                    intervalResp1 = setTimeout(() => {
                        store.setClassNotification({
                            msg: "",
                            title: "",
                            open: false,
                            data: null
                        })
                    }, 1000 * 1 * 60)
                }
            } catch (err) {

            }
            store.setNewNotification(true)
        })

        socket.on(`beforeTutorClassPopup${helper.getid()}`, (result) => {
            store.setAlertClassPop({
                open: true,
                data: result,
                type: 'tutor'
            })
        })
    }

    const checkForCoursesAndAvailability = async () => {
        await tutor.checkForTutorCourseAvailability().then((res) => {
            if (res.data.status) {
                setAlertState({
                    ...alertState,
                    open: (!res.data.result.is_availability_set || !res.data.result.is_course_enrolled) ? true : false,
                    is_availability_set: !res.data.result.is_availability_set,
                    is_course_enrolled: !res.data.result.is_course_enrolled
                })
            }
        })
    }
    return (
        <>
            <div className="row gx-0 mx-0 mb-3 p-sticky tp-0 pt-3 bg-white" style={{ zIndex: 1 }}>
                <div className="col-lg-4 col-md-5 col-6 f-fred d-flex flex-column justify-content-center ">
                    {page === "home" ?
                        <div className="mb-1 fs-24  d-flex align-items-start">
                            <span>
                                <label htmlFor="web-sideMenu"><FaBars size={24} className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                            </span>
                            {/* <div className='d-lg-block d-md-block d-none mx-2'>{t('tutorDash.header.tutor')} <span className="tx-green" >{t('tutorDash.header.dashboard')}</span></div> */}
                            <div className='d-lg-block d-md-block d-none mx-2'>{t('tutorDash.header.tutorDash')}</div>
                        </div> : page === "calendar" ?
                            <div className="mb-1 fs-24  d-flex align-items-start">
                                <span>
                                    <label htmlFor="web-sideMenu"><FaBars size={24} className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                    <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                </span>
                                <div className='d-lg-block d-md-block d-none mx-2'>
                                    {t('tutorDash.header.tutorCalendar')}
                                </div>
                            </div> : page === "library" ?
                                <div className="mb-1 fs-24  d-flex align-items-start">
                                    <span>
                                        <label htmlFor="web-sideMenu"><FaBars size={24} className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                        <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                    </span>     <div className='d-lg-block d-md-block d-none mx-2'>
                                        {t('tutorDash.header.tutorLibrary')}
                                    </div>
                                </div> : page === "mystudent" ?
                                    <div className="mb-1 fs-24  d-flex align-items-start">
                                        <span>
                                            <label htmlFor="web-sideMenu"><FaBars size={24} className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                            <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                        </span>      <div className='d-lg-block d-md-block d-none mx-2'>
                                            {t('tutorDash.header.tutorStudent')}
                                        </div>
                                    </div> : page === "mychat" ?
                                        <div className="mb-1 fs-24  d-flex align-items-start">
                                            <span>
                                                <label htmlFor="web-sideMenu"><FaBars size={24} className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                            </span>        <div className='d-lg-block d-md-block d-none mx-2'>
                                                {t('tutorDash.header.yourChat')} 
                                            </div>
                                        </div> :
                                        page === "payout" ?
                                            <div className="mb-1 fs-24  d-flex align-items-start">
                                                <span>
                                                    <label htmlFor="web-sideMenu"><FaBars size={24} className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                    <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                </span>      <div className='d-lg-block d-md-block d-none mx-2'>
                                                    {t('tutorDash.header.yourPayouts')}
                                                </div>
                                            </div> :
                                            page === "support" ?
                                                <div className="mb-1 fs-24  d-flex align-items-start">
                                                    <span>
                                                        <label htmlFor="web-sideMenu"><FaBars size={24} className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                        <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                    </span>      <div className='d-lg-block d-md-block d-none mx-2'>
                                                        {t('tutorDash.header.tutorSupport')} 
                                                    </div>
                                                </div> :
                                                page === "notification" ?
                                                    <div className="mb-1 fs-24  d-flex align-items-start">
                                                        <span>
                                                            <label htmlFor="web-sideMenu"><FaBars size={24} className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                            <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                        </span>
                                                        <div className='d-lg-block d-md-block d-none mx-2'>
                                                            {t('tutorDash.header.my')} <span className="tx-green">{t('tutorDash.header.notification')}</span>
                                                        </div>
                                                    </div>
                                                    : page === "profile" ?
                                                        <div className="mb-1 fs-24  d-flex align-items-start">
                                                            <span>
                                                                <label htmlFor="web-sideMenu"><FaBars size={24} className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                                <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                            </span>        <div className='d-lg-block d-md-block d-none mx-2'>{t('tutorDash.header.my')}  <span className="tx-green">{t('tutorDash.header.profile')}</span></div>
                                                        </div>
                                                        : page === "about" ?
                                                            <div className="mb-1 fs-24  d-flex align-items-start">
                                                                <span>
                                                                    <label htmlFor="web-sideMenu"><FaBars size={24} className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                                    <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                                </span>
                                                                <div className='d-lg-block d-md-block d-none mx-2'>About <span className="tx-green">Dhad</span></div>
                                                            </div>
                                                            : page === "terms-condition" ?
                                                                <div className="mb-1 fs-24  d-flex align-items-start">
                                                                    <span>
                                                                        <label htmlFor="web-sideMenu"><FaBars size={24} className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                                        <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                                    </span>

                                                                    <div className='d-lg-block d-md-block d-none mx-2'> Terms & Condition
                                                                    </div>
                                                                </div>
                                                                : page === "privacy-policy" ?
                                                                    <div className="mb-1 fs-24  d-flex align-items-start">
                                                                        <span>
                                                                            <label htmlFor="web-sideMenu"><FaBars size={24} className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                                            <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                                        </span>
                                                                        <div className='d-lg-block d-md-block d-none mx-2'>Privacy policy</div>
                                                                    </div>
                                                                    : page === "attendance" ?
                                                                        <div className="mb-1 fs-24  d-flex align-items-start">
                                                                            <span>
                                                                                <label htmlFor="web-sideMenu"><FaBars size={24} className='mr-2 inactive-icon-color  pointer d-lg-inline-block d-md-inline-block d-none mt-1' /></label>
                                                                                <FaBars size={24} onClick={() => setOpenMenu(!openMenu)} className='mr-2 inactive-icon-color  pointer pointer d-lg-none  d-md-none d-inline-block mt-1' />
                                                                            </span>
                                                                            <div className='d-lg-block d-md-block d-none mx-2'>{t('tutorDash.attendance.attendanceRecord')}</div>
                                                                        </div>
                                                                        : ''}
                    <p className={`${storeData.language === 'ar' && "text-right"} sub-heading lh-18  d-lg-block d-md-block d-none`}>{t('general.welcomeBack')} <span className='tx-green ms-900'>{storeData.name.split(" ").length > 0 ? storeData.name.split(" ")[0] : ""}</span></p>
                </div>
                <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-lg-center align-items-md-center align-items-start  f-fred" onMouseLeave={() => setUpcomingClass({ ...upcomingClass, open: false })}>
                    <div id='step-language' className="d-lg-block d-md-block d-none mr-2" onMouseOver={() => setUpcomingClass({ ...upcomingClass, open: false })}><LanguageTranslator /></div>
                    {page === 'profile' &&
                        <button className='btn  fs-14 mr-2 change-password d-lg-inline d-md-inline d-none' onClick={() => setState({ ...state, changePass: true })}>{t('general.changePass')}</button>
                    }

                    <div className='p-rel'>
                        <p id='step-today-classes' className="mr-2 icon-box all-center icon-border px-2 py-1 calendar-icon-hover pointer tx-green" onMouseOver={() => { setUpcomingClass({ ...upcomingClass, open: true }); getTodayClasses(helper.getid(), moment().format('YYYY-MM-DD')) }}>
                            <img src={calendarIcon} style={{ width: '25px' }} alt="calendar"/>
                        </p>
                        {upcomingClass.open && <div onMouseLeave={() => setUpcomingClass({ ...upcomingClass, open: false })} className='p-abs upcoming-class-popup'>
                            <div className='upcoming-class-card my-2 p-3' style={{ maxHeight: '380px', overflowY: 'auto' }}>
                                <h4 className='ms-500 text-white'>{t('tutorDash.chat.upcomingCalls')}</h4>

                                <section className='my-2 py-2'>
                                    {
                                        upcomingClassData.map((data, index) =>

                                            <div key={`tutor${index}`} className={` tutor-red-card pointer b-grey bg-white mb-2 px-2`} onClick={() => {

                                                if (helper.isUserCanJoinClass({ classDate: data.date, startTime: data.start_time, endTime: data.end_time })) {
                                                    history.push(`/dhad-video/${data.channel_name}`)
                                                }
                                                else {
                                                    alert("please wait for class time")
                                                }
                                            }}>
                                                <section className='d-flex'>
                                                    <div className="all-center my-2">
                                                        <div className='p-rel mr-2'>
                                                            <img style={{ height: '40px', width: '40px' }} className="rounded-circle mx-1" src={data.profilePicture ? data.profilePicture : avatar} alt="avatar" />
                                                        </div>
                                                    </div>
                                                    <div className='w-100 d-flex justify-content-between'>
                                                        <div className="my-2 pl-2">
                                                            <p className="text-capitalize fs-14 f-fred">{data.name}</p>
                                                            <p className="lh-18 fs-14 ms-500">Student from <span className='ms-900 text-capitalize'>{data.country}</span></p>

                                                        </div>

                                                        <div className="d-flex justify-content-end">
                                                            <span className="p-2">

                                                                <img width="20px" height="20px" className="rounded-circle" src={data.flag_uri}
                                                                    alt="flag" />

                                                            </span>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section className='fs-12 py-2'>
                                                    <p className='ms-700'>{t('tutorDash.calendar.scheduleOn')}</p>
                                                    <p className='ms-900'>{moment(data.date).format('MMMM DD, YYYY')} </p>
                                                    <p> {moment(data.start_time, 'hh:mm').format('hh : mm A')} - {moment(data.end_time, 'hh:mm').format('hh : mm A')}</p>
                                                </section>


                                            </div>
                                        )
                                    }

                                </section>

                                {
                                    upcomingClassData.length === 0 &&
                                    <section>
                                        <p className='my-2 ms-500 text-white'>{t('tutorDash.header.noLesson')}</p>
                                    </section>


                                }

                            </div>
                        </div>}
                    </div>

                    <div id='step-notification' className="mr-2 mr-2 all-center icon-box  icon-border p-rel px-2 py-1 pointer" onMouseOver={() => setUpcomingClass({ ...upcomingClass, open: false })} onClick={() => history.push('/tutor/dashboard/notification')} >

                        <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />

                        <div className={`${storeData.newNotification && "new-notification-tag"} p-abs tp-0 rt-0`}></div>

                    </div>
                    <p id='step-chat' className='mr-2 icon-box all-center icon-border px-2 py-1 d-lg-inline-block d-md-inline-block d-none  pointer tx-green' onMouseOver={() => setUpcomingClass({ ...upcomingClass, open: false })} onClick={() => history.push('/tutor/dashboard/mychat')}><img src={chatIcon} style={{ width: '25px' }} alt="chat" /></p>
                    <div className="mr-2 icon-box icon-border  d-lg-flex d-md-flex d-none py-1 all-center" >
                        <img width="25px" height="25px" className="rounded-circle" src={storeData.setFlag} alt="flag" />
                    </div>
                    <div id='step-profile' className="mr-2 all-center icon-box  pointer" onMouseOver={() => setUpcomingClass({ ...upcomingClass, open: false })} onClick={() => history.push('/tutor/dashboard/profile')}>
                        <div className='p-rel tp-0 '>
                            <img width="35px" height="35px" className="rounded-circle mt-1" src={storeData.profilePic ? storeData.profilePic : avatar} alt="pic" />

                            <div className={`${state.isOnline ? 'online-tag' : 'offline-tag'} p-abs`} style={{ top: '-1px', right: '-1px' }}></div>
                        </div>
                    </div>
                </div>

            </div>


            {state.changePass && <pops.PasswordResetPop
                close={() => setState({ ...state, changePass: false })} />}

            {
                openMenu && <Menubar tab={storeData.userTab} cb={() => setOpenMenu(false)} />
            }

            <ClassNotification /> {/* 15 min class alert */}
            <AlertClassPop /> {/* Direct class notifications */}
            <AlertForSetAvailability /* Notification for set availability */
                open={alertState.open}
                forAvailability={alertState.is_availability_set}
                forCourse={alertState.is_course_enrolled}
                onClose={() => setAlertState({ ...alertState, open: false })}
            />
            <UserTour steps={tutorSteps} /> {/* Tutor Web Tour */}
        </>
    )
}