import React, { useEffect } from 'react'
import logo from '../static/images/Logo.svg'
import userParrot from '../static/images/parrot.svg'
import { useHistory } from 'react-router'
import helper from '../helper/index'
import { useTranslation } from "react-i18next";

export default function TutorWelcome() {
    const { t } = useTranslation();
    const history = useHistory()
    useEffect(() => {
        const url = new URLSearchParams(window.location.search)
        let decEmail = helper.decrypt(url.get("tk1").toString().replace(/ /g, "+"))
        if (!decEmail) {
            history.push('/')
        }
        return () => { }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="tutor-login-home all-center">
            <div className="row gx-0 mx-0">
                <div className="col-lg-5 col-md-8 col-12 px-3 mx-auto all-center flex-column">
                    <div className="all-center mb-3">
                        <img width="80%" src={logo} alt="logo" />
                    </div>
                    <div>
                        <h4 className="f-fred my-2 text-center">{t('tutorOnBoard.welcome.heading')}</h4>
                        <p className="fs-16 ms-500 text-center">{t('tutorOnBoard.welcome.text1')}</p>
                        <p className="f-fred mt-3 text-center">{t('tutorOnBoard.welcome.text2')}</p>
                    </div>
                    <button className="btn btn-login fs-14 mt-4 p-rel" style={{ width: '200px', zIndex: '3' }} onClick={() => history.push('/tutor/signupcheck')}>{t('tutorOnBoard.welcome.start')}</button>
                    <div className="bottom-parrot">
                        <img src={userParrot} alt="parrot" />
                    </div>
                </div>
            </div>
        </div>
    )
}
