/* eslint-disable */
import React from 'react'
import Webcam from "react-webcam";
import { blobToFile } from '../helper/utils';
import { useStopwatch } from 'react-timer-hook';
import tutor from '../helper/tutor';
let chunks = []

export const WebcamStreamCapture = ({ onCapture }) => {
    const { seconds, minutes, start, pause } = useStopwatch({ autoStart: false });
    const webcamRef = React.useRef(null);
    const mediaRecorderRef = React.useRef(null);
    const [capturing, setCapturing] = React.useState(false);
    const [recordedChunks, setRecordedChunks] = React.useState([]);

    const handleStartCaptureClick = React.useCallback(() => {
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm"
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
        start()
    }, [webcamRef, mediaRecorderRef]);

    const handleDataAvailable = React.useCallback(
        ({ data }) => {

            if (data.size > 0) {
                chunks.push(data)
                const blob = new Blob(chunks, {
                    type: "video/webm"
                });
                var file = blobToFile(blob, `intro_vid_${Date.now()}.webm`);
                onCapture(file)
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]);

    const handleStopCaptureClick = React.useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
        if (chunks.length > 0) handleCapture()
    }, [mediaRecorderRef, webcamRef]);

    const handleCapture = React.useCallback(() => {
        if (chunks.length) {
            // const blob = new Blob(chunks, {
            //     type: "video/webm"
            // });
            // var file = blobToFile(blob, `intro_vid_${Date.now()}.webm`);
            // onCapture(file)
        }
    }, [recordedChunks]);

    return (
        <>
            <div className='all-center flex-column'>
                <Webcam style={{ width: '100%' }} audio={true} ref={webcamRef} />
                {capturing ? (
                    <div className='d-flex justify-content-between align-items-center w-100 px-2'>
                        <div className="br-1 ms-700  fs-18 tx-green"><span><i className="fas fa-compact-disc"></i></span> {tutor.makeTwoDigit(minutes)} : {tutor.makeTwoDigit(seconds)}</div>
                        <button className='btn btn-login mr-2 my-2' onClick={handleStopCaptureClick}>Stop Capture</button>
                    </div>
                ) : (
                    <button className='btn btn-tutor-profile my-2' onClick={handleStartCaptureClick}>Start Capture</button>
                )}

            </div>

        </>
    );
};

