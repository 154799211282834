import React,{ useRef } from 'react'
import Path from '../static/images/home/Path.svg'
import SubscribeImg from '../static/images/home/block.svg'
import Girl2Img from '../static/images/home/Bitmap.png'
import { useHistory } from 'react-router-dom'
import helper from '../helper'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'

export default function TutorSubscribe() {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation();
    const email = useRef()
    const history = useHistory()
    const handleClick = () => {
        let resp = helper.validate("email", email.current.value)
        if (!resp.isValid) { helper.showMessage("please enter valid email"); return; }
        else {
            let decEmail = helper.encrypt(email.current.value)
            history.push(`/tutor/signup?token=${decEmail}`)
        }
        
    }
    return (
        <div className={`row d-flex ${reduxState.language === 'ar' && "text-right"}`}>
            <div className="col-lg-1"></div>
            <div className="col-lg-4 px-3 left v-center order-lg-1 order-md-1 order-3">
                <div>
                    <p style={{ opacity: 0.7 }} className='tx-lt-black ms-700'>{t('tutorHome.sec4.getStarted')}</p>
                    <div className='tx-lt-black fs-24 ms-500 mb-3'>
                        <p className='mt-3'>{t('tutorHome.sec4.heading')}</p>
                        <p><span className='ms-900 mb-3 tx-green'>Say-Dhad</span></p>
                    </div>
                    <div>
                        <input placeholder='email' ref={email} type="text" className='f-fred' name="" id="" />
                        <button className='btn find-tutor-btn d-block mt-4' onClick={handleClick}>{t('tutorHome.sec4.signup')}</button>
                    </div>
                </div>
            </div>
            <div className="col-lg-3  pt-5 order-lg-2 order-md-2 order-3">
                <div>
                    <img src={SubscribeImg} className="mw-100" alt="subscribe" />
                </div></div>
            <div className="col-lg-4 v-center order-lg-3 order-md-3 order-1">
                <div className='d-flex'>
                    <div className='girl2-img-box'>
                        <img src={Path} className="cloud-img" alt='path' />
                        <img src={Girl2Img} className="mw-100 d-block mx-auto girl2-img" alt='girl' />
                    </div>

                </div>
            </div>
        </div>

    )
}
