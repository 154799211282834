/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import './checkout.css'
import { FaArrowLeft } from 'react-icons/fa'
import amazonLogo from '../static/images/amazonpayment.png'
import madaLogo from '../static/images/mada.png'

import { useHistory } from 'react-router-dom'
import helper from '../helper'
import { constant } from '../config/Contant'

import arCheckout from '../static/images/ar_checkout.png'
import enCheckout from '../static/images/en_checkout.png'
import { useSelector } from 'react-redux'
let BINS = require("../helper/bins.json")
var valid = require("card-validator");


export default function Checkout() {
    const reduxState = useSelector(state => state)
    const history = useHistory()
    const [state, setState] = useState({
        name: '',
        cardNo: '',
        cvc: '',
        date: '',
        error: '',
        type: '',
        access_code: "",
        amount: 0,
        amountInSar:0,
        language: "",
        merchant_identifier: "",
        merchant_reference: "",
        signature: "",
        return_url: "",
        currency : "INR"
    })
    const [isMada, setIsMada] = useState(false)
    const payRef = useRef()

    useEffect(() => {
        let query = new URLSearchParams(window.location.search)
        try {
            query = helper.decrypt(query.get("q").toString().replace(/ /g, "+"))
            let params = query.split("&")

            /* payment param object */
            let paymentParam = {}
            params.forEach((data) => {
                let p = data.split("=")
                paymentParam[p[0]] = p[1]
            })
            setState({ ...state, ...paymentParam })
        } catch (err) {
            history.goBack()
        }

    }, [])

    const validateForm = async (e) => {
        /* Validate form */
        const paymentForm = document.getElementById('paymentForm')
        let req = {}
        for (let i = 0; i < paymentForm.clientHeight; i++) {
            try {
                req[paymentForm[i].name] = paymentForm[i].value
            }
            catch (err) {
            }
        }

        if (!req.card_holder_name) {
            setState({ ...state, type: 'name', error: "Cardholder name is required" })
            e.preventDefault()
        } 
        else if (!req.card_number) {
            setState({ ...state, type: 'card', error: "Card number is required" })
            e.preventDefault()
        }
        else if (isNaN(req.card_number)) {
            setState({ ...state, type: 'card', error: "Enter valid card number" })
            e.preventDefault()
        }
        else if (req.card_number.length <= 15) {
            setState({ ...state, type: 'card', error: "Enter valid card number" })
            e.preventDefault()
        }
        else if (!req.expiry_date.length || req.expiry_date.length !== 4) {
            setState({ ...state, type: 'date', error: "Enter valid expiry date" })
            e.preventDefault()
        }
        else if (!req.card_security_code) {
            setState({ ...state, type: 'cvc', error: "Enter valid cvc/cvw" })
            e.preventDefault()
        } else if (!(req.card_security_code.length === 3 || req.card_security_code.length === 4)) {
            setState({ ...state, type: 'cvc', error: "Enter valid cvc/cvw" })
            e.preventDefault()
        }
    }

    const handleFieldValidation = ({ field, value }) => {
        if (field === "name") {
            const reg = new RegExp('^[a-zA-Z ]+$');
            const res = reg.test(value);
            if (res) {
                setState({ ...state, name: value })
            } else if (!value) {
                setState({ ...state, name: '' })
            }
        }
        else if (field === "cardNo") {
            let find = false
            BINS.some((item) => {
                if (value.startsWith(item.bin)) {
                    find = true
                    return find
                }
                return find
            })
            setIsMada(find)
            const reg = new RegExp('^[0-9]+$');
            const res = reg.test(value);
            if (res) {
                setState({ ...state, cardNo: value })
            } else if (!value) {
                setState({ ...state, cardNo: '' })
            }
        }
        else if (field === "date") {
            const reg = new RegExp('^[0-9]+$');
            const res = reg.test(value);
            if (res) {
                setState({ ...state, date: value })
            } else if (!value) {
                setState({ ...state, date: '' })
            }
        }
        else if (field === "cvw") {
            const reg = new RegExp('^[0-9]+$');
            const res = reg.test(value);
            if (res) {
                setState({ ...state, cvc: value })
            } else if (!value) {
                setState({ ...state, cvc: '' })
            }
        }
    }



    return (
        <div className="checkout">
            <div className="checkout-box">
                <div className="left-checkout">
                    <div className='mt-4'>
                        <img className='w-100 px-3' src={amazonLogo} alt="amazon-pay" />
                    </div>
                    <div className='mb-5'>
                        <h5 className='mb-2'>To Pay</h5>
                        <h3 className='pay-amount'>{state.currency} {parseFloat(state.amount)}</h3>
                    </div>
                    <div className='cancel-pay' onClick={() => history.goBack()}>
                        <FaArrowLeft /> <span className='ml-3'>Cancel your payment</span>
                    </div>
                </div>
                <div className="right-checkout">
                    <div className='mt-1 mb-2 d-lg-none d-md-none'>
                        {/* <img className='w-100 px-3' src={reduxState.language === 'ar' ? arCheckout : enCheckout} alt="amazon-pay" /> */}
                    </div>
                    <div className='d-lg-block d-md-block d-none'>
                        <h2 className='bg-heading'>Payments</h2>
                        <h5 className='sm-heading mb-2'>Payment with card</h5>
                    </div>
                    <div className='mb-2'>
                        <img className='w-100 px-3' src={reduxState.language === 'ar' ? arCheckout : enCheckout} alt="amazon-pay" />
                    </div>

                    <form ref={payRef} onSubmit={(e) => { validateForm(e); }} action={"https://checkout.PayFort.com/FortAPI/paymentPage"} method="post" id="paymentForm" >
                        <div>
                            {/* Hidden fields */}
                            <input type="hidden" name="service_command" value="TOKENIZATION" />
                            <input type="hidden" name="access_code" value={state.access_code} />
                            <input type="hidden" name="merchant_identifier" value={state.merchant_identifier} />
                            <input type="hidden" name="merchant_reference" value={state.merchant_reference} />
                            <input type="hidden" name="language" value={state.language} />
                            <input type="hidden" name="return_url" value={state.return_url} />
                            <input type="hidden" name="signature" value={state.signature} />

                            <div className='mb-2'>
                                <label htmlFor="card_holder_name" className='input-label'>Card holder name <small>(*)</small></label>
                                <input type="text" maxLength={30} name="card_holder_name" autoFocus value={state.name} placeholder='Fullname' id='card_holder_name' className='input-box p-2' onChange={(e) => { handleFieldValidation({ field: 'name', value: e.target.value }) }} />
                                <small className='tx-red'>{state.type === "name" && state.error}</small>
                            </div>
                            <div className='mb-2'>
                                <label htmlFor="card_number" className='input-label'>Card number <small> (*16 digit)</small></label>
                                <div className='input-box all-center'>
                                    <input type="tel" maxLength={19} name="card_number" value={state.cardNo} placeholder='0000 0000 0000 0000' id='card_number' className='input-box-card p-2 w-100' onChange={(e) => { handleFieldValidation({ field: 'cardNo', value: e.target.value }) }} />
                                    <div style={{ width: '80px', paddingRight: '10px' }}>
                                        {isMada && <img width={"60px"} src={madaLogo} alt="" />}
                                    </div>
                                </div>
                                <small className='tx-red'>{state.type === "card" && state.error}</small>
                            </div>
                            <div className="row mb-5">
                                <div className="col-lg-6 col-md-6 pr-lg-2 pr-0">
                                    <div className='mb-2'>
                                        <label htmlFor="expiry_date" className='input-label'>Expiry Date <small>(*)</small></label>
                                        <input type="tel" maxLength={4} name="expiry_date" id='expiry_date' value={state.date} placeholder='YYMM' className='input-box p-2' onChange={(e) => { handleFieldValidation({ field: 'date', value: e.target.value }) }} />
                                        <small className='tx-red'>{state.type === "date" && state.error}</small>
                                    </div>

                                </div>
                                <div className="col-lg-6 col-md-6 pl-lg-2 pl-0">
                                    <div className='mb-2'>
                                        <label htmlFor="card_security_code" className='input-label'>CVC/CW <small> (* 3 or 4 digit)</small></label>
                                        <input type="tel" maxLength={4} name="card_security_code" id='card_security_code' value={state.cvc} placeholder='123' className='input-box p-2' onChange={(e) => { handleFieldValidation({ field: 'cvw', value: e.target.value }) }} />
                                        <small className='tx-red'>{state.type === "cvc" && state.error}</small>
                                    </div>
                                </div>
                            </div>
                            <button id="pay-btn" type='submit' className='btn btn-pay'>PAY NOW</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
