/* eslint-disable */
import React from 'react'
import Webcam from "react-webcam";
import { dataURLtoFile } from '../helper/utils';

const videoConstraints = {
    width: 300,
    height: 250,
    facingMode: "user"
};

export default function CapturePhoto({onCapture}) {
    const webcamRef = React.useRef(null);
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            var file = dataURLtoFile(imageSrc, 'pic.jpeg');
            onCapture(file)
        },
        [webcamRef]
    );

    return (
        <div className="flex-column all-center p-2">
            <Webcam
                audio={false}
                ref={webcamRef}
                imageSmoothing={true}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
            />
            <button className='btn btn-login my-2' style={{ width: '120px' }} onClick={capture}> Capture</button>
        </div>
    );
}