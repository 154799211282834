/* eslint-disable */
import React, { useState, useEffect } from 'react'
import parrot from '../static/images/parrot.png'
import right from '../static/images/write.png'
import user from '../controller/user'
import helper from '../helper/index'
import avatar from '../static/images/avatar.png'
import UserPops from './UserPops'
import Testimonial from '../component/Testimonial'
import store from '../redux/AppState'
import Backdrop from '../component/Backdrop'
import moment from 'moment'
import AlertPopUp from '../component/AlertPopUp'
import { useSelector } from 'react-redux'
import ConfirmPopup from '../component/ConfirmPopup'
import { useTranslation } from "react-i18next";
export default function MyProfile() {
    const { t } = useTranslation();
    const storeData = useSelector(state => state)
    const [state, setState] = useState({
        resData: null,
        error: null,
        pageload: true,
        goalType: {
            1: "PROFESSIONAL DEVELOPMENT",
            2: "ACADMICS",
            3: "TRAVEL",
            4: "PERSONAL GROWTH",
        },
        level: {
            1: "Basic",
            2: "Intermediate",
            3: "Advanced"
        },
        showPicModal: false,
        selectedImage: null,
        editProfile: false
    })

    const [testimonial, setTestimonial] = useState({
        load: true,
        error: '',
        data: [],
        page: 1,
        limit: 5,
        hide: false
    })
    const [plan, setPlan] = useState()
    const [alert, setAlert] = useState({
        msg: '',
        type: 'ok',
        alertPop: false,
        messagePop: false,
        for: ''
    })
    useEffect(() => {
        getProfile()
        getTestimonials(1)
        getPlan()
        return () => { }
    }, [])

    const getProfile = async () => {
        await user.getMyProfile(helper.getid()).then((res) => {
            if (res.data.status) {
                store.setFlag(res.data.result && res.data.result.flag_uri)
                store.setProfilePic(res.data.result && res.data.result.profilePicture)
                store.setName(res.data.result && res.data.result.name)
                setState({ ...state, resData: res.data.result, pageload: false, profilePic: res.data.result && res.data.result.profilePicture })
            } else {
                setState({ ...state, resData: null, pageload: false, error: 'something went wrong' })
            }
        })
    }

    const getTestimonials = async (page) => {
        let list = testimonial.data
        await user.getRatings(helper.getid(), page, testimonial.limit).then((res) => {
            if (res.data.status) {
                list = list.concat(res.data.result)
                setTestimonial({ ...testimonial, data: list, load: false })
                if (res.data.result.length < 5) {
                    setTestimonial({ ...testimonial, data: list, load: false, error: '', hide: true })
                }
            }
            else {
                setTestimonial({ ...testimonial, data: [], load: false, error: '', hide: true })
            }
        })
    }

    const getPlan = async () => {
        await user.getMyPlan(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    if (res.data.result && res.data.result.message === "Please purchase a subscription plan") {
                        setPlan()
                    }
                    else {
                        const data = {
                            isPurchase: true,
                            data: res.data.result
                        }
                        helper.savePlanDetails({ plandata: data })
                        setPlan(res.data.result)
                    }
                }
            })
    }

    const cancelPlan = async () => {
        const req = {
            "student_id": helper.getid(),
            "subscription_plan_id": plan.subscription_plan_id
        }
        setState({ ...state, pageload: true })
        await user.cancelPlan(req).then((res) => {
            if (res.data.status) {
                const data = {
                    isPurchase: false,
                    data: ''
                }
                helper.savePlanDetails({ plandata: data })
                getPlan()
                setAlert({ ...alert, messagePop: true, alertPop: false, msg: t('messages.planCancelled'), type: 'ok' })
            } else {
                setAlert({ ...alert, messagePop: true, alertPop: false, msg: res.data.error, type: 'fail' })
            }
            store.setLoadWindow(!storeData.loadWindow)
            setState({ ...state, pageload: false })
        }).catch((err) => {
            setAlert({ ...alert, messagePop: true, msg: err, alertPop: false, type: 'fail' })
            setState({ ...state, pageload: false })
        })
    }

    const pausePlan = async (status) => {
        const req = {
            "user_id": helper.getid(),
            "status": status
        }
        setState({ ...state, pageload: true })
        await user.pauseResumePlan(req).then((res) => {
            if (res.data.status) {
                const data = {
                    isPurchase: false,
                    data: ''
                }
                helper.savePlanDetails({ plandata: data })
                getPlan()
                setAlert({ ...alert, messagePop: true, alertPop: false, msg: status === "Pause" ? t('messages.planPaused') : t('messages.planResumed'), type: 'ok' })
            } else {
                setAlert({ ...alert, messagePop: true, alertPop: false, msg: res.data.err, type: 'fail' })
            }
            store.setLoadWindow(!storeData.loadWindow)
            setState({ ...state, pageload: false })
        }).catch((err) => {
            setAlert({ ...alert, messagePop: true, msg: err, alertPop: false, type: 'fail' })
            setState({ ...state, pageload: false })
        })
    }

    return (
        <>
            {!state.pageload &&
                <div className={`student-profile ms-500 ${storeData.language === 'ar' && "text-right"}`}>
                    <div className="row gx-0 mx-0">
                        <div className="col-lg-6">
                            {plan &&
                                <section className="row gx-0 mx-0">
                                    <div className="col-lg-6">
                                        <div className='plan-summary py-4 px-3 h-100'>
                                            <div className='d-flex justify-content-between align-items-center mb-3'>
                                                <div>
                                                    <h3 className='ms-900 text-white text-upper'>{plan ? plan.subscription : "None"}</h3>
                                                    <p className='ms-900 tx-lt-black text-upper fs-12'>{t('userSidebar.plan.currentlyActive')}</p>
                                                </div>
                                                <div>
                                                    <img style={{ width: '30px', height: '30px' }} src={right} alt="right" />
                                                </div>
                                            </div>
                                            <div className='my-4' style={{ border: '2px dashed white' }}></div>
                                            <div className='mt-3 '>
                                                <p className='f-fred fs-14'>{t('userDash.profile.planSum')}</p>
                                                <p className='ms-500 fs-14'>{plan ? plan.minutes_per_day : 0} {t('userSidebar.plan.min/day')} , {plan ? plan.days_per_week : 0} {t('userSidebar.plan.day/week')} | {plan && plan.commitment_level}</p>
                                                <div className='my-3' style={{ border: '1px dashed black' }}></div>
                                                <div className='d-flex justify-content-between ms-700'>
                                                    <span>{t('userSidebar.plan.totalCost')}</span>
                                                    <span>{plan ? plan?.currency : ""} {plan ? plan?.currency_total_amount : 0}</span>
                                                </div>
                                                <div className='d-flex justify-content-between ms-700 mt-2'>
                                                    <span>{t('userSidebar.plan.purchaseOn')}</span>
                                                    <span>{moment(plan?.subscription_date).format("MMM DD, YYYY")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className='px-2 h-100'>
                                            <div style={{ backgroundColor: '#F7F7F7' }} className="plan-minutes d-flex justify-content-between px-2 py-2 br-1">
                                                <div className='d-flex ms-900 align-itemss-center flex-column'>
                                                    <p style={{ fontSize: '80px' }} className='f-fred   mr-0'>{plan ? plan.minutes : 0}</p>
                                                    <p className='fs-24 d-flex'><span>{t('userSidebar.plan.minLeft')}</span></p>
                                                </div>
                                                <div className='all-center'>
                                                    <img style={{ width: '60px' }} src={parrot} alt="parrot" />
                                                </div>
                                            </div>
                                            {plan && <p className='mt-3 text-center'><span className='ms-900'> <span className='tx-lt-grey ms-500 fs-18'>{t('userDash.profile.dueDate')} </span> <br />  {moment(plan.renewOn).format('MMM DD, YYYY')}</span></p>}
                                            {plan &&
                                                <div>
                                                    <button className='text-upper btn cancel-subscription py-2 my-1 fs-14 f-fred ls w-100' onClick={() => setAlert({ ...alert, alertPop: true, for: 'Cancel', msg: t('messages.askCancelPlan') })}>{t('userDash.profile.cancelPlan')}</button>
                                                    {
                                                        plan.subscription_pause_date ?
                                                            <button className='text-upper btn btn-tutor-profile text-white py-2 my-1 ls w-100' onClick={() => setAlert({ ...alert, alertPop: true, for: 'Resume', msg: t('messages.askResumePlan') })}>{t('userDash.profile.resumePlan')}</button>
                                                            :
                                                            <button className='text-upper btn btn-login text-white py-2 my-1  ls w-100' onClick={() => setAlert({ ...alert, alertPop: true, for: 'Pause', msg: t('messages.askPausePlan') })}>{t('userDash.profile.pausePlan')}</button>
                                                    }
                                                </div>

                                            }
                                        </div>
                                    </div>
                                </section>
                            }

                            <section>
                                <div style={{ backgroundColor: '#F4F6FB' }} className="address-body fs-14 mt-3 p-3 br-1">

                                    <div className="row gx-0 mx-0 f-fred mb-2">
                                        <div className="col-lg-5 col-md-6 col-8 d-flex align-items-center">
                                            <p className='fs-24'>{t('userDash.profile.proDetail')}</p>
                                        </div>

                                        <div className="col-lg-7 col-md-6 col-4 all-center align-items-center">
                                            <button className="btn edit-info ms-700 fs-12 ml-auto d-block px-3 wrap mr-2" onClick={() => setState({ ...state, editProfile: true })}><i className='fas fa-pencil mr-2'></i> <span className='d-lg-inline d-md-inline d-none'>{t('userSidebar.profile.edit')}</span></button>
                                            <div className='p-rel'>
                                                <img src={state.resData && state.resData.profilePicture ? state.resData.profilePicture : avatar} style={{ height: '50px', width: '50px' }} className="rounded-circle" alt="user" />
                                                <label htmlFor="pic" className='p-abs' style={{ right: '-4px', bottom: '-7px' }}><i className="fas fa-camera fs-24 pointer"></i></label>
                                                <input type="file" accept="image/png, image/jpeg, image/jpg" onChange={(e) => {
                                                    setState({ ...state, selectedImage: e.target.files[0], showPicModal: true })
                                                }} name="" id="pic" className='d-none' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row fs-16 tx-lt-grey">
                                        <div className="col-lg-4 col-md-4 col-4">
                                            <p className="ms-500">{t('userDash.profile.fullName')}</p>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                            <p className="ms-900 ml-auto text-capitalize">{state.resData && state.resData.name}</p>
                                        </div>
                                        <div className="col-12  border-dot my-1"></div>
                                        <div className="col-lg-4 col-md-4 col-4">
                                            <p className="ms-500">{t('userDash.profile.email')}</p>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                            <p className="ms-900  ml-auto">{state.resData && state.resData.email}</p>
                                        </div>
                                        <div className="col-12 border-dot my-1"></div>
                                        <div className="col-lg-4 col-md-4 col-4">
                                            <p className="ms-500">{t('userDash.profile.country')}</p>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                            <p className="ms-900  ml-auto">{state.resData && state.resData.country}</p>
                                        </div>
                                        <div className="col-12 border-dot my-1"></div>
                                        <div className="col-lg-4 col-md-4 col-4">
                                            <p className="ms-500">{t('userDash.profile.accountFor')}</p>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                            <p className="ms-900  ml-auto">{state.resData && state.resData.selfAccount ? "Myself" : "Kids"}</p>
                                        </div>
                                        <div className="col-12 border-dot my-1"></div>
                                        <div className="col-lg-4 col-md-4 col-4">
                                            <p className="ms-500">{t('userDash.profile.level')}</p>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                            <p className="ms-900  ml-auto">{state.resData && state.level[state.resData.levelType]}</p>
                                        </div>
                                        <div className="col-12 border-dot my-1"></div>
                                        <div className="col-lg-4 col-md-4 col-3">
                                            <p className="ms-500">{t('userDash.profile.goal')}</p>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-9 d-flex justify-content-center">
                                            <p className="ms-900  ml-auto text-capitalize">{state.resData && state.goalType[state.resData.goalType]}</p>
                                        </div>
                                    </div>
                                </div>

                            </section>
                        </div>
                        <div className="col-lg-6 col-12 px-2">
                            <h4 className='tx-lt-grey ms-900 mb-3 mt-2'>{t('userDash.profile.test')}</h4>
                            {
                                !testimonial.load && !testimonial.error && testimonial.data.map((result, index) => {
                                    return (
                                        <div key={`testimonial${index}`} className='mb-3'>
                                            <Testimonial
                                                userName={result.name}
                                                country={result.from}
                                                date={result.createdOn}
                                                rate={result.ratings}
                                                review={result.review}
                                                pic={result.profilePicture}
                                            />

                                        </div>
                                    )
                                })
                            }
                            {/* testimonial erro handling  */}
                            {!testimonial.load && testimonial.error &&
                                <p className='ms-700 my-2 text-center'>Something went wrong</p>
                            }
                            {!testimonial.load && !testimonial.error && testimonial.data.length === 0 && <div>
                                <img className='mx-auto d-block my-4' style={{ maxWidth: '100%', width: '300px' }} src="https://assets.justinmind.com/wp-content/uploads/2020/11/testimonial-examples-social-proof.png" alt="avatar" />
                            </div>
                            }

                            {!testimonial.hide && <button
                                onClick={() => {
                                    setTestimonial({ ...testimonial, page: testimonial.page + 1 });
                                    getTestimonials(testimonial.page + 1)
                                }}
                                className='btn load-more px-2 py-1 fs-14 f-fred mt-3'>{t('button.loadMore')}</button>}

                        </div>
                    </div>
                </div>
            }

            <UserPops.UploadProfilePic
                open={state.showPicModal}
                image={state.selectedImage}
                close={() => setState({ ...state, showPicModal: false })}
                cb={(data) => {
                    setState({ ...state, resData: data, showPicModal: false });
                    store.setProfilePic(
                        data && data.profilePicture
                    )
                }}
            />
            {state.editProfile &&
                <UserPops.EditDetails
                    open={state.editProfile}
                    data={state.resData}
                    close={() => setState({ ...state, editProfile: false })}
                    cb={(data) => { setState({ ...state, resData: data, editProfile: false }) }}
                />
            }

            <ConfirmPopup
                onClose={() => { setAlert({ ...alert, alertPop: false, messagePop: false }) }}
                onOk={() => {
                    if (alert.for === "Cancel") {
                        cancelPlan()
                    } else if (alert.for === "Pause") {
                        pausePlan('Pause')
                    }
                    else if (alert.for === "Resume") {
                        pausePlan('Resume')
                    }
                    setAlert({ ...alert, alertPop: false });
                }}
                open={alert.alertPop}
                msg={alert.msg}
            />

            <AlertPopUp
                onClose={() => { setAlert({ ...alert, messagePop: false, alertPop: false }) }}
                open={alert.messagePop}
                type={alert.type}
                msg={alert.msg}

            />

            {state.pageload && <Backdrop />}

        </>
    )
}
