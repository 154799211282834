/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import helper from '../helper/index'
import logo from '../static/images/Logo.svg'
import socketController from '../controller/socket'
import Backdrop from '../component/Backdrop'
import user, { getCourseTitleByTutorId } from '../controller/user'
import { socket } from '../config/socket'
import { useTranslation } from "react-i18next";

let isRespond = false
let cName = ''
let intervalResp1 = ""
let intervalResp2 = ""
export default function DirectClassByReferral() {
    const { t } = useTranslation();
    const history = useHistory()
    const { tutorId, name, referralId } = useParams()
    const [state, setState] = useState({
        pageload: false,
        err: null,
        data: null,
        isSubmit: false
    })
    const course = useRef()
    const [referral, setReferral] = useState({
        referral_code: "",
        referral_minutes: 0,
        courses: [],
        pageload: true,
        error: 'Please wait ...'
    })

    useEffect(() => {
        socketEnable()
        getReferralClasses()
        if (!helper.getPlanDetails().isPurchase) {
            history.goBack()
        }
        return () => {
            try { clearInterval(intervalResp1) } catch { }
            try { clearInterval(intervalResp2) } catch { }
        }
    }, [])

    const getReferralClasses = async () => {

        await user.getReferralDetailById({ studentId: helper.getid(), referralId: referralId }).then((res) => {
            if (res.data.status) {
                getCourseTitle(res.data.result)
            } else {
                setReferral({ ...referral, pageload: false, courses: [], error: res.data.error })
            }
        })
    }

    const getCourseTitle = async (referralData) => {
        getCourseTitleByTutorId().then((res) => {
            if (res.data.status) {
                setReferral({ ...referral, ...referralData, courses: res.data.result, pageload: false, error: '' })
            } else {
                setReferral({ ...referral, pageload: false, courses: [], error: res.data.error })
            }
        })
    }

    const socketEnable = () => {
        socket.on(`onTutorResponse${tutorId}`, (data) => {
            if (data.status == 'true') {
                history.push(`/instant-online-class/${cName}`)
            } else {
                setState({ ...state, isSubmit: true, err: 'Oops!, your request has been rejected' })
                intervalResp1 = setTimeout(() => {
                    history.goBack()
                }, 1500)
            }
            isRespond = true
        })
    }


    const sendReq = async () => {
        if (!tutorId) {
            helper.showMessage("Invalid action", "error")
            return
        }
        if (!helper.getid()) {
            helper.showMessage("Invalid action", "error")
            return
        }

        if (!course.current.value) {
            helper.showMessage("Please select course", "error")
            return
        }

        setState({ ...state, pageload: true, isSubmit: false })
        await socketController.sendClassReq({ tid: tutorId, sid: helper.getid(), min: referral.referral_minutes, courseName: course.current.value, referralId: referralId }).then((res) => {
            if (res.data.status) {
                cName = res.data.result.channel_name
                setState({ ...state, isSubmit: true, pageload: false })
                intervalResp2 = setTimeout(() => {
                    if (!isRespond) {
                        history.push(`/user/dashboard/home`)
                    }
                }, 1000 * 1 * 60)

            } else {
                setState({ ...state, isSubmit: true, pageload: false })
                helper.showMessage(res.data.error, "error")
            }
        })
    }


    return (
        <div className='video-access-container ltr tx-lt-black d-flex flex-column bg-white'>
            <section className='video-room-header p-3 px-4'>
                <table className='w-100'>
                    <tr>
                        <td className='table-break w-50'>
                            <img height={'50px'} src={logo} alt="logo" />
                        </td>
                        <td className='table-break w-100  d-lg-block d-md-block d-none'>
                            <div className='d-flex w-100 flex-column justify-content-lg-end mb-2 align-items-lg-end align-items-md-end'>
                                <p className='ms-900'>{helper.getEmail()}</p>
                                <p className='ms-900'>{helper.getName()}</p>
                            </div>
                        </td>
                    </tr>
                </table>
            </section>
            <div className='all-center h-100 d-flex p-2'>
                {
                    !referral.pageload && !referral.error ?
                        <div className='video-access-question d-flex justify-content-center flex-column user-dash px-5' style={{ height: '400px' }}>
                            {state.isSubmit ?
                                <>
                                    {!state.err ?
                                        <section>
                                            <h3 className='ms-700 text-center'>{t('userDash.directClass.informTutor')}</h3>
                                            <h4 className='ms-900 tx-sky text-center'>{name}</h4>
                                            <p className='text-center ms-500 mt-2'>{t('userDash.directClass.requestAccept')}</p>
                                            <p className='text-center ms-500 mb-2'>{t('userDash.directClass.redirectRoom')}</p>
                                        </section>
                                        :
                                        <h3 className='f-fred tx-lt-grey'>{state.err}</h3>
                                    }
                                    <img style={{ width: '200px' }} className="d-block mx-auto" src="https://www.scudamores.com/assets/damsel/assets/img/load.gif" alt="gif" />
                                </> :

                                <section className='tutor-dash' style={{ height: 'initial' }}>
                                    <h2 className='ms-900 my-2'>{t('userDash.directClass.welcomeClass')}</h2>
                                    <p className='ms-700'>{t('userDash.directClass.classDur')}</p>
                                    <p className='f-fred'>{referral.referral_minutes} {t('general.minutes')}</p>
                                    <p className='mt-3 ms-500 '>{t('userDash.directClass.whatYouLearn')}</p>
                                    <select ref={course} className='w-75 input-box py-2 fs-16 ms-700'>
                                        {referral.courses.map((data) => <option key={data.id} value={data.title}>{data.title}</option>)}
                                    </select>
                                </section>
                            }

                            <section className='mt-3'>
                                {!state.isSubmit && <>
                                    <button className='btn btn-login text-white py-2 px-4 mx-2 mb-2' style={{ width: '130px' }} onClick={() => sendReq()}>{t('button.submit')}</button>
                                    <button className='btn cancel-subscription text-white py-2 px-4 f-fred fs-14 mb-2' style={{ width: '130px' }} onClick={() => history.goBack()}>{t('button.cancel')}</button> </>}
                            </section>

                        </div>
                        :
                        <h2 className='f-fred tx-lt-grey'>{referral.error}</h2>
                }

            </div>
            {state.pageload && <Backdrop />}
        </div>
    )
}
