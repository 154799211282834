import React,{useState} from 'react'
import helper from '../helper/index'
import SupportChat from '../tutorDash/SupportChat'
import tutor from '../controller/tutor'
import moment from 'moment'
import SupportChatOnMobile from '../tutorDash/SupportChatOnMobile'
import { useTranslation } from "react-i18next";
export default function TicketDetails({ data, close, onUpdate }) {
    const { t } = useTranslation();
    const [openChat,setOpenChat] = useState(false)
    const markAsResolve = async () => {

        let req = {
            "user_id": helper.getid(),
            "id": data.id,
            "status": "Resolved"
        }
        if (helper.getRoleType() === "tutor") {
            req = {
                "tutor_id": helper.getid(),
                "id": data.id,
                "status": "Resolved"
            }
        }

        await tutor.ticketResolve(req).then((res) => {
            if (res.data.status) {
                helper.showMessage("Thanks for ticket mark as resolve","success")
                onUpdate();close();
            }
        })
    }
    return (
        <div className="ticket-details">
          
            <div className="row mb-2">
                <div className="col-lg-12 d-flex align-items-center">
                    <button className="btn back-btn py-1 fs-14 ls f-fred px-3 mr-3" onClick={close}>{t('general.back')}</button>
                    <div className='mx-2'>
                        <p className="lh-21 f-fred fs-20">{t('general.support.ticket.ticketId')} {data.id}</p>
                        <p className="fs-14 ms-700 tx-lt-grey">{t('general.support.supportText')} {t('general.support.ticket.ticket')}</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-5 col-md-6 col-12 mb-3">
                    <section className="my-address py-3 br-1 my-2 px-3">
                        <div className="row gx-0 mx-0 f-fred mb-3">
                            <div className="col-12">
                                <p className="fs-24 f-fred">{t('general.support.ticket.ticket')} {t('general.details')}</p>
                            </div>
                        </div>
                        <div className="address-body fs-16">
                            <div className="row">
                                <div className="col-4">
                                    <p className="ms-500 ">{t('general.support.ticket.ticketId')}</p>
                                </div>
                                <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                    <p className="ml-auto ms-900 ml-auto">{data.id}</p>
                                </div>
                                <div className="col-12 border-dot my-1"></div>
                                <div className="col-4">
                                    <p className="ms-500 ">{t('general.category')}</p>
                                </div>
                                <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                    <p className="ml-auto ms-900 ml-auto text-capitalize">{data.category}</p>
                                </div>
                                <div className="col-12 border-dot my-1"></div>
                                <div className="col-4">
                                    <p className="ms-500 ">{t('general.subCategory')}</p>
                                </div>
                                <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                    <p className="ml-auto ms-900 ml-auto text-capitalize">{data.sub_category}</p>
                                </div>

                                <div className="col-12 border-dot my-1"></div>
                                <div className="col-4">
                                    <p className="ms-500 ">{t('general.support.ticket.priorityLevel')}</p>
                                </div>
                                <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                    <p className={`ml-auto ms-900 ${data.priority_level === "High" ? "tx-error" : data.priority_level === "Medium" ? "tx-sky" :"tx-yellow"}`}>{data.priority_level ? data.priority_level : "Low" }</p>
                                </div>
                                <div className="col-12 border-dot my-1"></div>
                                <div className="col-4">
                                    <p className="ms-500 ">{t('general.createdOn')}</p>
                                </div>
                                <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                    <p className="ml-auto ms-900">{moment(data.created_at).format('DD MMM YYYY')} {moment(data.created_at).format('hh : mm A')}</p>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section className="my-address py-3 px-3 br-1">
                        <p className="fs-24 f-fred mb-3">{t('general.support.ticket.issue')}</p>
                        <p className="lh-18 ms-500 fs-16">{data.description}</p>
                    </section>
                    
                    {data.file_name &&
                    <section className="my-address my-3 py-3 px-3 br-1">
                    <a href={data.file_name} rel="noreferrer" target={"_blank"}> <img src={data.file_name} className="w-100" alt="ticket" /></a>
                    </section>}

                    {data.status !== "Resolved" && <section>
                        <button className='btn btn-login  fs-14 ls px-3 text-white py-1 mt-2' style={{ width: '150px' }} onClick={markAsResolve}>{t('general.support.ticket.resolve')}</button>
                        <button className='btn d-lg-none d-md-none btn-tutor-profile  fs-14 ls px-3 text-white py-1 mt-2 ml-2' style={{ width: '150px' }} onClick={()=>setOpenChat(true)}>Start chat</button>
                    </section>}

                </div>
                {data.status !== "Resolved" && <div className="col-lg-5 d-lg-block d-md-block d-none col-md-6 col-12 mb-3">
                    <SupportChat userName={"SayDhad support"} myId={helper.getid()} roomId={data.id} />
                </div>
                }
                 {data.status !== "Resolved" && openChat && <div className="mb-3 d-lg-none d-md-none">
                    <SupportChatOnMobile cb={()=>setOpenChat(false)} userName={"SayDhad support"} myId={helper.getid()} roomId={data.id} />
                </div>
                }
            </div>

        </div>
    )
}