/* eslint-disable */
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import Backdrop from '../component/Backdrop'
import NotifyCard from '../component/NotifyCard'
import { FaCalendarCheck } from "react-icons/fa";
import user from '../controller/user'
import helper from '../helper/index'
import avatar from '../static/images/avatar.png'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import store from '../redux/AppState'
function UserNotification() {

    const [state, setState] = useState({
        resData: [],
        pageload: true,
    })
    const [notifyId, setNotifyId] = useState({
        open: false,
        id: 0
    })

    useEffect(() => {
        getNotification()
        return () => { }
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getNotification = async () => {
        await user.getUserNotifications(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    let list = []
                    list = res.data.result.notificationList.sort((a, b) => a.created_at < b.created_at ? 1 : a.created_at > b.created_at ? -1 : 0)
                    setState({ ...state, resData: list, pageload: false })
                }
            })
    }

    const checkNew = (date, status) => {
        const today = new Date()
        if (moment(today).format('YYYY MM DD') == moment(date).format('YYYY MM DD') && !status) {
            return "New"
        }
        else if (status) {
            return "Read"
        }
        else {
            return "Unread"
        }
    }

    const markRead = async (notifyId, status, index) => {
        if (status) {
            return
        }
        await user.markAsRead(helper.getid(), notifyId)
            .then((res) => {
                if (res.data.status) {
                    let temp = [...state.resData]
                    temp[index].read_as = true
                    setState({ ...state, resData: temp, pageload: false })
                }
            })
    }

    return (
        <div className="tutor-notify ">
            {
                state.resData.map((result, index) =>
                    <div key={result.id}>
                        <NotifyCard
                            btn={checkNew(result.created_at, result.read_as) === "New" ? "New" : checkNew(result.created_at, result.read_as)}
                            border={checkNew(result.created_at, result.read_as) === "New" && "orange"}
                            name={checkNew(result.created_at, result.read_as) === "New" ? "New" : checkNew(result.created_at, result.read_as)}
                            onclick={() => { markRead(result.id, result.read_as, index); setNotifyId({ ...notifyId, open: true, id: result.id }) }}
                            data={result}
                        />
                    </div>
                )
            }
            {state.pageload && <Backdrop />}
            {
                notifyId.open &&
                <NotificationDetail
                    id={notifyId.id}
                    close={() => setNotifyId({ ...notifyId, open: false })}
                />
            }

        </div>
    )
}


const NotificationDetail = ({ id, close }) => {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation();
    const history = useHistory()
    const [state, setState] = useState({
        pageload: true,
        resData: null,
        error: null
    })
    useEffect(() => {
        apiCall()
        return () => { }
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const apiCall = async () => {
        await user.getNotificationDetail(helper.getid(), id)
            .then((res) => {
                if (res.data.status) {
                    setState({ ...state, resData: res.data.result, pageload: false })
                } else {
                    setState({ ...state, pageload: false, error: "Something went wrong" })
                }

            })
    }

    return (
        <>

            <div className="faded ">
                <div className={`p-5 rightpop-up  ${reduxState.language === 'ar' && "text-right"}`}>
                    <div className="row gx-0 mx-0">
                        <div className="col-lg-8 col-10">
                            <h4 className="f-fred tx-lt-black">{t('userSidebar.noti.title')} <span className="tx-green">{t('userSidebar.noti.det')}</span></h4>
                            <p className="sub-heading">{moment(state.resData && state.resData.created_at).format('ddd, MMM YYYY hh:mm A')}</p>
                        </div>
                        <div className="col-lg-4 col-2 d-flex justify-content-end tx-grey pointer"><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></div>
                        <div className="text-left my-3 ms-900">
                            <h5 className='tx-lt-grey'>{state.resData && state.resData.type}</h5>
                        </div>
                        <p className="mb-3 ms-500 text-justify"> {state.resData && state.resData.description}</p>
                        {state?.resData?.type === "New Chat Message" && <p className='link pointer' onClick={() => { store.setchatId(state?.resData?.type_id); history.push(`/user/dashboard/chat`) }}>View Message</p>}
                    </div>

                    {!state.pageload && !state.error && state.resData.tutor_id != 0 &&
                        <div className="row  b-grey px-2 py-2 ms-500 my-2">
                            <div className="col-2">
                                <img style={{ height: '40px', width: '40px' }} className="rounded-circle" src={state.resData.profilePicture ? state.resData.profilePicture : avatar} alt="avatar" />
                            </div>
                            <div className="col-5 wrap px-1 text-left  ms-900">
                                <p style={{ lineHeight: '18px' }}>{state.resData.name ? state.resData.name : "Anonymous"}</p>
                                <p className='ms-500 fs-14'>Tutor</p>
                            </div>
                            <div className="col-5 my-auto ">
                                <button className="btn bg-green ms-700 w-100 py-1 text-white fs-12 wrap"><FaCalendarCheck /> &nbsp;
                                    {moment(state.resData.date).format('MMM DD YYYY')} {" "}
                                    at {" "}
                                    {moment(`2021-12-3 ${state.resData.start_time}`).format('hh:mm a')}
                                </button>
                            </div>
                        </div>
                    }


                    {state.pageload && <Backdrop />}
                    <p className='ms-700 fs-16 text-center'>{state.error}</p>

                </div>
            </div>


        </>
    )
}


export default UserNotification
