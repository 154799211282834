import React from 'react'

export default function HowToVideosCard({img,title,subTitle,onChange}) {
    return (
        <div className="course-card b-grey px-2 py-2" >
        <div className="course-img br-1 mb-2 pointer" style={{height:'150px',overflow:'hidden'}} onClick={onChange && onChange}>
            <img className="w-100 h-100 br-1" src={img} alt="video-preview" />
        </div>
        <div className="course-body my-2">
            <p className="fs-18 f-fred text-capitalize w-100 overflow-elipse">{title}</p>
            <p className="fs-12 ms-500 text-capitalize w-100 overflow-elipse">{subTitle}</p>
           
        </div>
    </div>
    )
}
