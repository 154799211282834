import axios from 'axios'
import { constant } from '../config/Contant'
import helper from '../helper';
const URL_AUTH = constant.BASE_URL

export const getCountryList = async (code) => {
    try {
        const res = await axios.get(`${URL_AUTH}/master/country-list?country_code=${code}`);
        return res;
    }
    catch (err) {
        return err;
    }
}

export const notifiIllegalActivity = async (msg, recieverId) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'post',
            url: `${URL_AUTH}/chat/illegal-activity`,
            data: { message: msg, recieverId: recieverId },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err;
    }
}

