import React, { useState } from 'react'
import ReactStars from 'react-rating-stars-component'
import user from '../controller/user'
import helper from '../helper'
import AlertPopUp from '../component/AlertPopUp'
import { useTranslation } from "react-i18next";

export default function FeedBack({ open, tutorId, cb }) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        rate: 0,
        comment: '',
        error: ''
    })
    const [alert, setAlert] = useState({
        open: false,
        msg: '',
        type: 'success',
    })


    const submit = async () => {
        if (state.rate === 0) {
            setState({ ...state, error: t('messages.rate.selectRate') })
            return
        }
        else if (!state.comment) {
            setState({ ...state, error: t('messages.rate.writeReview') })
            return
        }
        const reqData = {
            ratings: state.rate,
            review: state.comment,
            tutorId: tutorId,
            studentId: helper.getid()
        }

        await user.giveRating(reqData)
            .then((res) => {
                if (res.data.status) {
                    setAlert({ ...alert, open: true, type: 'ok', msg: t('messages.rate.ratingSubmitted') })
                }
                else {
                    setAlert({ ...alert, open: true, type: 'fail', msg: t('messages.rate.scheduleClassRate') })
                }
            }).catch((err) => {

            })

    }
    return (
        <>
            {open &&
                <div className="popup-modal  h-100 all-center">
                    <div className="popup-modal-body sm">
                        <section className='all-center '>
                            <div className='my-3 row px-4' style={{ maxWidth: '400px' }}>
                                <div className="col-12 border-dot my-1"></div>
                                <div className="col-12">
                                    <h5 className='mt-2 f-fred'>{t('messages.rate.rateThe')} <span className='tx-green'>{t('auth.tutor')}</span></h5>
                                    <ReactStars
                                        count={5}
                                        size={30}
                                        isHalf={true}
                                        emptyIcon={<i className="far fa-star"></i>}
                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                        fullIcon={<i className="fa fa-star"></i>}
                                        classNames="my-0 py-0"
                                        activeColor="#ffcb00"
                                        onChange={(rate) => setState({ ...state, rate: rate })}

                                    />
                                    <div className='my-2 tutor-dash' style={{ height: 'initial' }}>
                                        <textarea cols="30" rows="5" placeholder={t('messages.rate.write')} className='input-box w-100 p-2 ms-700' onChange={(e) => setState({ ...state, comment: e.target.value })} style={{ height: 'initial' }}></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <p className='tx-error my-2 ms-500 text-center'>{state.error}</p>
                                    <button className='btn btn-tutor-profile ms-700 fs-14 mb-3 mx-auto d-block' style={{ width: '200px' }} onClick={() => submit()}>{t('button.submitRating')}</button>
                                </div>
                            </div>

                        </section>
                    </div>

                    <AlertPopUp
                        open={alert.open}
                        type={alert.type}
                        msg={alert.msg}
                        onClose={() => { cb(); setAlert({ ...alert, open: false }); }}
                    />
                </div>
            }
        </>
    )
}
