import React, { useState } from 'react'
import Tour from 'reactour';
import './reacttour.css';
import { useTranslation } from "react-i18next";

export default function UserTour({steps}) {
    const { t } = useTranslation();
    const [state, setState] = useState({ open: false })
    return (
        <div className="user-tour">
            <Tour
                className='react-tour'
                ViewCloseButton={false}
                // badgeContent={false}
                steps={steps}
                isOpen={state.open}
                prevButton={<button className='btn btn-primary fs-14 py-1 px-3 text-white'>{t('button.previous')}</button>}
                nextButton={<button className='btn btn-success fs-14 py-1 px-3 text-white'>{t('button.next')}</button>}
                lastStepNextButton={<button className='btn btn-success fs-14 py-1 px-3 text-white'>{t('button.done')}</button>}
                onRequestClose={() => { setState({ ...state, open: false }) }} />
            <button className='btn react-tour-button' onClick={() => { setState({ ...state, open: true }) }}><i className="fab fa-discourse fs-24 mx-1"></i>{t('general.startTour')}</button>
        </div>
    )
}
