/* eslint-disable */
import React, { useState } from 'react'
import TutorSlideShow from './TutorSlideShow'
import { useTranslation } from "react-i18next";
export default function SyllabusCard({ name, slides, lessonNo, file,isEnrolled }) {
    const [open, setOpen] = useState(false)
    const { t } = useTranslation();
    return (
        <>
            <div className="syllabus b-grey px-2 py-3">
                <div className="row gx-0 mx-0 fs-12">
                    <div className="col-lg-2 col-md-2 col-3 mb-2">
                        <p className="f-fred lh-15 fs-14">{lessonNo}</p>
                        <p className="tx-lt-grey ms-500 fs-12">{t('tutorDash.library.lessons')}</p>
                    </div>
                    <div className="col-lg-4 col-md-6 col-5 mb-2">
                        <p className="f-fred lh-15 fs-14 text-capitalize overflow-elipse">{name}</p>
                        <p className="tx-lt-grey ms-500 fs-12">{t('tutorDash.library.titleLessons')}</p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-4 mb-2 d-flex flex-column align-items-end px-2">
                        <p className="f-fred lh-15 fs-14">{slides}</p>
                        <p className="tx-lt-grey ms-500 fs-12">{t('tutorDash.library.slides')}</p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6 ">
                        <button className="btn btn-detail f-fred w-100 py-1 text-white fs-14" onClick={() => {
                            if(isEnrolled){
                                setOpen(true)
                            }else{
                                alert("please enrolled into course")
                            }
                           }}>{t('tutorDash.library.viewSlide')}</button>
                    </div>
                </div>
                {
                open && isEnrolled &&
                 <TutorSlideShow data={file}
                 cb={()=>setOpen(false)} 
                 lesson={lessonNo}
                 name={name} 
                 startIndex={0}
                 />}
            </div>
        </>
    )
}
