import * as React from "react";

const SvgDownArrow = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={10.165} {...props}>
    <g data-name="Iconly/Bold/Arrow---Left">
      <path
        data-name="Path 1132"
        d="M5.639.002h.354a27.291 27.291 0 0 1 3.688.216l.544.114a3.887 3.887 0 0 1 .783.233 1.815 1.815 0 0 1 .992 1.6v.058a5.031 5.031 0 0 1-.408 1.342A22.643 22.643 0 0 1 7.8 9.159l-.4.379a3.582 3.582 0 0 1-.334.28 1.768 1.768 0 0 1-1.058.347A1.856 1.856 0 0 1 4.87 9.79l-.42-.389-.09-.087A22.073 22.073 0 0 1 .336 3.422l-.094-.244A4.05 4.05 0 0 1 0 2.223a2 2 0 0 1 .219-.91 1.886 1.886 0 0 1 .9-.808A9.79 9.79 0 0 1 2.183.244 22.282 22.282 0 0 1 5.639.002Z"
        fill="#1b221b"
      />
    </g>
  </svg>
);

export default SvgDownArrow;
