import moment from 'moment'
import React, { useState, useEffect } from 'react'
import Backdrop from '../component/Backdrop'
import tutor from '../controller/tutor'
import helper from '../helper/index'
import avatar from '../static/images/avatar.png'
import { useHistory } from 'react-router'
import store from '../redux/AppState'
import Menubar from './Menubar'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
export default function MyStudents() {
    const { t } = useTranslation();
    const history = useHistory()
    const storeData = useSelector(state => state)
    const [resData, setResData] = useState([])
    const [upcomingClassData, setUpcomingClassData] = useState([])
    const [pageload, setPageload] = useState(true)
   
    const [profileDetail, setProfileDetail] = useState({
        id: '',
        data: '',
        index: -1
    })

    useEffect(() => {
        getStudentList()
        return () => { }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getStudentList = async () => {
        await tutor.getEnrolledStudent(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setResData(res.data.result)
                    setPageload(false)
                    if (res.data.result.length > 0) {
                        setProfileDetail({
                            ...profileDetail,
                            id: res.data.result[0].student_id,
                            data: res.data.result[0],
                            index: 0
                        })
                        upcomingClasses(res.data.result[0].student_id)
                    }

                }
            })
    }

    const upcomingClasses = async (studentId) => {

        await tutor.getChatStudentDetail(helper.getid(), studentId)
            .then((res) => {
                if (res.data.status) {
                    let list = []
                    res.data.result[0].bookingSlot.forEach((data) => {
                        if (data.status === "Booked" && (moment(data.end_time, 'hh:mm').format('HH:mm') >= moment().format("HH:mm"))) {
                            list.push(data)
                        }
                    })
                    setUpcomingClassData(list)
                }
            })

    }



    return (
        <>
            {!pageload && <>
                <div className={`tutor-students ${storeData.language === 'ar' && "text-right"}`}>

                    <section className="row gx-0 mx-0">
                        <div className="col-lg-3 col-md-4 col-12 px-1 my-2">
                            <div className="course-overview b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="fs-18 ms-900  tx-lt-black">{t('tutorDash.myStudent.students')}</p>
                                    <p className="sub-heading">{t('tutorDash.myStudent.inTotal')}</p>
                                </div>
                                <p className="ms-500 fs-40 tx-green">{resData.length}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12 px-1 my-2">
                            <div className="course-overview b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="fs-18 ms-900  tx-lt-black">{t('tutorDash.myStudent.call')}</p>
                                    <p className="sub-heading">{t('tutorDash.myStudent.schedule')}</p>
                                </div>
                                <p className="ms-500 fs-40 tx-green">{upcomingClassData.length}</p>
                            </div>
                        </div>
                    </section>

                    <section className="row gx-0 mx-0">
                        <div className="col-lg-3 col-md-5">
                            {resData.map((result, index) => {
                                return (

                                    <section key={"profile" + index} className={`chat-student-card d-flex b-grey p-2 align-items-center mb-2 ${index === profileDetail.index && "active-tutor-card"}`} onClick={() => { setProfileDetail({ ...profileDetail, id: result.student_id, data: result, index: index }); upcomingClasses(result.student_id) }}>
                                        <img width="40px" height="40px" className="rounded-circle mr-2" src={avatar} alt="avatar" />
                                        <div className="mr-3 wrap">
                                            <p className="f-fred lh-15 fs-14 tx-lt-grey">{!result.name ? "Anonymous" : result.name}</p>
                                            <p className="ms-500 lh-15 fs-12">{t('tutorDash.payout.student')}</p>
                                        </div>
                                    </section>
                                )
                            })}

                            {resData.length === 0 &&
                                <section>
                                    <img className="br-1" style={{ maxHeight: '300px', maxWidth: '100%' }} src="https://cdn.pixabay.com/photo/2018/04/17/09/02/child-3326960_960_720.png" alt="result" />
                                </section>
                            }
                        </div>


                        <div className="col-lg-5 col-md-7">

                            {profileDetail.data &&
                                <>
                                    <section className="my-address tx-lt-grey p-2 py-3 br-1 mx-2">
                                        <div className="row gx-0 mx-0 ms-900 mb-2">
                                            <div className="col-lg-8 col-md-6 col-8 d-flex align-items-center">
                                                <h4 className="f-fred">{t('tutorDash.myStudent.profileDetails')}</h4>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-4 d-flex justify-content-end">
                                                <img style={{ height: '50px', width: '50px' }} className="rounded-circle" src={avatar} alt="avatar" />
                                            </div>
                                        </div>

                                        <div className="address-body ">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-4">
                                                    <p className="ms-500">{t('tutorDash.myStudent.fullName')}</p>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                                    <p className="ms-900 ml-auto">{profileDetail.data.name}</p>
                                                </div>
                                                <div className="col-12  border-dot my-1"></div>
                                                {/* <div className="col-lg-4 col-md-4 col-4">
                                                    <p className="ms-500">{t('tutorDash.myStudent.email')}</p>
                                                </div> */}
                                                {/* <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                                    <p className="ms-900 ml-auto">{profileDetail.data.email}</p>
                                                </div> */}
                                                {/* <div className="col-12 border-dot my-1"></div> */}
                                                <div className="col-lg-4 col-md-4 col-4">
                                                    <p className="ms-500">{t('tutorDash.myStudent.accountFor')}</p>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                                    <p className="ms-900 ml-auto">{
                                                        profileDetail.data.levelType === 0 ? "Myself" : "Kids"
                                                    }</p>
                                                </div>
                                                <div className="col-12 border-dot my-1"></div>
                                                <div className="col-lg-4 col-md-4 col-4">
                                                    <p className="ms-500">{t('tutorDash.myStudent.mainGoal')}</p>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                                    <p className="ms-900 ml-auto">
                                                        {
                                                            profileDetail.data.goalType === 0 ? "Professional development" : profileDetail.data.goalType === 1 ? "Acadmics" : profileDetail.data.goalType === 2 ? "Travel" : "Personal growth"
                                                        }
                                                    </p>
                                                </div>
                                                <div className="col-12 border-dot my-1"></div>
                                                <div className="col-lg-4 col-md-4 col-4">
                                                    <p className="ms-500">{t('tutorDash.myStudent.country')}</p>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                                    <p className="ms-900 ml-auto">{profileDetail.data.country}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="d-flex justify-content-between my-2 mx-2 ">
                                        <button style={{ width: '200px' }} className="btn btn-tutor-profile  " onClick={() => { store.setchatId(profileDetail.id); history.push(`/tutor/dashboard/mychat`) }}>{t('tutorDash.myStudent.chatLog')}</button>
                                    
                                    </div>
                                </>
                            }

                        </div>
                        <div className="col-lg-4 col-md-7 " >
                            <h4 className="f-fred tx-lt-grey mt-2">{t('tutorDash.myStudent.upcomingCalls')}</h4>

                            {
                                upcomingClassData.map((result, index) => {

                                    return (
                                        <div key={`upcoming-call${index}`} className="b-grey px-2 py-2 f-robo my-2">
                                            <div className="row gx-0 ">
                                                <div className="col-lg-9 col-12 mb-2">
                                                    <p className="fs-12  ms-500">{t('tutorDash.calendar.scheduleOn')}</p>
                                                    <p className="ms-700  fs-18 tx-lt-black">{moment(result.date).format('DD MMM, YYYY')}, {moment("2017-03-13 , " + result.start_time).format("hh : mm A")}</p>
                                                </div>
                                                <div className="col-lg-3 col-12 mb-2 d-flex justify-content-end align-items-center">
                                                    {
                                                        helper.isUserCanJoinClass({ classDate: result.date, startTime: result.start_time, endTime: result.end_time }) && result.status === "Booked" &&
                                                    
                                                        <button className="btn px-1 py-1 fs-12 reshedule-btn f-fred pointer" onClick={() => history.push(`/dhad-video/${result.channel_name}`)}>{t('button.joinClass')}</button>
                                                    }

                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center mt-2">
                                                <div className="">
                                                    <img style={{ height: '40px', width: '40px' }} className="rounded-circle mr-3" src={profileDetail.data.profilePicture ? profileDetail.data.profilePicture : avatar} alt="avatar" />
                                                </div>
                                                <div className="">
                                                    <p className="f-fred  fs-14 tx-lt-black">{profileDetail.data.name ? profileDetail.data.name : "Anonymous"}</p>
                                                    <p className="fs-12 lh-15 ms-500 tx-lt-grey">{profileDetail.data.country ? profileDetail.data.country : "Unknown"}</p>

                                                </div>
                                            </div>

                                        </div>
                                    )

                                })
                            }
                            {
                                upcomingClassData.length === 0 &&
                                <section>
                                    <img className="br-1" style={{ maxHeight: '300px', maxWidth: '100%' }} src="https://cdn.pixabay.com/photo/2015/10/31/11/58/call-center-1015274_960_720.jpg" alt="calls" />
                                </section>
                            }
                        </div>
                    </section>


                </div>
            </>
            }
            {pageload && <Backdrop />}
            {storeData.showMenu && <Menubar tab={3} cb={() => store.setShowMenu(false)} />}

        </>
    )
}
