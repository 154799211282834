/* eslint-disable */
import React, { useState } from 'react'
import moment from 'moment'
import BookClass from './BookClass'
import helper from '../helper'
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next";
export default function ViewTutorSlots({ close, data, date }) {
    const { t } = useTranslation();
    const history = useHistory()
    const [state, setState] = useState({
        data: null,
        date: null,
        tutor_id: null
    })
    return (
        <div className='tutor-slots b-grey px-lg-3 px-2  py-3'>
            {!state.data ? <section>

                <button className='btn back-btn py-1 fs-14 ms-700 px-3 mr-2 mb-3' onClick={close}>{t('general.back')}</button>
                {
                    data.map((data, index) => {
                        if (!date) {
                            return (
                                <div key={`avail${index}`}>
                                    <p className='ms-500 text-center my-3'><span className='tag-box py-2 px-2'>{moment(data.date).format('MMMM DD, YYYY')}</span> </p>
                                    {
                                        data.slot.map((slotData, index1) =>
                                            <div className='bar pl-3 my-3' key={`slot${index1}`}>
                                                <div className='d-flex ms-500 justify-content-between my-2'>
                                                    <p className={`${slotData.status != "Available" && "ms-700"} ltr`}>{moment(slotData.start_time, 'hh:mm').format('hh : mm A')} <span className='ms-900 mx-3'>To</span> {moment(slotData.end_time, 'hh:mm').format('hh : mm A')}</p>
                                                    {
                                                        slotData.status == "Available" ?
                                                            <button className='btn px-3 py-2 fs-14 select-time ms-700' onClick={() => {
                                                                if (helper.getPlanDetails().isPurchase) {
                                                                    setState({ ...state, data: slotData, date: data.date, tutor_id: data.tutor_id })
                                                                } else {
                                                                    history.push('/user/dashboard/subscription')
                                                                }
                                                            }}>{t('general.calendar.select')}</button> :
                                                            <button className='btn px-3 py-2 fs-14 booked-time ms-700'>{t('general.calendar.booked')}</button>


                                                    }
                                                </div>
                                            </div>)
                                    }
                                </div>
                            )
                        }
                        else if (data.date === date) {
                            return (
                                <div>
                                    <p className='ms-500 text-center my-3'><span className='tag-box py-2 px-2'>{moment(data.date).format('MMMM DD, YYYY')}</span> </p>
                                    {
                                        data.slot.map((slotData, index1) =>
                                            <div className='bar pl-3 my-3' key={`slot${index1}`}>
                                                <div className='d-flex ms-500 justify-content-between my-2'>
                                                    <p className={`${slotData.status != "Available" && "ms-700"} ltr`}>{moment(slotData.start_time, 'hh:mm').format('hh : mm A')} <span className='ms-900 mx-3'>To</span> {moment(slotData.end_time, 'hh:mm').format('hh : mm A')}</p>
                                                    {
                                                        slotData.status === "Available" ?
                                                            <button className='btn px-3 py-2 fs-14 select-time px-2 ms-700' onClick={() => {
                                                                {
                                                                    if (helper.getPlanDetails().isPurchase) {
                                                                        setState({ ...state, data: slotData, date: data.date, tutor_id: data.tutor_id })
                                                                    } else {
                                                                        history.push('/user/dashboard/subscription')
                                                                    }
                                                                }
                                                            }}>{t('general.calendar.select')}</button> :
                                                            <button className='btn px-3 py-2 fs-14 booked-time ms-700'>{t('general.calendar.booked')}</button>


                                                    } </div>
                                            </div>)
                                    }
                                </div>
                            )
                        }else  return <></>
                    })
                }
            </section> :
                <BookClass
                    close={() => { setState({ ...state, data: null }) }}
                    data={state.data}
                    date={state.date}
                    tutor_id={state.tutor_id}
                />
            }

        </div>
    )


}
