import { t } from 'i18next';
export const userSteps = [
    {
        selector: '#step-subscribe',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
               {t('tourGuide.user.header.step1')}
            </div>
        ),
        position: "bottom",
    },
    {
        selector: '#step-leftminute',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                 {t('tourGuide.user.header.step2')}
            </div>
        ),
        position: "bottom",
    },
    {
        selector: '#step-today-classes',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                 {t('tourGuide.user.header.step3')}
            </div>
        ),
        position: "bottom",
    },
    {
        selector: '#step-notification',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                 {t('tourGuide.user.header.step4')}
                 </div>
        ),
        position: "bottom",
    },
    {
        selector: '#step-chat',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                 {t('tourGuide.user.header.step5')}
            </div>
        ),
        position: "bottom",
    },
    {
        selector: '#step-profile',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                 {t('tourGuide.user.header.step6')}
            </div>
        ),
        position: "bottom",
    },
    {
        selector: '#step-moreinfo',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                {t('tourGuide.user.header.step7')}
            </div>
        ),
        position: "bottom",
    },
    /* Side Bar Tutor */
    {
        selector: '#step-ud-dash',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                 {t('tourGuide.user.leftMenu.step1')}
            </div>
        ),
        position: "right",
    },
    {
        selector: '#step-ud-tutor',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                 {t('tourGuide.user.leftMenu.step2')}
            </div>
        ),
        position: "right",
    },
    {
        selector: '#step-ud-course',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                 {t('tourGuide.user.leftMenu.step3')}
            </div>
        ),
        position: "right",
    },
    {
        selector: '#step-ud-calendar',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                {t('tourGuide.user.leftMenu.step4')}
            </div>
        ),
        position: "right",
    },
    {
        selector: '#step-ud-progress',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                {t('tourGuide.user.leftMenu.step5')}
            </div>
        ),
        position: "right",
    },
    {
        selector: '#step-ud-chat',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                 {t('tourGuide.user.leftMenu.step6')}
            </div>
        ),
        position: "right",
    },
    {
        selector: '#step-ud-support',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                 {t('tourGuide.user.leftMenu.step7')}
            </div>
        ),
        position: "right",
    },
    {
        selector: '#step-ud-referral',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                 {t('tourGuide.user.leftMenu.step8')}
            </div>
        ),
        position: "right",
    },

]

export const homeSteps =[
    {
        selector: '#step-forstudent',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                {t('tourGuide.home.step1')}
            </div>
        ),
        position: "bottom",
    },
    {
        selector: '#step-login',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                {t('tourGuide.home.step3')}
            </div>
        ),
        position: "bottom",
    },
    {
        selector: '#step-fortutor',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
              {t('tourGuide.home.step2')}
            </div>
        ),
        position: "bottom",
    },
    {
        selector: '#step-login',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                {t('tourGuide.home.step3')}
            </div>
        ),
        position: "bottom",
    },
    {
        selector: '#step-language',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                {t('tourGuide.home.step4')}
            </div>
        ),
        position: "bottom",
    },
]

export const tutorSteps = [
    {
        selector: '#step-language',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
               {t('tourGuide.tutor.header.step1')}
            </div>
        ),
        position: "bottom",
    },
    {
        selector: '#step-today-classes',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                  {t('tourGuide.tutor.header.step2')}
            </div>
        ),
        position: "bottom",
    },
    {
        selector: '#step-notification',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                  {t('tourGuide.tutor.header.step3')}
            </div>
        ),
        position: "bottom",
    },
    {
        selector: '#step-chat',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                  {t('tourGuide.tutor.header.step4')}
            </div>
        ),
        position: "bottom",
    },
    {
        selector: '#step-profile',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                  {t('tourGuide.tutor.header.step5')}
            </div>
        ),
        position: "bottom",
    },
    /* Side Bar Tutor */
    {
        selector: '#step-td-dash',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                  {t('tourGuide.tutor.leftMenu.step1')}
            </div>
        ),
        position: "right",
    },
    {
        selector: '#step-td-calendar',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
               {t('tourGuide.tutor.leftMenu.step2')}
            </div>
        ),
        position: "right",
    },
    {
        selector: '#step-td-course',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                {t('tourGuide.tutor.leftMenu.step3')}
            </div>
        ),
        position: "right",
    },
    {
        selector: '#step-td-student',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                {t('tourGuide.tutor.leftMenu.step4')}
            </div>
        ),
        position: "right",
    },
    {
        selector: '#step-td-chat',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                {t('tourGuide.tutor.leftMenu.step5')}
            </div>
        ),
        position: "right",
    },
    {
        selector: '#step-td-payout',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                {t('tourGuide.tutor.leftMenu.step6')}
            </div>
        ),
        position: "right",
    },
    {
        selector: '#step-td-attendance',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
               {t('tourGuide.tutor.leftMenu.step7')}
            </div>
        ),
        position: "right",
    },
    {
        selector: '#step-td-support',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                {t('tourGuide.tutor.leftMenu.step8')}
            </div>
        ),
        position: "right",
    },
]

export const tutorCalendarStep = [
    {
        selector: '#step-td-update-availability',
        content: ({ goTo, inDOM }) => (
            <div className='p-2'>
                Set your availabilty from here.
            </div>
        ),
        position: "right",
    },
]


