import React, { useState, useEffect } from 'react'
import PayoutCard from '../component/PayoutCard'
import PayoutDetail from './PayoutDetail'
import { useTranslation } from "react-i18next";
import { payoutListing } from '../controller/tutorPayout'
import helper from '../helper'
import moment from 'moment'
import Backdrop from '../component/Backdrop'
export default function Payout() {
    const { t } = useTranslation();
    const [openPayout, setOpenPayout] = useState({ open: false, id: 0, transactionID: 0 })
    /* maintian listing state */
    const [state, setState] = useState({ paidTutors: [], payout_analytics: {}, pageload: true, error: null })

    /* Calendar state */
    const [startDate, setStartDate] = useState(moment(new Date(new Date().setDate(new Date().getDate() - 30))));
    const [endDate, setEndDate] = useState(moment());
   
    /* Get Data Payout listing on before render */
    useEffect(() => {
        getPayout(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getPayout = async (startDate, endDate) => {
        setState({ ...state, pageload: true, error: null })
        await payoutListing(helper.getid(), startDate, endDate).then((res) => {
            if (res.data.status) {

                setState({ ...res.data.result, pageload: false, error: null })
            } else {
                setState({ ...state, pageload: false, error: res.data.error })
            }
        }).catch((err) => { setState({ ...state, pageload: false, error: err }) })
    }

    /* Handle calendar */
    const onRange = (st, en) => {
        if (st) {
            setStartDate(moment(st));
        }
        if (en) {
            setEndDate(moment(en))
        }
        if (st && en) {
            getPayout(moment(st).format('YYYY-MM-DD'), moment(en).format('YYYY-MM-DD'))
        }
    }

    return (
        <div className="tutor-payout w-100">
            {
                !openPayout.open ? <>
                    {/* Payout analytics */}
                    <section className="row gx-0 mx-0">
                        <div className="col-lg-3 col-md-6 col-12 px-2 my-2">
                            <div className="course-overview h-100  b-grey px-3 py-3 d-flex flex-column">
                                <div>
                                    <p className="fs-18 ms-900 tx-lt-black">{t('tutorDash.payout.totalEarning')}</p>
                                    <p className="sub-heading">From {startDate.format('DD MMM YYYY')} to {endDate.format('DD MMM YYYY')}</p>
                                </div>
                                <p className="ms-500 fs-40 tx-green ltr">{parseFloat(state.payout_analytics.tot_amount).toFixed(2)} <button className="btn edit-info fs-10 ">{parseFloat(state.payout_analytics.amount_percentage).toFixed(2)}%</button></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 px-2 my-2">
                            <div className="course-overview h-100 b-grey px-3 py-3  d-flex flex-column" >
                                <div>
                                    <p className="fs-18 ms-900 tx-lt-black">{t('tutorDash.payout.totalCalls')}</p>
                                    <p className="sub-heading">From {startDate.format('DD MMM YYYY')} to {endDate.format('DD MMM YYYY')}</p>
                                </div>
                                <p className="ms-500 fs-40 tx-green ltr">{state.payout_analytics.total_calls} <button className="btn edit-info fs-10 ">{parseFloat(state.payout_analytics.call_percentage).toFixed(2)}%</button></p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 px-2 my-2">
                            <div className="course-overview h-100  b-grey px-3 py-3 d-flex flex-column">
                                <div>
                                    <p className="fs-18 ms-900 tx-lt-black">{t('tutorDash.payout.totalNewStu')}</p>
                                    <p className="sub-heading">From {startDate.format('DD MMM YYYY')} to {endDate.format('DD MMM YYYY')}</p>
                                </div>
                                <p className="ms-500 fs-40 tx-green ltr">{state.payout_analytics.total_student} <span className="btn edit-info fs-10 ">{parseFloat(state.payout_analytics.stud_percentage).toFixed(2)}%</span></p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 px-2 my-2">
                            <div className="course-overview h-100  b-grey px-3 py-3 d-flex flex-column">
                                <div>
                                    <p className="fs-18 ms-900  tx-lt-black">{t('tutorDash.payout.currCall')}</p>
                                    <p className="sub-heading">Rate per minute</p>
                                </div>
                                <p className="ms-500 fs-40 tx-green ltr">{state.payout_analytics.rates_per_minute}</p>
                            </div>
                        </div>
                    </section>

                    <section className='ms-700 my-3 fs-14 p-2 ltr d-flex'>
                        <input 
                            type="text" name="startDate"
                            className="px-2 mx-2 input-box f-fred"
                            onFocus={(e) => { e.target.type = "date"; e.target.defaultValue = moment(startDate).format('MM/DD/YYYY'); }}
                            onBlur={(e) => {
                                e.target.type = "text";
                                e.target.defaultValue = moment(startDate).format('MM/DD/YYYY');
                            }}
                            max={moment(endDate).format('YYYY-MM-DD')}
                            placeholder="Start date"
                            style={{ width: '150px' }}
                            onChange={(e) => { onRange(e.target.value, endDate) }}
                            defaultValue={moment(startDate).format('YYYY-MM-DD')}
                        />
                        <input
                            max={moment().format('YYYY-MM-DD')}
                            type="text"
                            name="startDate"
                            className="px-2 mx-2  input-box f-fred"
                            onFocus={(e) => { e.target.type = "date"; e.target.defaultValue = moment(endDate).format('MM/DD/YYYY'); }}
                            onBlur={(e) => {
                                e.target.type = "text";
                                e.target.defaultValue = moment(endDate).format('MM/DD/YYYY');
                            }} placeholder="End date"
                            style={{ width: '150px' }}
                            onChange={(e) => { onRange(startDate, e.target.value) }}
                            defaultValue={moment(endDate).format('YYYY-MM-DD')}
                        />
                    </section>
                    {/* Calendar filter */}
                    {/* <section className='p-rel ms-700 my-3 fs-14 p-2 ltr'>
                        <DateRangePicker
                            maxDate={moment()}
                            className="w-100 h-100 p-2"
                            style={{ height: '100%' }}
                            initialStartDate={null}
                            disabled={false}
                            startDate={startDate}
                            isOutsideRange={() => false}
                            isDayBlocked={() => false}
                            enableOutsideDays={true}
                            numberOfMonths={2}
                            startDateId="s_id"
                            endDate={endDate}
                            endDateId="e_id"
                            onDatesChange={({ startDate, endDate }) => { onRange(startDate, endDate) }}
                            focusedInput={focusedInput}
                            onFocusChange={e => setFocusedInput(e)}
                            displayFormat="DD MMM YYYY"

                        />

                    </section> */}


                    {/* Payout listing */}
                    {
                        state.paidTutors.map((object) =>
                            <PayoutCard
                                btn="transferred"
                                name="transfered"
                                data={object}
                                onChange={() => setOpenPayout({ ...openPayout, open: true, id: object.id, transactionID: object.transaction_id })}
                            />)
                    }

                </> : <PayoutDetail transactionID={openPayout.transactionID} id={openPayout.id} cb={() => setOpenPayout({ ...openPayout, open: false, id: 0 })} />
            }

            {state.pageload && <Backdrop />}
            {/* Not Record Found */}
            {!state.pageload && !state.error && state.paidTutors.length === 0 && <p className='my-2 ms-700 text-center'>{t('messages.noRecordFound')}</p>}
        </div>
    )
}
