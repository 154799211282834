/* eslint-disable */
import React, { useEffect } from 'react'
import helper from '../helper'
import '../static/css/home.css'
import WebHeader from './WebHeader'
import block from '../static/images/home/Path 4437.svg'
import pic from '../static/images/home/pic.jpg'
import Google from '../static/images/home/Google.svg'
import Apple from '../static/images/home/Apple.svg'
import TutorListImg from '../static/images/home/Tutors List  More.png'
import TutorDetailImg from '../static/images/home/TutorDetail.png'
import Girl1Img from '../static/images/home/Mask Group 48.png'
import Mask from '../static/images/home/Mask.png'
import parrot from '../static/images/home/parrot.svg'
import parrot1 from '../static/images/home/parrot-1.svg'
import Shape from '../static/images/home/Shape.svg'
import WebFooter from './WebFooter'
import Subscribe from './Subscribe'
import vid from '../static/images/home/v1.mp4'
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { constant } from '../config/Contant'

export default function Home() {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation();
    const history = useHistory()

    useEffect(() => {
        helper.scroll()
    }, [])

    const handleRedirect = () => {
        const status = helper.isLoggedIn();
        if (status.res) {
            history.push(`/user/dashboard/tutor`)
        } else {
            history.push(`/login`)
        }
    }


    return (
        <>
            {/* For SEO */}
            <Helmet>
                <title>SayDhad - The Best Arabic Learning Platform</title>
                <meta name="description" content='Learn Arabic without leaving the comfort of your home with the Best Arabic Tutors Online. Sign up now to start your learning journey and experience the power of convenient, trusted and authoritative Arabic education. Connect with SayDhad, the best Arabic learning platform, today!' />
                <link rel="canonical" href="https://saydhad.com/" />
            </Helmet>
            <div className={`web-home ${reduxState.language === 'ar' && "text-right"}`}>
                <section className='row  banner-section '>
                    <div className="col-lg-11 col-12 mx-auto">
                        <WebHeader headerFor={'Student'} />
                        <section className='banner-body'>
                            <div className="row">
                                <div className={`col-lg-5  v-center mb-3 order-lg-1 order-md-1 order-2`}>
                                    <div>
                                        <img src={block} alt="block" className='mw-100 mb-2' />
                                        <h1 className='web-heading my-1'>{t('home.hero.title1')} </h1>
                                        <p className='web-heading my-1'> {t('home.hero.title2')}</p>
                                        <div className={`banner-sub-heading ${reduxState.language === 'ar' && "text-right"}`}>
                                            <p className='mt-3'>{t('home.hero.subTitle1')}
                                            </p>
                                            <p className='mt-4'>{t('home.hero.subTitle2')} <span className='ms-900'>{t('home.hero.sayDhad')}</span></p>
                                        </div>
                                        <button className='btn find-tutor-btn mt-4' onClick={handleRedirect}>{t('home.getStarted.button')}</button>

                                    </div>
                                </div>
                                <div className="col-lg-1 order-lg-2"></div>

                                <div className="col-lg-6 p-rel mb-3 order-lg-3 order-md-3 order-1">
                                    <div className='banner-video'>
                                        <video autoPlay={true} muted preload='auto' loop={true} controls={false}>
                                            <source src={vid} />
                                        </video>
                                        <div className="img overlay"></div>
                                    </div>
                                    <div className='banner-parrot-body'>
                                        <img src={parrot} className="banner-parrot mw-100" alt="parrot" />
                                        <div className="img overlay"></div>
                                    </div>
                                </div>
                            </div>
                            <div className={`row learn-arabic-point web-sub-heading ${reduxState.language === 'ar' && "text-right"}`}>
                                <React.Fragment>
                                    <div className="col-12 pl-2 pt-5"><p className='web-sub-heading text-right'>{t('home.hero.subTitle3')}</p></div>
                                    <div className="col-12 pl-2"><p className='mt-4 web-heading'>{t('home.hero.title3')}</p></div>
                                    <div className="col-lg-5 col-md-5 pl-2">
                                        <p className={`mt-4 ${reduxState.language === 'ar' ? "web-heading" : "web-point-heading"}`}><i class="far fa-arrow-alt-right"></i> {t('home.hero.list1.title')}</p>
                                        <ul>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list1.point1')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list1.point2')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list1.point3')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list1.point4')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list1.point5')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list1.point6')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list1.point7')}</li>
                                        </ul>
                                        <p className={`mt-4 ${reduxState.language === 'ar' ? "web-heading" : "web-point-heading"}`}><i class="far fa-arrow-alt-right"></i> {t('home.hero.list2.title')}</p>
                                        <ul>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list2.point1')}</li>
                                            <p className={`mt-4 ${reduxState.language === 'ar' ? "web-heading" : "web-point-heading"}`}><i class="far fa-dot-circle"></i> {t('home.hero.list2.point2.title')}</p>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list2.point2.point1')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list2.point2.point2')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list2.point2.point3')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list2.point2.point4')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list2.point2.point5')}</li>
                                            {t('home.hero.list2.point2.point6') && <li><i className='fa fa-check tx-green'></i> {t('home.hero.list2.point2.point6')}</li>}
                                            <p className={`mt-4 ${reduxState.language === 'ar' ? "web-heading" : "web-point-heading"}`}><i class="far fa-dot-circle"></i> {t('home.hero.list2.point3.title')}</p>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list2.point3.point1')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list2.point3.point2')}</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-2 col-md-2"></div>
                                    <div className="col-lg-5 col-md-5  pl-2">
                                        <p className={`mt-4 ${reduxState.language === 'ar' ? "web-heading" : "web-point-heading"}`}><i class="far fa-arrow-alt-right"></i> {t('home.hero.list3.title')}</p>
                                        <ul>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list3.point1')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list3.point2')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list3.point3')}</li>
                                        </ul>
                                        <p className={`mt-4 ${reduxState.language === 'ar' ? "web-heading" : "web-point-heading"}`}><i class="far fa-arrow-alt-right"></i> {t('home.hero.list4.title')}</p>
                                        <p className={`mt-4 ${reduxState.language === 'ar' ? "web-heading" : "web-point-heading"}`}><i class="far fa-arrow-alt-right"></i> {t('home.hero.list5.title')}</p>
                                        <ul>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list5.point1')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list5.point2')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list5.point3')}</li>
                                        </ul>
                                        <p className={`mt-4 ${reduxState.language === 'ar' ? "web-heading" : "web-point-heading"}`}><i class="far fa-arrow-alt-right"></i> {t('home.hero.list6.title')}</p>
                                        <ul>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list6.point1')}</li>
                                            <li><i className='fa fa-check tx-green'></i> {t('home.hero.list6.point2')}</li>
                                        </ul>
                                        <p className={`mt-4 ${reduxState.language === 'ar' ? "web-heading" : "web-point-heading"}`}><i class="far fa-arrow-alt-right"></i> {t('home.hero.list7.title')}</p>
                                        <p className={`mt-4 ${reduxState.language === 'ar' ? "web-heading" : "web-point-heading"}`}><i class="far fa-arrow-alt-right"></i> {t('home.hero.list8.title')}</p>
                                        <p className={`mt-4 ${reduxState.language === 'ar' ? "web-heading" : "web-point-heading"}`}><i class="far fa-arrow-alt-right"></i> {t('home.hero.list9.title')}</p>
                                        <p className={`mt-4 ${reduxState.language === 'ar' ? "web-heading" : "web-point-heading"}`}><i class="far fa-arrow-alt-right"></i> {t('home.hero.list10.title')}</p>
                                    </div>
                                </React.Fragment>
                            </div>
                        </section>
                    </div>
                </section>

                <section className='section-2  row'>
                    <div className='col-lg-10 mx-auto'>
                        <div className="row">
                            <div className="col-lg-5">
                                <div>
                                    <img src={pic} className="sec2-img px-3" alt="pic" />
                                    <div className="img overlay"></div>
                                </div>
                            </div>
                            <div className="col-lg-1"></div>
                            <div className={`col-lg-6 v-center ${reduxState.language === 'ar' && "text-right"}`}>
                                <p className='web-heading'>{t('home.sec2.heading')}</p>
                                <p className={`web-sub-heading ${reduxState.language === 'ar' && "text-right"}`}>{t('home.sec2.subheading')}</p>
                                <div className='testimonial-view'>
                                    <div>
                                        <img src={Mask} alt="mask" />
                                        <div className="img overlay"></div>
                                    </div>
                                    <div className='mx-2'>
                                        <p className='testimonial-view-text'>“{t('home.sec2.title1')}”</p>
                                        <p className='testimonial-view-author'>{t('home.sec2.title2')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='section-3 row'>
                    <div className='col-lg-11 mx-auto p-rel'>
                        <div className="row">
                            <div className={`col-lg-5 v-center px-3 pb-4 ${reduxState.language === 'ar' && "text-right"}`}>
                                <div>
                                    <p className='web-heading'>{t('home.greeting.heading')}</p>
                                    <div className={`web-sub-heading ${reduxState.language === 'ar' && "text-right"}'`}>
                                        <p className={`${reduxState.language === 'ar' && "text-right"} mb-3`}>{t('home.greeting.para1.title1')} <span className='ms-900'>{t('home.greeting.para1.span1')}</span> {t('home.greeting.para1.title2')} <span className='ms-900'>{t('home.greeting.para1.span2')}</span> {t('home.greeting.para1.title3')}</p>
                                        <p className={`${reduxState.language === 'ar' && "text-right"} mb-3`}><span className='ms-900'>{t('home.greeting.subheading')}</span> </p>
                                        <p className={`${reduxState.language === 'ar' && "text-right"} mb-3`}>{t('home.greeting.para2.title1')} <span className='ms-900'>{t('home.greeting.para2.span1')}</span> {t('home.greeting.para2.title2')} <span className='ms-900'>{t('home.greeting.para2.span2')}</span></p>
                                        <p className={`${reduxState.language === 'ar' && "text-right"} mb-3`}>{t('home.greeting.para3')}</p>
                                        <p className={`${reduxState.language === 'ar' && "text-right"} mb-3 ms-900`}>{t('home.greeting.subheading2')}</p>
                                    </div>
                                </div>

                                <div className='mt-4 p-rel' style={{ zIndex: 1 }}>
                                    <a rel="noopener noreferrer" href={constant.ANDROID_APP} target={"_blank"}><img src={Google} className="mx-2" alt="google" /></a>
                                    <a rel="noopener noreferrer" href={constant.IOS_APP} target={"_blank"}><img src={Apple} alt="apple" /></a>
                                    {/* <div className="img overlay"></div> */}
                                </div>
                                <div className='mt-3 d-lg-block d-none'>
                                    <img src={parrot1} style={{ width: '120px' }} className="mw-100" alt="parrot" />
                                    <div className="img overlay"></div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className='d-flex'>
                                    <div className='mr-4'>
                                        <img className='tutor-img' src={TutorListImg} alt="pic" />
                                        <div className="img overlay"></div>
                                    </div>
                                    <div >
                                        <img className='tutor-img' src={TutorDetailImg} alt="pic" />
                                        <div className="img overlay"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='p-abs' style={{ bottom: '-150px' }}>
                            <img src={parrot1} style={{width:'120px'}} className="mw-100" alt="" />
                            <div className="img overlay"></div>
                        </div> */}
                    </div>

                </section>


                <section className='section-4  row '>
                    <div className='col-lg-11 mx-auto'>
                        <div className="row">
                            <div className="col-lg-5  px-3">
                                <div className='all-center'>
                                    <div className='girl-img-box'>
                                        <img src={Girl1Img} className="girl1-img px-3" alt="girl" />
                                        <div className="img overlay"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 d-lg-block d-md-block d-none"></div>
                            <div className="col-lg-6  v-center" >
                                <img src={Shape} className="sec4-quote-img" alt='quote' />
                                <p className={` ${reduxState.language === 'ar' && "text-right"} web-sub-heading mb-3`} style={{ opacity: 0.7 }}>{t('home.women.para')}.</p>
                                <p className={`${reduxState.language === 'ar' && "text-right"} web-sub-heading ms-900`}>{t('home.women.title')}</p>
                                <div className='testimonial-view '>
                                    <div >
                                        <img src={Mask} alt="mask" />
                                        <div className="img overlay"></div>
                                    </div>
                                    <div className={`mx-2 ${reduxState.language === 'ar' && "text-right"}`}>
                                        <p className='testimonial-view-text'>“{t('home.women.comment')}”</p>
                                        <p className='testimonial-view-author'>{t('home.women.who')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className='section-5 row'>
                    <div className='col-lg-10 mx-auto section-5-box'>
                        <Subscribe />
                    </div>
                </section>
                {/* <section className='web-testimonial row'>
                    <div className='col-lg-8 mx-auto'>
                        <UserTestimonial faqFor={2} />
                    </div>
                </section> */}

                <section className='row footer-section '>
                    <div className="col-lg-10 col-12 mx-auto">
                        <WebFooter />
                    </div>
                </section>

            </div>

        </>
    )
}

