import axios from 'axios'
import helper from '../helper/index'
import { constant } from '../config/Contant'
const URL = constant.TUTOR_URL

export const payoutListing = async (tid,startDate,endDate) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'get',
            url: `${URL}/payout-list?tutor_id=${tid}&start_date=${startDate}&end_date=${endDate}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

export const payoutDetails = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'get',
            url: `${URL}/transaction-details-by-id?id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}