import React, { useState, useEffect } from 'react'
import helper from '../helper/index';
import tutor from '../controller/tutor';
import Backdrop from '../component/Backdrop';
import SupportTicketPopup from '../support/SupportTicketPopup';
import HowToVideosCard from '../component/HowToVideosCard';
import PlayVideos from '../support/PlayVideos';
import { useHistory } from 'react-router'
import banner from "../static/images/banner.png";
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
export default function Support() {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const history = useHistory()
    const [pageLoad, setPageload] = useState(true)
    const [openSupport, setOpenSupport] = useState(false)
    const [state, setState] = useState({
        openTickets: 0,
        totalFaqQuestions: 0,
        totalSupportArticles: 0,
        totalSupportVideos: 0
    })
    const [hwVideoData, setHwVideoData] = useState([])
    const [playVideo, setPlayVideo] = useState({ open: false, id: '', category: '' })

    useEffect(() => {
        getHowToVideos()
        getCount()
        return () => { }
    }, [])

    const getCount = async () => {
        await tutor.getSupportCount(helper.getid()).then((res) => {
            if (res.data.status) {
                setState({ ...res.data.result })
            }
        })
    }

    const getHowToVideos = async () => {
        await tutor.getHowToVideos()
            .then((res) => {
                if (res.data.status) {
                    let list = []
                    res.data.result.forEach((data) => {
                        if (helper.getDateDifference(data.created_at) < 30) {
                            list.push(data)
                        }
                    })
                    setHwVideoData(list)
                }
                setPageload()
            })
    }

    return (
        <div className={`tutor-support tutor-dash ${reduxState.language === 'ar' && "text-right"}`}>

            {
                !playVideo.open && <>
                    <section className="banner-box-support row gx-0 mx-0" style={{ overflow: 'hidden' }}>
                        <div className="col-lg-7 col-md-7 col-12 py-4 px-3 d-flex flex-column justify-content-center">
                            <h4 className="f-fred fs-32 text-white">{helper.getRoleType() === "user" ? t('general.support.student.heading') : t('general.support.tutorHeading')}</h4>
                            <p className="mb-3 mt-2 fs-18 ms-500 lh-21" style={{ color: 'white', opacity: '0.8' }}>
                                {t('general.support.student.para')}
                            </p>
                            <div>
                                <button className="btn btn-tutor-profile fs-12" onClick={() => setOpenSupport(true)}> {t('general.support.student.contactSupport')}</button>
                            </div>

                        </div>
                        <div className="col-lg-5 col-md-5 col-12">
                            <img className="w-100 h-100 d-lg-block d-md-block d-none" style={{ borderRadius: '250px 20px 20px 0px' }} src={banner} alt="banner" />
                        </div>
                    </section>
                    <section className="banner-bottom-support mx-auto mb-3"></section>

                    <section className="row gx-0 mx-0">
                        <div className="col-lg-3 col-md-4 col-12 px-2 my-2">
                            <div className="course-overview b-grey px-3 py-3 d-flex justify-content-between align-items-center pointer" onClick={() => history.push(`/${helper.getRoleType() === "user" ? "user" : "tutor"}/dashboard/support/ticket`)}>
                                <div>
                                    <p className="fs-18 f-fred lh-18"> {t('general.support.openTicket')}</p>
                                    <p className="sub-heading"> {t('general.support.dhadSupport')}</p>
                                </div>
                                <p className="ms-500 fs-40 tx-green">{state.openTickets}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12 px-2 my-2">
                            <div className="course-overview pointer b-grey px-3 py-3 d-flex justify-content-between align-items-center" onClick={() => history.push(`/${helper.getRoleType() === "user" ? "user" : "tutor"}/dashboard/support/faq`)}>
                                <div>
                                    <p className="fs-18 f-fred lh-18">{t('general.support.faq')}</p>
                                    <p className="sub-heading"> {t('general.support.faqText')}</p>
                                </div>
                                <p className="ms-500 fs-40 tx-green">{state.totalFaqQuestions}</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-12 px-2 my-2 pointer">
                            <div className="course-overview b-grey px-3 py-3 d-flex justify-content-between align-items-center"
                                onClick={() => history.push(`/${helper.getRoleType() === "user" ? "user" : "tutor"}/dashboard/support/how-to-videos`)}>
                                <div>
                                    <p className="fs-18 f-fred lh-18"> {t('general.support.howVideo')}</p>
                                    <p className="sub-heading">{t(`general.${helper.getRoleType() === "user" ? 'student' : 'tutor'}`)} {t('general.support.techSupport')}</p>
                                </div>
                                <p className="ms-500 fs-40 tx-green">{state.totalSupportVideos}</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-12 px-2 my-2">
                            <div className="course-overview b-grey px-3 py-3 d-flex justify-content-between pointer align-items-center"
                                onClick={() => history.push(`/${helper.getRoleType() === "user" ? "user" : "tutor"}/dashboard/support/articles`)}
                            >
                                <div>
                                    <p className="fs-18 f-fred lh-18"> {t('general.support.howArticles')}</p>
                                    <p className="sub-heading">{t(`general.${helper.getRoleType() === "user" ? 'student' : 'tutor'}`)}  {t('general.support.marketSupport')}</p>
                                </div>
                                <p className="ms-500 fs-40 tx-green">{state.totalSupportArticles}</p>
                            </div>
                        </div>
                    </section>

                    {hwVideoData.length !== 0 &&
                        <div className="row gx-0 mx-0 my-2">
                            <div className="col-12 px-0"> <p className="f-fred fs-24 my-2 tx-lt-grey ml-2">Recently added how to videos</p></div>
                            {
                                hwVideoData.map((data) => {
                                    return (
                                        <div className="col-lg-3 px-1 mb-2 pointer">
                                            <HowToVideosCard
                                                img={data.featured_image_link}
                                                title={data.title}
                                                onChange={() => setPlayVideo({ ...playVideo, open: true, id: data.id, category: data.category })}
                                            />
                                        </div>
                                    )
                                })}
                        </div>
                    }
                </>}

            {pageLoad && <Backdrop />}
            {openSupport && <SupportTicketPopup close={() => setOpenSupport(false)} />}

            {playVideo.open &&
                <PlayVideos
                    data={hwVideoData}
                    id={playVideo.id}
                    category={playVideo.category}
                    cb={() => setPlayVideo({ ...playVideo, open: false })} />}

        </div>


    )
}
