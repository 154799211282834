/* eslint-disable */
import React, { useState, useEffect } from 'react'
import user from '../controller/user'
import helper from '../helper/index'
import Backdrop from '../component/Backdrop';
import RateTutor from '../component/RateTutor';
import Loader from '../component/Loader';
import AlertPopUp from '../component/AlertPopUp';
import { useHistory, useParams } from 'react-router-dom';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import { FaTimesCircle, FaSearch } from 'react-icons/fa'
import { getLanguage } from '../controller/master';
import TutorCard from '../component/TutorCard';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
export default function TutorsByReferral() {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation();
    const history = useHistory()
    const { id } = useParams()
    const [pageload, setPageload] = useState(true)
    const [accent, setAccent] = useState([])
    const [defaultValue, setDefaultValue] = useState({
        accent: [],
        level: [],
        course: []
    })
    const [resData, setResData] = useState([])
    const [profileData, setProfileData] = useState({ id: 0, data: '' })
    const [fav, setFav] = useState([])
    const [state, setState] = useState({
        openRatePop: false,
        data: '',
        qry: '',
        loader: false,
        openAlert: false,
        type: 'ok',
        msg: '',
        tutorId: 0,
        openTutorCourse: false
    })
    const [filter, setFilter] = useState({
        online: true,
        fav: false
    })
    useEffect(() => {
        let list = []
        async function getLngDialects() {
            await getLanguage().then((res) => {
                if (res.data.status) {
                    res.data.result.dialects.forEach((data) => {
                        list.push({
                            label: data.name, value: data.name, classes: 'p-2 ms-500'
                        })
                    })
                }
            })
        }
        getLngDialects()
        setAccent(list)
        getCourseTitle()
        getFilteredCourses('', '', true, '')
        return () => { }
    }, [])

    // filters 
    const [tutorfilter, setTutorFilter] = useState({
        allCoursesTitle: [],
        selectedCoursesTitle: [],
        selectedLevel: [],
        accentLevel: []
    })


    const getCourseTitle = async () => {

        user.getCourseTitle().then((res) => {
            if (res.data.status) {
                let list = []
                res.data.result.forEach((data) => {
                    list.push({
                        label: data.title,
                        value: data.title,
                        classes: 'p-2 ms-500'
                    })
                })
                setTutorFilter({ ...tutorfilter, allCoursesTitle: list })
            } else {

            }
        })
    }
    const getTutors = async () => {
        setState({ ...state, loader: true, openRatePop: false })
        await user.getTutor()
            .then((res) => {
                if (res.data.status) {
                    setResData(res.data.result)

                    let list = []
                    res.data.result.forEach((data, index) => {
                        if (data.isFavorite) {
                            list.push(index)
                        }
                    })

                    setFav(list)
                }

                setState({ ...state, loader: false, openRatePop: false })
                setPageload(false)
            })
    }



    const getFavpriteTutors = async () => {
        setState({ ...state, loader: true, openRatePop: false })

        await user.getFavouriteTutor(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    setResData(res.data.result)
                    let list = []
                    res.data.result.forEach((data, index) => {
                        list.push(index)
                    })

                    setFav(list)
                }

                setState({ ...state, loader: false, openRatePop: false })
                setPageload(false)
            })
    }



    const searchByName = async ({ clearSearch }) => {
        setState({ ...state, loader: true })
        await user.getTutorByName(clearSearch ? "" : state.qry).then((res) => {
            if (res.data.status) {
                setResData(res.data.result)
                if (res.data.result.length > 0) {
                    setProfileData({ ...profileData, id: res.data.result[0].tutorId, data: res.data.result[0] })
                }
            }
            setState({ ...state, loader: false, qry: clearSearch ? "" : state.qry })
        })
    }

    const addtoFavorite = async (tutorId, index) => {
        await user.addToFavourite({
            tutorId: tutorId,
            isFavorite: true,
            studentId: helper.getid()
        }).then((res) => {

            if (res.data.status) {
                let list = [...fav]

                list.push(index)
                setFav(list)
            }


        })
    }


    const removeFromFavorite = async (tutorId, index) => {

        await user.removeFromFavourite(
            tutorId,
            helper.getid()
        ).then((res) => {

            if (res.data.status) {
                let list = [...fav]
                let find = fav.indexOf(index)
                if (find != -1) {
                    list.splice(find, 1)
                    setFav(list)
                }
            }
        })
    }

    const onSelectCourse = (val) => {
        if (val) {
            setTutorFilter({
                ...tutorfilter,
                allCoursesTitle: tutorfilter.allCoursesTitle,
                selectedCoursesTitle: val,
                selectedLevel: tutorfilter.selectedLevel,
                accentLevel: tutorfilter.accentLevel
            })
            getFilteredCourses(val, tutorfilter.selectedLevel, filter.online, tutorfilter.accentLevel)
        } else {
            setTutorFilter({
                ...tutorfilter,
                allCoursesTitle: tutorfilter.allCoursesTitle,
                selectedCoursesTitle: val,
                selectedLevel: tutorfilter.selectedLevel,
                accentLevel: tutorfilter.accentLevel
            })
            getFilteredCourses(val, tutorfilter.selectedLevel, filter.online, tutorfilter.accentLevel)
        }

    }
    const onSelectLevel = (val) => {
        if (val) {
            setTutorFilter({
                ...tutorfilter,
                allCoursesTitle: tutorfilter.allCoursesTitle,
                selectedCoursesTitle: tutorfilter.selectedCoursesTitle,
                selectedLevel: val,
                accentLevel: tutorfilter.accentLevel
            })
            getFilteredCourses(tutorfilter.selectedCoursesTitle, val, filter.online, tutorfilter.accentLevel)
        } else {
            setTutorFilter({
                ...tutorfilter,
                allCoursesTitle: tutorfilter.allCoursesTitle,
                selectedCoursesTitle: tutorfilter.selectedCoursesTitle,
                selectedLevel: val,
                accentLevel: tutorfilter.accentLevel
            })
            getFilteredCourses(tutorfilter.selectedCoursesTitle, val, filter.online, tutorfilter.accentLevel)

        }
    }

    const onSelectAccent = (val) => {
        if (val) {
            setTutorFilter({
                ...tutorfilter,
                allCoursesTitle: tutorfilter.allCoursesTitle,
                selectedCoursesTitle: tutorfilter.selectedCoursesTitle,
                selectedLevel: tutorfilter.selectedLevel,
                accentLevel: val
            })
            getFilteredCourses(tutorfilter.selectedCoursesTitle, tutorfilter.selectedLevel, filter.online, val)
        } else {
            setTutorFilter({
                ...tutorfilter,
                allCoursesTitle: tutorfilter.allCoursesTitle,
                selectedCoursesTitle: tutorfilter.selectedCoursesTitle,
                selectedLevel: tutorfilter.selectedLevel,
                accentLevel: val
            })
            getFilteredCourses(tutorfilter.selectedCoursesTitle, tutorfilter.selectedLevel, filter.online, val)

        }
    }

    const getFilteredCourses = async (courses, level, isOnline, accent) => {
        setState({ ...state, loader: true, openRatePop: false })
        await user.getTutorsByFilter(courses, level, isOnline, accent).then((res) => {
            if (res.data.status) {
                setResData(res.data.result)
                let list = []
                res.data.result.forEach((data, index) => {
                    if (data.isFavorite) {
                        list.push(index)
                    }
                })

                setFav(list)
            }

            setState({ ...state, loader: false, openRatePop: false })
            setPageload(false)
        })
    }

    const addProfileView = async (tid) => {
        await user.addProfileView({ tutor_id: tid, student_id: helper.getid() })
            .then((res) => { })
    }
    return (
        <div className="tutor-listing">
            {

                <>
                    <form onSubmit={(e) => { e.preventDefault(); searchByName() }}>

                        <div className="row gx-0 mx-0">
                            <div className="col-lg-3 d-flex w-100 align-items-center col-md-4 col-12 px-1 mb-2">
                                <input type="search" placeholder={t('general.courses.searchTutor')} value={state.qry} onChange={(e) => {
                                    if (e.target.value) {
                                        setState({ ...state, qry: e.target.value })
                                    } else {
                                        searchByName({ clearSearch: true })
                                    }
                                }} className="w-100 search-box ms-700 px-2 py-2 fs-14" />
                                <FaSearch onClick={() => { searchByName({ clearSearch: false }) }} size={22} style={{ right: '10px' }} className='mx-2 inactive-icon-color p-abs d-lg-none d-md-none  pointer pointer mt-1' />
                                {state.qry && <FaTimesCircle onClick={() => { getTutors(); setState({ ...state, qry: '' }) }} size={22} style={{ right: '40px' }} className='mx-2 inactive-icon-color p-abs d-lg-none d-md-none  pointer pointer mt-1' />}
                            </div>
                            <div className="col-lg-2 col-md-4 col-12 px-1 mb-2">
                                <button type="submit" className="btn btn-login w-100 d-lg-block d-md-block d-none  wrap">{t('general.searchTutor')}</button>
                            </div>
                            <div className="col-lg-12 d-lg-block d-none"></div>
                            <div className="col-lg-3 mb-2 d-lg-block d-none">
                                <div className='d-flex h-100  ms-500'>
                                    <div className='h-100 all-center flex-column mr-2' onClick={() => { setFilter({ ...filter, online: true, fav: false }); getFilteredCourses(tutorfilter.selectedCoursesTitle, tutorfilter.selectedLevel, true, tutorfilter.accentLevel) }}>
                                        <p className={`mx-2 pointer ${filter.online && "tx-sky"}`} >{t('general.online')}</p>
                                        <div className={`${filter.online && "bg-sky"} mx-2 w-100`} style={{ height: '2px' }}></div>
                                    </div>
                                    <div className='h-100 all-center flex-column mr-2' onClick={() => { setFilter({ ...filter, fav: true, online: '' }); getFavpriteTutors() }}>
                                        <p className={`mx-2 pointer ${filter.fav && "tx-sky"}`} >{t('general.favorite')}</p>
                                        <div className={`px-2 w-100 ${filter.fav && "bg-sky"}`} style={{ height: '2px' }} ></div>
                                    </div>
                                    <div className='h-100 all-center flex-column mr-2' onClick={() => { setFilter({ ...filter, fav: false, online: '' }); getTutors(); setDefaultValue({ accent: [], course: [], level: [] }) }}>
                                        <p className={`mx-2 pointer`} >{t('general.reset')}</p>

                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-2 d-lg-block d-none"></div>
                            <div className="col-lg-2 col-md-6 px-2 fs-18 mb-2">
                                <MultiSelect
                                    className='w-100  fs-20'
                                    placeholder={t('general.courses.accent')}
                                    defaultValue={defaultValue.accent}
                                    disableChip={true}
                                    chipAlternateText={t('general.courses.accent')}
                                    options={accent}
                                    style={{ padding: '5px' }}
                                    onChange={(data) => { onSelectAccent(data) }}
                                />


                            </div>
                            <div className="col-lg-2 col-md-6 px-2 fs-18 mb-2">
                                <MultiSelect
                                    className='w-100  fs-20'
                                    placeholder={t('general.courses.level')}
                                    disableChip={true}
                                    chipAlternateText={t('general.courses.level')}
                                    defaultValue={defaultValue.level}
                                    options={[
                                        { label: t('general.level.basic'), value: 'Basic', classes: 'p-2 ms-500' },
                                        { label: t('general.level.intermediate'), value: 'Intermediate', classes: 'p-2 ms-500' },
                                        { label: t('general.level.advanced'), value: 'Advanced', classes: 'p-2 ms-500' }
                                    ]}
                                    style={{ padding: '5px' }}
                                    onChange={(data) => { onSelectLevel(data) }}
                                />


                            </div>
                            <div className="col-lg-3 col-md-6 col-12 mb-2 px-2">
                                <MultiSelect
                                    className='w-100  fs-20'
                                    placeholder={t('general.courses.courses')}
                                    disableChip={true}
                                    chipAlternateText={t('general.courses.courses')}
                                    defaultValue={defaultValue.course}
                                    options={tutorfilter.allCoursesTitle}
                                    style={{ padding: '10px' }}
                                    onChange={(data) => { onSelectCourse(data) }}
                                />

                            </div>


                        </div>
                    </form>

                    <p className={`f-fred fs-24 mt-3 pl-2 ${reduxState.language === 'ar' && "text-right mr-2"}`}>{t('general.availableTutors')}</p>
                    <section className="row mt-3">

                        {
                            resData.map((data, index) => {
                                return (
                                    <div key={index} className="col-lg-4 px-2">
                                        <TutorCard
                                            onClick={() => history.push(`/user/dashboard/referral-tutor-profile/${data.tutorId}/${id}`)}
                                            index={index}
                                            data={data}
                                            addProfileView={(data) => addProfileView(data)}
                                            fav={fav}
                                            addtoFavorite={(data, index) => { addtoFavorite(data, index) }}
                                            removeFromFavorite={(data, index) => { removeFromFavorite(data, index) }}
                                        />
                                    </div>

                                )
                            })


                        }
                        {resData.length == 0 && !pageload && <h5 className='f-fred col-12 my-4 tx-lt-grey text-center'>No online available tutor found</h5>}
                    </section >
                </>
            }
            {pageload && <Backdrop />}


            <AlertPopUp
                open={state.openAlert}
                msg={state.msg}
                type={state.type}
                onClose={() => setState({ ...state, openAlert: false })}
            />
            <Loader open={state.loader && !pageload} />


            <RateTutor
                open={state.openRatePop}
                data={state.data}
                cb={() => {
                    setState({ ...state, openRatePop: false })
                    getTutors()
                }}
                onClose={() => { setState({ ...state, openRatePop: false }) }}
            />
        </div >
    )
}
