/* eslint-disable */
import React, { useState, useEffect } from 'react'
import logo from '../static/images/Logo.svg'
import { useHistory } from 'react-router'
import helper from '../helper/index'
import auth from '../controller/auth'
import Backdrop from '../component/Backdrop'
import { useTranslation } from "react-i18next";
import ToggleHidePassword from '../component/ToggleHidePassword'
export default function TutorResetPassword() {
    const { t } = useTranslation();
    const history = useHistory()
    const [password, setPassword] = useState({
        password: '',
        confirmPassword: ''
    })
    const [open, setOpen] = useState(false)
    useEffect(() => {
        const url = new URLSearchParams(window.location.search)
        if (!url.get('token')) {
            history.push('/')
        }
    }, [])
    const submit = async () => {
        const url = new URLSearchParams(window.location.search)
        const data = {
            password: password.password,
            token: url.get('token')
        }
        const res = helper.validate("password", password.password)
        if (!res.isValid) {
            return helper.showMessage(res.msg, "warning")
        }
        else if (password.password !== password.confirmPassword) {
            return helper.showMessage("Password mismatch", "warning")
        }

        setOpen(true)
        await auth.tutorResetPassword(data)
            .then((res) => {
                if (res.data.status) {
                    helper.showMessage(res.data.result, "success")
                    setTimeout(() => {
                        history.push('/tutor/login')
                    }, 1500)
                } else {
                    helper.showMessage(res.data.error, "error")
                }
                setOpen(false)
            })

    }

    return (
        <div className="user-little-more tutor-login-home all-center flex-column">
            <div className="row gx-0 mx-0 " style={{ width: '300px' }}>
                <div className="col-lg-12 col-md-8 col-12 px-3 mx-auto all-center flex-column">
                    <div className="all-center mb-3">
                        <img width="100%" src={logo} alt="logo" />
                    </div>
                    <div className="f-fred mt-4" style={{ minWidth: '300px' }}>
                        <form onSubmit={(e) => { e.preventDefault(); submit() }}>
                            <h4 className="f-fred my-2">{t('auth.forgot.reset')} <span className="tx-green">{t('auth.forgot.password')}</span></h4>
                            <ToggleHidePassword className="mt-3" placeholder={t('auth.forgot.newPass')} onChange={(e) => { setPassword({ ...password, password: e.target.value }); }} />
                            <ToggleHidePassword placeholder={t('auth.forgot.confirmPass')} onChange={(e) => { setPassword({ ...password, confirmPassword: e.target.value }); }} />
                                 <button type="submit" className="btn btn-login box-height mt-2 d-block  mx-auto w-100">{t('userOnBoard.proceed')}</button>
                        </form>
                    </div>

                </div>
            </div>
            {open && <Backdrop />}
        </div>
    )
}
