/* eslint-disable */
import React, { useState, useEffect } from 'react'
import HowToVideosCard from '../component/HowToVideosCard'
import helper from '../helper'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
export default function ArticlesDetails({ data, cb, id, category }) {
    const { t } = useTranslation();
    const [relatedArticle, setRelatedArticle] = useState([])
    const [Article, setArticle] = useState({ title: '', link: '', desc: '' })
    const reduxState = useSelector(state => state)
    useEffect(() => {
        let list = []
        data.forEach(ArticleData => {
            if (ArticleData.id === id) {
                setArticle({ ...Article, title: ArticleData.title, link: ArticleData.featured_image_link, desc: ArticleData.description })
            }
            if (ArticleData.category === category && ArticleData.id !== id) {
                list.push(ArticleData)
            }
        });
        setRelatedArticle(list)
        return () => { }
    }, [])

    const setArticleData = (articleData,id) => {
        setArticle({ ...Article, title: articleData.title, link: articleData.featured_image_link, desc: articleData.description })
        let list = []
        data.forEach((result) => {
            if (result.category === category && result.id !== id) {
                list.push(result)
            }
        })
        setRelatedArticle(list)
        helper.scroll()
    }
    return (
        <div className={`article details tx-lt-grey ${reduxState.language === 'ar' && "text-right"}`}>
            <div className="row mb-2">
                <div className="col-lg-12 d-flex align-items-center">
                    <button className="btn back-btn py-1 fs-14 f-fred ls px-3 mr-2" onClick={() => cb()}>{t('general.back')}</button>
                    <div className='mx-2'>
                        <p className="lh-15 f-fred fs-18">{Article.title} </p>
                        <p className="sub-heading">{t(`general.${helper.getRoleType() === "user" ? 'student' : 'tutor'}`)}  {t('general.support.supportText')}</p>
                    </div>
                </div>
            </div>


            <section className="br-2 mx-auto my-4" style={{ height: '378px' }}>
                <img className="br-1 h-100 d-block mx-auto" src={Article.link} style={{ maxWidth: '100%' }} alt="featured" />
            </section>

            <section className="row ms-500">
                <div className="col-lg-10 col-12 mx-auto text-justify" dangerouslySetInnerHTML={{ __html: Article.desc }}>

                </div>
            </section>
            {relatedArticle.length !== 0 &&
            <section>
                <h4 className="plan f-fred fs-24  mt-3 mb-3 px-2">Related articles</h4>
                <div className="row gx-0 mx-0">

                    {relatedArticle.map((result, index) => {
                        return (
                            <div key={`rel-article${index}`} className="col-lg-3 mb-2 col-md-4 col-12">
                               
                                <HowToVideosCard
                                    img={result.featured_image_link}
                                    title={result.title}
                                    onChange={() => setArticleData(result,result.id)}

                                />
                            </div>
                        )


                    })}
                    

                </div>
            </section>}
        </div>
    )
}
