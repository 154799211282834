import React, { useState, useEffect } from 'react'
import parot from '../static/images/parrot-1.png'
import tutor from '../controller/tutor'
import helper from '../helper/index'
import Backdrop from '../component/Backdrop'
import pops from '../component/PopUps'
import { useTranslation } from "react-i18next";
export default function DigInDeep({ next, prev }) {
    const { t } = useTranslation();
    const [openPop, setOpenPop] = useState({ name: '', open: false })
    const [pageload, setPageload] = useState(true)
    const [work, setWork] = useState({
        vip_kid: false,
        go_go_kid: false,
        cambly: false,
        byte_dance: false,
        other: false,
        why_dhad: '',
        hear_about_dhad: '',
        tutorId: helper.getid()
    })
    useEffect(() => {
        apiCall()
        return () => { }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const apiCall = async () => {
        await tutor.getDigInDeep(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    if (res.data.result) {
                        setWork({
                            ...work,
                            ...res.data.result
                        })
  
                    }

                }
                setPageload(false)
            })
    }

    const submit = async () => {
        if (!work.why_dhad || !work.hear_about_dhad) {
            helper.showMessage("Please fill required information")
            return
        }

        setPageload(true)

        await tutor.addDigInDeep(work).then((res) => {
            if (res.data.status) {
                next()
            }
        })
    }

    return (
        <>  {
            !pageload ? <>
                <div className="row gx-0 mx-0 tx-lt-black">
                    <div className="col-lg-11 col-12 mx-auto pt-3">
                        <div className='p-sticky tp-0 py-3 w-100 bg-white' style={{ zIndex: 1 }}>
                            <div className="row gx-0 mx-0">
                                <div className="col-lg-6 col-md-5 col-12 col-md-6 col-12 f-fred mb-2">
                                    <h4>{t('tutorOnBoard.digInDeep.heading')} <span className="tx-green">{t('tutorOnBoard.digInDeep.deep')}</span></h4>
                                    <p className="sub-heading">{t('tutorOnBoard.digInDeep.subtitle')}</p>
                                </div>
                                <div className="col-lg-6 col-md-7 col-12 d-flex justify-content-lg-end justify-content-md-end mb-2 align-items-center">
                                    <button className="btn btn-tutor-profile mr-2  wrap h-100" onClick={() => prev()}>{t('tutorOnBoard.digInDeep.back')}</button>
                                    <button className="btn btn-login  wrap h-100" onClick={() => submit()}>{t('tutorOnBoard.digInDeep.next')}</button>
                                </div>
                            </div>
                        </div>

                        <div className="alert fs-14 ms-500 tx-lt-grey py-2 my-3 px-2 d-flex align-items-center">
                            <img width="50px" src={parot} alt="parrot" />
                            <span> {t('tutorOnBoard.basicInfo.para1')} <span className="ms-900">{t('tutorOnBoard.basicInfo.para2')}  </span> </span>

                        </div>

                        <section className="row gx-0 mx-0 tx-lt-black f-fred basic-info">
                            <div className="col-lg-9 px-1 mx-auto">
                                <h4 className="mt-3 mb-2 tx-lt-grey">{t('tutorOnBoard.digInDeep.why.heading')}<small>*</small></h4>
                                <ul>
                                    <li>
                                        <div className="row gx-0 mx-0 py-2">
                                            <div className="col-lg-9 col-md-8 col-8 d-flex flex-column justify-content-center px-2">
                                                <p className="lh-15 f-fred fs-14 ">{t('tutorOnBoard.digInDeep.why.loveDhad')}</p>
                                                <p className="ms-500 tx-lt-grey fs-14">{t('tutorOnBoard.digInDeep.why.title')}</p>

                                            </div>
                                            <div className="col-lg-3 col-md-4 col-4 all-center  justify-content-end ">
                                                <button className="btn edit-info" onClick={() => setOpenPop({ ...openPop, name: 'aboutDhad', open: true })}>{t('tutorOnBoard.digInDeep.why.btn')}</button>
                                            </div>

                                            <div className="col-12 ms-500 fs-14 text-justify  px-2 mt-2">
                                                <p>{work.why_dhad}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                <h4 className="mt-3 mb-2 tx-lt-grey">{t('tutorOnBoard.digInDeep.hear.heading')}<small>*</small></h4>

                                <ul><li>
                                    <div className="row gx-0 mx-0 py-2">

                                        <div className="col-lg-9 col-md-8 col-8 d-flex flex-column justify-content-center px-2">
                                            <p className="lh-15 f-fred fs-14">{t('tutorOnBoard.digInDeep.hear.media')}</p>
                                            <p className="ms-500 tx-lt-grey fs-14">{t('tutorOnBoard.digInDeep.hear.title')}</p>

                                        </div>
                                        <div className="col-lg-3 col-md-4 col-4 all-center  justify-content-end ">
                                            <button className="btn edit-info" onClick={() => setOpenPop({ ...openPop, name: 'aboutDhad', open: true })}>{t('tutorOnBoard.digInDeep.hear.select')}</button>
                                        </div>
                                        <div className="col-12 f-fred   px-2 mt-2">
                                            <span className="lang-tags fs-14 text-capitalize">{work.hear_about_dhad}</span>
                                        </div>
                                    </div>

                                </li>


                                </ul>

                                <h4 className="mt-3 mb-2 tx-lt-grey">{t('tutorOnBoard.digInDeep.work.heading')}</h4>

                                <div className="row gx-0 mx-0">
                                    <div className="col-lg-6 ">
                                        <div className={`ask-social pointer p-2 ${work.vip_kid && "active-border"} ${work?.vip_kid && "active-border"}`} onClick={() => { setWork({ ...work, vip_kid: !work.vip_kid }) }}>
                                            <p className="lh-15">{t('tutorOnBoard.digInDeep.work.opt1')}</p>
                                            <p className="ms-500 fs-14">Click to select</p>
                                        </div>

                                    </div>
                                    <div className="col-lg-6 ">
                                        <div className={`ask-social pointer p-2 ${work.go_go_Kid && "active-border"} ${work?.go_go_kid && "active-border"} `} onClick={() => { setWork({ ...work, go_go_kid: !work.go_go_kid }) }}>
                                            <p className="lh-15">{t('tutorOnBoard.digInDeep.work.opt2')}</p>
                                            <p className="ms-500 fs-14">Click to select</p>
                                        </div>

                                    </div>

                                    <div className="col-lg-6">
                                        <div className={`ask-social pointer p-2 ${work.cambly && "active-border"} ${work?.cambly && "active-border"} `} onClick={() => { setWork({ ...work, cambly: !work.cambly }) }}>
                                            <p className="lh-15">{t('tutorOnBoard.digInDeep.work.opt3')}</p>
                                            <p className="ms-500 fs-14">Click to select</p>
                                        </div>

                                    </div>
                                    <div className="col-lg-6">
                                        <div className={`ask-social pointer p-2 ${work.byte_dance && "active-border"} ${work?.byte_dance && "active-border"} `} onClick={() => { setWork({ ...work, byte_dance: !work.byte_dance }) }}>
                                            <p className="lh-15">{t('tutorOnBoard.digInDeep.work.opt4')}</p>
                                            <p className="ms-500 fs-14">Click to select</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className={`ask-social pointer p-2 ${work.other && "active-border"} ${work?.other && "active-border"}`} onClick={() => { setWork({ ...work, other: !work.other }) }}>
                                            <p className="lh-15">{t('tutorOnBoard.digInDeep.work.opt5')}</p>
                                            <p className="ms-500 fs-14">Click to select</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {
                                openPop.open && <pops.DigInDeepPop
                                    cb={(data) => { setWork({ ...work, why_dhad: data.whyDhad, hear_about_dhad: data.hearDhad }) }}
                                    close={() => setOpenPop({ ...openPop, open: false })}
                                    data={work}
                                />
                            }

                        </section>

                    </div>
                </div>

            </>
                : <Backdrop />}
        </>
    )
}
