/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import TimeSlots from '../component/TimeSlots';
import tutor from '../controller/tutor';
import helper from '../helper/index'
import LeftPop from '../component/LeftPop';
import moment from 'moment';
import avatar from '../static/images/avatar.png'
import TutorAvalability from './TutorAvalability';
import calendar from '../controller/calendar';
import ResheduleClass from './RescheduleClass';
import { useHistory } from 'react-router-dom';
import StudentProfile from './StudentProfile';
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
let monthDate = ""
export default function MyCalendar() {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation();
    const [state, setState] = useState({
        openSlot: false,
        slotDay: null,
        data: [],
        selectedDate: [],
        pageload: true,
        openAvailabilty: false,
        openBooking: false,
        bookingData: '',
        bookingDate: ''
    })
    const history = useHistory()
    const [slotdata, setSlotdata] = useState([])
    const [copySlotdata, setCopySlotdata] = useState([])
    const [upcomingClassData, setUpcomingClassData] = useState([])
    const [user, setUser] = useState({
        openUserPop: false,
        openUserProfile: false,
        id: 0
    })

    useEffect(() => {
        monthDate = moment().format("YYYY-MM-DD")
        getAvailability(monthDate)
        upcomingClasses(new Date())
        return () => {
            setState({}); setSlotdata([]); setCopySlotdata([]); setUpcomingClassData([]); setUser({})
        }
    }, [])

    const getAvailability = async (date) => {
        await calendar.getAvailibilty(helper.getid(), date)
            .then((res) => {
                if (res.data.status) {
                    let sort = res.data.result.sort((a, b) => {
                        return new Date(a.date) - new Date(b.date)
                    })
                    setSlotdata(sort)
                    setCopySlotdata(sort)
                    setState({
                        ...state,
                        pageload: false,
                        openAvailabilty: false,
                        openBooking: false,
                    })
                } else {
                }
            })
    }

    const upcomingClasses = async (currDate) => {
        let date = moment(currDate).format("YYYY-MM-DD")
        await tutor.getBookedStudentDetailsSlot(helper.getid(), date)
            .then((res) => {
                if (res.data.status) {
                    let list = []
                    res.data.result.forEach((data) => {
                        if (moment(data.end_time, 'hh:mm').format('HH:mm') >= moment(new Date()).format("HH:mm")) {
                            list.push(data)
                        }
                    })
                    setUpcomingClassData(list)
                }
            })
    }

    const onChangeCalendar = (date) => {
        if (moment(monthDate).format("YYYY-MM") !== moment(date).format("YYYY-MM")) {
            monthDate = moment(date).format("YYYY-MM-DD")
            getAvailability(monthDate)
        }

        upcomingClasses(date)
        let find = false
        slotdata.some((data) => {
            if (data.date === moment(date).format('YYYY-MM-DD')) {
                let list = [data]
                setCopySlotdata(list)
                find = true
                return true
            }
            return false
        })
        if (!find) {
            setCopySlotdata([])
        }
    }

    return (
        <>
            <div className={`tutor-calendar ${reduxState.language === 'ar' && "text-right"}`}>

                {
                    !state.openAvailabilty ?
                        <section className='row gx-0 mx-0'>
                            <div className="col-lg-5 px-1 mb-2">
                                <div className="calendar-body b-grey pt-3 ">
                                    <p className="ms-900 fs-18 px-3  tx-lt-grey">{t('tutorDash.calendar.scheduledReservation')}</p>
                                    <Calendar
                                        onChange={(day) => { onChangeCalendar(day) }}
                                        onClickMonth={(day) => { onChangeCalendar(day) }}
                                        tileClassName="tilename"
                                        className="w-100 cl-body mt-3 ms-700"
                                        navigationAriaLabel="go up"
                                        next2Label={false}
                                        nextLabel = {false}
                                        prevLabel={false}
                                        prev2Label={false}
                                        tileContent={({ date }) => {
                                            let find = false
                                            slotdata.map((data) => {
                                                if (data.date === moment(date).format('YYYY-MM-DD')) {
                                                    find = true
                                                    return
                                                }
                                                return
                                            })
                                            if (find) {
                                                return <div className='all-center mt-2 p-rel' >
                                                    <p className='p-rel' style={{ borderBottom: '3px solid green', borderRadius: '10px', width: '20px' }}>
                                                        <i className='fas fa-clock  fs-12 p-abs tx-green' style={{ bottom: '0px', right: '-10px' }}></i>
                                                    </p>
                                                </div>

                                            }
                                            state.selectedDate?.map((data) => {
                                                if (moment(data).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
                                                    find = true
                                                    return
                                                }
                                                return
                                            })
                                            if (find) {
                                                return <div className='all-center mt-2 p-rel'>
                                                    <p className='p-rel' style={{ borderBottom: '3px solid #ffcb00', borderRadius: '10px', width: '20px' }}> <i className='fas fa-check-circle fs-12 p-abs tx-yellow' style={{ bottom: '0px', right: '-10px' }}></i>
                                                    </p>
                                                </div>

                                            }

                                        }}
                                    />
                                </div>
                                <section>

                                    <h4 className="f-fred tx-lt-grey my-3">{t('tutorDash.calendar.call')}</h4>

                                    {
                                        upcomingClassData.map((result, index) => {
                                            return (
                                                <div key={result.id} className="b-grey px-2 py-2 f-robo my-2">
                                                    <div className="row gx-0 ">
                                                        <div className="col-lg-9 col-12 mb-2">
                                                            <p className="fs-12 lh-18 ms-500 tx-lt-grey">{t('tutorDash.calendar.scheduleOn')}</p>
                                                            <p className="ms-700  fs-24">{moment(result.date).format('DD MMM YYYY')}, {moment(result.start_time, 'HH:mm').format("hh : mm A")}</p>
                                                        </div>
                                                        <div className="col-lg-3 col-12 mb-2 d-flex justify-content-end align-items-center">
                                                            {

                                                                moment(result.start_time, 'hh:mm').format('HH:mm') <= moment().format("HH:mm") && moment(result.end_time, 'hh:mm').format('HH:mm') >= moment().format("HH:mm") && moment(result.date).format('YYYY-MM-DD') >= moment().format("YYYYY-MM-DD") && result.status === "Booked" &&
                                                                <button className="btn px-1 py-1 fs-12 reshedule-btn f-fred pointer" onClick={() => history.push(`/dhad-video/${result.channel_name}`)}>{t('button.joinClass')}</button>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="row gx-0 mt-2">
                                                        <div className="col-lg-2 col-3 ">
                                                            <img style={{ height: '50px', width: '50px' }} className="rounded-circle" src={result.profilePicture ? result.profilePicture : avatar} alt="avatar" />
                                                        </div>
                                                        <div className="col-lg-10 col-9 d-flex flex-column justify-content-center">
                                                            <p className="f-fred  fs-14 tx-lt-black">{result.name ? result.name : "Anonymous"}</p>
                                                            <p className="fs-12 lh-15 ms-500 tx-lt-grey">From {result.country ? result.country : "Unknown"}</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        upcomingClassData.length === 0 &&
                                        <section>
                                            <img className="br-1" style={{ maxHeight: '300px', maxWidth: '100%' }} src="https://cdn.pixabay.com/photo/2015/10/31/11/58/call-center-1015274_960_720.jpg" alt="calls" />
                                        </section>
                                    }


                                </section>

                            </div>
                            <div className="col-lg-7 cpx-4">
                                <button id="step-td-update-availability" className='btn btn-login fs-14 mb-3' onClick={() => { setState({ ...state, openAvailabilty: true }) }} >{t('tutorDash.calendar.updateAvailabilty')}</button>
                                <hr className='my-2' />
                                {copySlotdata.length === 0 && <h5 className='my-3 text-center'>{t('tutorDash.calendar.scheduleAvail')}</h5>}

                                {
                                    copySlotdata.map((data, index) => {
                                        return (
                                            <div key={`schedule${index}`}>
                                                <p className='ms-500 my-3'><span className='tag-box py-2 px-2'>{moment(data.date).format('MMMM DD, YYYY')} </span> </p>
                                                <section className="time-slot-container row f-fred mt-2 ">
                                                    {
                                                        data.slot.map((slotData, index1) =>
                                                            <div key={`slot${index1}`} className={`col-lg-4 p-rel pr-2 mb-2 ${["Booked", "Completed"].includes(slotData.status) && "pointer"}`}
                                                                onMouseOver={() => {
                                                                    if (["Booked", "Completed"].includes(slotData.status)) {
                                                                        setUser({
                                                                            ...user,
                                                                            openUserPop: true,
                                                                            openUserProfile: false,
                                                                            id: index + "#" + index1
                                                                        })

                                                                    } else {
                                                                        setUser({
                                                                            ...user,
                                                                            openUserPop: false,
                                                                            openUserProfile: false

                                                                        })
                                                                    }
                                                                }}>
                                                                <TimeSlots
                                                                    time={
                                                                        <div className='ltr'>
                                                                            <p className='ms-900'>{moment(slotData.start_time, 'hh:mm').format('hh : mm A')}</p>
                                                                            <p className='mx-2  fs-12 text-center'>To</p>
                                                                            <p className='ms-900'>{moment(slotData.end_time, 'hh:mm').format('hh : mm A')}</p>
                                                                        </div>
                                                                    }
                                                                    status={slotData.status}
                                                                    bgClass={["Booked", "Completed"].includes(slotData.status) ? "time-slots-booked" : slotData.status === "Available" ? "time-slots-available" : "time-slots-unavailable"}
                                                                />
                                                                {
                                                                    ["Booked", "Completed"].includes(slotData.status) && user?.openUserPop && user.id === index + "#" + index1 &&
                                                                    <section className='overlay-card  py-3 px-2 p-abs br-1 bg-white'
                                                                        style={{ width: '250px' }}
                                                                        onMouseLeave={() => {
                                                                            setUser({
                                                                                ...user,
                                                                                openUserPop: false,
                                                                                openUserProfile: false

                                                                            })
                                                                        }}>
                                                                        <div className='d-flex  w-100 align-items-center'>
                                                                            <img width={'40px'} height="40px" className='rounded-circle mr-2' src={slotData.profilePicture ? slotData.profilePicture : avatar} alt="avatar" />
                                                                            <div className='tx-lt-black'>
                                                                                <p className='f-fred fs-14 lh-15 tx-lt-black'>{slotData.name}</p>
                                                                                <p className='sub-heading'>From {slotData.country}</p>
                                                                                <div className='lh-15'>
                                                                                    <span className='ms-900 fs-12'>{moment(slotData.start_time, 'hh:mm').format('hh : mm A')}</span>
                                                                                    <span className='ms-900 fs-12'> - </span>
                                                                                    <span className='ms-900 fs-12'>{moment(slotData.end_time, 'hh:mm').format('hh : mm A')}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className='mt-2 d-flex all-center align-items-center'>
                                                                            <button className='btn px-2 bg-green mx-2 py-1 text-white ms-700 fs-12' onClick={() => {
                                                                                setUser({
                                                                                    ...user,
                                                                                    openUserPop: false,
                                                                                    openUserProfile: true,
                                                                                    id: slotData.student_id

                                                                                })
                                                                            }}>View profile</button>
                                                                            {slotData.status === "Booked" &&
                                                                                <button className="btn px-1 py-1 mx-2 fs-12 reshedule-btn f-fred pointer"
                                                                                    onClick={() => {
                                                                                        setState({ ...state, openBooking: true, bookingData: slotData, bookingDate: moment(data.date).format('MMMM DD, YYYY') })
                                                                                    }}>Join/Cancel class</button>}

                                                                        </div>
                                                                    </section>
                                                                }

                                                            </div>
                                                        )
                                                    }
                                                </section>
                                                <hr className='my-2' />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </section>
                        :
                        <section>
                            <TutorAvalability
                                close={() => { setState({ ...state, openAvailabilty: false }); getAvailability() }} />

                        </section>
                }

                {
                    state.openBooking &&
                    <LeftPop
                        title={t('tutorSidebar.book.classDet')}
                        close={() => setState({ ...state, openBooking: false })}
                        body={
                            <ResheduleClass
                                callDate={state.bookingDate}
                                callData={state.bookingData}
                                slotData={slotdata}
                                close={() => setState({ ...state, openBooking: false })}
                                cb={() => { getAvailability(); upcomingClasses(new Date()); }} />
                        }
                    />
                }
                {
                    user?.openUserProfile && <StudentProfile id={user.id} onClose={() => setUser({ ...user, openUserProfile: false })} />
                }
            </div>
        </>
    )
}
