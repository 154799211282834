/* eslint-disable */
import React, { useEffect, useState } from 'react'
import UserReferPopup from '../component/UserReferPopup'
import user from '../controller/user'
import helper from '../helper'
import avatar from '../static/images/avatar.png'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
export default function Referral() {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation();
    const [state, setState] = useState({
        data: [],
        load: true,
        code: '',
        open: false
    })
    useEffect(() => { getReferral() }, [])
    const getReferral = async () => {

        await user.getSharedReferral(helper.getid()).then((res) => {
            if (res.data.status) {
                setState({ ...state, data: res.data.result.referral_user, load: false,code : res.data.result.referral_code })
                
            }
        })
    }
    return (
        <div className='referral'>
            <section className='row gx-0 mx-0 mb-3'>
                {!state.load &&
                    <div className={`col-lg-6 px-0 ${reduxState.language === 'ar' && "text-right"}`}>
                        <p className='f-fred fs-20 mb-3'>{t('general.referral.heading')}</p>
                        {state.data.map((result) =>
                            <div className='b-grey p-2  mb-3 d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <img className='rounded-circle mr-3' width={'50px'} height="50px" src={result.profilePicture ? result.profilePicture : avatar} alt="avatar" />
                                    <div>
                                        <p className='f-fred'>{result.name ? result.name : 'N/A'}</p>
                                        <p className='sub-heading'>{result.email ? result.email : 'N/A'}</p>
                                    </div>
                                </div>
                                <button className='btn fs-14 py-1 px-3 f-fred tx-lt-black btn-unread'>Success</button>
                            </div>)}

                        <section>
                            {state.data.length === 0 && <img style={{ height: '40%', borderRadius: '50px 0 50px 0px',maxWidth:'100%' }} className="d-block mx-auto" src="https://cdn.pixabay.com/photo/2018/03/22/02/37/email-3249062_960_720.png" alt="avatar" />}
                            <p className='my-3 text-center ms-700'>{t('general.referral.para')}</p>
                            <button className='btn btn-login fs-700 mx-auto d-block' style={{ width: '150px' }} onClick={() => { setState({ ...state, open: true}) }}>{t('general.share')}</button>
                        </section>
                    </div>}

            </section>
            {
                state.open &&
                <UserReferPopup
                    code={state.code}
                    handleClose={() => setState({ ...state, open: false })} />
            }

        </div>
    )
}
