/* eslint-disable */
import React, { useEffect } from 'react'
import { toast } from 'react-toastify';
import fb from '../config/firebaseAuth';
import { ref, onChildAdded } from "firebase/database";
import helper from '../helper';
import { useSelector } from 'react-redux';
const db = fb.database
let unsubscribe = ""
let localCount = 0
let name = ""
let showChatMessage = false
export default function LiveNotification({ roomId, myId, onMessage, showChat }) {
    const reduxState = useSelector(state => state)
    useEffect(() => {
        enable()
        return () => { try { unsubscribe() } catch { } }
    }, [])

    const enable = () => {
        unsubscribe = onChildAdded(ref(db, `classChat/${roomId}`), (data) => {
            const val = data.val()
            if (val.createdBy !== myId) {
                showMessage(val.message)
            }
        })
    }


    const showMessage = (message) => {
        if (!showChatMessage) {
            localCount++;
            onMessage(localCount)
            const role = helper.getRoleType() === "user" ? "Tutor" : "Student"
            toast(`${name || role}: ${message}`, {
                position: "top-left",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }else{
            localCount = 0;
            onMessage(0)
        }
    }

    return (
        <>
            <span className='d-none'>{name = reduxState.remoteUserName}{showChatMessage = showChat}{localCount = showChat ? 0 : localCount} </span>
        </>
    )
}
