/* eslint-disable */
import React, { useState, useEffect } from 'react'
import helper from '../helper/index'
import user from '../controller/user'
import ChatPop from '../component/ChatPop'
import moment from 'moment'
import { DateRangePicker } from 'react-dates';
import Backdrop from '../component/Backdrop'
import VideoOpen from '../component/VideoOpen'
import StudentSlideShow from '../component/StudentSlideShow'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { Menu } from '@mui/material'
import { constant } from '../config/Contant'


export default function Attendance() {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const [pageload, setPageload] = useState(false)
    const [attendance, setAttendance] = useState({
        name: "N/A",
        minutes_per_day: "N/A",
        subscription_date: "N/A",
        subscription_expiry_date: "N/A",
        attendance_history: [],
        load: false,
    })

    const [slide, setSlide] = useState({
        open: false,
        file: null,
        name: '',
        lessonNo: 0
    })

    const [state, setState] = useState({
        chatLogOpen: false,
        chatRoomId: null,
        name: '',
        pic: '',
        openVideo: false,
        videoFile: '',
        menuRef: null,
        menuOpen: null,
        videoList: []
    })

    const [startDate, setStartDate] = useState(moment(new Date(new Date().setDate(new Date().getDate() - 30))));
    const [orgDate, setOrgDate] = useState({ st: new Date(), en: new Date() });

    const [endDate, setEndDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(null);
    const onRange = (st, en) => {
        if (st) {
            setStartDate(st);
        }
        if (en) {
            setEndDate(en)
        }
        if (st && en) {
            getAttendance(moment(st).format('YYYY-MM-DD'), moment(en).format('YYYY-MM-DD'))
        }
    }

    useEffect(() => {
        getAttendance(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    }, [])

    const getAttendance = async (st, en) => {
        setPageload(true)
        await user.getAttendanceRecord(helper.getid(), st, en).then((res) => {
            setOrgDate({ st, en })
            if (res.data.status) {
                let list = []
                list = res.data.result.attendance_history.sort((a, b) => a.date < b.date ? 1 : a.date > b.date ? -1 : 0)
                setAttendance({ ...attendance, ...res.data.result, attendance_history: list, load: false })
            }

            setPageload(false)
        })
    }

    const getNoOfDays = (st, en) => {
        if (st && en) {
            var startTime = moment(st);
            var endTime = moment(en);
            var dif = moment.duration(endTime.diff(startTime));
            let day = dif.days()
            return day
        }
    }

    const getTotalAttendance = () => {
        let prev = ''
        let count = 0
        attendance.attendance_history.forEach((data) => {
            if (data.date != prev) {
                count++;
                prev = data.date
            }
        })
        return count;
    }

    return (
        <div className={`my-progress-body mx-1 p-3 ${reduxState.language === 'ar' && "text-right"}`}>
            <h4 className='f-fred fs-24 mt-2 mb-3'>{t('general.attendance.attendance')}</h4>
            <div className="row gx-0 mx-0 ms-500 mt-3">
                <div className="col-lg-6 col-12 py-1 fs-14">

                    <div className="row gx-0 mx-0 py-1">
                        <div className="col-lg-4 px-0">
                            <p className='ms-700 fs-16'>{t('general.attendance.studentName')}</p>
                        </div>
                        <div className="col-lg-8 px-2 ">
                            <p >{attendance.name}</p>
                        </div>
                    </div>
                    <hr />

                    <hr />
                    <div className="row gx-0 mx-0 ms-500 py-1">
                        <div className="col-lg-4">
                            <p className='ms-700 fs-16'>{t('general.attendance.planDuration')}</p>
                        </div>
                        <div className="col-lg-8 px-2 ltr">
                            <p >{attendance.subscription_date} to {attendance.subscription_expiry_date}</p>
                        </div>
                    </div>
                    <hr />

                    <hr />
                    <div className="row gx-0 mx-0 py-1 ms-500">
                        <div className="col-lg-4">
                            <p className='ms-700 fs-16'>{t('general.attendance.planType')}</p>
                        </div>
                        <div className="col-lg-8 px-2 ltr">
                            <p>
                                {attendance.minutes_per_day ? attendance.minutes_per_day + " minutes, " : '--'}
                                {attendance.days_per_week ? attendance.days_per_week + " day/per week" : '--'}
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <section className='ms-700 mt-3 ltr'>
                <DateRangePicker
                    className="w-100 h-100 p-2"
                    showClearDates={false}
                    style={{ height: '100%' }}
                    navPrev={null}
                    initialStartDate={null}
                    disabled={false}
                    startDate={startDate}
                    isOutsideRange={() => false}
                    isDayBlocked={() => false}
                    enableOutsideDays={true}
                    numberOfMonths={2}
                    startDateId="s_id"
                    endDate={endDate}
                    endDateId="e_id"
                    onDatesChange={({ startDate, endDate }) => { onRange(startDate, endDate) }}
                    focusedInput={focusedInput}
                    onFocusChange={e => setFocusedInput(e)}
                    displayFormat="DD MMM, YYYY"
                />
            </section>
            <section className='progress-table'>
                {attendance.attendance_history.length !== 0 ?
                    <table className='mt-4' style={{ minWidth: '700px' }}>
                        <tr className='bg-sky text-white w-100'>
                            <td className='ms-700 py-2 mx-4'>
                                <p>{t('general.attendance.tutorProfile')}</p>
                            </td>
                            <td className='ms-700 py-2'>
                                <p>{t('general.attendance.tutorName')}</p>
                            </td>
                            <td className='ms-700'>
                                <p>{t('general.attendance.date')}</p>
                            </td>
                            <td className='ms-700'>
                                <p>{t('general.attendance.duration')}</p>
                            </td>
                            <td className='ms-700'>
                                <p>{t('general.attendance.syllabus')}</p>
                            </td>
                            <td className='ms-700'>
                                <p>{t('general.attendance.video')} </p>
                            </td>
                            <td className='ms-700'>
                                <p>{t('general.attendance.chat')}</p>
                            </td>

                        </tr>

                        {
                            attendance.attendance_history.map((result, index) =>
                                <tr key={`attendance${index}`}>
                                    <td className='ms-500 py-1'>
                                        <img src={result.profilePicture} className='rounded-circle' alt="pic" width={"40px"} height={"40px"} />
                                    </td>
                                    <td className='ms-500 py-1'>
                                        <p>{result.name}</p>
                                    </td>
                                    <td className='ms-500 py-1'>
                                        <p>{result.date}</p>
                                    </td>
                                    <td className='ms-500 py-1'>
                                        <p>{result.duration}</p>
                                    </td>
                                    <td align='center' className='ms-500 py-1'>
                                        {result?.syllabus?.length > 0 && result?.syllabus[0]?.slideDetails?.length > 0 && <button className='btn my-1 text-white fs-14 bg-sky ms-700 px-2 py-1'
                                            onClick={
                                                () => {
                                                    setSlide({
                                                        ...slide,
                                                        open: true,
                                                        name: result?.syllabus[0]?.name,
                                                        file: result?.syllabus[0],
                                                    })
                                                }
                                            }
                                        >{t('general.attendance.viewLesson')}</button>}
                                    </td>
                                    <td align='center' className='ms-500 py-1'>
                                        <button className='btn my-1 text-white fs-14 bg-sky ms-700 px-2 py-1' onClick={(e) => setState({ ...state, menuRef: e.currentTarget, menuOpen: true, videoList: result.record_url && result.record_url.split("#") })}>{t('general.attendance.view')}</button>
                                    </td>
                                    <td align='center' className='ms-500 py-1'>
                                        <button className='btn my-1 text-white fs-14 bg-sky ms-700 px-2 py-1' onClick={() => setState({ ...state, chatLogOpen: true, chatRoomId: result.channel_name, name: result.name, pic: result.profilePicture })}>{t('general.attendance.view')}</button>
                                    </td>

                                </tr>
                            )}
                        <tr>
                            <td className='ms-500 py-1'>

                            </td>
                            <td className='ms-500 py-1'>

                            </td>
                            <td className='ms-500 py-1'>

                            </td>
                            <td className='ms-500 py-1'>

                            </td>
                            <td className='ms-500 py-1'>

                            </td>

                            <td align='center' className='ms-500 py-1'>
                                <p>Total</p>
                            </td>
                            <td className='ms-500 py-1'>
                                <p>{getTotalAttendance() + "/" + getNoOfDays(orgDate.st, orgDate.en)}</p>
                            </td>
                        </tr>
                    </table>
                    :

                    <p className='text-center my-2'>{t('messages.noRecordFound')}</p>}
            </section>

            <div className="row gx-0 mx-0  mt-4">
                <div className="col-lg-11 fs-14 ms-500 py-1">
                    <p>{t('general.attendance.sayDhadRemark')} <b>help@Saydhad.com</b>
                    </p>
                </div>
                <div className="col-lg-11 fs-14 ms-500 py-1">
                    <p>2022-01-01</p>
                </div>
                <div className="col-lg-11 fs-14 ms-500 py-1">
                    <p>{t('home.hero.sayDhad')} Inc.</p>
                </div>
            </div>
            {slide.open &&
                <StudentSlideShow
                    name={slide.name}
                    startIndex={0}
                    data={slide.file.slideDetails}
                    cb={() => setSlide({ ...slide, open: false })}
                />
            }

            {state.chatLogOpen &&
                <ChatPop
                    roomId={state.chatRoomId}
                    name={state.name}
                    pic={state.pic}
                    onClose={() => { setState({ ...state, chatLogOpen: false }) }}
                />
            }

            {
                state.openVideo &&
                <VideoOpen
                    onClose={() => setState({ ...state, openVideo: false })}
                    file={constant.AWS_S3_URL + state.videoFile}
                />
            }

            {pageload && <Backdrop />}

            <Menu
                anchorEl={state.menuRef}
                open={state.menuOpen}
                onClose={(e) => setState({ ...state, menuOpen: false })}>
                <div className='mobile-nav'>
                    <ul>
                        {state.videoList.map((video) => <li className='ms-500' onClick={() => setState({ ...state, openVideo: true, videoFile: video, menuOpen: false })}>{t('general.attendance.view')}</li>)}
                    </ul>
                </div>
            </Menu>
        </div>
    )
}
