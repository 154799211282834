import { constant } from '../config/Contant';
import { notifiIllegalActivity } from '../controller/common';
import axios from 'axios';
import helper from '.';
const BASE_URL = constant.BASE_URL

export const fileUploadToS3 = async (dirName, file) => {
    try {
        let form = new FormData()
        form.append("file", file)
        form.append("folder_name", dirName)
        const token = helper.getToken()

        const res = await axios({
            method: 'POST',
            url: `${BASE_URL}/upload-s3`,
            data: form,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        let url = ""
        if (res?.data?.status) {
            // url = res?.data?.result?.length ? `${constant.AWS_S3_URL}${res?.data?.result[0]}` : ""
            url = res?.data?.result?.length ? `${res?.data?.result[0]}` : ""
        }
        return url;
    } catch (err) {
        return ""
    }
}

export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export function blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}

export const encryptChatMessages = async (msg, remoteUserId, cb) => {
    let reg = new RegExp(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
    let inValidDetected = reg.test(msg.toLowerCase());
    if (inValidDetected) { /* Email detected */
        let newMsg = msg.replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi, " * * * * * ")
        newMsg = newMsg.replace(/([1-9]){6,}/g, " * * * * * * ")
        cb({ isValid: false, msg: newMsg })
        await notifiIllegalActivity(msg, remoteUserId)
        return
    }
    reg = new RegExp(/([1-9]){6,}/);
    inValidDetected = reg.test(msg.toLowerCase());
    if (inValidDetected) {  /* MobileNo detected */

        let newMsg = msg.replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi, " * * * * * ")
        newMsg = newMsg.replace(/([1-9]){6,}/g, " * * * * * * ")
        cb({ isValid: false, msg: newMsg })
        await notifiIllegalActivity(msg, remoteUserId)
        return
    }

    cb({ isValid: true, msg })
}
