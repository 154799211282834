import React from 'react'
import Path from '../static/images/home/Path.svg'
import SubscribeImg from '../static/images/home/block.svg'
import Girl2Img from '../static/images/home/Bitmap.png'
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import helper from '../helper'
export default function Subscribe() {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation();
    const history = useHistory()
    const handleClick = () => {
        // let resp = helper.validate("email", email.current.value)
        // if (!resp.isValid) { toast("please enter valid email"); return; }
        // else {
        //     let decEmail = helper.encrypt(email.current.value)
        //     history.push(`/login?token=${decEmail}`)
        // }
        const status = helper.isLoggedIn();
        if (status.res) {
            history.push(`/user/dashboard/tutor`)
        } else {
            history.push(`/login`)
        }
    }
    return (
        <div className="row d-flex">
            <div className="col-lg-1"></div>
            <div className="col-lg-6 px-3 left v-center order-lg-1 order-md-1 order-3">
                <div className={`${reduxState.language === 'ar' && "text-right"}`}>
                    <p style={{ opacity: 0.7 }} className='tx-lt-black ms-700'>{t('home.getStarted.get')}</p>
                    <div className={`tx-lt-black fs-24 ms-500 `}>
                        <p className='mt-3'>{t('home.getStarted.para1')}</p>
                        <p><span className='ms-900 mb-1'>{t('home.getStarted.name')}</span></p>
                        <p className='mb-3'>{t('home.getStarted.para2')}</p>
                    </div>
                    <div>
                        {/* <input placeholder='email' ref={email} type="text" className='f-fred' name="" id="" /> */}
                        <button className='btn find-tutor-btn d-block mt-4' onClick={handleClick}>{t('home.getStarted.button')}</button>
                    </div>
                </div>
            </div>
            <div className="col-lg-5 v-center  order-lg-3 order-md-3 order-1">
                <div className='d-flex'>
                    <div className='girl2-img-box'>
                        <img src={Path} className="cloud-img" alt='path' />
                        <img src={Girl2Img} className="mw-100 d-block mx-auto girl2-img" alt='girl' />
                        <div className="img overlay"></div>
                    </div>
                    <div>
                        <img src={SubscribeImg} className="mw-100 p-rel" style={{ zIndex: 2 }} alt="subscribe" />
                        <div className="img overlay"></div>
                    </div>
                </div>
            </div>
        </div>

    )
}
