import React from 'react'
import '../static/css/messagepop.css'
export default function Message({message,type}) {
    return (
        <div className="message-popup all-center">
            <div className={`message-pop-body f-fred px-2 py-1 ${type}`}>
                    {message}
            </div> 
        </div>
    )
}
