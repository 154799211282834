/* eslint-disable */
import axios from 'axios'
import { constant } from '../config/Contant'
const URL_AUTH = constant.BASE_URL

const tutorRegister = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/auth-tutor/registration`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

const tutorVerify = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/tutor/verify-account`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

const tutorLogin = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/tutor/login`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

const tutorSocialLogin = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/tutor/social-login`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

const userSocialLogin = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/auth/student-social-login`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

const userSocialRegistration = async (data,token)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/auth/social-registration`,data,
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return res;
    }
    catch(err){
        return err;
    }
}

const userRegister = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/auth/registration`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

const userLogin = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/login`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

const userVerify = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/verify-account`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

const tutorForgotPassword= async (data)=>{
    try{
        
        const res = await axios.post(`${URL_AUTH}/tutor/forgot-password/link`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

const tutorResetPassword= async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/tutor/reset-password`,

        data,
        {
            headers:{
                'Authorization':`Bearer ${data.token}`
            } 
        });
        return res;
    }
    catch(err){
        return err;
    }
}

const userForgotPassword= async (data)=>{
    try{
        
        const res = await axios.post(`${URL_AUTH}/forgot-password/link`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

const userResetPassword= async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/reset/password`,
        data,
        {
            headers:{
                'Authorization':`Bearer ${data.token}`
            } 
        });
        return res;
    }
    catch(err){
        return err;
    }
}

export const verifyUserEmail = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/auth/verify-email`,
        data,
        {
            headers:{
                'Authorization':`Bearer ${data.token}`
            } 
        });
        return res;
    }
    catch(err){
        return err;
    }
}


const resendOTP = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/auth/resend-otp`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

export default {
    tutorRegister,tutorVerify,tutorLogin,tutorSocialLogin,userSocialLogin,
    userRegister,userLogin,userVerify,tutorForgotPassword,tutorResetPassword,
    userForgotPassword,userResetPassword,userSocialRegistration,resendOTP
}