/* eslint-disable */
import React, { useEffect } from 'react'
import '../static/css/userDash.css'
import UserCalendar from './UserCalendar'
import UserChat from './UserChat'
import UserCourses from './UserCourses'
import UserHome from './UserHome'
import logo from '../static/images/Logo.svg'
import userLogo from '../static/images/user.svg'
import parot from '../static/images/logo-parot.svg'
import UserNotification from './UserNotification'
import { useHistory, useParams } from 'react-router'
import helper from '../helper/index'
import Support from '../tutorDash/Support'
import TutorsListing from './TutorsListing'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import About from '../legalpages/About'
import Privacypolicy from '../legalpages/Privacypolicy'
import TermsCondition from '../legalpages/TermsCondition'
import Header from './Header'
import MyProfile from './MyProfile'
import Subscription from './Subscription'
import MyCourses from './MyCourses'
import ScheduleClass from './ScheduleClass'
import UserTutors from './UserTutors'
import Referral from './Referral'
import UserCoursesDetails from './UserCoursesDetails'
import TutorsByCourse from './TutorByCourse'
import OpenTickets from '../support/OpenTickets'
import ArticleListing from '../support/ArticleListing'
import HowToVideo from '../support/HowToVideo'
import Faqs from '../tutorDash/Faqs'
import SvgChat from '../Icons/Chat'
import SvgDashboard from '../Icons/Dashboard'
import SvgStudent from '../Icons/Student'
import SvgLibrary from '../Icons/Library'
import SvgSupport from '../Icons/Support'
import SvgLogout from '../Icons/Logout'
import SvgStudents from '../Icons/Students'
import { FaAffiliatetheme } from "react-icons/fa";
import TutorsByReferral from './TutorByReferral'
import ReferralTutorProfile from './ReferralTutorProfile'
import { useTranslation } from "react-i18next";
import { Tooltip } from '@mui/material'
function UserDash() {
    const { t } = useTranslation();
    const storeData = useSelector(state => state)
    const history = useHistory()
    const { page, id, courseName } = useParams()

    useEffect(() => {
        let intervalResp = setInterval(() => {
            const resp = helper.checkSecurity("user")
            if (!resp.res) {
                clearInterval(intervalResp)
                history.push('/login')
            }
        }, 2000)
        return () => {
            try {
                clearInterval(intervalResp)
            } catch { }
        }
    }, [])

    return (
        <>
            <div className="userdash">
                <input type="checkbox" className='d-none' id="web-sideMenu" />
                <table className='w-100 my-0 py-0' >
                    <tr className='my-0 py-0'>
                        <td className='left-dash left-icon-sm-strip  hide-scroll'>
                            <div className='hide-scroll'>
                                <div className="all-center overlay-cowver my-2 mx-2">
                                    <img width="40px" className="pointer" onClick={() => history.push('/')} src={parot} alt="parrot" />
                                    <div className="p-abs overlay-text px-2 py-1" >
                                        SayDhad
                                    </div>
                                </div>
                                <ul className="mt-3 list-unstyled">

                                    <li onClick={() => history.push('/user/dashboard/home')} className={`${page === "home" && "active-tab-sm"}`}>
                                        <Tooltip title={t('userDash.sidebar.option1')} >
                                            <p className="f-fred text-upper"><SvgDashboard className="active-icon-color" /></p>
                                        </Tooltip>
                                    </li>
                                    <li onClick={() => history.push('/user/dashboard/tutor')} className={`${page === "tutor" && "active-tab-sm "}`}>
                                        <Tooltip title={t('userDash.sidebar.option2')} >
                                            <p className="f-fred text-upper"><SvgStudents className="active-icon-color" /></p>
                                        </Tooltip>
                                    </li>
                                    <li onClick={() => history.push('/user/dashboard/course')} className={`${page === "course" && "active-tab-sm "}`}>
                                        <Tooltip title={t('userDash.sidebar.option3')} >
                                            <p className="f-fred text-upper"> <SvgLibrary className="active-icon-color" /></p>
                                        </Tooltip>
                                    </li>
                                    <li onClick={() => history.push('/user/dashboard/calendar')} className={`${page === "calendar" && "active-tab-sm"}`}>
                                        <Tooltip title={t('userDash.sidebar.option4')} >
                                            <p className="f-fred text-upper"><SvgStudent className="active-icon-color" /></p>
                                        </Tooltip>
                                    </li>

                                    <li onClick={() => history.push('/user/dashboard/myprogress')} className={`${page === "myprogress" && "active-tab-sm "}`}>
                                        <Tooltip title={t('userDash.sidebar.option5')} >
                                            <p className="f-fred text-upper"><SvgLibrary className="active-icon-color" /></p>
                                        </Tooltip>
                                    </li>

                                    <li onClick={() => history.push('/user/dashboard/chat')} className={`${page === "chat" && "active-tab-sm "}`}>
                                        <Tooltip title={t('userDash.sidebar.option6')} >
                                            <p className="f-fred text-upper"><SvgChat className="active-icon-color" /></p>
                                        </Tooltip>
                                    </li>
                                    <li onClick={() => history.push('/user/dashboard/support')} className={`${page === "support" && "active-tab-sm "}`}>
                                        <Tooltip title={t('userDash.sidebar.option7')} >
                                            <p className="f-fred text-upper"><SvgSupport className="active-icon-color" /></p>
                                        </Tooltip>
                                    </li>
                                    <li onClick={() => history.push('/user/dashboard/referral')} className={`${page === "referral" && "active-tab-sm"}`}>
                                        <Tooltip title={t('userDash.sidebar.option8')} >
                                            <p className="f-fred text-upper"> <FaAffiliatetheme size={22} className="active-icon-color" /></p>
                                        </Tooltip>

                                    </li>

                                    <li onClick={() => { helper.logOut(); helper.showMessage(t('messages.auth.logout'), "success"); history.push('/login') }}>
                                        <Tooltip title={t('userDash.sidebar.option9')} >
                                            <p className="f-fred text-upper"><SvgLogout className="active-icon-color" /></p>
                                        </Tooltip>
                                    </li>

                                </ul>

                            </div>
                        </td>

                        <td className={`left-dash left-icon-lg-strip hide-scroll ${storeData.language === 'ar' && "text-right"}`}>
                            <div className='d-flex flex-column justify-content-between px-2 hide-scroll'>
                                <div>
                                    <div className="all-center pb-4 pt-3 p-sticky bg-white tp-0" style={{ zIndex: '1' }}>
                                        <img width="190px" onClick={() => history.push('/')} height={'68px'} src={logo} alt="logo" />
                                        <img width="25px" className='p-abs rt-0' style={{ bottom: '-10px' }} onClick={() => history.push('/')} src={userLogo} alt="logo" />
                                    </div>
                                    <ul className="mt-3 list-unstyled">
                                        <li id="step-ud-dash" onClick={() => history.push('/user/dashboard/home')} className={`${page === "home" && "active-tab"}`}>
                                            <div className="f-fred text-upper  d-flex align-items-center">
                                                <span className='icon-width d-flex align-items-center'>
                                                    <SvgDashboard className={`${page === "home" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                </span>
                                                <p>{t('userDash.sidebar.option1')}</p>
                                            </div>
                                        </li>
                                        <li id="step-ud-tutor" onClick={() => history.push('/user/dashboard/tutor')} className={`${page === "tutor" && "active-tab "}`}>
                                            <div className="f-fred text-upper d-flex align-items-center">
                                                <span className='icon-width d-flex align-items-center'>
                                                    <SvgStudents className={`${page === "tutor" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                </span>
                                                <p>{t('userDash.sidebar.option2')}</p>
                                            </div>
                                        </li>
                                        <li id="step-ud-course" onClick={() => history.push('/user/dashboard/course')} className={`${page === "course" && "active-tab "}`}>
                                            <div className="f-fred text-upper d-flex align-items-center">
                                                <span className='icon-width d-flex align-items-center'>
                                                    <SvgLibrary className={`${page === "course" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                </span>
                                                <p>{t('userDash.sidebar.option3')}</p>
                                            </div>

                                        </li>
                                        <li id="step-ud-calendar" onClick={() => history.push('/user/dashboard/calendar')} className={`${page === "calendar" && "active-tab"}`}>
                                            <div className="f-fred text-upper d-flex align-items-center">
                                                <span className='icon-width d-flex align-items-center'>
                                                    <SvgStudent className={`${page === "calendar" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                </span>
                                                <p>{t('userDash.sidebar.option4')}</p>
                                            </div>
                                        </li>

                                        <li id="step-ud-progress" onClick={() => history.push('/user/dashboard/myprogress')} className={`${page === "myprogress" && "active-tab "}`}>
                                            <div className="f-fred text-upper d-flex align-items-center">
                                                <span className='icon-width d-flex align-items-center'>
                                                    <SvgLibrary className={`${page === "myprogress" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                </span>
                                                <p>{t('userDash.sidebar.option5')}</p>
                                            </div>
                                        </li>

                                        <li id="step-ud-chat" onClick={() => history.push('/user/dashboard/chat')} className={`${page === "chat" && "active-tab "}`}>
                                            <div className="f-fred text-upper d-flex align-items-center">
                                                <span className='icon-width d-flex align-items-center'>
                                                    <SvgChat className={`${page === "chat" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                </span>
                                                <p>{t('userDash.sidebar.option6')}</p>
                                            </div>

                                        </li>
                                        <li id="step-ud-support" onClick={() => history.push('/user/dashboard/support')} className={`${page === "support" && "active-tab "}`}>
                                            <div className="f-fred text-upper d-flex align-items-center">
                                                <span className='icon-width'>
                                                    <SvgSupport className={`${page === "support" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                </span>
                                                <p>{t('userDash.sidebar.option7')}</p>
                                            </div>
                                        </li>
                                        <li id="step-ud-referral" onClick={() => history.push('/user/dashboard/referral')} className={`${page === "referral" && "active-tab"}`}>
                                            <div className="f-fred text-upper d-flex align-items-center">
                                                <span className='icon-width d-flex align-items-center'>
                                                    <FaAffiliatetheme size={22} className={`${page === "referral" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                </span>
                                                <p>{t('userDash.sidebar.option8')}</p>
                                            </div>
                                        </li>

                                        <li onClick={() => { helper.logOut(); helper.showMessage(t('messages.auth.logout'), "success"); history.push('/login') }}>
                                            <div className="f-fred text-upper d-flex align-items-center">
                                                <span className='icon-width d-flex align-items-center'>
                                                    <SvgLogout className={`inactive-icon-color`} />
                                                </span>
                                                <p>{t('userDash.sidebar.option9')}</p>
                                            </div>
                                        </li>

                                    </ul>
                                </div>

                                <div className="ms-700 fs-14 ml-3 mt-5">
                                    <Link to="/about-us" className="d-block"><span className='link-hover'>{t('userDash.footer.about')}</span></Link>
                                    <Link to="/user/dashboard/privacy-policy" className="d-block"><span className='link-hover'>{t('userDash.footer.privacy')}</span></Link>
                                    <Link to="/user/dashboard/terms-condition" className="d-block"><span className='link-hover'>{t('userDash.footer.terms')}</span></Link>
                                    <p className="mt-3 fs-12 mb-2">{t('userDash.footer.rights')}</p>
                                </div>
                            </div>
                        </td>
                        <td className='w-100 my-0 py-0'>
                            <div className="right-dash bg-white pb-3 px-2 mx-0 hide-scroll">
                                <Header />

                                {
                                    page === "home" ? <UserHome /> :
                                        page === "calendar" ? <UserCalendar /> :
                                            page === "course" && id ? <UserCoursesDetails /> :
                                                page === "course" ? <UserCourses /> :
                                                    page === "tutor" && id ? <UserTutors /> :
                                                        page === "tutor-by-course" ? <TutorsByCourse /> :
                                                            page === "tutor-by-referral" ? <TutorsByReferral /> :
                                                                page === "referral-tutor-profile" && id && courseName ? <ReferralTutorProfile /> :
                                                                    page === "schedule" && id ? <ScheduleClass /> :
                                                                        page === "tutor" ? <TutorsListing /> :
                                                                            page === "chat" ? <UserChat /> :
                                                                                page === "support" && id === "ticket" ? <OpenTickets /> :
                                                                                    page === "support" && id === "faq" ? <Faqs /> :
                                                                                        page === "support" && id === "how-to-videos" ? <HowToVideo /> :
                                                                                            page === "support" && id === "articles" ? <ArticleListing /> :
                                                                                                page === "support" ? <Support /> :
                                                                                                    page === "notification" ? <UserNotification /> :
                                                                                                        page === "profile" ? <MyProfile />
                                                                                                            : page === "about" ? <About />
                                                                                                                : page === "privacy-policy" ? <Privacypolicy />
                                                                                                                    : page === "terms-condition" ? <TermsCondition />
                                                                                                                        : page === "subscription" ? <Subscription /> :
                                                                                                                            page === "myprogress" ? <MyCourses /> :
                                                                                                                                page === "referral" ? <Referral /> : ""
                                }

                            </div>
                        </td>
                    </tr>

                </table>

                <button className={`${storeData.language === 'ar' ? "btn-ar-support" : "btn-support"} btn  py-2 px-4 ms-700`} onClick={() => history.push('/user/dashboard/support')} ><i className="fas mr-2 fa-question"></i> {t('general.support.supportText')}</button>
            </div>
        </>
    )
}

export default UserDash
