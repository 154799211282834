/* eslint-disable */
import { createStore } from 'redux'
import moment from 'moment'
const initialState = {
    isAuth: false,
    videoRoom: {
        load: false,
        userJoined: false
    },
    screenShare: false,
    headerText: "Dashboard",
    tutor: {
        tabNo: -1
    },
    userTab: -1,
    userText: "Dashboard",
    chatId: 0,
    showMenu: false,
    profilePic: null,
    isOnline: false,
    setFlag: 'in',
    name: '',
    calendarDate: new Date(),
    showLeftBar: false,
    planMinutes: 0,
    planLeftMinutes: 0,
    newNotification: 0,
    pushNotification: {
        msg: '',
        title: '',
        open: false
    },
    classNotification: {
        msg: '',
        title: '',
        channelName: '',
        open: false,
        data: ''
    },
    loadWindow: false,
    alertClassPopup: {
        data: null,
        open: false,
        type: ''
    },
    analyticsDate: {
        start: moment(new Date(new Date().setDate(new Date().getDate() - 30))),
        end: moment(new Date())
    },
    liveClassDetails: {
        isRecordingStart: false
    },
    remoteUserName: '',
    language: "en",
    utilizedAmount: 0
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case 'auth':
            return { ...state, isAuth: action.payload }
        case 'videoRoom':
            return { ...state, videoRoom: { ...action.payload } }
        case 'screenShare':
            return { ...state, screenShare: action.payload }
        case 'headertext':
            return { ...state, headerText: action.payload }
        case 'tutor':
            return { ...state, tutor: action.payload }
        case 'chatId':
            return { ...state, chatId: action.payload }
        case 'userTab':
            return { ...state, userTab: action.payload }
        case 'userText':
            return { ...state, userText: action.payload }
        case 'showMenu':
            return { ...state, showMenu: action.payload }
        case 'setProfilePic':
            return { ...state, profilePic: action.payload }
        case 'setFlag':
            return { ...state, setFlag: action.payload }
        case 'name':
            return { ...state, name: action.payload }
        case 'isOnline':
            return { ...state, isOnline: action.payload }
        case 'calendarDate':
            return { ...state, calendarDate: action.payload }
        case 'showLeftBar':
            return { ...state, showLeftBar: action.payload }
        case 'planMinutes':
            return { ...state, planMinutes: action.payload }
        case 'newNotification':
            return { ...state, newNotification: action.payload }
        case 'planLeftMinutes':
            return { ...state, planLeftMinutes: action.payload }
        case 'pushNotification':
            return { ...state, pushNotification: action.payload }
        case 'classNotification':
            return { ...state, classNotification: action.payload }
        case 'loadWindow':
            return { ...state, loadWindow: action.payload }
        case 'alertClassPopup':
            return { ...state, alertClassPopup: action.payload }
        case 'analyticsDate':
            return { ...state, analyticsDate: action.payload }
        case 'liveClassDetails':
            return { ...state, liveClassDetails: action.payload }
        case 'language':
            return { ...state, language: action.payload }
        case 'utilizedAmount':
            return { ...state, utilizedAmount: action.payload }
        case 'remoteUserName':
            return { ...state, remoteUserName: action.payload }
        default:
            return state;
    }
}

const store = createStore(reducer)

function setIsAuth(value) {
    store.dispatch({ type: 'auth', payload: value })
}
function setVideoRoom(data = { load, userJoined }) {
    store.dispatch({ type: 'videoRoom', payload: { ...data } })
}
function setScreenShare(value) {
    store.dispatch({ type: 'screenShare', payload: value })
}
function setHeaderText(value) {
    store.dispatch({ type: 'headertext', payload: value })
}
function setTutor({ tab }) {
    store.dispatch({
        type: 'tutor',
        payload: {
            tabNo: tab,
        }
    })
}

function setchatId(value) {
    store.dispatch({
        type: 'chatId',
        payload: value
    })
}
function setUserTab({ tab }) {
    store.dispatch({
        type: 'userTab',
        payload: tab
    })
}
function setUserText({ text }) {
    store.dispatch({
        type: 'userText',
        payload: text
    })
}
function setShowMenu(value) {
    store.dispatch({
        type: 'showMenu',
        payload: value
    })
}

function setProfilePic(value) {
    store.dispatch({
        type: 'setProfilePic',
        payload: value
    })
}

function setIsOnline(value) {
    store.dispatch({
        type: 'isOnline',
        payload: value
    })
}
function setFlag(value) {
    store.dispatch({
        type: 'setFlag',
        payload: value
    })
}
function setName(value) {
    store.dispatch({
        type: 'name',
        payload: value
    })
}

function setCalendarDate(value) {
    store.dispatch({
        type: 'calendarDate',
        payload: value
    })
}
function setShowLeftBar(value) {
    store.dispatch({
        type: 'showLeftBar',
        payload: value
    })
}
function setPlanMinutes(value) {
    store.dispatch({
        type: 'planMinutes',
        payload: value
    })
}

function setNewNotification(value) {
    store.dispatch({
        type: 'newNotification',
        payload: value
    })
}

function setPlanLeftMinutes(value) {
    store.dispatch({
        type: 'planLeftMinutes',
        payload: value
    })
}

function setUtilizedAmount(value) {
    store.dispatch({
        type: 'utilizedAmount',
        payload: value
    })
}

function setPushNotification({ msg, title, open }) {
    store.dispatch({ type: 'pushNotification', payload: { msg: msg, title: title, open: open } })
}

function setClassNotification({ msg, title, open, channelName, data }) {
    store.dispatch({ type: 'classNotification', payload: { msg: msg, title: title, open: open, channelName: channelName, data: data } })
}
function setLoadWindow(value) {
    store.dispatch({ type: 'loadWindow', payload: value })
}
function setAlertClassPop({ data, open, type }) {
    store.dispatch({ type: 'alertClassPopup', payload: { open: open, data: data, type: type } })
}
function setAnalyticDate({ start, end }) {
    store.dispatch({ type: 'analyticsDate', payload: { start: start, end: end } })
}
function setLiveClassDetails({ isRecordingStart }) {
    store.dispatch({ type: 'liveClassDetails', payload: { isRecordingStart: isRecordingStart } })
}

function setLng(value) {
    store.dispatch({
        type: 'language',
        payload: value
    })
}


export function setRemoteUserName(value) {
    store.dispatch({
        type: 'remoteUserName',
        payload: value
    })
}

export default {
    setIsAuth, store, setVideoRoom, setScreenShare, setHeaderText,
    setTutor, setchatId, setUserTab, setUserText, setShowMenu,
    setProfilePic, setIsOnline, setFlag, setCalendarDate, setShowLeftBar,
    setPlanMinutes, setPushNotification, setPlanLeftMinutes, setNewNotification, setClassNotification,
    setLoadWindow, setAlertClassPop, setAnalyticDate,
    setLiveClassDetails, setName, setLng, setUtilizedAmount
}