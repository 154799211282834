import React from 'react'
import logo from '../static/images/Logo.svg'
import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import LanguageTranslator from '../component/LanguageTranslator';
import UserTour from '../webtour/UserTour';
import { homeSteps } from '../webtour/steps';
import helper from '../helper';
import { useState, useEffect } from 'react';
export default function WebHeader({ headerFor }) {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    const pathName = window.location.pathname
    const [authState, setAuthState] = useState({ url: "", login: false })


    useEffect(() => {
        const resp = helper.isLoggedIn()
        if (resp.res && resp.role === "tutor") {
            const status = helper.getProfileStatus();
            if (status === "Approved") {
                setAuthState({ url: "/tutor/dashboard/home", login: true })
            }
            else {
                setAuthState({ url: "/tutor/signupcheck", login: true })
            }
        } else if (resp.res && resp.role === "user") {
            setAuthState({ url: "/user/dashboard/home", login: true })
        }
        return () => { }
    }, [])



    return (
        <header className='web-header'>
            <div className='row gx-0 web-header-body'>
                <div className="col-lg-4  col-md-5 col-5">
                    <div className='mb-3 d-flex align-items-center'>
                        <NavLink to="/"><img className='logo mw-100' src={logo} alt="logo" /></NavLink>
                    </div>
                    <div className='d-lg-none d-md-none'>
                        <LanguageTranslator />
                    </div>
                </div>
                <div className={`col-lg-8 navigation col-md-7 col-7 ${reduxState.language === 'ar' && 'text-left'}`}>
                    <div>
                        <ul>
                            <li className="d-lg-inline-block d-md-inline-block d-none mr-3"><Link id="step-forstudent" to="/" className={`${pathName === "/" && "active-web-link"} `}>{t('home.nav.forStudent')}</Link></li>
                            <li className="d-lg-inline-block d-md-inline-block d-none mr-3"><Link id="step-fortutor" to="/tutor-knowlegde" className={`${pathName === "/tutor-knowlegde" && "active-web-link"} `}>{t('home.nav.forTutor')}</Link></li>
                            <li className='d-lg-inline-block h-100 d-md-inline-block d-none text-left mr-3'><span id="step-language"><LanguageTranslator /></span></li>
                            <li className={`${reduxState.language === 'ar' && 'mr-3'}`}>
                                <>
                                    {
                                        authState.login ? <Link className='web-login btn' to={authState.url}>{t('home.nav.goToDash')}</Link> :
                                            headerFor === "Tutor" ?
                                                <Link id="step-login" className='web-login btn' to={'/tutor/login'}>{t('home.nav.loginsignup')}</Link>
                                                :
                                                <Link id="step-login" className='web-login btn' to={'/login'}>{t('home.nav.loginsignup')}</Link>
                                    }
                                </>

                            </li>


                        </ul>
                    </div>
                </div>
            </div>

            <UserTour steps={homeSteps} />
        </header>
    )
}
