/* eslint-disable */
import React, { useState, useEffect } from 'react'
import Calendar from 'react-calendar';
import user from '../controller/user'
import moment from 'moment';
import ViewTutorSlots from '../usercalendar/ViewTutorSlots';
import helper from '../helper/index';
import CourseCard from '../component/CourseCard'
import ReactStars from "react-rating-stars-component";
import avatar from '../static/images/avatar.png'
import { useHistory, useParams } from 'react-router'
import Backdrop from '../component/Backdrop';
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
export default function ScheduleClass() {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation();
    const { id } = useParams()
    const lastDate =  helper.getPlanDetails()
    const [state, setState] = useState({
        resData: [],
        pageload: true,
        err: false,
        openSlots: false,
        slotData: null,
        date: null,
        tutorDetails: null
    })
    const history = useHistory()
    const [tutor, setTutor] = useState({
        load: true,
        data: null
    })

    useEffect(() => {
        apiCall(id)
        getTutorDetails(id)
        return () => { }
    }, [])

    const apiCall = async (id) => {
        await user.getTutorAvailability(id)
            .then((res) => {
                if (res.data.status) {
                    setState({ ...state, resData: res.data.result, pageload: false })
                } else {
                    setState({ ...state, resData: [], pageload: false })

                }
            }).catch((err) => {
                setState({ ...state, resData: [], err: err, pageload: false })
            })
    }

    const getTutorDetails = async (id) => {
        await user.getTutorById(id, helper.getid())
            .then((res) => {
                if (res.data.status) {
                   
                    setTutor({ ...tutor, load: false, data: res.data.result[0] })

                }
            })
    }

    const onChangeCalendar = (day) => {

        setState({ ...state, slotData: state.resData, openSlots: true, date: moment(day).format('YYYY-MM-DD') })

    }
    return (
        <div className={`schedule-class ${reduxState.language === 'ar' && "text-right"}`}>
            <div className="row">
                <div className="col-lg-10 col-12 mx-auto">
                    {/* show by default calendar  */}
                    {!state.openSlots &&
                        <>
                            <button className="btn back-btn py-1 ms-700 fs-14 px-3 mr-2 mb-3" onClick={() => history.goBack()}>{t('general.back')}</button>

                            {!tutor.load &&
                                <div className={`d-flex tutor-big-card b-grey bg-white mb-2 px-2 p-stiscky tp-0`} style={{ zIndex: '1' }}>

                                    <div className="all-center my-2">
                                        <div className='p-rel mr-4'>
                                            <img style={{ height: '70px', width: '70px' }} className="rounded-circle mx-1" src={tutor.data.profilePicture ? tutor.data.profilePicture : avatar} alt="avatar" />
                                            <div className={`${tutor.data.isOnline ? "online-tag" : "offline-tag"} p-abs bt-0 rt-0`}></div>
                                        </div>
                                    </div>
                                    <div className='w-100 d-flex justify-content-between'>
                                        <div className="my-2 pl-2">
                                            <p className="text-capitalize fs-18 f-fred">{tutor.data.name}</p>
                                            <p className="lh-18 fs-14 ms-500">Tutor from <span className='ms-900 text-capitalize'>{tutor.data.from}</span></p>
                                            <div className="d-flex align-items-center">
                                                <div className="d-inline-block py-0 my-0 mr-1">
                                                    <ReactStars
                                                        edit={false}
                                                        value={tutor.data.ratings ? parseFloat(tutor.data.ratings).toFixed(1) : 0}
                                                        count={5}
                                                        size={24}
                                                        isHalf={true}
                                                        emptyIcon={<i className="far fa-star"></i>}
                                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                        fullIcon={<i className="fa fa-star"></i>}
                                                        classNames="my-0 py-0"
                                                        activeColor="#ffcb00"
                                                    />
                                                </div>
                                                <span className="tx-black ms-700 fs-18">{tutor.data.ratings ? parseFloat(tutor.data.ratings).toFixed(1) : 0}</span>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-end justify-content-end p-rel">
                                            {helper.getDateDifference(tutor.data.createdOn) < 30 && <p className="new-flag px-2 fs-18 ms-500">New</p>}
                                            <span className="p-2">

                                                <img width="30px" height="30px" className="rounded-circle" src={tutor.data.flag_uri}
                                                    alt="flag" />

                                            </span>
                                        </div>
                                    </div>
                                </div>}
                            <Calendar
                                // minDetail={"month"}
                                onChange={(day) => { onChangeCalendar(day) }}
                                tileClassName="tilename"
                                className="w-100 cl-body b-grey  br-1 mt-3 ms-700"
                                navigationAriaLabel="go up"
                                minDate={new Date()}
                                maxDate={new Date( lastDate ? lastDate.data.renewOn : moment().endOf('month'))}
                                showNavigation={true}
                                // maxDetail='month'
                                tileDisabled={({ date }) => {
                                    let minDate = moment(new Date()).format('YYYY-MM-DD')
                                    let formatedDate = moment(date).format('YYYY-MM-DD')
                                    if (formatedDate < minDate) {
                                        return true
                                    }
                                    let find = false
                                    state.resData.map((data) => {
                                        if (data.date == moment(date).format('YYYY-MM-DD')) {
                                            find = true
                                        }
                                    })
                                    if (find) {
                                        return false
                                    }
                                    else {
                                        return true
                                    }
                                }}
                                tileContent={({ date }) => {
                                    let find = false
                                    state.resData.map((data) => {
                                        if (data.date == moment(date).format('YYYY-MM-DD')) {
                                            find = true
                                        }
                                    })
                                    if (find) {
                                        return <div className='all-center mt-2 p-rel' >

                                            <p className='p-rel' style={{ borderBottom: '3px solid green', borderRadius: '10px', width: '20px' }}>
                                                <i className='fas fa-clock  fs-12 p-abs tx-green' style={{ bottom: '0px', right: '-10px' }}></i>
                                            </p>
                                        </div>
                                    }
                                }}
                            />
                            {state.resData.length != 0 && <p className='text-center ms-500 my-3 pointer'><span className='link-hover' onClick={() => { setState({ ...state, slotData: state.resData, openSlots: true, date: null }) }}>{t('general.calendar.viewAllTime')} </span></p>}

                            {tutor.data && tutor.data.course.length != 0 &&
                                <section className='courses row'>
                                    <div className="col-12 my-3">
                                        <h4 className='ms-700'>
                                            {tutor.data && tutor.data.name} {t('general.calendar.teachCourses')}
                                        </h4>
                                        <hr className='my-2' />
                                    </div>
                                    {
                                        tutor.data && tutor.data.course.map((courses, index1) => {

                                            return (
                                                <div key={`course+${index1}`} className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                                    <CourseCard
                                                        name={courses.title}
                                                        subTitle={courses.subTitle}
                                                        lesson={courses.courseLength}
                                                        slides={courses.noOfSlides}
                                                        isRemove={false}
                                                        img={courses.imgName}
                                                        onChange={() => { history.push(`/user/dashboard/course/${courses.id}`) }}
                                                    />
                                                </div>
                                            )

                                        })
                                    }
                                </section>
                            }

                        </>
                    }


                    {
                        state.openSlots &&
                        <ViewTutorSlots
                            cb={() => setState({ ...state, openSlots: false })}
                            close={() => setState({ ...state, openSlots: false })}
                            data={state.slotData}
                            date={state.date}
                        />
                    }

                    {state.pageload && <Backdrop />}
                </div>
            </div>
        </div>
    )
}
