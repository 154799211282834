import React, { useEffect } from 'react'
import helper from '../helper'
import TermsCondition from '../legalpages/TermsCondition'
import WebFooter from './WebFooter'
import WebHeader from './WebHeader'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
export default function WebTerms() {
  const reduxState = useSelector(state => state)
  const { t } = useTranslation()
  useEffect(() => {
    helper.scroll()
  }, [])
  return (
    <React.Fragment>
      <Helmet>
        <title>Terms and Conditions – SayDhad</title>
        <meta name="description" content='SayDhad is an online Arabic language service provider. We connect many people who want to learn Arabic with highly qualified professionals. We adhere to strict Saudi law guidelines regarding any dispute with our website. Browse our terms and conditions page and learn more about our policy.' />
        <link rel="canonical" href=" https://saydhad.com/web/terms-condition/" />
      </Helmet>
      <div>
        <section className='row banner-section '>
          <div className="col-lg-11 col-12 mx-auto">
            <WebHeader />
            <section className={`margin-top ms-500 fs-16 mb-5 px-3 ${reduxState.language === 'ar' && "text-right"}`}>
              <div className="row ms-500">
                <div className="col-lg-10 col-12 mx-auto  py-2">
                  <h2 className='mb-3 tx-lt-grey f-fred'>{t('legalPages.terms&Condition.heading')}</h2>
                </div>
              </div>
              <TermsCondition />
            </section>
            <WebFooter />
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}
