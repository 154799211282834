import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
export default function CourseCard({ title, pic, subTitle, onClick, isEnroll, slides, isExpand, onEnrolled, isTutorEnrolled, completedSlide, showProgress }) {
    return (
        <>
            <section className={`library-card b-grey br-1 row mb-2 ${isExpand ? "bg-lt-grey" : "bg-white"}  py-2 pointer  px-2`} onClick={() => { if (completedSlide !== slides) { onClick() }else{alert("Course already has been completed")} }}>
                <div className="col-4">
                    <img className="w-100 h-100 br-1" src={pic} alt="library" />
                </div>
                <div className="col-8 pl-2 tx-lt-grey">
                    {showProgress &&
                        <div className="ms-900 text-capitalize">
                            <div className="row">
                                <div className="col-9 d-flex align-items-center">
                                    <p> {title}</p>
                                </div>
                                <div className="col-3  d-flex justify-content-end">
                                    <div className='all-center' style={{ width: '40px', height: '40px' }}>
                                        <CircularProgressbar
                                            strokeWidth={7}
                                            text={parseInt((completedSlide / slides) * 100).toFixed(0)}
                                            value={parseInt((completedSlide / slides) * 100).toFixed(0)}
                                            styles={buildStyles({
                                                textSize: '30px',
                                                pathColor: `#f88`,
                                                textColor: '#f88',
                                                trailColor: '#d6d6d6',
                                                backgroundColor: '#f88',
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="d-flex justify-content-between align-items-center">
                        <div >
                            <p className="fs-14 ms-700 text-capitalize">{subTitle}</p>
                            <p className="ms-500 tx-lt-grey fs-12">Slides  <span className='ms-900'>{slides}</span></p>
                        </div>
                        <div className="">
                        </div>
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center">
                    {!isEnroll ? <button className="btn px-2 bg-sky text-white py-1 px-2 ms-700 fs-12 mt-2" style={{ maxWidth: '100%' }} onClick={onEnrolled && onEnrolled}>ENROLL STUDENT</button> : <div></div>}
                    <div>
                        {isTutorEnrolled && <i className="fas fa-graduation-cap mx-2 fs-20"></i>}
                        {isExpand ?
                            <i className="fas fa-angle-down  pointer fs-20" ></i>
                            :
                            <i className="fas fa-angle-right pointer fs-20" ></i>
                        }
                    </div>
                </div>

            </section>
        </>
    )
}
