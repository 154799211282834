import React from 'react';
import avatar from '../static/images/avatar.png'
import { useTranslation } from "react-i18next";
export default function AttendanceCard({name,course,country,callDuration,time,date,onClick ,img}) {
    const { t } = useTranslation();
    return (<>
        <section className={`notify-card fs-14 b-grey p-2 my-2 row gx-0 mx-0 fs-14 ms-500`}>
            <div className="col-lg-2 wrap col-md-4  col-6 d-flex align-items-center mb-2">
                <img width="50px" height="50px" className="rounded-circle mr-2" src={img ? img : avatar} alt="avatar" />
                <div className="mx-3">
                    <p className="f-fred lh-15">{name}</p>
                    <p className="ms-500 ">{t('tutorDash.payout.student')}</p>
                </div>
            </div>
            <div className="col-lg-1 col-md-4 d-none col-4 d-lg-flex d-md-flex align-items-center mb-2">
                <div className="mx-2">
                    <p className="f-fred lh-15">{country}</p>
                    <p className="ms-500">{t('tutorDash.payout.country')}</p>
                </div>
            </div>
            <div className="col-lg-5 col-md-4 col-6 d-flex align-items-center mb-2">
            <div className="mr-3">
                    <p className="f-fred lh-15">{course}</p>
                    <p className="ms-500 ">{t('tutorDash.payout.course')}</p>
                </div>
            </div>

            <div className="col-lg-1 col-md-4 col-6 d-flex align-items-center mb-2">
            <div className="mr-3">
                    <p className="f-fred lh-15">{callDuration}</p>
                    <p className="ms-500 ">{t('tutorDash.payout.callDur')}</p>
                </div>
            </div>
            <div className="col-lg-1 col-md-4 col-6 d-flex align-items-center mb-2">
            <div className="mr-3">
                    <p className="f-fred lh-15 ltr">{date}</p>
                    <p className="ms-500 ">{t('tutorDash.payout.schedule')}</p>
                </div>
            </div>
            <div className="col-lg-1 col-md-4 d-none col-4 d-lg-flex d-md-flex align-items-center mb-2">
            <div className="mr-2">
                    <p className="f-fred lh-15 ltr">{time}</p>
                    <p className="ms-500 ">{t('tutorDash.payout.time')}</p>
                </div>
            </div>

            <div className="col-lg-1 col-12 d-flex align-items-center">
                <button className="btn btn-detail w-100 py-1 ms-700 text-white fs-14" onClick={()=>onClick()} >{t('tutorDash.myStudent.chatLog')}</button>
            </div>
        </section>
    </>);
}
