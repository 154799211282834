/* eslint-disable */
import axios from 'axios'
import helper from '../helper/index'
import { constant } from '../config/Contant'
const BASE_URL = constant.BASE_URL

const getCourseOld = () => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/course-detail`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getCourseListing = (userId) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/course/course-detail-list?user_id=${userId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getCourseById = (id, studentId) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/course/find-course-detail-by-id?courseId=${id}&user_id=${studentId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}
const getMyProfile = (id) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/student/profile/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getTutorByCourseId = (id) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/student/course/find-tutor-by-courseId?courseId=${6}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}



const getTutorAvailabilityByDate = (id, date) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/find-student-slot-booked?tutor_id=${id}&date=${date}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const getTutorAvailability = (id) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/student/find-tutor-availability?tutor_id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getUserBreakedSlots = (tid, sid, stTime, enTime, date) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/student/tutor-availability-slot?student_id=${sid}&tutor_id=${tid}&start_time=${stTime}&end_time=${enTime}&date=${date}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getTutorById = (tid, sid) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/student/tutor-details-by-Id?student_id=${sid}&tutor_id=${tid}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const resetPassword = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/auth/change-password`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const bookClass = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `post`,
            url: `${BASE_URL}/student/tutor/booking-slot`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const cancelClass = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `post`,
            url: `${BASE_URL}/student/cancel-booking-slot`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const getBookedClasses = async (id, date) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/find-booking-slot?student_id=${id}&date=${date}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getMonthlyBookedClasses = async (id, date) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/upcoming-booking-class?student_id=${id}&date=${date}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getBookedClassesByDate = async (id, date) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/upcoming-booking-class-by-date?student_id=${id}&date=${date}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getUserNotifications = async (user_id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/notifications-list/${user_id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const markAsRead = async (user_id, notification_id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `put`,
            url: `${BASE_URL}/student/notification/mark-as-read`,
            data: { user_id: user_id, id: notification_id },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getNotificationDetail = async (user_id, notification_id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/notifications-by-Id?user_id=${user_id}&id=${notification_id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getTutor = async () => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/tutor-search?name=`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getTutorsByFilter = async (courseTitle, experienceLevel, isOnline, accent) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/tutor-list/search?course_title=${courseTitle}&experience_level=${experienceLevel}&isOnline=${isOnline}&language=${accent}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getChatTutorList = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/tutor-chat-list-by-studentId/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const getTutorUpcomingClasses = async (tutor_id, student_id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/chat/find-upcoming-class-by-studentId?tutor_id=${tutor_id}&student_id=${student_id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const userClassend = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `put`,
            url: `${BASE_URL}/student/add-booking-class-time`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const userDirectClassend = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `put`,
            url: `${BASE_URL}/student/add-direct-class-time`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const giveRating = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `put`,
            url: `${BASE_URL}/student/add-ratings-tutor`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const addProfileView = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `post`,
            url: `${BASE_URL}/student/tutor-profile-views`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const changePic = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `put`,
            url: `${BASE_URL}/student/profile-picture`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const editProfile = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `put`,
            url: `${BASE_URL}/student/profile`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const changePassword = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `post`,
            url: `${BASE_URL}/auth/change-password`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getBankInfo = async (studentId) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/card-details/${studentId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const editBankInfo = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `put`,
            url: `${BASE_URL}/student/edit-card-details`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getRatings = async (id, page, limit) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/testimonial-details?studentId=${id}&page=${page}&limit=${limit}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getTutorByName = async (name) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/tutor-search?name=${name}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getFavouriteTutor = async (userId) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/favourite-tutor-details/${userId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}
const addToFavourite = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `post`,
            url: `${BASE_URL}/student/add-favorite-tutor`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const removeFromFavourite = async (tid, sid) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `delete`,
            url: `${BASE_URL}/student/remove-favorite-tutor?tutorId=${tid}&studentId=${sid}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const getSubscription = async (min, week) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/subscription-plan?minutes_per_day=${min}&days_per_week=${week}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getSubscriptionOffers = async () => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/subscription/minutes-per-day-list`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getSubscriptionCalc = async (body) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `POST`,
            url: `${BASE_URL}/student/subscription-plan/calculation`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data:body
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const purchasePlan = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `post`,
            url: `${BASE_URL}/student/purchase-plan`,
            // url: `${BASE_URL}/student/subscription-plan`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const cancelPlan = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `post`,
            url: `${BASE_URL}/student/cancel-subscription`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getMyPlan = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/currently-subscription-plan?student_id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getAnalytics = async (id, date) => {
    try {

        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/total-subscription-minutes-left?student_id=${id}&date=${date}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const enrolledIntoCourse = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `post`,
            url: `${BASE_URL}/student/enroll-courses`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getEnrolledCourses = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/student-enrolled-course-list/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const getCourseTitle = async () => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/course/course-title-list`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

export const getCourseTitleByTutorId = async (tid) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/tutor/course/course-title-list?tutor_id=${tid}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getLeftMinutes = async (uid) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/subscription-plan-reset-per-day-minute-details?user_id=${uid}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const getProgressAnalytics = async (uid) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/progress-details/${uid}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const getAttendanceRecord = async (uid, st, en) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/attendance-history?student_id=${uid}&start_date=${st}&end_date=${en}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const shareReferralCode = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `post`,
            url: `${BASE_URL}/student/get-free-minutes`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getSharedReferral = async (uid) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/shared-referral-details-list?student_id=${uid}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}




const pauseResumePlan = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `post`,
            url: `${BASE_URL}/student/pause-subscription`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}



const getReferralClasses = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/referral-minutes-list?user_id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getCertificate = async ({ studentId, courseId }) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/get-certificate?student_id=${studentId}&course_id=${courseId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            responseType: 'blob'
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getReferralDetailById = async ({ studentId, referralId }) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: `get`,
            url: `${BASE_URL}/student/referral-minutes-details-by-id?student_id=${studentId}&id=${referralId}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const contactUs = async (data) => {
    try {
        const res = await axios({
            method: `post`,
            url: `${BASE_URL}/student/contact-us`,
            data: data
        })
        return res
    }
    catch (err) {
        return err;
    }
}




export default {
    getCourseOld, getCourseListing, getCourseById, getMyProfile, getTutorByCourseId, getTutorAvailabilityByDate,
    resetPassword, bookClass, cancelClass, getBookedClasses, markAsRead, getUserNotifications, getTutor, getTutorsByFilter,
    getChatTutorList, getTutorUpcomingClasses, userClassend, giveRating, addProfileView,
    changePic, editProfile, changePassword, getNotificationDetail, getBankInfo, editBankInfo, getRatings,
    getTutorByName, getFavouriteTutor, addToFavourite, removeFromFavourite, getSubscription, purchasePlan, getMyPlan, cancelPlan, getAnalytics,
    enrolledIntoCourse, getEnrolledCourses, getTutorAvailability, getUserBreakedSlots, getTutorById, getMonthlyBookedClasses, getBookedClassesByDate,
    getCourseTitle, getLeftMinutes, getProgressAnalytics, getAttendanceRecord, shareReferralCode, pauseResumePlan,
    userDirectClassend, getSharedReferral, getReferralClasses, getCertificate, getSubscriptionOffers, getSubscriptionCalc, getReferralDetailById, contactUs
}