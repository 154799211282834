/* eslint-disable */
import moment from 'moment'
import React, { useState, useEffect, useRef } from 'react'
import user from '../controller/user'
import helper from '../helper/index'
import AlertPopUp from '../component/AlertPopUp';
import { useHistory, useParams } from 'react-router-dom';
import Backdrop from '../component/Backdrop';
import avatar from '../static/images/avatar.png'
import { useTranslation } from "react-i18next";
export default function BookClass({ close, data, date, tutor_id }) {
    const { t } = useTranslation();
    const history = useHistory()
    const [load, setLoad] = useState(false)
    const { courseName } = useParams()
    const [state, setState] = useState({
        data: null,
        pageload: true,
        error: null,
        btnEnable: false,
        openMsg: false,
        msg: '',
        type: 'ok'
    })
    const startTime = useRef()
    const reason = useRef()

    useEffect(() => {
        apiCall()
        return () => { }
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const apiCall = async () => {
        await user.getUserBreakedSlots(tutor_id, helper.getid(), data.start_time, data.end_time, date)
            .then((res) => {
                if (res.data.status) {
                    setState({ ...state, pageload: false, data: res.data.result })
                } else {
                    setState({ ...state, pageload: false, data: null, error: res.data.error })
                }
            }).catch((err) => {
                setState({ ...state, pageload: false, data: null, error: err })

            })
    }

    const bookClass = async () => {
        if (!startTime.current.value) {
            helper.showMessage("Please select class timing","warning")
           
            return
        }
        else if (!reason.current.value) {
            helper.showMessage("What would you learn.","warning")
          
            return
        }
        else if (reason.current.value.split(' ').length > 50) {
            helper.showMessage("Text cannot be grater than 50 words","warning")
            return
        }

        setLoad(true)
        const reqData = {
            start_time: moment(startTime.current.value, 'hh:mm').format('HH:mm'),
            end_time: moment(startTime.current.value, 'hh:mm').add(state.data.minutes, 'minutes').format('HH:mm'),
            student_id: helper.getid(),
            tutor_id: tutor_id,
            date: moment(date).format('YYYY-MM-DD'),
            reason: reason.current.value,
            userType: "student",
            slot_minutes: state.data.minutes,
            courseName: courseName
        }

        await user.bookClass(reqData)
            .then((res) => {
                if (res.data.status) {
                    setState({
                        ...state,
                        openMsg: true,
                        type: 'ok',
                        msg: `Congratulations! Remember to arrive a few minutes early to your reservation on ${moment(date).format('MMM DD, YYYY')} at ${moment(startTime.current.value, 'hh:mm').format('hh:mm')}`
                    })
                } else {
                    setState({
                        ...state,
                        openMsg: true,
                        type: 'fail',
                        msg: res.data.error
                    })
                }
                setLoad(false)
            }).catch((err) => {
                setState({
                    ...state,
                    openMsg: true,
                    type: 'fail',
                    msg: err
                })

            })
    }
    return (
        <div className='book-class'>
            <button className='btn back-btn py-1 fs-14 ms-700 px-lg-3 px-1  mr-2 mb-3' onClick={close}>{t('general.back')}</button>
            {!state.pageload && !state.error ?
                <section className='row'>

                    <div className="col-lg-8 mx-auto">
                        <div className="row">
                            <div className='col-lg-2 col-3 mb-3 all-center'>
                                <img width={'50px'} height={'50px'} className='rounded-circle mr-3' src={state.data.profilePicture ? state.data.profilePicture : avatar} alt="avatar" />
                            </div>
                            <div className='col-lg-10 col-9 mb-3'>
                                <p className='ms-900 fs-18'>{state.data.name}</p>
                            </div>
                            <div className='col-lg-2 col-2 mb-3 all-center'>
                                <i className="fas fs-24 fa-calendar-day tx-grey"></i>
                            </div>
                            <div className='col-lg-10 ms-700 col-10 mb-3 tutor-dash h-100'>
                                <span className='mr-3'>{moment(date).format('MMMM DD, YYYY')}</span>
                                <select ref={startTime} className='py-2 input-box p-2 ltr' style={{ minWidth: '200px' }}>
                                    <option value="">{t('general.calendar.selectTiming')}</option>
                                    {state.data.slot.map((data) => (moment(date).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) || (moment(date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') && moment(data.slot_time, 'HH:mm').format('HH:mm') >= moment().format('HH:mm')) ? <option value={data.slot_time}>{moment(data.slot_time, 'hh:mm A').format('hh : mm A')}</option> : '')}
                                </select>
                            </div>
                            <div className='col-lg-2 col-2 mb-3 all-center'>
                                <i className="far fs-24 fa-clock tx-grey"></i>
                            </div>
                            <div className='col-lg-10 col-10 mb-3 tutor-dash h-100'>
                                <p className='ms-700'>{state.data.minutes} {t('general.minutes')}</p>
                            </div>
                            <div className='col-lg-2 col-2 mb-3 d-flex justify-content-center'>
                                <i className="far fs-24 fa-comment tx-grey"></i>
                            </div>
                            <div className='col-lg-10 col-10 mb-3 tutor-dash h-100'>
                                <textarea ref={reason} style={{ minHeight: '150px' }} className='input-box w-100 p-2 ms-700 tx-lt-black' cols="30" rows="5" placeholder={t('general.calendar.whatLearn')}></textarea>
                            </div>
                            <div className="col-12">

                                <button className='btn btn-login fs-14 d-block mx-auto w-100' onClick={bookClass}>{t('button.reserveSlot')}</button>
                            </div>

                            <div className="col-12 my-3 alert">
                                <p className='ms-500 m-2 text-center'> <i className="fas fa-exclamation-triangle mx-2 tx-red"></i> {t('general.calendar.alertText')}</p>
                            </div>
                        </div>
                    </div>

                </section> :
                <section>
                    <p className='my-4 ms-500 fs-18 text-center'>{state.error}</p>
                </section>
            }
            <AlertPopUp
                open={state.openMsg}
                msg={state.msg}
                type={state.type}
                onClose={() => { setState({ ...state, openMsg: false }); history.push('/user/dashboard/calendar') }}
            />
            {(state.pageload || load) && <Backdrop />}
        </div>
    )
}
