/* eslint-disable */
import React, { useState, useEffect } from 'react'
import CourseCard from './CourseCard'
import SyllabusCard from './SyllabusCard'
import tutor from '../controller/tutor'
import helper from '../helper'
import { DualRing } from 'react-awesome-spinners'
export default function TutorLibrary({ onClose }) {
    const [activeCourse, setActiveCourse] = useState({
        index: -1,
    })
    const [state, setState] = useState({ data: [], load: true })
    useEffect(() => {
        getLibrary()
        return () => { }
    }, [])

    const getLibrary = async () => {
        await tutor.getCourseFromLibrary(helper.getid())
            .then((res) => {
                if (res.data.status) {
                    let list = []
                    res.data.result.forEach((data) => {
                        data.course.forEach((result) => {
                            list.push(result)
                        })
                    })
                    setState({ ...state, data: list, load: false })
                }
            })
    }
    return (
        <>
            <div className="all-center popup-modal">
                <div className="left-popup p-fix tp-0 rt-0  p-4 sm" >
                    <p className="ms-900 fs-24 tx-lt-grey  mb-0">My <span className="tx-green">library</span><span className="float-right fs-24 tx-grey pointer" onClick={onClose}><i className="far fa-times-circle hover-shadow"></i></span></p>
                    <p className="sub-heading">Tutor courses</p>
                    <section className='mt-3'>
                        <section className='mt-3'>
                            {
                                state.data.map((course, index) =>
                                    <>
                                        <CourseCard
                                            key={`tc${index}`}
                                            title={course.title}
                                            subTitle={course.subTitle}
                                            courseLength={course.totalLessons}
                                            slides={course.totalSlide}
                                            isEnroll={true}
                                            pic={course.imgName}
                                            isExpand={activeCourse.index === index}
                                            onClick={() => setActiveCourse({ ...activeCourse, index: activeCourse.index === index ? -1 : index })}
                                        />
                                        {
                                            activeCourse.index === index && course.syllabusList.map((syllabus, index) =>
                                                <SyllabusCard
                                                    key={`tcs${index}`}
                                                    isTutorEnrolled={true}
                                                    lessonNo={index + 1}
                                                    title={syllabus.name}
                                                    slide={syllabus.noOfSlides}
                                                    isComplete={true}
                                                    islock={false}
                                                    showProgress={false}
                                                    completedSlides={0}
                                                    files={[]}
                                                    courseId={0}
                                                    studentId={0}
                                                    enrolledCourseId={0}

                                                />)

                                        }
                                    </>)
                            }


                        </section>
                        {state.load && <div className='all-center my-4'>
                            <DualRing size={40} color="#20bef8" />
                        </div>}
                    </section>
                </div>
            </div>

        </>)

}
