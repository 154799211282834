import axios from 'axios'
import helper from '../helper/index'
import { constant } from '../config/Contant'
const BASE_URL = constant.BASE_URL

const getLanguage = async () => {
    try {
       
        const res = await axios({
            method: 'get',
            url: `${BASE_URL}/languages`,
           
        });
        return res;
    }
    catch (err) {
        return err;
    }
}


const getAllSupportCategory = async (role) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'get',
            url: `${BASE_URL}/support-ticket-categories-list?role=${role}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return res;
    }
    catch (err) {
        return err;
    }
}

export { getLanguage,getAllSupportCategory}