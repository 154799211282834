/* eslint-disable */
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyB_opZ0V3VzcjLV-Su0yk6CAtg8WgpmOAM",
  authDomain: "saydhad-99aa5.firebaseapp.com",
  databaseURL: "https://saydhad-99aa5-default-rtdb.firebaseio.com",
  projectId: "saydhad-99aa5",
  storageBucket: "saydhad-99aa5.appspot.com",
  messagingSenderId: "5049449942",
  appId: "1:5049449942:web:6b236d1ac157b015573877",
  measurementId: "G-00SN5SE9LT"
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();

/* Access firebase database */
const database = getDatabase();

/* Google login handler */
const googleProvider = new firebase.auth.GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await auth.signInWithPopup(googleProvider);
    const user = res.user;
    return { status: true, uid: user.multiFactor.user.auth.currentUser.providerData[0].uid };
  } catch (err) {
    return { status: false, uid: null, error: err.message };
  }
};

/* Facebook login handler */
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const signInFacebook = async () => {
  try {
    const res = await auth.signInWithPopup(facebookProvider);
    return { status: true, uid: res.user.providerData[0].uid };
  } catch (err) {
    return { status: false, uid: null, error: err.message };
  }
};

export default { signInWithGoogle, signInFacebook, database, firebase };
