import React, { useState, useEffect, useRef } from 'react'
import tutorHelper from '../helper/tutor'
import moment from 'moment';
import './calendar.css'
import parrot from '../static/images/Logo.svg'
import Backdrop from '../component/Backdrop'
import calendar from '../controller/calendar';
import helper from '../helper/index';
import { useTranslation } from "react-i18next";
export default function SetAvailibilty({ open, day, close, cb }) {
    const { t } = useTranslation();
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [slot, setSlot] = useState([])
    const slotForm = useRef()
    const [state, setState] = useState({
        openPopup: false,
        type: 'ok',
        msg: '',
        load: false
    })
    useEffect(() => {
        setStartTime()
        setEndTime()
        setSlot([])
    }, [open])

    const addSlot = () => {
        if (!startTime || !endTime) {
            helper.showMessage(t('messages.calendar.plzSelectSlot'), "warning")
            return
        }
        let list = [...slot]
        const st = moment(startTime, 'hh:mm').format('HH:mm')
        const et = moment(endTime, 'hh:mm').format('HH:mm')

        if (st >= et) {
            helper.showMessage(t('messages.calendar.timeInvalid'), "warning")
            return
        }

        let find = false;
        list.some((data) => {
            let existStartTime = moment(data.start_time, 'hh:mm').format('HH:mm')
            let existEndTime = moment(data.end_time, 'hh:mm').format('HH:mm')
            if (!((st <= existStartTime && et <= existStartTime) || (st >= existEndTime && et >= existEndTime))) {
                find = true
                return true
            }else{
                return false
            }
        })
        if (find) {
            helper.showMessage(t('messages.calendar.slotExist'),"info")
            return
        }

        list.push({
            start_time: moment(startTime, 'hh:mm').format('HH:mm'),
            end_time: moment(endTime, 'hh:mm').format('HH:mm')
        })
        slotForm.current.reset()
        setSlot(list)
    }

    const removeSlot = (index) => {
        let list = [...slot]
        list.splice(index, 1)
        setSlot(list)
    }

    const saveSlot = async () => {
        let list = []
        day.forEach((days) => {
            list.push({ date: moment(days).format('YYYY-MM-DD') })
        })
        let req = {
            tutor_id: helper.getid(),
            dates: list,
            availability_time: slot
        }
        if (req.availability_time.length === 0) {
            helper.showMessage(t('messages.calendar.plzAddSlot'),"warning")
            return
        }
        setState({ ...state, load: true })
        await calendar.addAvailibilty(req).then((res) => {
            if (res.data.status) {
                helper.showMessage(t('messages.calendar.availabilityAdded'),"success")
                close()
                cb()
            }
            else {
                helper.showMessage(res.data.error,"success")  
            }
            setState({ ...state, load: false })
        }).catch((err) => {
            helper.showMessage(err,"success")
            setState({ ...state, load: false })
        })
    }
    
    return (
        <>
            {open &&
                <div className="popup-modal h-100 all-center ltr">
                    <div className="popup-modal-body sm p-2 pb-3  px-3 hide-scroll">
                        <span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far hover-shadow fa-times-circle"></i></span>
                        <div className='clear my-0'></div>
                        <div className='mb-3 mt-2'>
                            <img className='mx-auto d-block' height={'70px'} src={parrot} alt="parrot" />
                        </div>
                        <h4 className='f-fred tx-lt-grey  text-center py-2'>{t('tutorDash.calendar.setAvailabilty')}</h4>
                        <form ref={slotForm} onSubmit={(e) => e.preventDefault()}>
                            <div className="row gx-0 fs-16 mx-0 ms-700 mt-4">
                                <div className="col-5 px-2">
                                    <select className="mr-2 icon-border w-100 fs-14 px-2 f-fred tx-lt-grey py-2"
                                        onChange={(e) => { setStartTime(e.target.value); }}>
                                        <option className="fs-16 ms-900 tx-lt-black" value="">{t('tutorDash.calendar.selectStartTime')}</option>
                                        {
                                            tutorHelper.getSlots2().map((data, index) =>
                                                // day.includes(moment().format('YYYY-MM-DD')) ?
                                                //     moment(data, 'HH:mm').format('HH:mm') >= moment().format('HH:mm') &&
                                                //     <option className="fs-16 ms-700 " key={`time${index}`} value={moment(data, 'hh:mm').format('HH : mm')}>{moment(data, 'hh:mm').format('hh : mm A')}</option> :
                                                <option className="fs-16 ms-700 " key={`time${index}`} value={moment(data, 'hh:mm').format('HH : mm')}>{moment(data, 'hh:mm').format('hh : mm A')}</option>
                                            )}
                                    </select>
                                </div>
                                <div className="col-5 px-2">
                                    <select className="mr-2 icon-border w-100 fs-14 px-2 f-fred tx-lt-grey py-2"
                                        onChange={(e) => { setEndTime(e.target.value); }}>
                                        <option className="fs-16 ms-900 tx-lt-black" value="">{t('tutorDash.calendar.selectEndTime')}</option>
                                        {
                                            tutorHelper.getSlots2().map((data, index) =>
                                                // day.includes(moment().format('YYYY-MM-DD')) ?
                                                //     moment(data, 'HH:mm').format('HH:mm') >= moment().format('HH:mm') &&
                                                //     <option className="fs-16 ms-700 " key={`etime${index}`} value={moment(data, 'hh:mm').format('HH : mm')}>{moment(data, 'hh:mm').format('hh : mm A')}</option> :
                                                <option className="fs-16 ms-700 " key={`etime${index}`} value={moment(data, 'hh:mm').format('HH : mm')}>{moment(data, 'hh:mm').format('hh : mm A')}</option>
                                            )}
                                    </select>
                                </div>
                                <div className="col-2 px-2 d-flex justify-content-end align-items-center">
                                    <i className="fas fs-24 pointer hover-shadow fa-plus-square tx-yellow touch-hover" onClick={addSlot}></i>
                                </div>
                                <div className="col-12 px-2 mt-3">
                                    {
                                        slot.map((data, index) => {
                                            return (
                                                <div key={`slot${index}`}>
                                                    <div className='d-flex ms-500 justify-content-between my-2'>
                                                        <p>{moment(data.start_time, 'hh:mm').format('hh : mm A')} <span className='ms-900 mx-3'>To</span> {moment(data.end_time, 'hh:mm').format('hh : mm A')}</p>
                                                        <i className="far pointer fs-20 tx-error fa-trash-alt touch-hover" onClick={() => { removeSlot(index) }}></i>
                                                    </div>
                                                    <hr className='my-2' />
                                                </div>
                                            )
                                        })
                                    }

                                </div>

                                <div className="col-12 px-2 mt-3">
                                    <button className='btn mx-auto d-block btn-login  fs-14' style={{ width: '200px' }} onClick={saveSlot}>{t('general.submit')}</button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            }

            <div>

            </div>
            {state.load && <Backdrop />}
        </>
    )
}
