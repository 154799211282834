/* eslint-disable */
import React, { useEffect } from 'react'
import helper from '../helper'
import { toast } from 'react-hot-toast'
import avatar from '../static/images/avatar.png'
import { socket } from '../config/socket'
import ring from '../static/chatNotification.mp3'

export default function ChatNotification() {

    useEffect(() => {
        socketEnable()
    }, [])

    const socketEnable = () => {
        socket.on(`onChatNotification${helper.getid()}`, (result) => {
            raiseToast({ pic: result.profilePicture, text: result.msgDetail.description })
        })
    }

    const raiseToast = ({ pic, text }) => {
        toast.custom((t) => (
            <div className={`${t.visible ? 'animate-enter' : 'animate-leave'}  w-full bg-white border-rounded toast-body`}>
                <audio src={ring} autoPlay />
                <div className="p-2">
                    <div className="d-flex ">
                        <div className="">
                            <img
                                style={{ width: '50px', height: '50px' }}
                                className="rounded-circle"
                                src={pic ? pic : avatar}
                                alt="pic"
                            />
                        </div>
                        <div className="all-center">
                            <p className="tx-lt-grey px-2 my-1 ms-500">
                                {text}
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        ), { duration: 2000 })

    }


    return (
        <>
        </>
    )

}
