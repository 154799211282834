/* eslint-disable */
import moment from "moment";

const makeTwoDigit = (data) => {
    return data.toString().length === 2 ? data : ("0" + data)
}

const getSlots = (stTime, endTime, duration) => {
    let currStartTime = stTime;
    const list = []
    let breakInterval = 0.5;
    list.push(currStartTime)
    if (duration === "00:15") {
        breakInterval = 0.25
        let min = (0.25 / 15) * parseInt(endTime.substr(3, 5))
        let loopcontrol = parseInt(endTime.substr(0, 2)) + min
        for (let i = parseInt(stTime.substr(0, 2)); i < loopcontrol - 0.25;) {
            if (currStartTime.substr(3, 5) == "45") {
                currStartTime = ((parseInt(currStartTime.substr(0, 2)) + 1).toString().length == 2 ? (parseInt(currStartTime.substr(0, 2)) + 1) : "0" + (parseInt(currStartTime.substr(0, 2)) + 1)) + ":" + "00"
            }
            else {
                currStartTime = makeTwoDigit(currStartTime.substr(0, 2)) + ":" + makeTwoDigit(parseInt(currStartTime.substr(3, 5)) + 15)
            }
            list.push(currStartTime)
            i = i + breakInterval
        }
    }
    else if (duration === "00:30") {
        breakInterval = 0.50
        let min = (0.25 / 15) * (
            parseInt(endTime.substr(3, 5)) == 15 ? 0 : parseInt(endTime.substr(3, 5)) == 30 ? parseInt(endTime.substr(3, 5)) :
                parseInt(endTime.substr(3, 5)) == 45 ? 30 : 0)
        let loopcontrol = parseInt(endTime.substr(0, 2)) + min
        if(parseInt(endTime.substr(3, 5)) == 15){
            list.splice(0,1)
        }
        for (let i = parseInt(stTime.substr(0, 2)); i < loopcontrol - 0.50;) {
            if (currStartTime.substr(3, 5) == "30") {
                currStartTime = ((parseInt(currStartTime.substr(0, 2)) + 1).toString().length == 2 ? (parseInt(currStartTime.substr(0, 2)) + 1) : "0" + (parseInt(currStartTime.substr(0, 2)) + 1)) + ":" + "00"
            }
            else {
                currStartTime = makeTwoDigit(currStartTime.substr(0, 2)) + ":" + makeTwoDigit(parseInt(currStartTime.substr(3, 5)) + 30)
            }
            list.push(currStartTime)
            i = i + breakInterval
        }
    }
    else if (duration === "01:00") {
        breakInterval = 1
        for (let i = parseInt(stTime.substr(0, 2)); i < parseInt(endTime.substr(0, 2)) - 1;) {
            currStartTime = makeTwoDigit(parseInt(currStartTime.substr(0, 2)) + 1) + ":" + "00"
            list.push(currStartTime)
            i = i + breakInterval
        }
    }
    else if (duration === "02:00") {
        breakInterval = 2
        for (let i = parseInt(stTime.substr(0, 2)); i < parseInt(endTime.substr(0, 2)) - 2;) {
            currStartTime = makeTwoDigit(parseInt(currStartTime.substr(0, 2)) + 2) + ":" + "00"
            list.push(currStartTime)
            i = i + breakInterval
        }
    }
    return list
}

const addMinutes = (time, min) => {
    const hours = time.substr(0, 2)
    const minutes = time.substr(5, 7)
    const dHours = min.substr(0, 2)
    const dMinutes = min.substr(5, 7)
    let newMinutes = parseInt(minutes) + parseInt(dMinutes)
    let newHours = parseInt(hours) + parseInt(dHours)
    if (newMinutes === 60) {
        newMinutes = 0
        newHours = newHours + 1;
    }
    else if (newMinutes > 60) {
        newMinutes = newMinutes - 60
        newHours = newHours + 1;
    }
    return makeTwoDigit(newHours) + ":" + makeTwoDigit(newMinutes)
}

const getSlots2 = () => {
    let stTime = "00:00:00", endTime = "00:00:00", duration = "00:30"
    /* slot list init */
    const list = []

    /* Total dif between startTime, endTime */
    let formatStartTime = moment(`${stTime}`, 'HH:mm:ss');
    let formatEndTime = moment(`${endTime}`, 'HH:mm:ss');


    /* Valid time slot accept only */
    if (!formatStartTime.isValid() || !formatEndTime.isValid()) {
        return []
    }
    let dif = moment.duration(formatEndTime.diff(formatStartTime));
    let totalMin = dif.hours() * 60 + dif.minutes()

    /* calculate duration */
    var formatDuration = moment(`${duration}`, 'HH:mm:ss');
    var intDuration = moment.duration(formatDuration.diff(moment(`00:00:00`, 'HH:mm:ss')));
    let totalIntDuration = intDuration.hours() * 60 + intDuration.minutes()
    if (formatStartTime.format('HH:mm:ss') === "00:00:00" && formatEndTime.format('HH:mm:ss') === "00:00:00") {
        totalMin = 24 * 60
    }
    else if (formatStartTime.format('HH:mm:ss') !== "00:00:00" && formatEndTime.format('HH:mm:ss') === "00:00:00") {
        let dif = moment.duration(formatEndTime.diff(formatStartTime));
        totalMin = Math.abs((dif.hours() + 1) * 60 + dif.minutes())
    }

    /* Valid duration accept only */
    if (!formatDuration.isValid()) {
        return []
    }
    /* temporary startTime */
    let tempStartTime = formatStartTime

    if (totalMin >= totalIntDuration) {
        list.push(tempStartTime.format('HH:mm'))
    }

    for (let i = totalMin - totalIntDuration; i > 0; i = i - totalIntDuration) {
        /* Add duration in slots */
        if (i >= totalIntDuration) {
            tempStartTime = moment(tempStartTime).add(intDuration, 'minute')
            list.push(tempStartTime.format('HH:mm'))
        }
    }
    return list
}

const hours = [
    "01 : 00",
    "02 : 00",
    "03 : 00",
    "04 : 00",
    "05 : 00",
    "06 : 00",
    "07 : 00",
    "08 : 00",
    "09 : 00",
    "10 : 00",
    "11 : 00",
    "12 : 00",
    "13 : 00",
    "14 : 00",
    "15 : 00",
    "16 : 00",
    "17 : 00",
    "18 : 00",
    "19 : 00",
    "20 : 00",
    "21 : 00",
    "22 : 00",
    "23 : 00",
    "24 : 00"
]

const getTimeFormat = (data) => {
    const hours = parseInt(data.substr(0, 2));
    const time = hours > 12 ? hours - 12 : hours
    const ampm = hours >= 12 ? "PM" : "AM"
    return (time.toString().length == 2 ? time : "0" + time) + " : " + "00" + " " + ampm;
}

const getTimeFormatAMPM = (data) => {
    const hours = parseInt(data.substr(0, 2));
    const minutes = parseInt(data.substr(5, 7));
    const time = hours > 12 ? hours - 12 : hours
    const ampm = hours >= 12 ? "PM" : "AM"
    return makeTwoDigit(time) + " : " + makeTwoDigit(minutes) + " " + ampm;
}

export default {
    getSlots,
    makeTwoDigit,
    addMinutes,
    hours,
    getTimeFormat,
    getTimeFormatAMPM,
    getSlots2
}