/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import logo from '../static/images/Logo.svg'
import helper from '../helper/index'
import Backdrop from '../component/Backdrop'
import firebase from '../config/firebaseAuth';
import Image from "../static/images/Group 587.png";
import googleImage from "../static/images/Group 587.png";
import facebookImage from "../static/images/Group 586.png";
import auth from "../controller/auth";
import quoteIcon from '../static/images/quote.svg'
import ToggleHidePassword from '../component/ToggleHidePassword'
import { NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet'
import LanguageTranslator from '../component/LanguageTranslator'

export default function TutorSignup() {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const url = new URLSearchParams(window.location.search)
    if (url.get('token')) {
      try {
        let decEmail = helper.decrypt(url.get("token").toString().replace(/ /g, "+"))
        if (decEmail) setEmail(decEmail);
      } catch { }
    }
  }, [])

  const submit = () => {
    let resp;
    resp = helper.validate("email", email);
    if (!resp.isValid) {
     return helper.showMessage(resp.msg, "error")
     
    }
    resp = helper.validate("password", password);
    if (!resp.isValid) {
      return  helper.showMessage(resp.msg, "error")
    }
    const decEmail = helper.encrypt(email.toLowerCase())
    const decPass = helper.encrypt(password)
    history.push(`/select-role?tk1=${decEmail}&tk2=${decPass}`)
  }

  const signInWithGoogle = async () => {
    const response = await firebase.signInWithGoogle();
    if(!response.status){
      return helper.showMessage(response.error,"error")
    }
    const reqData = {
      socialType: "google",
      uid: response.uid
    };
    setOpen(true)
    await auth.tutorSocialLogin(reqData).then((res) => {
      if (res.data.status) {
        helper.saveCredential(
          res.data.result.token,
          "tutor",
          res.data.result.id,
          res.data.result.profileStatus,
          res.data.result.email
        );
        if (res.data.result.profileStatus === "Pending") {
          history.push(`/tutor/signupcheck`);
        } else if (res.data.result.profileStatus === "Uploaded") {
          history.push(`/tutor/signupcheck`);
        } else if (res.data.result.profileStatus === "Approved") {
          history.push(`/tutor/dashboard/home`);
        }
      } else {
        helper.showMessage(res.data.error, "error")
      }
      setOpen(false)
    });
  };

  const signInWithFacebook = async () => {
    Promise.all([firebase.signInFacebook()]).then(async (resp) => {
      if(!resp[0].status){
        return helper.showMessage(resp[0].error,"error")
      }
      const reqData = {
        socialType: "facebook",
        uid: resp[0].uid
      };
      setOpen(true)
      await auth
        .tutorSocialLogin(reqData)
        .then((res) => {

          if (res.data.status) {
            helper.saveCredential(
              res.data.result.token,
              "tutor",
              res.data.result.id,
              res.data.result.profileStatus,
              res.data.result.email
            );
            if (res.data.result.profileStatus === "Pending") {
              history.push(`/tutor/signupcheck`);
            } else if (res.data.result.profileStatus === "Uploaded") {
              history.push(`/tutor/signupcheck`);
            } else if (res.data.result.profileStatus === "Approved") {
              history.push(`/tutor/dashboard/home`);
            }
          } else {
            helper.showMessage(res.data.error, "error")
          }
          setOpen(false)
        })
        .catch((e) => { });
    });
  };
  
  return (
    <div className="tutor-login-home lg-bg-image ">
      <Helmet>
        <title>Create Your Tutor Profile - SayDhad</title>
        <meta name="description" content="Create your tutor profile on our platform and become a valued part of our team. To get started, make an accessible account by entering basic details, expertise, availability, and, rates. Make an engaging profile by adding your teaching tutorial and setting the stage with successful tutoring with us." />
        <link rel="canonical" href=" https://saydhad.com/tutor/signup/" />
      </Helmet>
      <section className="row gx-0 mx-0 h-100 py-5 cpx-4">
        <div className="col-lg-7 col-md-7 cpx-4 d-lg-flex d-md-flex d-none  align-items-start flex-column f-fred text-white">
        <div className="overlay-bg"></div>
          <div className="fs-61 overlay-text">
            <h1>
              <img src={quoteIcon} alt="quote" />
            </h1>
            <p className="text-white" style={{ lineHeight: '66px' }}>{t('auth.tutorQuote1')}</p>
            <p style={{ lineHeight: '66px' }}>{t('auth.tutorQuote2')}</p>
            <p className="fs-31 mt-3">~{t('general.quoteAuthor')}</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-5 my-3  bg-white login-home-box px-3 py-3  all-center" >
          <div className="row gx-0 mx-0 w-100">
            <div className="col-lg-10 mx-auto d-flex flex-column justify-content-between">
            <div className="mb-2 ml-auto">
                <LanguageTranslator />
              </div>
              <div className="upper">
                <div className="all-center px-2">
                  <NavLink to="/"> <img alt='logo' width="230px" src={logo} /></NavLink>
                </div>
                <div className="all-center flex-column">
                  {/* <p className="f-fred text-center heading-y-margin fs-24 mb-1 mt-3">{t('auth.create-a')} <span className="tx-green">{t('auth.tutor')} </span>{t('auth.profile')}</p> */}
                  <p className="f-fred text-center heading-y-margin fs-24 mb-1 mt-3">{t('auth.createTutorProfile')} </p>
                  <div className="mt-1 w-100 ">
                    <div className="input-box mb-2">
                      <input maxLength={40} type="text" defaultValue={email} placeholder={t('auth.forgot.email')} className="px-2 py-1 ms-700 box-height" onChange={(e) => { setEmail(e.target.value);  }} />
                    </div>
                    <ToggleHidePassword onChange={(e) => { setPassword(e.target.value); }} />
                  </div>
                  <button className="btn heading-y-margin btn-tutor-profile my-3 box-height px-2  w-100" onClick={() => submit()}>{t('auth.proceed')}</button>
                </div>
                <div className="inline-text my-4 all-center">
                  <p ><span className="bold">OR</span></p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <img src={facebookImage} height={'39px'} onClick={() => signInWithFacebook()} className='pointer mr-2 f-fred box-height d-inline-block' alt="facebook" />
                  <img src={googleImage} className='pointer f-fred box-height d-inline-block' onClick={() => signInWithGoogle()} alt="google" />
                </div>
              </div>
              <div className="all-center flex-column">
                <button className="btn btn-login  w-100 mt-5 box-height" onClick={() => history.push('/tutor/login')}>{t('auth.loginExistAccount')}</button>
                <p className="my-2 mt-4 ms-500 text-center login-footer-text" style={{ width: '80%' }}>{t('auth.policy.text1')} <span className="tx-lt-black ms-900">{t('auth.saydhad')}</span>. {t('auth.policy.text2')} <span className="ms-900 tx-lt-black"> <NavLink to="/web/terms-condition">{t('auth.policy.terms')} </NavLink></span> {t('auth.policy.and')} <span className="ms-900 tx-lt-black"><NavLink to="/web/privacy-policy">{t('auth.policy.policy')}</NavLink></span></p>

              </div>
            </div>
          </div>
        </div>
      </section>
      {open && <Backdrop />}
      <Helmet htmlAttributes={{ dir: "ltr"}} />
    </div>
  )
}
