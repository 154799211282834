import React from 'react'
import ProgressBar from './ProgressBar'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import helper from '../helper';
export default function CourseCard({ img, name,level, subTitle, lesson, slides, isRemove, onRemove, onChange, isEnrolled,totalSlide,completedSlide }) {
    const { t } = useTranslation();
    const reduxState = useSelector(state => state)
    return (
        <div className="course-card b-grey px-2 py-2 p-rel tp-0 rt-0" >
            <div className="course-img br-1 mb-2 pointer" style={{ height: '160px', overflow: 'hidden' }} onClick={onChange && onChange}>
                <img className="w-100 h-100 br-1" src={img && img !== 'null' ? helper.replaceS3Url(img) : "https://saydhad-new.s3.amazonaws.com/course_prev_image/FirstCourseImage.png"} alt="course" />
            </div>
            <div className={`course-body my-2 ${reduxState.language === 'ar' && "text-right"}`}>
                <p className="f-fred text-capitalize w-100 fs-18 overflow-elipse tx-lt-black" >{name}</p>
                <p className="fs-14 ms-500 text-capitalize w-100 overflow-elipse tx-lt-grey" >{subTitle}</p>
                <p className="fs-14 ms-500 text-capitalize w-100 overflow-elipse tx-lt-grey" >{level}</p>
                <div className="d-flex mt-1 tx-lt-grey justify-content-between align-items-center">
                    <p className="fs-12 ms-700">{lesson} {t('tutorDash.library.lessons')} <span className="ms-900">{slides} {t('tutorDash.library.slides')}</span></p>
                    {isRemove && <span className="px-1 fs-12 reshedule-btn ms-700 pointer" onClick={onRemove}>{t('tutorDash.library.removeFromLibrary')}</span>}
                </div>
            </div>
            {isEnrolled &&
                <section className="my-2 e">
                    <ProgressBar
                        width="100%"
                        height={"7px"}
                        bg="#099d14"
                        now={completedSlide}
                        total={totalSlide}
                    />
                </section>
            }
        </div>
    )
}
