import React, { useEffect } from 'react'
import helper from '../helper'
import WebFooter from './WebFooter'
import WebHeader from './WebHeader'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import CancelPolicy from '../legalpages/CancelPolicy';
import { Helmet } from 'react-helmet';

export default function WebCancelPolicy() {
    const reduxState = useSelector(state => state)
    const { t } = useTranslation()
    useEffect(() => {
        helper.scroll()
    }, [])

    return (
        <>
            <Helmet>
                <title>Cancel Policy - SayDhad</title>
                <meta name="description" content='When you opt for a payment plan with us, you sign up for an auto-renewal subscription. If your payment method is unsuccessful due to insufficient balance or expiration, we suspend access to the platform. Browse our page to learn about the cancellation policy and the process.' />
                <link rel="canonical" href=" https://saydhad.com/web/cancel-policy" />
            </Helmet>
            <div>
                <section className='row banner-section '>
                    <div className="col-lg-11 col-12 mx-auto">
                        <WebHeader />
                        <section className={`margin-top ms-500 fs-16 mb-5 px-3 ${reduxState.language === 'ar' && "text-right"}`}>
                            <div className="row ms-500">
                                <div className="col-lg-10 col-12 mx-auto  py-2">
                                    <h2 className='mb-3 tx-lt-grey f-fred'>{t('legalPages.cancelPolicy.heading')}</h2>
                                </div>
                            </div>
                            <CancelPolicy />
                        </section>
                        <WebFooter />
                    </div>
                </section>
            </div>
        </>
    )
}

