/* eslint-disable */
import React, { useEffect, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux'
import reduxstore from './redux/AppState'
import CreateProfile from "./auth/TutorSignup";
import SelectRole from "./auth/SelectRole";
import TutorWelcome from "./auth/TutorWelcome";
import TutorSignUpCheck from "./tutor/TutorSignUpCheck";
import TutorDash from "./tutorDash/TutorDash";
import TutorLogin from "./auth/TutorLogin";
import TutorVerifyOTP from "./auth/TutorVerifyOTP";
import VerifyAccount from "./auth/VerifyAccount";
import PrivateRoute from "./private/PrivateRoute";
import UserSignUpCheck from "./usersignup/UserSignUpCheck";
import UserLogin from "./auth/UserLogin";
import UserVerifyOTP from "./auth/UserVerifyOTP";
import UserDash from "./userDash/UserDash";
import TutorFogotPassword from "./auth/TutorFogotPassword";
import TutorResetPassword from "./auth/TutorResetPassword";
import UserFogotPassword from "./auth/UserForgotPassword";
import UserResetPassword from "./auth/UserResetPassword";
import VideoAccess from "./agorasetup/VideoAccess";
import VideoRoom from "./agorasetup/VideoRoom";
import UserSignupReferral from "./auth/UserSignUpReferral";
import Certificate from "./userDash/Certificate";
import DirectClass from "./agorasetup/DirectClass";
import DirectClassRoom from "./agorasetup/DirectClassRoom";
import PageNotFound from "./PageNotFound";
import DirectClassByReferral from "./agorasetup/DirecClassByReferral";
import UserSignup from "./auth/UserSignup";
import TermsCondition from "./legalpages/TermsCondition";
import Privacypolicy from "./legalpages/Privacypolicy";
import VerifyCertificate from "./userDash/VerifyCertificate";
import Home from "./web/Home";
import TutorKnowledge from "./web/TutorKnowledge";
import Aboutus from "./web/Aboutus";
import Contactus from "./web/Contactus";
import WebTerms from "./web/WebTerms";
import WebPrivacyPolicy from "./web/WebPrivacyPolicy";
import WebDisclaimer from "./web/WebDisclaimer";
import { useClearCache } from 'react-clear-cache';
import StudentPrivateRoute from "./private/StudentPrivateRoute";
import TutorPrivateRoute from "./private/TutorPrivateRoute";
import Checkout from "./amazonPay/Checkout";
import MobileAboutUs from "./legalpages/MobileAboutUs";
import Backdrop from "./component/Backdrop";
import GlobalComponent from "./component/GlobalComponent";
import RefundPolicy from "./legalpages/RefundPolicy";
import CancelPolicy from "./legalpages/CancelPolicy";
import WebRefundPolicy from "./web/WebRefundPolicy";
import WebCancelPolicy from "./web/WebCancelPolicy";
import StudentFaqs from "./web/StudentFaqs";
import TutorFaqs from "./web/TutorFaqs";
import WebStudentFaqs from "./web/WebStudentFaqs";
import WebTutorFaqs from "./web/WebTutorFaqs";

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  useEffect(() => {
    !isLatestVersion && emptyCacheStorage();
  }, [])
// test
  return (
    <Suspense fallback={<Backdrop />}>
      <Provider store={reduxstore.store}>
        <GlobalComponent />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/tutor-knowlegde" component={TutorKnowledge} />
          <Route exact path="/about-us" component={Aboutus} />
          <Route exact path="/support" component={Contactus} />

          {/* Policies */}
          <Route exact path="/web/terms-condition" component={WebTerms} />
          <Route exact path="/web/privacy-policy" component={WebPrivacyPolicy} />
          <Route exact path="/web/disclaimer" component={WebDisclaimer} />
          <Route exact path="/web/refund-policy" component={WebRefundPolicy} />
          <Route exact path="/web/cancel-policy" component={WebCancelPolicy} />

          <Route exact path="/certificate" component={Certificate} />

          {/* Auth  */}
          <Route exact path="/tutor/signup" component={CreateProfile} />
          <Route exact path="/signup" component={UserSignup} />

          <Route path="/verify-account/link" component={VerifyAccount} />
          <Route exact path="/tutor/login" component={TutorLogin} />
          <Route exact path="/login" component={UserLogin} />
          <Route exact path="/user/signup/:referralcode" component={UserSignupReferral} />
          <Route path="/select-role" component={SelectRole} />
          <Route path="/tutor/verify-otp" component={TutorVerifyOTP} />
          <Route path="/user/little-more" component={UserSignUpCheck} />
          <Route path="/user/verify-otp" component={UserVerifyOTP} />

          {/* Forgot & Reset  */}
          <Route exact path="/tutor/forgot-password" component={TutorFogotPassword} />
          <Route path="/tutor/reset-password/link" component={TutorResetPassword} />
          <Route exact path="/user/forgot-password" component={UserFogotPassword} />
          <Route path="/reset-password/link" component={UserResetPassword} />

          {/* class scheduling  */}
          <PrivateRoute restricted={true} path="/user/class-room/:channelName" component={VideoAccess} />
          <PrivateRoute restricted={true} path="/user/start-class/:name/:tutorId/:referralId" component={DirectClassByReferral} />
          <PrivateRoute restricted={true} path="/user/start-class/:name/:tutorId/" component={DirectClass} />
          <PrivateRoute restricted={true} exact path="/dhad-video/:channelName" component={VideoRoom} />
          <PrivateRoute restricted={true} exact path="/instant-online-class/:channelName" component={DirectClassRoom} />

          {/* User  */}
          <StudentPrivateRoute exact restricted={true} path="/user/dashboard/:page" component={UserDash} />
          <StudentPrivateRoute exact restricted={true} path="/user/dashboard/:page/:id" component={UserDash} />
          <StudentPrivateRoute exact restricted={true} path="/user/dashboard/:page/:id/:courseName" component={UserDash} />

          {/* Tutor  */}
          <TutorPrivateRoute restricted={true} path="/tutor/welcome" component={TutorWelcome} />
          <TutorPrivateRoute restricted={true} exact path="/tutor/signupcheck" component={TutorSignUpCheck} />
          <TutorPrivateRoute exact restricted={true} path="/tutor/dashboard/:page" component={TutorDash} />
          <TutorPrivateRoute exact restricted={true} path="/tutor/dashboard/:page/:id" component={TutorDash} />

          {/* Web views legal pages  */}
          <Route exact path="/web-view/refund-policy" component={WebRefundPolicy} />
          <Route exact path="/web-view/cancel-policy" component={WebCancelPolicy} />
          <Route exact path="/web-view/aboutus" component={Aboutus} />
          <Route exact path="/web-view/terms-condition" component={WebTerms} />
          <Route exact path="/web-view/privacy-policy" component={WebPrivacyPolicy} />
          <Route exact path="/web-view/faqs/student" component={WebStudentFaqs} />
          <Route exact path="/web-view/faqs/tutor" component={WebTutorFaqs} />

          {/* Certificate */}
          <Route exact path="/certificate/verify/:id" component={VerifyCertificate} />

          <Route exact path="/faqs/student" component={WebStudentFaqs} />
          <Route exact path="/faqs/tutor" component={WebTutorFaqs} />


          {/*payment gateway  */}
          <PrivateRoute restricted={true} path="/checkout" component={Checkout} />

          {/* page not found */}
          <Route component={PageNotFound} />

        </Switch>
      </Provider>
    </Suspense>
  );
}

export default App;
