/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { FiXCircle } from "react-icons/fi";
import user from '../controller/user'
import helper from '../helper/index'
import Calendar from 'react-calendar';
import tutor from '../controller/tutor';
import tutorHelper from '../helper/tutor';
import cryParrot from '../static/images/cryparrot.png'
import moment from 'moment'
import Loader from './Loader';
import ReactStars from "react-rating-stars-component";
import avatar from '../static/images/avatar.png'
import ConfirmPopup from './ConfirmPopup';
import AlertPopUp from './AlertPopUp';
import country from '../helper/country.json'
const week = ["Sunday", "Monday", "tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]


export default function UserCourseCallpopup({ close, courseId,syllabusId }) {
    const [resData, setResData] = useState([])
    const [searchData, setSearchData] = useState([])
    const [openShedule, setOpenShedule] = useState({ open: false, data: '' })
    const [pageload, setPageload] = useState(true)
    useEffect(() => {
        apiCall()
        return () => { }
    }, [])

    const apiCall = async () => {
        setPageload(true)
        await user.getTutorByCourseId(courseId)
            .then((res) => {
                if (res.data.status) {
                    setSearchData(res.data.result)
                    setResData(res.data.result)
                }
                setPageload(false)
            }).catch((err) => setPageload(false))
    }

    const searchQuery = (tutorName) => {
        if (tutorName) {
            let regEx = new RegExp(tutorName, 'si')
            const res = resData.filter((data) => {
                return data.name.match(regEx)
            })
            setSearchData(res)
        }
        else {
            setSearchData(resData)
        }
    }

    return (
        <div>
            <div className="faded" >
                <div className="py-4 px-4 rightpop-up">
                    <div className="row">
                        <div className="col-lg-8 col-9 ">
                            <h5 className="f-fred ">Schedule a  <span className="tx-green">lesson</span></h5>
                            <p className="f-fred tx-lt-black fs-14">{helper.getDay()}, {helper.getCuurentDate()} {helper.getTime()}</p>
                        </div>
                        <div className="col-lg-4 col-3 d-flex justify-content-end tx-grey"><FiXCircle className="pointer" size="2rem" onClick={close} /></div>
                    </div>
                    {!openShedule.open &&
                        <>
                            <div className="row my-3 gx-0 f-fred" >
                                <p className="tx-sky fs-16">Step 1 : Select a tutor</p>
                                <p className="tx-lt-black fs-12">Search a tutor  below and then check their availiability.</p>
                            </div>

                            <div className="row  my-3">
                                <div className="col-lg-12 tutor-library f-fred">
                                    <input type="text" placeholder="Search tutors by name" className="input-box  w-100 h-100 px-2 py-2 tx-lt-black" onChange={(e) => { searchQuery(e.target.value) }}></input>
                                </div>
                            </div>

                            {
                                searchData.map((data, index) => {
                                    return (
                                        <div key={index} className="row b-grey notify-card ms-500 my-2 pointer" onClick={() => setOpenShedule({ ...openShedule, open: true, data: data })}>
                                            <div className="col-2 all-center my-2">
                                                <div className='p-rel'>
                                                    <img style={{ height: '40px', width: '40px' }} className="rounded-circle mx-1" src={data.profilePicture ? data.profilePicture : avatar} alt="tutor" />
                                                    <div className={`${data.isOnline ? "online-tag" : "offline-tag"} p-abs bt-0 rt-0`}></div>
                                                </div>
                                            </div>
                                            <div className="col-7 my-2 pl-2">
                                                <p className="lh-18 text-capitalize"><strong>{data.name}</strong></p>
                                                <p className="lh-18 fs-12 text-capitalize ms-500">Tutor from <b>{data.from}</b></p>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-inline-block py-0 my-0 mr-1">
                                                        <ReactStars
                                                            edit={false}
                                                            value={data.ratings ? parseFloat(data.ratings).toFixed(1) : 0}
                                                            count={5}
                                                            size={20}
                                                            isHalf={true}
                                                            emptyIcon={<i className="far fa-star"></i>}
                                                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                            fullIcon={<i className="fa fa-star"></i>}
                                                            classNames="my-0 py-0"
                                                            activeColor="#ffcb00"
                                                            tileClassName="my-0 "
                                                        />
                                                    </div>
                                                    <span className="tx-black ms-700">{data.ratings ? parseFloat(data.ratings).toFixed(1) : 0}</span>

                                                </div>
                                            </div>

                                            <div className="col-3 d-flex align-items-end justify-content-end p-rel">
                                                {helper.getDateDifference(data.createdOn) < 30 && <p className="new-flag px-2 ms-500 fs-14">New</p>}
                                                <span className="p-2">
                                                    {
                                                        country.map((flag,index) => {
                                                            if (flag.name.toLowerCase() === data.from.toLowerCase()) {
                                                                return <img   key={`country2${index}`} width="20px" height="20px" className="rounded-circle" src={`https://flagcdn.com/w320/${flag.code.toLowerCase()}.png`}
                                                                    alt="flag" />
                                                            }else{
                                                              return  <></>
                                                            }
                                                        })
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                    }

                    {
                        openShedule.open && 
                        <SheduleLesson
                            cb={() => setOpenShedule({ ...openShedule, open: false })}
                            data={openShedule.data}
                            cid={courseId}
                            syllabusId = {syllabusId}
                        />
                    }
                    {
                        < Loader open={pageload} />
                    }
                </div>
            </div>
        </div>
    )
}


const SheduleLesson = ({ cb, data, cid,syllabusId }) => {
    const [pageload, setPageload] = useState(true)
    const [value, setValue] = useState(new Date());
    const [slotList, setSlotList] = useState([])

    const [error, setError] = useState()
    const [resData, setResData] = useState([])
    const [slotDuartion, setSlotDuartion] = useState('')
    const [startTime, setStartTime] = useState('')
    const [activeSlot, setActiveSlot] = useState({ indexNo: 0, active: false })
    const [state, setState] = useState({
        open: false,
        msg: '',
        openModal: false,
        type: 'ok'
    })

    useEffect(() => {
        getSlots()
        return () => { }
    }, [])

    const getSlots = async () => {
        await tutor.getAvailibilty(data.tutorId)
            .then((res) => {
                if (res.data.status) {
                    setResData(res.data.result)
                    setPageload(false)
                }
            })
    }

    const getBookedSlot = async (date, dateField) => {
        setPageload(true)
        await tutor.getBookedSlot(data.tutorId, date)
            .then((res) => {
                if (res.data.status) {
                    let findDay = false
                    resData.map((data) => {
                        if (data.day.toLowerCase() === week[dateField.getDay()].toLowerCase()) {
                            findDay = true
                            timeSlotByDays(data.start_time, data.end_time, data.slot_time, res.data.result.bookingSlot, res.data.result.slots)
                        }
                    })
                    if (!findDay) {
                        setSlotList([])
                    }
                }
                setPageload(false)
            })
    }

    const getTimeFormat2 = (data) => {
        const hours = parseInt(data.substr(0, 2));
        const minutes = parseInt(data.substr(5, 7));
        const time = hours > 12 ? hours - 12 : hours
        const ampm = hours >= 12 ? "PM" : "AM"
        return tutorHelper.makeTwoDigit(time) + " : " + tutorHelper.makeTwoDigit(minutes) + " " + ampm;
    }

    const timeSlotByDays = (stTime, endTime, duration, bookingInfo, slotInfo) => {
        const stTime1 = moment(stTime, 'hh:mm').format('HH : mm')
        const endTime1 = moment(endTime, 'hh:mm').format('HH : mm')
        const durTime1 = moment(duration, 'hh:mm').format('HH : mm')
        const slots = tutorHelper.getSlots(stTime1, endTime1, durTime1)
        let list = []
        setSlotDuartion(durTime1)
        
        slots.forEach((data) => {
            let find = false
            slotInfo.forEach((slotData) => {
                if (data.replace(" : ", ":") === slotData.start_time.substr(0, 5)) {
                    find = true
                    list.push({
                        time: data,
                        status: slotData.status
                    })
                }
            })
            if (!find) {
                list.push({
                    time: data,
                    status: "Available"
                })
            }
        })

      
        let newList = [...list]
        list = []
        newList.forEach((data) => {
            let find = false
            bookingInfo.forEach((slotData) => {
                if (data.time.replace(" : ", ":") === slotData.start_time.substr(0, 5)) {
                    find = true
                    list.push({
                        time: data.time,
                        status: slotData.status,
                        tutorClassURL: slotData.status === "Booked" && slotData.tutor_class_url,
                        data: slotData

                    })
                }
            })
            if (!find) {
                list.push({
                    time: data.time,
                    status: data.status,
                    data: null
                })
            }
        })
        setSlotList(list)

    }

    const onChangeCalendar = (date) => {
        setError('')
        setStartTime('')
        getBookedSlot(moment(date).format('YYYY-MM-DD'), date)
        setValue(date)
        setActiveSlot({ ...activeSlot, indexNo: -1, active: false })
    }

    const bookSlot = async () => {
        const url = helper.generateClassUrl(data.tutorId, helper.getid())
        const reqData = {
            start_time: moment(startTime, 'hh:mm').format('HH:mm'),
            end_time: tutorHelper.addMinutes(startTime, slotDuartion),
            student_id: parseInt(helper.getid()),
            tutor_id: data.tutorId,
            date: moment(value).format('YYYY-MM-DD'),
            course_id: cid,
            student_class_url: url.userUrl,
            tutor_class_url: url.tutorUrl
        }
        if (reqData.start_time == "Invalid date") {
            setError("Please select valid slot")
            return
        }
        setError('')
        setState({
            ...state,
            open: true, msg:
                <div>Do you want book the class with <br /><span className='ms-900 text-capitalize'>{data.name}</span> ?</div>
        })
    }

    const confirmBookClass = async () => {
        setPageload(true)
        const url = helper.generateClassUrl(data.tutorId, helper.getid())
        const reqData = {
            start_time: moment(startTime, 'hh:mm').format('HH:mm'),
            end_time: tutorHelper.addMinutes(startTime, slotDuartion),
            student_id: parseInt(helper.getid()),
            tutor_id: data.tutorId,
            date: moment(value).format('YYYY-MM-DD'),
            course_id: cid,
            student_class_url: url.userUrl,
            tutor_class_url: url.tutorUrl,
            syllabus_id : syllabusId
        }
     
        await user.bookClass(reqData)
            .then((res) => {
                if (res.data.status) {
                    setState({
                        ...state, type: 'ok', openModal: true, msg:
                            <div>
                                your class successfully booked with <br /><span className='ms-900 text-capitalize'>{data.name}</span>
                            </div>
                    })
                }
                else {
                    setState({
                        ...state, type: 'fail', openModal: true,
                        msg: res.data.error
                    })
                }
                setPageload(false)
            })
            .catch((err) => setPageload(false))
    }

    return (
        <>
            <div className="row mt-3 mb-3 gx-0 " >
                <div className="col-8">
                    <p className="tx-sky ms-900 fs-16">Step 1 : Select a tutor </p>
                </div>
                <div className="col-4"><button className="btn btn-tutor-profile fs-12 float-right w-100" onClick={cb}>Change</button></div>
            </div>
            <div className="row b-grey ms-500 my-2 pointer">
                <div className="col-2 all-center my-2">
                    <div className='p-rel'>
                        <img style={{ height: '40px', width: '40px' }} className="rounded-circle mx-1" src={data.profilePicture ? data.profilePicture : avatar} alt="tutor" />
                        <div className={`${data.isOnline ? "online-tag" : "offline-tag"} p-abs bt-0 rt-0`}></div>
                    </div> </div>
                <div className="col-7 my-2 pl-2">
                    <p className="lh-18 text-capitalize f-fred fs-14">{data.name}</p>
                    <p className="lh-18 fs-12 ms-500">Tutor from <b>{data.from}</b></p>
                    <div className="d-flex align-items-center">
                        <div className="d-inline-block py-0 my-0 mr-1">
                            <ReactStars
                                edit={false}
                                value={data.ratings ? parseFloat(data.ratings).toFixed(1) : 0}
                                count={5}
                                size={20}
                                isHalf={true}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                                classNames="my-0 py-0"
                                activeColor="#ffcb00"
                            />
                        </div>
                        <span className="tx-black ms-700">{data.ratings ? parseInt(data.ratings).toFixed(0) : 0}</span>
                    </div>
                </div>
                <div className="col-3 d-flex align-items-end justify-content-end p-rel">
                    {helper.getDateDifference(data.createdOn) < 30 && <p className="new-flag px-2 fs-14 ms-500">New</p>}
                    <span className="p-2">
                        {
                            country.map((flag) => {
                                if (flag.name.toLowerCase() === data.from.toLowerCase()) {
                                    return <img width="20px" height="20px"  className="rounded-circle" src={`https://flagcdn.com/w320/${flag.code.toLowerCase()}.png`}
                                        alt="flag" />
                                }
                            })
                        }
                    </span>
                </div>
            </div>
            <p className="tx-sky ms-900 mt-3 fs-16">Step 2 : Select a date & time </p>

            <section className="calendar">
                <Calendar
                    onChange={(day) => { onChangeCalendar(day) }}
                    value={value}
                    minDate={new Date()}
                    tileClassName="tilename "
                    className="w-100 cl-body mt-3 ms-900 fs-14"
                    navigationAriaLabel="go up"
                />
            </section>

            <section className="mt-2">
                <p className="fs-16 ms-900 mb-2">Select lesson start time</p>
                {slotList.map((data, index) => {
                    if (data.status == "Available" || data.status == "Booked") {
                        return (
                            <span
                                className={`px-2 mb-2 pointer br-1 py-1 mr-2 d-inline-block ms-700 fs-12
                                ${data.status === "Booked" ? "booked-time" : data.status === "Available" ? "available-time" : "unavailable-time"}
                                ${index === activeSlot.indexNo ? "active-time" : "inactive-time"}`}
                                onClick={() => {
                                    if (data.status === "Available") {
                                        setStartTime(data.time);
                                        setActiveSlot({ ...activeSlot, indexNo: index, active: true })
                                    }
                                }}>
                                {
                                    data.status === "Available" ? getTimeFormat2(data.time) :
                                        data.status === "Booked" ? <p className="text-center lh-15">{data.status}</p> : ''
                                }

                            </span>
                        )
                    }

                })
                }

                {slotList.length === 0 &&
                    <div>
                        <img width="70px" src={cryParrot} alt="cryParrot" />
                        <p className="ms-700 fs-14 mt-2 tx-lt-black">Not available for the selected date.Please change the date to see the schedule.</p>
                    </div>
                }
                <p className="ms-500 my-2 tx-sky text-center">{error}</p>

                <button className="btn btn-tutor-profile mt-2 fs-12" onClick={bookSlot}>Select & schedule a call</button>
            </section>

            <Loader open={pageload} />
            <ConfirmPopup
                open={state.open}
                msg={state.msg}
                onOk={() => { setState({ ...state, open: false }); confirmBookClass() }}
                onClose={() => setState({ ...state, open: false })}
            />
            <AlertPopUp
                open={state.openModal}
                msg={state.msg}
                type={state.type}
                onClose={() => { setState({ ...state, openModal: false }); cb() }}

            />
        </>
    )
}

