/* eslint-disable */
import React, { useState ,useRef} from 'react'
import helper from '../helper'
import { useSelector } from 'react-redux'
export default function CourseFilter({ open, close, categories, cb }) {
    const reduxState = useSelector(state => state)
    const outSide = useRef()
    const [category, setCategory] = useState([])
    const [level, setLevel] = useState([])

    const select = (data, type) => {
        if (type === "cat") {
            const list = [...category]
            if (!list.includes(data)) {
                list.push(data)
            } else {
                let index = list.indexOf(data);
                list.splice(index, 1)
            }
            setCategory(list)
        }
        else if (type === "level") {
            const list = [...level]
            if (!list.includes(data)) {
                list.push(data)
            } else {
                let index = list.indexOf(data);
                list.splice(index, 1)
            }

            setLevel(list)
        }
    }

    return (
        <>
            {open &&
                <div className="all-center tx-lt-black tutor-dash popup-modal" ref={outSide} id="outSide" onClick={(e)=>{helper.handleOutSide(e,outSide.current.id,()=>close())}}>
                    <div className={`left-popup ${reduxState.language === 'ar' && "text-right"} p-fix tp-0 rt-0 p-5 sm`}>
                        <h4 className="f-fred mb-0">Filter <span className="tx-green">courses</span><span className="float-right fs-24 tx-grey pointer" onClick={close}><i className="far fa-times-circle hover-shadow"></i></span></h4>
                        <p className='sub-heading'>SayDhad course</p>

                        <h5 className='f-fred mt-3 mb-2  tx-lt-grey'>Via categories</h5>

                        <section className='fs-14  tx-lt-grey ms-500'>
                            {categories.map((data, index) => {
                                return (
                                    <div key={`cat-filter-${index}`} className='mb-2 d-flex justify-content-between'>
                                        <label htmlFor={`cat-${index}`} className='pointer'>{data}</label>
                                        <input type="checkbox" defaultChecked={category.includes(data)} onChange={(e) => { select(data, "cat") }} id={`cat-${index}`} />
                                    </div>
                                )
                            })}
                        </section>

                        <h5 className='f-fred mt-3 mb-2  tx-lt-grey'>Via course level</h5>

                        <section className=' fs-14  tx-lt-grey ms-500'>

                            <div className='d-flex justify-content-between mb-2'>
                                <label htmlFor={`level-1`} className='pointer'>Basic</label>
                                <input type="checkbox" defaultChecked={level.includes("Basic")} onChange={(e) => { select("Basic", "level") }} id={`level-1`} />
                            </div>
                            <div className='d-flex justify-content-between mb-2'>
                                <label htmlFor={`level-2`} className='pointer'>Intermediate</label>
                                <input type="checkbox" defaultChecked={level.includes("Intermediate")} onChange={(e) => { select("Intermediate", "level") }} id={`level-2`} />
                            </div>
                            <div className='d-flex justify-content-between mb-2'>
                                <label htmlFor={`level-3`} className='pointer'>Advanced</label>
                                <input type="checkbox" defaultChecked={level.includes("Advance")} onChange={(e) => { select("Advance", "level") }} id={`level-3`} />
                            </div>

                        </section>
                        <section className='d-flex align-items-center jsutify-content-between'>
                            <button className='btn btn-login  my-3 mx-2 w-100' onClick={() => cb({
                                categories: category,
                                level: level
                            })}>APPLY FILTERS</button>
                            <button className='btn cancel-subscription f-fred w-100 ls fs-14 my-3 mx-2'
                                onClick={() =>{cb({
                                    categories: [],
                                    level: []
                                });setCategory([]);setLevel([])}}>CLEAR FILTERS</button>
                        </section>

                    </div>
                </div>
            }
        </>
    )
}
