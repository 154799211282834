import React from 'react'
import parrot from '../static/images/parrot.svg'
const styles = {
    zIndex: 10,
    // backgroundColor: '#727272cc',
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
}

export default function Backdrop() {
    return (
        <div className="custom-backdrop all-center" style={styles}>
            <img className="p-anim" width="80px" src={parrot} alt="parrot" />
            
        </div>
    )
}
