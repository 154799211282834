/* eslint-disable */
import React, { useState, useEffect } from 'react'
import logo from '../static/images/Logo.svg'
import userParrot from '../static/images/parrot.svg'
import { useHistory } from 'react-router'
import LittleMore from './LittleMore'
import helper from '../helper/index'
import { useTranslation } from "react-i18next";

export default function UserSignUpCheck() {
    const { t } = useTranslation();
    const [openLittleMore, setOpenLittleMore] = useState(false)
    const history = useHistory()

    useEffect(() => {
        const url = new URLSearchParams(window.location.search)
        try {
            let decEmail = helper.decrypt(url.get("tk1").toString().replace(/ /g, "+"))
            let decPass ;
            try{
                 decPass =  helper.decrypt(url.get("tk2").toString().replace(/ /g, "+"))
            }
            catch(err){}
            let isSocial = url.get("auth")
            if (!decEmail) {
                history.push('/signup')
            } else if (isSocial && (isSocial == "google" || isSocial == "facebook")) { }
            else if (!decPass) {
                history.push('/signup')
            }
        }
        catch (err) {
            history.push('/signup')
        }

        return () => { }
         //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
 
    return (
        <>
            <div className="p-fix tp-0 pl-4">
                <button className="btn btn-tutor-profile fs-12 p-rel mt-3 mb-2 mr-3" style={{ width: '150px',zIndex:3 }} onClick={() => { !openLittleMore ? history.goBack() : setOpenLittleMore(false) }}>{t('userOnBoard.goback')}</button>
            </div>
            <div className="tutor-login-home all-center hide-scroll">
                {
                    !openLittleMore &&
                    <div className="row gx-0 mx-0 tx-lt-black">
                        <div className="col-lg-5 col-md-8 col-12 px-3 mx-auto all-center flex-column">
                            <div className="all-center mb-3 " >
                                <img width="80%" src={logo} alt="logo" />
                            </div>
                            <div>
                                <p className="f-fred my-2 text-center fs-32">{t('userOnBoard.littleMore.heading')}</p>
                                <p className="ms-500 tx-lt-grey text-center ">{t('userOnBoard.littleMore.para')}</p>
                                <p className="ms-900 fs-24 mt-3 tx-lt-grey text-center">{t('userOnBoard.littleMore.text1')} <span className="tx-green">{t('userOnBoard.littleMore.text2')}</span> {t('userOnBoard.littleMore.text3')}</p>
                            </div>
                            <button className="btn btn-login mt-4 box-height" style={{ width: '200px',zIndex:2 }} onClick={() => setOpenLittleMore(true)}>{t('userOnBoard.littleMore.start')}</button>
                            <div className="bottom-parrot d-lg-block d-md-block d-none">
                                <img src={userParrot} alt="parrot" />
                            </div>
                        </div>
                    </div>}
                {
                    openLittleMore && <LittleMore close={() => setOpenLittleMore(false)} />
                }

            </div>
        </>
    )
}
