import React from 'react'
import parot from '../static/images/logo-parot.png'
import helper from '../helper'
import { constant } from '../config/Contant'
import SvgChat from '../Icons/Chat'
import SvgDashboard from '../Icons/Dashboard'
import SvgStudent from '../Icons/Student'
import SvgLibrary from '../Icons/Library'
import SvgSupport from '../Icons/Support'
import SvgStudents from '../Icons/Students'
import SvgPayout from '../Icons/Payout'
import { FaPortrait } from 'react-icons/fa'
import { Badge, Tooltip } from '@mui/material'
import { useTranslation } from "react-i18next";
const URL = constant.LIVE_URL

export default function LeftStrip({ count }) {
    const { t } = useTranslation();
    return (
        <>
            {
                helper.getRoleType() === "user" ?
                    <section className="h-100  d-lg-block d-md-block d-none" style={{ width: '50px' }}>
                        <div className="left-dash text-dark">
                            <div className="all-center overlay-cover my-2">
                                <img width="40px" className="pointer" src={parot} alt="saydhad" />
                                <div className="p-abs overlay-text px-2 py-1" >
                                    {t('userDash.sidebar.saydhad')}
                                </div>
                            </div>
                            <ul className="mt-3 list-unstyled">
                                <li className={`all-center mb-2`}>
                                    <Tooltip title={t('userDash.sidebar.option1')} >
                                        <p className="f-fred text-upper"><a rel="noopener noreferrer" href={`${URL}/user/dashboard/home`} target='_blank'><SvgDashboard className="inactive-icon-color" /></a> </p>
                                    </Tooltip>
                                </li>
                                <li className={`overlay-cover all-center mb-2`}>
                                    <Tooltip title={t('userDash.sidebar.option2')} >
                                        <p className="f-fred text-upper"><a rel="noopener noreferrer" href={`${URL}/user/dashboard/tutor`} target='_blank'><SvgStudent className="inactive-icon-color" /></a></p>
                                    </Tooltip>
                                </li>
                                <li className={` overlay-cover all-center mb-2`}>
                                    <Tooltip title={t('userDash.sidebar.option3')}>
                                        <p className="f-fred text-upper"><a rel="noopener noreferrer" href={`${URL}/user/dashboard/course`} target='_blank'><SvgLibrary className="inactive-icon-color" /></a></p>
                                    </Tooltip>

                                </li>
                                <li className={`overlay-cover all-center mb-2`}>
                                    <Tooltip title={t('userDash.sidebar.option4')}>
                                        <p className="f-fred text-upper"><a rel="noopener noreferrer" href={`${URL}/user/dashboard/calendar`} target='_blank'><SvgStudent className="inactive-icon-color" /></a></p>
                                    </Tooltip>
                                </li>
                                <li className={`overlay-cover all-center mb-2`}>
                                    <Tooltip title={t('userDash.sidebar.option5')}>
                                        <p className="f-fred text-upper"><a rel="noopener noreferrer" href={`${URL}/user/dashboard/myprogress`} target='_blank'><SvgLibrary className="inactive-icon-color" /></a></p>
                                    </Tooltip>
                                </li>
                                <li className={`overlay-cover all-center mb-2`}>
                                    <Tooltip title={t('userDash.sidebar.option6')}>
                                        <p className="f-fred text-upper">
                                            <Badge badgeContent={count} color="primary">
                                                <a rel="noopener noreferrer" href={`${URL}/user/dashboard/chat`} target='_blank'><SvgChat className="inactive-icon-color" /></a>
                                            </Badge>
                                        </p>
                                    </Tooltip>
                                </li>
                                <li className={`overlay-cover all-center mb-3`}>
                                    <Tooltip title={t('userDash.sidebar.option7')} >
                                        <p className="f-fred text-upper"><a rel="noopener noreferrer" href={`${URL}/user/dashboard/support`} target='_blank'><SvgSupport className="inactive-icon-color" /></a></p>
                                    </Tooltip>
                                </li>
                            </ul>
                        </div>
                    </section> :
                    <section className="h-100  d-lg-block d-md-block d-none" style={{ width: '50px' }}>
                        <div className="left-dash">
                            <div className="all-center overlay-cover my-2">
                                <Tooltip title={t('userDash.sidebar.saydhad')} >
                                    <img width="40px" className="pointer" src={parot} alt="saydhad" />
                                </Tooltip>

                            </div>
                            <ul className="mt-3 list-unstyled">
                                <li className={`overlay-cover all-center mb-2`}>
                                    <Tooltip title={t('tutorDash.sidebar.option1')} >
                                        <p className="f-fred text-upper"><a rel="noopener noreferrer" href={`${URL}/tutor/dashboard/home`} target='_blank'><SvgDashboard className="inactive-icon-color" /></a> </p>
                                    </Tooltip>
                                </li>
                                <li className={`overlay-cover all-center mb-2`}>
                                    <Tooltip title={t('tutorDash.sidebar.option2')} >
                                        <p className="f-fred text-upper"><a rel="noopener noreferrer" href={`${URL}/tutor/dashboard/calendar`} target='_blank'><SvgStudent className="inactive-icon-color" /></a></p>
                                    </Tooltip>
                                </li>
                                <li className={` overlay-cover all-center mb-2`}>
                                    <Tooltip title={t('tutorDash.sidebar.option3')} >
                                        <p className="f-fred text-upper"><a rel="noopener noreferrer" href={`${URL}/tutor/dashboard/library`} target='_blank'><SvgLibrary className="inactive-icon-color" /></a></p>
                                    </Tooltip>

                                </li>
                                <li className={`overlay-cover all-center mb-2`}>
                                    <Tooltip title={t('tutorDash.sidebar.option4')} >
                                        <p className="f-fred text-upper"><a rel="noopener noreferrer" href={`${URL}/tutor/dashboard/mystudent`} target='_blank'><SvgStudents className="inactive-icon-color" /></a></p>
                                    </Tooltip>


                                </li>
                                <li className={`overlay-cover all-center mb-2`}>
                                    <Tooltip title={t('tutorDash.sidebar.option5')} >
                                        <p className="f-fred text-upper">
                                            <Badge badgeContent={count} color="primary">
                                                <a rel="noopener noreferrer" href={`${URL}/tutor/dashboard/mychat`} target='_blank'><SvgChat className="inactive-icon-color" /></a>
                                            </Badge>
                                        </p>
                                    </Tooltip>
                                </li>
                                <li className={` overlay-cover all-center mb-2`}>
                                    <Tooltip title={t('tutorDash.sidebar.option6')} >
                                        <p className="f-fred text-upper"><a rel="noopener noreferrer" href={`${URL}/tutor/dashboard/payout`} target='_blank'><SvgPayout className="inactive-icon-color" /></a></p>
                                    </Tooltip>
                                </li>
                                <li className={` overlay-cover all-center mb-2`}>
                                    <Tooltip
                                        title={t('tutorDash.sidebar.option7')} >
                                        <p className="f-fred text-upper mb-2">
                                        <a rel="noopener noreferrer" href={`${URL}/tutor/dashboard/attendance`} className="d-flex  justify-content-center" target='_blank'>  <FaPortrait size={24} className="inactive-icon-color" /></a></p>
                                    </Tooltip>
                                </li>
                                <li className={`overlay-cover all-center mb-2`}>
                                    <Tooltip title={t('tutorDash.sidebar.option8')} >
                                        <p className="f-fred text-upper"> <a rel="noopener noreferrer" href={`${URL}/tutor/dashboard/support`} target='_blank'><SvgSupport className="inactive-icon-color" /></a></p>
                                    </Tooltip>
                                </li>
                            </ul>
                        </div>
                    </section>
            }

        </>
    )
}
