import React, { useState, useEffect } from 'react'
import ApexChart from './ApexChart'
import "../static/css/userDash.css"
import parrot from '../static/images/parrot-1.png'
import UserReferPopup from '../component/UserReferPopup'
import { useHistory } from 'react-router-dom'
import user from '../controller/user'
import helper from '../helper'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Backdrop from '../component/Backdrop'
import banner from "../static/images/banner.png";
import store from '../redux/AppState'
import { useTranslation } from "react-i18next";

function UserHome() {
    const { t } = useTranslation();
    const storeData = useSelector(state => state)
    const history = useHistory()
    const [isOpen, setIsOpen] = useState(false);
    const [pageload, setPageload] = useState(true)
    const [resData, setResData] = useState()
    const [week, setWeek] = useState([])
    const [value, setValue] = useState([])
    useEffect(() => {
        getAnalytics()
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeData.calendarDate])

    const getAnalytics = async () => {
        setPageload(true)
        let date = moment(storeData.calendarDate).format('YYYY-MM-DD')
        await user.getAnalytics(helper.getid(), date).then((res) => {
            if (res.data.status) {
                setResData(res.data.result)
                let list = []
                let list2 = []
                let map = res.data.result.learnAnalytics[0]
                for (const [key, value] of Object.entries(map)) {
                    list.push(key)
                    list2.push(value)
                }
                setWeek(list)
                setValue(list2)
            }
            setPageload(false)
        })
    }
    return (
        <> {!pageload &&
            <div className={`user-home ${storeData.language === 'ar' && "text-right"}`}>
                <section className="row gx-0 mx-0 analytic ">
                    <div className="col-lg-4 col-12 px-1 px-2 mb-2 pb-2 h-100">
                        <div className="col-12 f-fred">
                            <h4 className="mb-2 fs-30"> {t('userDash.banner1.heading')}  </h4>
                        </div>

                        <div className="row gx-0 mx-0" style={{ backgroundColor: "#F5F5F5", borderRadius: "10px" }}>
                            <div className="col-lg-8 col-8 d-flex align-items-center justify-content-center f-fred fs-14 "><h1 style={{ fontSize: '80px' }}> {resData ? resData.totalMinutesLeft.totalMinutes : 0} </h1> <span className='f-fred fs-24' style={{ transform: 'rotate(90deg)' }}> min</span> </div>
                            <div className="col-lg-4 col-4 d-flex justify-content-end"><img className='w-100' src={parrot} alt="parrot" /></div>
                        </div>

                        <div className="row mb-2 f-fred mt-2 ">
                            <div className="col-lg-4 col-md-6 col-6 p-1 " >
                                <div className="d-flex flex-column h-100 align-items-center justify-content-center " style={{ backgroundColor: "#089D12", color: "#035D0A", borderRadius: "10px" }} >
                                    <p style={{ fontSize: '42px' }} className="ms-500 text-white">{resData ? resData.totalMinutesLeft.totMinutesConsumed <= 0 ? 0 : resData.totalMinutesLeft.totMinutesConsumed : 0}</p>
                                    <p className="text-center fs-12 ms-900 px-1 lh-15">{t('general.minConsumed')}</p>
                                    <p className="text-center fs-12 ms-900 px-1 lh-15 mb-2"> {t('general.tillNow')}</p>
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-6 col-6  p-1 " >
                                <div className="d-flex flex-column h-100 align-items-center justify-content-center " style={{ backgroundColor: "lightpink", color: "#FF5757", borderRadius: "10px" }}>
                                    <p style={{ fontSize: '42px' }} className='ms-500'>{resData ? resData.totalMinutesLeft.leftDays : 0}</p>
                                    <p className="text-center fs-12 ms-900 px-1 lh-15"> {t('general.days')} </p>
                                    <p className="text-center fs-12 ms-900 px-1 lh-15">{t('general.left')}</p>

                                </div>
                            </div>
                            {resData && resData.totalMinutesLeft.subscription && <div className="col-lg-4 d-lg-block d-md-block d-none col-md-6 col-6 p-1">
                                <div className="d-flex flex-column align-items-center justify-content-center h-100" style={{ backgroundColor: "#ffcb00", color: "white", borderRadius: "10px" }}>
                                    <p style={{ fontSize: '20px', lineHeight: '27px' }} className=" text-center ms-500">{resData.totalMinutesLeft.subscription}</p>

                                </div>
                            </div>}
                            <div className="col-lg-12 col-md-6 col-12 p-1">
                                <button className="btn btn-login  w-100  " onClick={() => setIsOpen(true)}>{t('userDash.banner1.refer')} </button>

                            </div>
                        </div>



                    </div>


                    <div className="col-lg-4 col-md-6 col-12 px-1 mb-2 ">
                        <div className="analytic-data br d-flex flex-column justify-content-between px-3 py-2 h-100">
                            <section className='d-flex justify-content-between'>
                                <div>
                                    <p className="ms-900 fs-18">{t('userDash.banner2.heading')} </p>
                                    <p className="ms-700 fs-14 tx-lt-black">{moment(storeData.calendarDate).format('MMMM YYYY')}</p>
                                </div>
                                <div className='tx-lt-black f-fred fs-14 tutor-dash' style={{ height: 'initial' }}>
                                    <input type="month" style={{ width: '150px' }}
                                        defaultValue={moment(storeData.calendarDate).format('YYYY-MM')}

                                        className='input-box px-2 py-1'
                                        onChange={(e) => { store.setCalendarDate(moment(e.target.value).format('YYYY-MM-DD')) }}
                                    />
                                </div>
                            </section>
                            <ApexChart week={week} data={value} />
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-12 mb-2 ">
                        <div className="analytic-data px-3 pt-2 h-100">
                            <p className="fs-18 ms-900">{t('userDash.banner3.heading')} </p>
                            <p className="ms-700 fs-14 tx-lt-black">{t('userDash.banner3.subheading')} </p>
                            <div className="row gx-0 mx-0 mt-2 ">
                                <div className="col-6 px-1 mb-2 ">
                                    <p className="tx-lt-black-op-5 ms-900">{t('userDash.banner3.sec1')} </p>
                                    <p style={{ fontSize: '40px' }} className="ms-500 pointer" onClick={() => history.push('/user/dashboard/course')}>{resData ? resData.statsAndAnalytics.enrolled_courses : 0}</p>
                                </div>
                                <div className="col-6 px-1 mb-2 ">
                                    <p className="tx-lt-black-op-5 fs-14 ms-900">{t('userDash.banner3.sec2')}</p>
                                    <p style={{ fontSize: '40px' }} className="ms-500">{resData ? resData.statsAndAnalytics.total_tutors : 0}</p>
                                </div>
                                <div className="col-6 px-1 mb-2">
                                    <p className="tx-lt-black-op-5  ms-900">{t('userDash.banner3.sec3')}</p>
                                    <p style={{ fontSize: '40px' }} className="ms-500">{resData ? resData.statsAndAnalytics.total_calls : 0}</p>
                                </div>
                                <div className="col-6 px-1 mb-2">
                                    <p className="tx-lt-black-op-5 ms-900">{t('userDash.banner3.sec4')}</p>
                                    <p style={{ fontSize: '40px' }} className="ms-500">{resData ? resData.statsAndAnalytics.certificates : 0}</p>
                                </div>
                                <div className="col-6 px-1 mb-2">
                                    <p className="tx-lt-black-op-5  ms-900">{t('userDash.banner3.sec5')}</p>
                                    <p style={{ fontSize: '40px' }} className="ms-500 pointer" onClick={() => history.push('/user/dashboard/referral')}>{resData ? resData.statsAndAnalytics.referrals : 0}</p>
                                </div>
                                <div className="col-6 px-1 mb-2">
                                    <p className="tx-lt-black-op-5  ms-900">{t('userDash.banner3.sec6')}</p>
                                    <p style={{ fontSize: '40px' }} className="ms-500 pointer" onClick={() => history.push('/user/dashboard/myprogress')}>{resData ? resData.statsAndAnalytics.attendance.toString().replace(/ /g, '') : 0}</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

                <section className="banner-box  row gx-0 mx-0 mt-4">
                    <div className={`${storeData.language === 'ar' && "text-right"} col-lg-7 col-md-7 col-12 py-4 px-4 d-flex flex-column justify-content-center`}>
                        <h5 className="f-fred fs-32">{t('userDash.banner4.title')}</h5>
                        <p className="mb-3 mt-2 fs-18 ms-500" style={{ color: '#434943', lineHeight: '24px' }}>
                            {t('userDash.banner4.body')}
                        </p>
                        <div>
                            <button className="btn btn-login mx-2 mb-2" onClick={() => history.push('/user/dashboard/tutor')} style={{ width: '193px' }}>{t('userDash.banner4.button1')}</button>
                            <button className="btn btn-tutor-profile mb-2 mx-2" onClick={() => history.push('/user/dashboard/course')} style={{ width: '193px' }}>{t('userDash.banner4.button2')}</button>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-12">
                        <img className="w-100 h-100" style={{ borderRadius: '250px 20px 20px 0px', maxHeight: '100%' }} src={banner} alt="banner" />
                    </div>
                </section>
                <section className="banner-bottom mx-auto mb-3"></section>

            </div>}
            {pageload && <Backdrop />}
            {isOpen && <UserReferPopup code={resData && resData.referral_code} handleClose={() => setIsOpen(false)} />}
           
        </>
    )
}

export default UserHome
